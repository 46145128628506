const BellOutlinedIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='22'
			height='28'
			viewBox='0 0 22 28'
			fill='none'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M21.0696 17.8298C22.5323 19.5482 21.1407 22.0003 18.7029 22.0003H3.29707C0.859225 22.0003 -0.532344 19.5482 0.930361 17.8298C1.5779 17.0691 1.97696 16.1668 2.08435 15.2207L2.6806 9.96724C3.00211 7.1345 5.11686 4.80992 7.97339 3.83187V3.69359C7.97339 2.02204 9.32844 0.666992 11 0.666992C12.6715 0.666992 14.0266 2.02204 14.0266 3.69359V3.83187C16.8831 4.80992 18.9979 7.1345 19.3194 9.96724L19.9156 15.2207C20.023 16.1668 20.4221 17.0691 21.0696 17.8298ZM14.9628 24.7624C14.3529 26.2659 12.8087 27.3337 11 27.3337C9.19129 27.3337 7.64702 26.2659 7.03719 24.7624C7.01175 24.6997 6.99998 24.6323 6.99998 24.5647C6.99998 24.2528 7.25279 24 7.56464 24H14.4353C14.7472 24 15 24.2528 15 24.5647C15 24.6323 14.9882 24.6997 14.9628 24.7624Z'
				fill='black'
			/>
		</svg>
	);
};

export { BellOutlinedIcon };
