import { createSlice } from "@reduxjs/toolkit";
import { TAddressState } from "../../types/address";
import { getAddressByPincode, getAddressListByCompany } from "../services/address";
import { SliceNames } from "../../shared/constants/constant";

const initialState: TAddressState = {
	isLoading: false,
	isSuccess: false,
	address: null,
	addressError: null,
	message: "",
	addressList: {
		isLoading: false,
		isSuccess: false,
		message: "",
		data: null
	}
};

const addressSlice = createSlice({
	name: SliceNames.ADDRESS,
	initialState,
	reducers: {
		clearAddressData: state => {
			state.address = null;
			state.addressError = null;
			state.addressList = {
				isLoading: false,
				isSuccess: false,
				message: "",
				data: null
			};
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getAddressByPincode.pending, state => {
				state.isLoading = true;
			})
			.addCase(getAddressByPincode.fulfilled, (state, { payload }: any) => {
				state.isSuccess = true;
				state.isLoading = false;
				state.address = payload?.data;
				state.message = payload?.message;
				state.addressError = null;
			})
			.addCase(getAddressByPincode.rejected, (state, { payload }: any) => {
				state.addressError = payload;
				state.address = payload;
				state.isSuccess = false;
				state.isLoading = false;
				state.message = payload?.message;
			})
			.addCase(getAddressListByCompany.pending, state => {
				state.addressList.isLoading = true;
				state.addressList.isSuccess = false;
			})
			.addCase(getAddressListByCompany.fulfilled, (state, { payload }: any) => {
				state.addressList.isSuccess = true;
				state.addressList.isLoading = false;
				state.addressList.data = payload?.data;
				state.addressList.message = payload?.message;
			})
			.addCase(getAddressListByCompany.rejected, (state, { payload }: any) => {
				state.addressList.data = null;
				state.addressList.isSuccess = false;
				state.addressList.isLoading = false;
				state.addressList.message = payload?.message;
			});
	}
});

export const { clearAddressData } = addressSlice.actions;
export default addressSlice.reducer;
