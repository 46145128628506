const DeleteOutlinedIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2957_4118)">
        <path
          d="M3.83333 5V11.6667C3.83333 13.1394 5.02724 14.3333 6.5 14.3333H10.5C11.9728 14.3333 13.1667 13.1394 13.1667 11.6667V5M9.83333 7V11M7.16667 7L7.16667 11M11.1667 3L10.2292 1.59373C9.98187 1.2228 9.56556 1 9.11975 1H7.88025C7.43444 1 7.01813 1.2228 6.77084 1.59373L5.83333 3M11.1667 3H5.83333M11.1667 3H14.5M5.83333 3H2.5"
          stroke="#F5222D"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2957_4118">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export { DeleteOutlinedIcon };
