import React, { memo, useEffect, useRef, useState } from "react";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import { BulkProductData, ProductSizeModal, unitsData } from "../../types/productsType";
import Limits from "../../utils/limits.json";

interface TableRowProps {
	product: BulkProductData;
	productIndex: number;
	onInputChange: (
		productIndex: number,
		sizeIndex: number | null,
		field: string,
		value: string
	) => void;
	inputRefs: (HTMLInputElement | string)[][];
	row: number;
	size: ProductSizeModal;
	sizeIndex: number;
	handleFocus: (number: number) => void;
	handleEnterKey: (e: React.KeyboardEvent, rowIndex: number, colIndex: number) => void;
	productUnits: unitsData[];
}

type dropDownState = {
	selectedValue: string;
	filteredOptions: unitsData[];
	isDropdownOpen: boolean;
};

const MemoizedTooltip = memo(({ errors }: { errors?: string[] }) => {
	if (!errors || errors.length === 0) return null;
	return (
		<Tooltip
			title={errors.join(", ")}
			arrow
			className='error-tooltip'
			destroyTooltipOnHide
			getPopupContainer={() => document.body} // Optimized placement
		>
			<InfoCircleOutlined />
		</Tooltip>
	);
});

MemoizedTooltip.displayName = "MemoizedTooltip";

const ProductSizeToolTip = memo(
	({
		sizeError,
		productSizeUnit
	}: {
		sizeError: string[];
		productSizeUnit: string[];
	}) => {
		const [visible, setVisible] = useState(false);

		if (sizeError?.length === 0 && productSizeUnit?.length === 0) return null;
		return (
			<Tooltip
				title={
					<>
						{sizeError?.length > 0 && (
							<p>Product Size : {sizeError?.join(", ")}</p>
						)}
						{productSizeUnit?.length > 0 && (
							<p>Product Size Unit : {productSizeUnit?.join(", ")}</p>
						)}
					</>
				}
				arrow
				className='error-tooltip'
				destroyTooltipOnHide
				visible={visible}
				onVisibleChange={setVisible}
				getPopupContainer={() => document.body} // Optimized placement
			>
				<InfoCircleOutlined />
			</Tooltip>
		);
	}
);

ProductSizeToolTip.displayName = "ProductSizeToolTip";

const BulkImportTableRow: React.FC<TableRowProps> = ({
	product,
	productIndex,
	onInputChange,
	inputRefs,
	sizeIndex,
	row,
	size,
	handleFocus,
	handleEnterKey,
	productUnits
}) => {
	const [sizeUnitState, setSizeUnitState] = useState<dropDownState>({
		selectedValue: size.productSizeUnit.value,
		filteredOptions: [] as unitsData[],
		isDropdownOpen: false
	});

	const [orderUnitState, setOrderUnitState] = useState<dropDownState>({
		selectedValue: size.orderUnit.value,
		filteredOptions: [] as unitsData[],
		isDropdownOpen: false
	});

	const sizeDropdownRef = useRef<HTMLDivElement>(null);
	const orderDropdownRef = useRef<HTMLDivElement>(null);

	const handleSelectInputChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		stateSetter: React.Dispatch<React.SetStateAction<dropDownState>>,
		key: string
	) => {
		const searchValue = e?.target?.value?.toLowerCase();
		stateSetter(prev => ({
			...prev,
			selectedValue: e?.target?.value,
			isDropdownOpen: true,
			filteredOptions: productUnits?.filter(option =>
				`${option.label} - ${option.value}`?.toLowerCase()?.includes(searchValue)
			)
		}));
		if (!searchValue) {
			onInputChange(productIndex, sizeIndex, key, "");
		}
	};

	const handleOptionSelect = (
		selectedValue: string,
		key: string,
		stateSetter: React.Dispatch<React.SetStateAction<dropDownState>>
	) => {
		stateSetter(prev => ({ ...prev, selectedValue, isDropdownOpen: false }));
		onInputChange(productIndex, sizeIndex, key, selectedValue);
	};

	useEffect(() => {
		const handleClickOutside = (e: MouseEvent) => {
			if (sizeUnitState.isDropdownOpen || orderUnitState.isDropdownOpen) {
				const sizeRef = sizeDropdownRef?.current;
				const orderRef = orderDropdownRef?.current;

				if (
					sizeRef &&
					!sizeRef.contains(e.target as Node) &&
					orderRef &&
					!orderRef.contains(e.target as Node)
				) {
					setSizeUnitState(prev =>
						prev.isDropdownOpen ? { ...prev, isDropdownOpen: false } : prev
					);
					setOrderUnitState(prev =>
						prev.isDropdownOpen ? { ...prev, isDropdownOpen: false } : prev
					);
				}
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, [sizeUnitState.isDropdownOpen, orderUnitState.isDropdownOpen]);

	const handleFocusOut = (e: React.FocusEvent) => {
		if (sizeUnitState.isDropdownOpen || orderUnitState.isDropdownOpen) {
			// setTimeout(() => {
			const sizeRef = sizeDropdownRef?.current;
			const orderRef = orderDropdownRef?.current;
			if (
				(sizeRef && !sizeRef.contains(e.target as Node)) ||
				(orderRef && !orderRef.contains(e.target as Node))
			) {
				setSizeUnitState(prev =>
					prev.isDropdownOpen ? { ...prev, isDropdownOpen: false } : prev
				);
				setOrderUnitState(prev =>
					prev.isDropdownOpen ? { ...prev, isDropdownOpen: false } : prev
				);
			}
			// }, 0);
		}
	};

	return (
		<tr className={` ${sizeIndex === 0 ? "border-t" : ""}`}>
			{sizeIndex === 0 ? (
				<td className='border px-4 py-2 text-center bg-gray-100'>
					<input
						className={`input ${product?.productName?.isError ? "error" : ""}`}
						value={product?.productName?.value}
						onChange={e =>
							onInputChange(
								productIndex,
								null,
								"productName",
								e?.target?.value
							)
						}
						ref={el => {
							if (el) {
								inputRefs[row] = inputRefs?.[row] || [];
								inputRefs[row][0] = el;
							}
						}}
						onKeyDown={e => handleEnterKey(e, row, 0)}
						onFocus={() => handleFocus(row * 5 + 0)}
						maxLength={Limits?.productName}
					/>
					<MemoizedTooltip errors={product?.productName?.errorMessages} />
				</td>
			) : (
				<td
					className='empty'
					ref={el => {
						if (el) {
							inputRefs[row] = inputRefs?.[row] || [];
							inputRefs[row][0] = "";
						}
					}}
				></td>
			)}

			<td>
				<div
					className={`input select-input  ${
						size?.productSize?.isError || size?.productSizeUnit?.isError
							? "error"
							: ""
					}`}
				>
					<input
						className={`w-100  ${size?.productSize?.isError ? "error" : ""}`}
						value={size?.productSize?.value}
						onChange={e =>
							onInputChange(
								productIndex,
								sizeIndex,
								"productSize",
								e?.target?.value
							)
						}
						ref={el => {
							if (el) {
								inputRefs[row] = inputRefs?.[row] || [];
								inputRefs[row][1] = el;
							}
						}}
						onKeyDown={e => handleEnterKey(e, row, 1)}
						onFocus={() => handleFocus(row * 5 + 1)}
						maxLength={Limits?.productSize}
					/>
					<div ref={sizeDropdownRef} className='w-100' onBlur={handleFocusOut}>
						<input
							className={`w-100 dropDown-input  ${
								size?.productSizeUnit?.isError ? "error" : ""
							}`}
							value={sizeUnitState?.selectedValue}
							onChange={e =>
								handleSelectInputChange(
									e,
									setSizeUnitState,
									"productSizeUnit"
								)
							}
							ref={el => {
								if (el) {
									inputRefs[row] = inputRefs?.[row] || [];
									inputRefs[row][2] = el;
								}
							}}
							onKeyDown={e => handleEnterKey(e, row, 2)}
							onFocus={() => {
								setSizeUnitState(prev => ({
									...prev,
									isDropdownOpen: true,
									filteredOptions: productUnits?.filter(option =>
										`${option.label} - ${option.value}`
											.toLowerCase()
											.includes(
												prev?.selectedValue
													?.toString()
													?.toLocaleLowerCase()
											)
									)
								}));
								handleFocus(row * 5 + 2);
							}}
						/>

						{sizeUnitState?.isDropdownOpen && (
							<ul className=' dropDown'>
								{sizeUnitState?.filteredOptions?.length > 0 ? (
									sizeUnitState?.filteredOptions.map(option => (
										<li
											key={option.value}
											className='px-4 py-2 cursor-pointer '
											onClick={() =>
												handleOptionSelect(
													`${option.label} - ${option.value}`,
													"productSizeUnit",
													setSizeUnitState
												)
											}
											onMouseDown={e => e.preventDefault()}
										>
											{option?.label} - {option?.value}
										</li>
									))
								) : (
									<li className='px-4 py-2 text-gray-500'>
										Select valid Size unit
									</li>
								)}
							</ul>
						)}
					</div>
				</div>
				<ProductSizeToolTip
					productSizeUnit={size?.productSizeUnit?.errorMessages ?? []}
					sizeError={size?.productSize?.errorMessages ?? []}
				/>
			</td>

			<td>
				<input
					className={`input ${size?.productSizeRate?.isError ? "error" : ""}`}
					value={size?.productSizeRate?.value}
					onChange={e => {
						const value = e?.target?.value;

						if (/^\d*\.?\d*$/.test(value) || value === "") {
							onInputChange(
								productIndex,
								sizeIndex,
								"productSizeRate",
								value
							);
						}
					}}
					ref={el => {
						if (el) {
							inputRefs[row] = inputRefs?.[row] || [];
							inputRefs[row][3] = el;
						}
					}}
					maxLength={Limits?.productRate}
					inputMode='decimal'
					pattern='[0-9]*[.]?[0-9]*'
					onKeyDown={e => handleEnterKey(e, row, 3)}
					onFocus={() => handleFocus(row * 5 + 3)}
				/>
				<MemoizedTooltip errors={size?.productSizeRate?.errorMessages} />
			</td>
			<td>
				<div
					className={`input select-input ${
						size?.orderUnit?.isError ? "error" : ""
					}`}
					ref={orderDropdownRef}
					onBlur={handleFocusOut}
				>
					<input
						className={`w-100 ${size?.orderUnit?.isError ? "error" : ""}`}
						value={orderUnitState?.selectedValue}
						onChange={e =>
							handleSelectInputChange(e, setOrderUnitState, "orderUnit")
						}
						ref={el => {
							if (el) {
								inputRefs[row] = inputRefs?.[row] || [];
								inputRefs[row][4] = el;
							}
						}}
						onKeyDown={e => handleEnterKey(e, row, 4)}
						onFocus={() => {
							setOrderUnitState(prev => ({
								...prev,
								isDropdownOpen: true,
								filteredOptions: productUnits?.filter(option =>
									`${option.label} - ${option.value}`
										.toLowerCase()
										.includes(
											prev?.selectedValue
												?.toString()
												?.toLocaleLowerCase()
										)
								)
							}));
							handleFocus(row * 5 + 4);
						}}
					/>
					{orderUnitState.isDropdownOpen && (
						<ul className=' dropDown'>
							{orderUnitState?.filteredOptions?.length > 0 ? (
								orderUnitState?.filteredOptions?.map(option => (
									<li
										key={option?.value}
										className='px-4 py-2 cursor-pointer '
										onClick={() =>
											handleOptionSelect(
												`${option.label} - ${option.value}`,
												"orderUnit",
												setOrderUnitState
											)
										}
										onMouseDown={e => e.preventDefault()}
									>
										{option.label} - {option.value}
									</li>
								))
							) : (
								<li className='px-4 py-2 text-gray-500'>
									Select valid Size unit
								</li>
							)}
						</ul>
					)}
				</div>
				<MemoizedTooltip errors={size?.orderUnit?.errorMessages} />
			</td>
		</tr>
	);
};

export default memo(BulkImportTableRow);
