import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";

import endPoints from "./endPoints.json";
import axiosInstance from "../../middleware/axios";
import { POST } from "../../shared/services/api-service";

export const getNewOrders = createAsyncThunk(
	"orders/new",
	async (args, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(endPoints.getNewOrders);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const orderSeen = createAsyncThunk(
	endPoints.orderSeen,
	async (args, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(endPoints.orderSeen);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getCompanyOrderData = createAsyncThunk(
	endPoints.getCompanyOrderDetails,
	async (args: { customerId: number }, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(
				endPoints.getCompanyOrderDetails,
				args
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getOrdersData = createAsyncThunk(
	"orders/get",
	async (
		args: {
			type: number; //// 1 = Customer's Orders, 0 = my orders
			search: string;
			startDate: string;
			endDate: string;
			limit: number;
			offset: number;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await axiosInstance.post(endPoints.getOrdersData, args);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getOrderItemsByOrder = createAsyncThunk(
	"orders/productsByCustomer",
	async (args: number, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(
				`${endPoints.getOrderItemsByOrder}/${args}`
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const acceptOrRejectOrder = createAsyncThunk(
	"orders/acceptOrRejectOrder",
	async (
		args: {
			orderId: number;
			action: string;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await axiosInstance.patch(
				endPoints.acceptOrRejectOrder,
				args
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getOrderDetails = createAsyncThunk(
	"orders/getOrderDetails",
	async (orderId: number, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get(
				`${endPoints.getOrderDetails}/${orderId}`
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const updateOrderByViewer = createAsyncThunk(
	"orders/updateOrder",
	async (args: { orderId: number }, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.patch(
				endPoints.updateOrderByViewer,
				args
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const updateStatusByOrderId = createAsyncThunk(
	"status/update",
	async (
		args: { orderId: number; action: string; cancelReason?: string },
		{ rejectWithValue }
	) => {
		try {
			const response = await axiosInstance.patch(
				endPoints.updateStatusByOrderId,
				args
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const updateStatusByorderDispatchId = createAsyncThunk(
	"status/updateDispatchStatus",
	async (
		args: { orderDispatchId: number; action: string; cancelReason?: string },
		{ rejectWithValue }
	) => {
		try {
			const response = await axiosInstance.patch(
				endPoints.updateStatusByOrderDispatchId,
				args
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

interface dispatchDetailsType {
	orderId: number;
	transporterName?: string;
	trackingId?: string;
	pincode?: string;
	addressLine?: string;
	dispatchItems?: { orderItemId: number; quantity: number }[] | undefined;
}

export const orderDispatch = createAsyncThunk(
	"order/dispatch",
	async (
		args: {
			dispatchDetails: dispatchDetailsType;
			transportationAttachments?: File[];
		},
		{ rejectWithValue }
	) => {
		try {
			const formData = new FormData();
			args?.transportationAttachments?.forEach((file: string | Blob) => {
				formData.append("transportationAttachments", file);
			});
			formData.append("dispatchDetails", JSON.stringify(args.dispatchDetails));

			const response = await axiosInstance.post(endPoints.orderDispatch, formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			});
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const updateTransportation = createAsyncThunk(
	"order/addTransportation",
	async (
		args: {
			transportationDetails: dispatchDetailsType;
			transportationAttachments?: File[];
		},
		{ rejectWithValue }
	) => {
		try {
			const formData = new FormData();
			args?.transportationAttachments?.forEach((file: string | Blob) => {
				formData.append("transportationAttachments", file);
			});
			formData.append(
				"transportationDetails",
				JSON.stringify(args.transportationDetails)
			);

			const response = await axiosInstance.post(
				endPoints.updateTransportation,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data"
					}
				}
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getDispatchDetails = createAsyncThunk(
	endPoints.getDispatchDetails,
	async (orderId: number, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get(
				`${endPoints.getDispatchDetails}/${orderId}`
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getDispatchesList = createAsyncThunk(
	endPoints.getDispatchesList,
	async (orderId: number, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get(
				`${endPoints.getDispatchesList}/${orderId}`
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const fetchOrderCancleReasons = createAsyncThunk(
	"orders/fetchOrderCancleReasons",
	async (_, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get(endPoints.fetchOrderCancleReasons);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

const downloadReceiptApi = (
	orderId: number,
	value: {
		customerInfo: boolean;
		rate: boolean;
	}
) => {
	return POST({
		URL: `/${endPoints.orderReciptDownload}/${orderId}`,
		body: JSON.stringify(value),
		responseType: "blob"
	});
};

export { downloadReceiptApi };
