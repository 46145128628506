const BasketIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'
		>
			<path
				d='M7.34162 1.66797L4.32495 4.69297'
				stroke='black'
				strokeWidth='1.6'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M12.6583 1.66797L15.675 4.69297'
				stroke='black'
				strokeWidth='1.6'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M1.66666 6.54167C1.66666 5 2.49166 4.875 3.51666 4.875H16.4833C17.5083 4.875 18.3333 5 18.3333 6.54167C18.3333 8.33333 17.5083 8.20833 16.4833 8.20833H3.51666C2.49166 8.20833 1.66666 8.33333 1.66666 6.54167Z'
				stroke='black'
				strokeWidth='1.6'
			/>
			<path
				d='M8.13315 11.668V14.6263'
				stroke='black'
				strokeWidth='1.6'
				strokeLinecap='round'
			/>
			<path
				d='M11.9666 11.668V14.6263'
				stroke='black'
				strokeWidth='1.6'
				strokeLinecap='round'
			/>
			<path
				d='M2.91666 8.33203L4.09166 15.532C4.35832 17.1487 4.99999 18.332 7.38332 18.332H12.4083C15 18.332 15.3833 17.1987 15.6833 15.632L17.0833 8.33203'
				stroke='black'
				strokeWidth='1.6'
				strokeLinecap='round'
			/>
		</svg>
	);
};

export { BasketIcon };
