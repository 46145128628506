import { createSelector } from "@reduxjs/toolkit";
import { TRootState } from "../types/store";
import { getModuleRoleAccessData } from "../utils/helper";

const selectToken = (state: TRootState) => state.auth?.token;
const selectIsLoggedIn = (state: TRootState) => state.auth?.isLoggedIn;
const getPermissionData = (state: TRootState) =>
	state.accountSetting?.loggedInUserRole?.data?.activityAccess;

//INFO: auth object selector
const authObjectSelector = createSelector(
	[selectToken, selectIsLoggedIn],
	(token, isLoggedIn) => {
		return { authenticated: !!token && isLoggedIn };
	}
);
const accessPermissionSelector = createSelector(
	[
		getPermissionData,
		(_, parentKey: string) => parentKey,
		(_, __, childKeys: string) => childKeys
	],
	(permissionsData, parentKey, childPermissionKeys) => {
		return getModuleRoleAccessData(permissionsData, parentKey, childPermissionKeys);
	}
);

export { authObjectSelector, accessPermissionSelector };
