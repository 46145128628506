import { createSlice } from "@reduxjs/toolkit";
import { SliceNames } from "../../shared/constants/constant";
import { ProductListForGroup, TProductState } from "../../types/productsType";

import {
	bulkProductDataImport,
	bulkProductFileImport,
	customFieldSuggesion,
	getAllProductsForGroup,
	getListOfProducts,
	getListOfProductSizes,
	getPlaceOrderProductsGroups,
	getProductDetails,
	getProductListInGroup,
	getProductsGroups,
	getProductUnits,
	manageField,
	productNameSuggestion
} from "../services/products";

const initialState: TProductState = {
	isLoading: false,
	isSuccess: false,
	managementFields: null,
	productUnits: null,
	productsList: null,
	productGroups: {
		isLoading: false,
		data: null,
		isSuccess: false,
		grandTotal: 0,
		dataCount: 0
	},
	placeorderProductGroups: null,
	groupProductList: null,
	allProductsForGroup: null,
	allProductsForGroupLoading: false,
	selectedProductDetails: null,
	productSizes: null,
	error: "",
	suggestedField: {
		1: null,
		2: null
	},
	productNameSuggestionData: [],
	activeFilter: -1,

	bulkImport: {
		isLoading: false,
		dataImportLoading: false,
		isSuccess: false,
		isErrorCount: 0,
		data: []
	},
	groupId: 0
};

const productSlice = createSlice({
	name: SliceNames.PRODUCTS,
	initialState,
	reducers: {
		updateSelectedProductDetails: (state, { payload }) => {
			state.selectedProductDetails = payload;
		},
		clearSelectedProductDetails: state => {
			state.selectedProductDetails = initialState.selectedProductDetails;
		},
		clearSuggestion: state => {
			state.suggestedField = initialState.suggestedField;
		},
		clearProductNameSuggestion: state => {
			state.productNameSuggestionData = initialState.productNameSuggestionData;
		},
		clearProductData: state => {
			state.selectedProductDetails = initialState.selectedProductDetails;
			state.managementFields = initialState.managementFields;
			state.suggestedField = initialState.suggestedField;
		},
		clearProductSizeData: state => {
			state.productSizes = initialState.productSizes;
		},
		clearProductGroupsData: state => {
			state.placeorderProductGroups = initialState.placeorderProductGroups;
		},
		clearProductListGroup: state => {
			state.groupProductList = initialState.groupProductList;
		},
		setActiveFilter: (state, { payload }) => {
			state.activeFilter = payload;
		},
		resetBulkImportStateAction: state => {
			state.bulkImport = initialState?.bulkImport;
		},
		resetAllProductsForGroup: state => {
			state.allProductsForGroup = initialState?.allProductsForGroup;
		},
		updateGroupId: (state, { payload }) => {
			state.groupId = payload;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(manageField.pending, state => {
				state.isLoading = true;
			})
			.addCase(manageField.fulfilled, (state, { payload }: any) => {
				state.isSuccess = true;
				state.isLoading = false;
				state.managementFields = payload;
			})
			.addCase(manageField.rejected, (state, { payload }: any) => {
				state.error = payload;
				state.isSuccess = false;
				state.isLoading = false;
			})

			// Get Product Unit

			.addCase(getProductUnits.pending, state => {
				state.isLoading = true;
			})
			.addCase(getProductUnits.fulfilled, (state, { payload }: any) => {
				state.isSuccess = true;
				state.isLoading = false;
				state.productUnits = payload;
			})
			.addCase(getProductUnits.rejected, (state, { payload }: any) => {
				state.error = payload;
				state.isSuccess = false;
				state.isLoading = false;
			})

			// Product List

			.addCase(getListOfProducts.pending, state => {
				state.isLoading = true;
			})
			.addCase(getListOfProducts.fulfilled, (state, { payload }: any) => {
				state.isSuccess = true;
				state.isLoading = false;
				state.productsList = payload?.data;
			})
			.addCase(getListOfProducts.rejected, (state, { payload }: any) => {
				state.error = payload?.data;
				state.isSuccess = false;
				state.isLoading = false;
			})

			// Product Size List

			.addCase(getListOfProductSizes.pending, state => {
				state.isLoading = true;
			})
			.addCase(getListOfProductSizes.fulfilled, (state, { payload }: any) => {
				state.isSuccess = true;
				state.isLoading = false;
				state.productSizes = payload;
			})
			.addCase(getListOfProductSizes.rejected, (state, { payload }: any) => {
				state.error = payload?.data;
				state.isSuccess = false;
				state.isLoading = false;
			})

			// Product Group

			.addCase(getProductsGroups.pending, state => {
				state.productGroups.isLoading = true;
				state.productGroups.isSuccess = false;
			})
			.addCase(getProductsGroups.fulfilled, (state, { payload }: any) => {
				state.productGroups.isSuccess = true;
				state.productGroups.isLoading = false;
				state.productGroups.data = payload?.data?.productGroup;
				state.productGroups.grandTotal = payload?.data?.total ?? 0;
				state.productGroups.dataCount = payload?.data?.dataCount ?? 0;
			})
			.addCase(getProductsGroups.rejected, (state, { payload }: any) => {
				state.productGroups.isSuccess = false;
				state.productGroups.isLoading = false;
			})

			// getPlaceOrderProductsGroups

			.addCase(getPlaceOrderProductsGroups.pending, state => {
				state.isLoading = true;
			})
			.addCase(getPlaceOrderProductsGroups.fulfilled, (state, { payload }: any) => {
				state.isSuccess = true;
				state.isLoading = false;
				state.placeorderProductGroups = payload?.data?.productGroup;
			})
			.addCase(getPlaceOrderProductsGroups.rejected, (state, { payload }: any) => {
				state.error = payload?.productGroup;
				state.isSuccess = false;
				state.isLoading = false;
			})

			// get product list in group

			.addCase(getProductListInGroup.pending, state => {
				state.isLoading = true;
			})
			.addCase(getProductListInGroup.fulfilled, (state, { payload }: any) => {
				state.isSuccess = true;
				state.isLoading = false;
				state.groupProductList = payload?.productGroup;
			})
			.addCase(getProductListInGroup.rejected, (state, { payload }: any) => {
				state.groupProductList = payload?.productGroup;
				state.isSuccess = false;
				state.isLoading = false;
			})

			// get all products for group

			.addCase(getAllProductsForGroup.pending, state => {
				state.isLoading = true;
				state.allProductsForGroupLoading = true;
			})
			.addCase(getAllProductsForGroup.fulfilled, (state, { payload }: any) => {
				const grouped = payload?.data?.products?.reduce(
					(
						acc: { [key: string]: ProductListForGroup[] },
						product: ProductListForGroup
					) => {
						const firstLetter = product.productName[0].toUpperCase();
						const groupKey = /^[A-Z]$/.test(firstLetter) ? firstLetter : "#";
						if (!acc[groupKey]) acc[groupKey] = [];
						acc[groupKey].push(product);
						return acc;
					},
					{} as Record<string, ProductListForGroup[]>
				);

				const sortedGrouped: {
					letter: string;
					products: ProductListForGroup[];
				}[] = Object.keys(grouped)
					.sort((a, b) => (a === "#" ? 1 : b === "#" ? -1 : a.localeCompare(b)))
					.map(letter => ({ letter, products: grouped[letter] }));

				state.isSuccess = true;
				state.isLoading = false;
				state.allProductsForGroupLoading = false;
				state.allProductsForGroup = {
					products: sortedGrouped,
					total: payload?.data?.total
				};
			})
			.addCase(getAllProductsForGroup.rejected, (state, { payload }: any) => {
				state.error = payload?.data;
				state.isSuccess = false;
				state.allProductsForGroupLoading = false;
				state.isLoading = false;
			})

			// selected Product Details

			.addCase(getProductDetails.pending, state => {
				state.selectedProductDetails = initialState.selectedProductDetails;
				state.isLoading = true;
				state.selectedProductDetails = null;
			})
			.addCase(getProductDetails.fulfilled, (state, { payload }: any) => {
				state.isSuccess = true;
				state.isLoading = false;
				state.selectedProductDetails = payload?.data;
			})
			.addCase(getProductDetails.rejected, (state, { payload }: any) => {
				state.error = payload?.data;
				state.isSuccess = false;
				state.isLoading = false;
			})

			.addCase(customFieldSuggesion.pending, state => {
				state.isLoading = true;
			})
			.addCase(customFieldSuggesion.fulfilled, (state, { payload }: any) => {
				state.isSuccess = true;
				state.isLoading = false;
				state.suggestedField = payload;
			})
			.addCase(customFieldSuggesion.rejected, (state, { payload }: any) => {
				state.error = payload?.data;
				state.isSuccess = false;
				state.isLoading = false;
			})

			// product Name suggestion
			.addCase(productNameSuggestion.pending, state => {
				// state.isLoading = true;
			})
			.addCase(productNameSuggestion.fulfilled, (state, { payload }: any) => {
				state.isSuccess = true;
				// state.isLoading = false;
				state.productNameSuggestionData = payload;
			})
			.addCase(productNameSuggestion.rejected, (state, { payload }: any) => {
				state.error = payload?.data;
				state.isSuccess = false;
				// state.isLoading = false;
			})

			//
			.addCase(bulkProductFileImport.pending, state => {
				state.bulkImport.isLoading = true;
				state.bulkImport.isSuccess = false;
			})
			.addCase(bulkProductFileImport.fulfilled, (state, { payload }) => {
				state.bulkImport.isLoading = false;
				state.bulkImport.isSuccess = true;

				const data = payload?.data;

				state.bulkImport.isErrorCount = data?.totalErrors ?? 0;
				state.bulkImport.data = data?.productData ?? [];
			})
			.addCase(bulkProductFileImport.rejected, state => {
				state.bulkImport.isLoading = false;
				state.bulkImport.isSuccess = false;
				state.bulkImport.isErrorCount = 0;
				state.bulkImport.data = [];
			})

			//
			.addCase(bulkProductDataImport.pending, state => {
				state.bulkImport.dataImportLoading = true;
				state.bulkImport.isErrorCount = 0;
			})
			.addCase(bulkProductDataImport.fulfilled, (state, { payload }) => {
				state.bulkImport.dataImportLoading = false;

				if (payload?.data?.totalErrors > 0) {
					state.bulkImport.isErrorCount = payload?.data?.totalErrors;
					state.bulkImport.data = payload?.data?.productData ?? [];
				}
			})
			.addCase(bulkProductDataImport.rejected, state => {
				state.bulkImport.dataImportLoading = false;
			});
	}
});

export const {
	clearSuggestion,
	clearProductNameSuggestion,
	clearSelectedProductDetails,
	updateSelectedProductDetails,
	clearProductData,
	setActiveFilter,
	clearProductSizeData,
	clearProductGroupsData,
	clearProductListGroup,
	resetBulkImportStateAction,
	resetAllProductsForGroup,
	updateGroupId
} = productSlice.actions;
export default productSlice.reducer;
