import {
	CheckCircleOutlined,
	ClockCircleOutlined,
	UserAddOutlined
} from "@ant-design/icons";
import { Input, InputRef, Modal, Spin } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { WpIcon } from "../../assets/svg";
import { DownArrow, UserIcon } from "../../assets/svg/icons";
import ImageLoad from "../../components/ImageLoad";
import NoDataFound from "../../components/NoDataFound";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import useMessageHook from "../../hooks/useMessageHook";
import {
	getCompanyByPhoneNumber,
	getConnectionList,
	getSentRequest,
	sendInvitationRequest
} from "../../redux/services/connection";
import {
	clearCompanyList,
	clearConnectionList
} from "../../redux/slices/connectionSlice";
import { REGEX_STRINGS } from "../../shared/constants/constant";
import { ERROR_STRINGS } from "../../shared/constants/content-constant";
import { ConnectionsSubModules, RoleAccessModules } from "../../shared/enum/enum";
import socket from "../../socket";
import { CleverTapEvents } from "../../utils/clevertapEvents";
import { getModuleRoleAccessData } from "../../utils/helper";
import axiosInstance from "../../middleware/axios";
import { debounce } from "../../utils/debounce";
import { takeActionOnConnectionRequestAsyncThunk } from "../../redux/services/connection-async-thunk";

interface Props {
	setVisible: (data: boolean) => void;
	visible: boolean;
}

const InviteModal = ({ setVisible, visible }: Props) => {
	const [error, setError] = useState<any>();
	const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
	const [phoneNumber, setPhoneNumber] = useState("");
	const [isLoading, setLoading] = useState(false);
	const [isInvited, setIsInvited] = useState({ message: "", isOpen: false });
	const [sentRequests, setSentRequests] = useState({ isToggle: false, data: [] });

	const inputRef = useRef<InputRef | null>(null);
	const { showMessage, contextHolder } = useMessageHook();

	const dispatch = useAppDispatch();

	const { sentRequest, companyList, connectionActiveTab, connectionPayload } =
		useAppSelector(state => state.connections);
	const { userDetails } = useAppSelector(state => state.common);
	const { loggedInUserRole } = useAppSelector(state => state.accountSetting);
	const viewConnections = getModuleRoleAccessData(
		loggedInUserRole?.data?.activityAccess,
		RoleAccessModules.CONNECTIONS,
		ConnectionsSubModules.VIEW_ONLY
	);
	const acceptRejectConnections = getModuleRoleAccessData(
		loggedInUserRole?.data?.activityAccess,
		RoleAccessModules.CONNECTIONS,
		ConnectionsSubModules.ACCEPT_REJECT
	);

	const companyId = userDetails.data?.company?.companyId;

	useEffect(() => {
		setTimeout(() => {
			if (inputRef.current) {
				inputRef.current.focus();
			}
		}, 200);
	}, []);

	const companyByPhoneNumber = useCallback(
		debounce((value: string) => {
			value?.length === 10 &&
				dispatch(getCompanyByPhoneNumber({ phoneNo: value }))
					.then(result => {
						setLoading(true);
						if (result.payload.status === 200) {
							setIsInvited({
								message: "",
								isOpen: false
							});
							setError({});
						} else {
							setIsInvited({
								isOpen: true,
								message: result?.payload?.message
							});
							setError({ phone: true });
						}
					})
					.catch(error => {
						setIsInvited({
							isOpen: true,
							message: error?.message
						});
						setError({ phone: true });
					})
					.finally(() => {
						setLoading(false);
					});
		}, 500),
		[]
	);

	const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (REGEX_STRINGS.NUMBER.test(value)) {
			companyByPhoneNumber(value);
			setPhoneNumber(value);
			dispatch(clearCompanyList());
			// Check if the number starts with 6, 7, 8, or 9
			if (REGEX_STRINGS.PHONE_NUMBER.test(value) && value.length === 10) {
				companyByPhoneNumber(value);
			}
			if (!REGEX_STRINGS.PHONE_NUMBER.test(value) && value.length === 10) {
				showMessage("error", "Please enter a valid number");
				setError({ phone: true });
			} else {
				setError({});
			}
		}
	};

	useEffect(() => {
		socket.on(`requestUpdated_${companyId}`, async data => {
			dispatch(clearConnectionList());
			if (data?.flage) {
				const payload = {
					search: "",
					sort: "ASC",
					type: connectionActiveTab,
					limit: connectionPayload.data,
					offset: 0
				};
				dispatch(getConnectionList(payload));
				dispatch(getCompanyByPhoneNumber({ phoneNo: phoneNumber })).finally(
					() => {
						setLoading(false);
					}
				);
			}
		});

		socket.on(`connectionUpdated_${companyId}`, data => {
			if (data.flage) {
				dispatch(getCompanyByPhoneNumber({ phoneNo: phoneNumber })).finally(
					() => {
						setLoading(false);
					}
				);
			}
		});

		return () => {
			socket.off(`connectionUpdated_${companyId}`);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socket, companyId, phoneNumber, connectionPayload, dispatch]);

	const sendInvitation = async () => {
		if (!error?.phone) {
			setLoading(true);
			CleverTapEvents.trackEvent("Connection Sent - Invite");
			await dispatch(
				sendInvitationRequest({
					phoneNo: phoneNumber,
					inviteAsVendor: false
				})
			)
				.then(result => {
					setIsInvited({
						message: result.payload?.message,
						isOpen: true
					});
					setPhoneNumber("");
				})
				.catch(error => {
					console.error("error", error);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const sendConnection = async (data: any) => {
		setLoading(true);
		const payload = {
			receiverId: data?.companyId,
			requestAsVendor: false
		};
		CleverTapEvents.trackEvent("Connection Sent - Connect");
		axiosInstance
			.post("/request/send", payload)
			.then(result => {
				setLoading(false);
				if (result.status === 200) {
					setIsInvited({
						message: result.data.message,
						isOpen: true
					});
					getSendedRequests();
					dispatch(getCompanyByPhoneNumber({ phoneNo: phoneNumber }))
						.catch(error => console.error("error", error))
						.finally(() => {
							setLoading(false);
						});
				}
			})
			.catch(() => console.error(ERROR_STRINGS.SOMETHING_WRONG));
	};

	const getSendedRequests = async () => {
		setLoading(true);
		const payload = {
			search: "",
			limit: 0,
			offset: 0,
			forVendor: false
		};
		await dispatch(getSentRequest(payload))
			.catch(error => console.error("error", error))
			.finally(() => {
				setLoading(false);
			});
	};

	const handleOk = () => {
		setConfirmLoading(true);
		setTimeout(() => {
			setVisible(false);
			setConfirmLoading(false);
		}, 2000);
	};

	const handleCancel = () => {
		setVisible(false);
		setPhoneNumber("");
	};

	const requestAction = async (reqType: string, id: string | number) => {
		setLoading(true);
		const payload = {
			requestId: +id,
			action: reqType
		};
		await dispatch(takeActionOnConnectionRequestAsyncThunk(payload))
			.then(result => {
				if (result?.payload?.status === 200 && reqType === "Cancel") {
					getSendedRequests();
					setSentRequests({
						isToggle: false,
						data: sentRequests?.data
					});
				}
				if (result?.payload?.data?.receiver?.createdBy?.phoneNo) {
					setLoading(true);
					setTimeout(() => {
						dispatch(
							getCompanyByPhoneNumber({ phoneNo: phoneNumber })
						).finally(() => {
							setLoading(false);
						});
					}, 500);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const InviteSuccess = () => {
		setTimeout(() => {
			setIsInvited({
				message: "",
				isOpen: false
			});
		}, 5000);
		return error?.phone ? (
			<div
				className={`${error?.phone ? "invitation-message-wrapper error" : "invitation-message-wrapper"}`}
			>
				<CheckCircleOutlined />
				<p className='caption'>{isInvited.message}</p>
			</div>
		) : (
			<></>
		);
	};

	return (
		<Modal
			open={visible}
			onOk={handleOk}
			confirmLoading={confirmLoading}
			onCancel={handleCancel}
			className='invite-modal'
			footer={false}
			rootClassName='invite-modal-wrapper'
			maskClosable={false}
		>
			{contextHolder}
			{isInvited.isOpen ? <InviteSuccess /> : ""}
			{isLoading ? (
				<div className='loading-container'>
					<Spin />
				</div>
			) : (
				<></>
			)}
			<div className='modal-header'>
				<div className='modal-text-container'>
					<h3 className='title-3'>Invite people</h3>
					<p className='caption'>
						Invite your contacts and start collaborating today.
					</p>
				</div>
			</div>
			<div className='invite-form-body'>
				<div className='custom-input-container'>
					<WpIcon />
					<Input
						className={error?.phoneNo ? "custom-input error" : "custom-input"}
						ref={inputRef}
						defaultValue=''
						onChange={handleChange}
						value={phoneNumber}
						placeholder='Invite by WhatsApp Number'
						autoFocus={true}
						allowClear
					/>
				</div>
				{sentRequest?.data?.length ? (
					<div className='accordian-container'>
						<button className='accordian-header flex justifyBetween'>
							<h4 className='title-4'>Pending Request</h4>
							<button
								className={
									sentRequests.isToggle
										? "collapse-button flex alignCenter active"
										: "collapse-button flex alignCenter"
								}
								onClick={() =>
									setSentRequests({
										isToggle: !sentRequests.isToggle,
										data: []
									})
								}
							>
								{sentRequests.isToggle ? "Hide All" : "Show All"}
								<DownArrow />
							</button>
						</button>
						<div className='numbers-list-wrapper'>
							{sentRequests.isToggle ? (
								sentRequest?.data?.map((item: any, index) => {
									return (
										<div className='numbers-list' key={index}>
											<div className='filterd-number flex rounded-8'>
												<div className='mobile-number flex gap-2'>
													<div className='image-container'>
														{item?.receiver
															?.profileThumbnailImg ? (
															<ImageLoad
																src={
																	item?.receiver
																		?.profileThumbnailImg
																}
																alt='profile-icon'
															/>
														) : (
															<UserIcon />
														)}
													</div>
													<div className='user-info'>
														<p className='company-name'>
															{item?.receiver?.name}
														</p>
														<p className='user-name'>
															{item?.receiver?.createdBy
																?.firstName +
																" " +
																item?.receiver?.createdBy
																	?.lastName}
														</p>
													</div>
												</div>
												<button
													className='status pending'
													type='button'
													disabled={isLoading}
												>
													<div className='flex gap-1'>
														<ClockCircleOutlined /> Pending
													</div>
													<div
														className='flex gap-1 hover'
														onClick={() =>
															requestAction(
																"Cancel",
																item?.requestId
															)
														}
													>
														Cancel
													</div>
												</button>
											</div>
										</div>
									);
								})
							) : sentRequests?.isToggle ? (
								<NoDataFound />
							) : (
								<></>
							)}
						</div>
					</div>
				) : (
					<></>
				)}
				{phoneNumber && companyList?.data?.length ? (
					<div className='search-result-body'>
						<p className='heading'>Result found</p>
						<div className='numbers-list-wrapper'>
							{companyList?.data.map((item: any, index: string) => {
								return (
									<div className='numbers-list' key={index}>
										<div className='filterd-number flex rounded-8'>
											<div className='mobile-number flex gap-2'>
												<div className='image-container'>
													{item?.profileThumbnailImg ? (
														<ImageLoad
															src={
																item?.profileThumbnailImg
															}
															alt='profile-icon'
														/>
													) : (
														<UserIcon />
													)}
												</div>
												<div className='user-info'>
													<p className='company-name'>
														{item.name}
													</p>
													<p className='user-name'>
														{item?.createdBy?.firstName +
															" " +
															item?.createdBy?.lastName}
													</p>
												</div>
											</div>
											{item?.isConnected ? (
												<NavLink
													key={item?.companyId}
													to={`/connection/${item?.connectionId}`}
												>
													<button
														className='connect-button flex alignCenter gap-1'
														onClick={() => setVisible(false)}
													>
														View Profile
													</button>
												</NavLink>
											) : item?.sentRequest ? (
												<button
													className='status pending'
													type='button'
													disabled={isLoading}
												>
													<div className='flex gap-1'>
														<ClockCircleOutlined /> Pending
													</div>
													<div
														className='flex gap-1 hover'
														onClick={() =>
															requestAction(
																"Cancel",
																item?.requestId
															)
														}
													>
														Cancel
													</div>
												</button>
											) : item.receivedRequest ? (
												!viewConnections &&
												acceptRejectConnections ? (
													<div className='buttons-container flex gap-3'>
														<button
															className='request-button'
															type='button'
															onClick={() => {
																requestAction(
																	"Reject",
																	item?.requestId
																);
															}}
															disabled={isLoading}
														>
															Reject
														</button>
														<button
															className='request-button active'
															type='button'
															onClick={() => {
																requestAction(
																	"Accept",
																	item?.requestId
																);
															}}
															disabled={isLoading}
														>
															Accept
														</button>
													</div>
												) : (
													<></>
												)
											) : (
												<button
													disabled={isLoading}
													className='connect-button flex alignCenter gap-1'
													onClick={() => sendConnection(item)}
												>
													<UserAddOutlined /> Connect
												</button>
											)}
										</div>
									</div>
								);
							})}
						</div>
					</div>
				) : (
					""
				)}
				{phoneNumber &&
				phoneNumber.length === 10 &&
				!companyList?.data?.length &&
				!companyList?.isLoading &&
				!error?.phone ? (
					<div className='search-result-body'>
						<p className='heading'>Result found</p>
						<div className='numbers-list-wrapper'>
							<div className='numbers-list'>
								<div className='filterd-number flex rounded-8'>
									<div className='mobile-number flex gap-2'>
										<div className='image-container'>
											<UserIcon />
										</div>
										<p className='caption bold'>{phoneNumber}</p>
									</div>
									<button
										disabled={isLoading}
										className='invite-button'
										onClick={sendInvitation}
									>
										Invite
									</button>
								</div>
							</div>
						</div>
					</div>
				) : (
					""
				)}
			</div>
		</Modal>
	);
};

export default InviteModal;
