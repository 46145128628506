import { createAsyncThunk } from "@reduxjs/toolkit";

import endPoints from "./endPoints.json";
import axiosInstance from "../../middleware/axios";

export const getUserModulesActivity: any = createAsyncThunk(
	"get/userModuleActivity",
	async (args, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get(endPoints.userRoleModuleActivity);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getUserRoleList: any = createAsyncThunk(
	"rolePermission/getUserRoleList",
	async (args, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get(endPoints.userRoleList);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getUserRoleDetails: any = createAsyncThunk(
	"get/userRoleDetails",
	async (id: string, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get(
				`${endPoints.getUserRoleDetails}/${id}`
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const createUserRolePermission: any = createAsyncThunk(
	"create/userModuleActivity",
	async (payload, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(
				endPoints.createUserRolePermission,
				payload
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getRoleAccessData = createAsyncThunk(
	"rolePermission/getRoleAccessPayload",
	async (args, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get(endPoints.rolePermissionData);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error);
		}
	}
);

export const roleSuggestionList = createAsyncThunk(
	"rolePermission/roleSuggest",
	async (payload: { keyword: string }, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(endPoints.roleSuggest, payload);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error);
		}
	}
);

export const getOrederSeriesData = createAsyncThunk(
	"company/getPrefixSuffix",
	async (args, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get(endPoints.getPrefixSuffix);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error);
		}
	}
);

export const setPrefixSuffix = createAsyncThunk(
	"company/updatePrefixSuffix",
	async (
		args: {
			orderNumberPrefix: string;
			orderNumberSuffix: string;
			financialYearEnd: string;
			financialYearStart: string;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await axiosInstance.put(endPoints.updatePrefixSuffix, args);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error);
		}
	}
);

export const getProductsManagementData = createAsyncThunk(
	"company/getProductManagement",
	async (args, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get(endPoints.getProductManagementList);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error);
		}
	}
);
export const updateProductManagementData = createAsyncThunk(
	"company/updateProductManagement",
	async (
		args: {
			productAccountSettingId: number;
			isActive: boolean;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await axiosInstance.patch(
				endPoints.updateProductManagement,
				args
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error);
		}
	}
);

// Connection Settings
export const getBlockedConnectionsList = createAsyncThunk(
	"connection/block-connection",
	async (args, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(endPoints.blockedConnectionsList);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error);
		}
	}
);

// Notification Settings
export const getNotificationModulesList = createAsyncThunk(
	"company/getUpdateNotificationManagement",
	async (args, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get(endPoints.getNotificationtList);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error);
		}
	}
);

export const updateNotificationManagementData = createAsyncThunk(
	"company/updateNotificationManagement",
	async (
		args: {
			notificationSettingId: number;
			isActive: boolean;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await axiosInstance.patch(
				endPoints.updateNotificationManagement,
				args
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error);
		}
	}
);
