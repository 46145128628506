import { LogoutOutlined } from "@ant-design/icons";
import { message } from "antd";
import { memo, useState } from "react";
import OneSignal from "react-onesignal";
import { useNavigate } from "react-router-dom";
import { EditIcon } from "../../assets/svg/icons";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import useMessageHook from "../../hooks/useMessageHook";
import { selectCompany } from "../../redux/services/auth";
import { fetchTokenData, logoutDevice } from "../../redux/services/common";
import { getCompanyDetails } from "../../redux/services/company";
import { resetStateAction, setTokenAction } from "../../redux/slices/authSlice";
import {
	CHOOSE_COMPANY_POPUP,
	ERROR_STRINGS,
	LOGOUT_POPUP
} from "../../shared/constants/content-constant";
import { getProfileIcon, useIsAdmin } from "../../utils/helper";
import ConfirmationPopup from "../actionModals/confirmationPopup";
import ImageLoad from "../ImageLoad";
import UserInfo from "../userInfo";

const SettingPopOver = ({ handleOpenChange }: { handleOpenChange: () => void }) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const isAdmin = useIsAdmin();
	const { showMessage, contextHolder } = useMessageHook();

	const { userDetails } = useAppSelector(state => state.common);
	const { fcm_token } = useAppSelector(state => state.auth);
	const { companyDetailList } = useAppSelector(state => state.company);

	const [isConfirmToggle, setIsConfirmToggle] = useState(false);
	const [loading, setLoading] = useState(false);

	const currentCompany = userDetails.data?.company;
	const userData = userDetails.data?.user;

	const goToSetting = () => {
		navigate(`/settings/${currentCompany?.companyId}/profile`, {
			state: { companyId: currentCompany?.companyId }
		});
		handleOpenChange();
	};

	const handleLogout = async () => {
		setLoading(true);
		const payload = {
			token: fcm_token,
			from: "Web"
		};
		if (userData?.userId)
			await dispatch(logoutDevice({ id: userData?.userId, payload }))
				.then(result => {
					if (result?.payload?.status === 200) {
						OneSignal.logout();
						message.success(result?.payload?.message);
					}
					dispatch(resetStateAction());
				})
				.catch(error => console.error(error))
				.finally(() => {
					dispatch({ type: "RESET_STATE" });
					setLoading(false);
				});
	};

	const handleSwitchCompany = async (companyId: number, isAdmin?: boolean) => {
		if (userData?.userId) {
			try {
				const result = await dispatch(
					selectCompany({ companyId: companyId, userId: userData?.userId || 0 })
				);

				if (isAdmin) {
					navigate(`/settings/${companyId}/company-details`, {
						state: { companyId: companyId }
					});
				} else {
					navigate(`/settings/${companyId}/profile`, {
						state: { companyId: companyId }
					});
				}

				if (result?.payload?.status === 200) {
					dispatch(setTokenAction(result?.payload?.data?.token));
					handleOpenChange();
					dispatch({ type: "RESET_STATE" });
					await dispatch(getCompanyDetails());
					await dispatch(fetchTokenData());
				} else {
					showMessage(
						"error",
						result?.payload.message || "Something went wrong"
					);
					handleOpenChange();
				}
			} catch {
				handleOpenChange();
				showMessage("error", ERROR_STRINGS.SOMETHING_WRONG);
			}
		}
	};

	return (
		<div className='company-list-popover'>
			{contextHolder}
			<div className='selected-company-box'>
				<div
					className={"cursorPointer company-box"}
					onClick={() => {
						isAdmin && goToSetting();
					}}
				>
					<div className='user-box gap-2 flex alignCenter'>
						<div className='img-box'>
							{userDetails?.data?.user?.profileImage?.length ? (
								<ImageLoad
									src={userDetails?.data?.user?.profileImage}
									alt='profile_image'
								/>
							) : (
								getProfileIcon(
									`${userData?.firstName} ${userData?.lastName}`
								)
							)}
						</div>
						<div className='customer-info flex direction-column'>
							<span>{`${userData?.firstName} ${userData?.lastName}`}</span>
							{currentCompany?.district && (
								<span className='user-name'>{`${currentCompany?.district}, ${currentCompany?.state}`}</span>
							)}
						</div>
					</div>

					<button
						className='icon-button edit-icon'
						onClick={() => goToSetting()}
					>
						<EditIcon />
					</button>
				</div>

				{userData && (
					<div className='switch-company-container'>
						<div className='switch-company-box'>
							{CHOOSE_COMPANY_POPUP.TITLE_2}
						</div>
					</div>
				)}

				{companyDetailList?.data &&
					companyDetailList?.data?.map((data, index) => {
						return (
							<button
								key={index}
								className='switch-company-profile-container'
								onClick={() =>
									handleSwitchCompany(
										data.companyId,
										data?.user?.userRole?.role?.roleName?.toLowerCase() ===
											"admin"
									)
								}
							>
								<div className='inner-box flex alignCenter justifyBetween'>
									<UserInfo
										profileImage={data.profileThumbnailImg || ""}
										comanyName={data.name}
										userName={`${data.district}, ${data.state}`}
									/>
									{data?.user?.userRole?.role?.roleName?.toLowerCase() ===
										"admin" && (
										<div
											className='icon-button edit-icon'
											onClick={() =>
												handleSwitchCompany(
													data.companyId,
													data?.user?.userRole?.role?.roleName?.toLowerCase() ===
														"admin"
												)
											}
										>
											<EditIcon />
										</div>
									)}
								</div>
							</button>
						);
					})}

				<button
					className='logout-button'
					onClick={() => setIsConfirmToggle(true)}
				>
					<span className='flex gap-2 alignCenter justifyCenter'>
						<LogoutOutlined />
						<span className='button-text'>
							{CHOOSE_COMPANY_POPUP?.LOGOUT}
						</span>
					</span>
				</button>
			</div>

			<ConfirmationPopup
				open={isConfirmToggle}
				close={() => {
					setIsConfirmToggle(false);
				}}
				confirm={() => handleLogout()}
				infoContent={LOGOUT_POPUP.TITLE}
				cancelButtonText={LOGOUT_POPUP.NO}
				confirmButtonText={LOGOUT_POPUP.SUCCESS}
				disabled={loading}
			/>
		</div>
	);
};

export default memo(SettingPopOver);
