import { Pagination as AntdPagination } from "antd";
import React from "react";

interface Props {
	total: number;
	showSizeChanger: boolean;
	onChange: (data: { page: number; pageSize: number }) => void;
	itemRender?:
		| ((
				page: number,
				type: "page" | "prev" | "next" | "jump-prev" | "jump-next",
				element: React.ReactNode
		  ) => React.ReactNode)
		| undefined;
	current: number;
	pageSize?: number;
}

const Pagination = ({
	total,
	showSizeChanger,
	onChange,
	itemRender,
	current,
	pageSize
}: Props) => {
	const handleChange = (data: { page: number; pageSize: number }) => {
		onChange(data);
	};
	return (
		<AntdPagination
			showSizeChanger={showSizeChanger}
			onChange={(page, pageSize) => handleChange({ page, pageSize })}
			total={total}
			current={current}
			itemRender={itemRender}
			defaultPageSize={pageSize}
			pageSize={pageSize}
		/>
	);
};

export default Pagination;
