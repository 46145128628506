import React, { useCallback, useEffect, useRef, useState } from "react";
import {
	ArrowLeftOutlined,
	ArrowRightOutlined,
	InfoCircleOutlined
} from "@ant-design/icons";
import { produce } from "immer";

import { useAppSelector } from "../../hooks/useAppSelector";
import { BulkProductData, ProductSizeModal } from "../../types/productsType";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getProductUnits } from "../../redux/services/products";
import BulkImportTableRow from "./BulkImportTableRow";

const BulkImportReview = ({
	resetFile,
	uploadBulkImportData
}: {
	resetFile: () => void;
	uploadBulkImportData: (data: BulkProductData[]) => void;
}) => {
	const dispatch = useAppDispatch();
	const productUnits = useAppSelector(state => state.products.productUnits);
	const { data: productData, isErrorCount } = useAppSelector(
		state => state.products.bulkImport
	);

	const [data, setData] = useState<BulkProductData[]>([]);
	const [errorCount, setErrorCount] = useState<number>(isErrorCount);
	const [errorResolved, setErrorResolved] = useState<number>(0);
	const [focusEle, setFocusEle] = useState(0);

	const inputRefs = useRef<(HTMLInputElement | string)[][]>([]);

	useEffect(() => {
		if (productData?.length > 0) {
			setData(productData);
		}
	}, [productData]);

	useEffect(() => {
		if (isErrorCount > 0) {
			setErrorCount(isErrorCount ?? 0);
			setErrorResolved(0);
		}
	}, [isErrorCount]);

	useEffect(() => {
		if (productUnits === null) {
			dispatch(getProductUnits({ search: "" }));
		}
	}, [productUnits, dispatch]);

	let row = 0;

	const handleFocus = useCallback((number: number) => {
		setFocusEle(number);
	}, []);

	// Handle input changes with Immer for efficient state updates
	const onInputChange = useCallback(
		(
			productIndex: number,
			sizeIndex: number | null,
			field: string,
			value: string
		) => {
			setData(prevData =>
				produce(prevData, draft => {
					if (sizeIndex === null) {
						const product = draft?.[productIndex]?.productName;

						product.value = value;

						if (value) {
							const isProductName = draft?.filter(
								data => data?.productName?.value === value
							)?.length;

							if (isProductName > 1) {
								!product.isError && setErrorCount(pre => pre + 1);
								product.isError = true;
								product.errorMessages = ["Duplicate Product Name found."];
							} else {
								product?.isError && setErrorResolved(pre => pre + 1);
								product.isError = false;
								product.errorMessages = [];
							}
						} else {
							!product.isError && setErrorCount(pre => pre + 1);
							product.isError = true;
							product.errorMessages = ["Please Enter Value"];
						}
					} else {
						const productSize =
							draft[productIndex].productSizes[sizeIndex][
								field as keyof ProductSizeModal
							];

						const currentProductSize =
							draft[productIndex].productSizes[sizeIndex];
						productSize.value = value;
						if (value) {
							if (field === "productSizeUnit" || field === "productSize") {
								let localErrorCount = 0;
								let resolvedErrorCount = 0;

								const productSizes = draft[productIndex].productSizes;
								const sizeMap = new Map();

								if (currentProductSize.productSize.value) {
									if (currentProductSize.productSize.isError)
										resolvedErrorCount++;
									currentProductSize.productSize.isError = false;
									currentProductSize.productSize.errorMessages = [];
								}

								if (currentProductSize.productSizeUnit.value) {
									if (currentProductSize.productSizeUnit.isError)
										resolvedErrorCount++;
									currentProductSize.productSizeUnit.isError = false;
									currentProductSize.productSizeUnit.errorMessages = [];
								}

								productSizes?.forEach((data, idx) => {
									const key = `${data.productSizeUnit.value}-${data.productSize.value}`;

									if (sizeMap.has(key)) {
										const currentSize = productSizes[idx];

										if (!currentSize.productSize.isError)
											localErrorCount++;

										currentSize.productSize.isError = true;
										currentSize.productSize.errorMessages = [
											"Duplicate Product Size."
										];
									} else {
										sizeMap.set(key, idx);

										if (
											data?.productSize?.errorMessages?.[0]?.includes(
												"Duplicate Product Size."
											)
										) {
											if (data.productSize.isError)
												resolvedErrorCount++;
											data.productSize.isError = false;
											data.productSize.errorMessages = [];

											if (data.productSizeUnit.isError)
												resolvedErrorCount++;
											data.productSizeUnit.isError = false;
											data.productSizeUnit.errorMessages = [];
										}
									}
								});

								setErrorCount(prev => prev + localErrorCount);
								setErrorResolved(prev => prev + resolvedErrorCount);
							} else if (field === "productSizeRate") {
								if (Number(value) > 0) {
									productSize.isError &&
										setErrorResolved(pre => pre + 1);
									productSize.isError = false;
									productSize.errorMessages = [];
								} else {
									!productSize.isError && setErrorCount(pre => pre + 1);
									productSize.isError = true;
									productSize.errorMessages = [
										"Please Enter Value greater than 0."
									];
								}
							} else {
								productSize.isError && setErrorResolved(pre => pre + 1);
								productSize.isError = false;
								productSize.errorMessages = [];
							}
						} else {
							!productSize.isError && setErrorCount(pre => pre + 1);
							productSize.isError = true;

							if (field === "productSizeUnit") {
								productSize.errorMessages = ["Please Select Size unit"];
							} else if (field === "orderUnit") {
								productSize.errorMessages = ["Please Select Order unit"];
							} else {
								productSize.errorMessages = ["Please Enter Value"];
							}
						}
					}
				})
			);
		},
		[]
	);

	const focusErrorInput = useCallback(
		(direction: "next" | "prev") => {
			const flattenedRefs = inputRefs.current.flatMap(ele => ele);
			const errorRefsIndex: number[] = [];
			const errorInputs = flattenedRefs.filter(
				(input: HTMLInputElement | string, index: number) => {
					if (typeof input === "string") {
						return false; // Exclude strings from the "error" check
					}

					const isError = input?.classList?.contains("error");
					if (isError) {
						errorRefsIndex.push(index);
						return isError;
					}
				}
			) as HTMLInputElement[];

			if (errorInputs.length === 0) return;

			let nextFocusEle = 0;

			const errorElementIndexInErrorRefs = errorRefsIndex?.findIndex(
				res => res === focusEle
			);

			if (errorRefsIndex?.includes(focusEle)) {
				if (direction === "next") {
					nextFocusEle =
						errorElementIndexInErrorRefs >= errorRefsIndex?.length - 1
							? 0
							: errorElementIndexInErrorRefs + 1;
				}
				if (direction === "prev") {
					nextFocusEle =
						errorElementIndexInErrorRefs - 1 < 0
							? errorRefsIndex?.length - 1
							: errorElementIndexInErrorRefs - 1;
				}
			}

			// Focus the next/previous error input
			errorInputs[nextFocusEle]?.focus();
		},
		[inputRefs, focusEle]
	);

	const handleEnterKey = useCallback(
		(e: React.KeyboardEvent, row: number, col: number) => {
			if (e.key === "Enter") {
				e.preventDefault();

				const totalRow = inputRefs.current?.length;

				const focusElement = (row: number) => {
					// Check if the next row exists
					if (row >= totalRow) return;

					const element = inputRefs.current?.[row]?.[col];

					if (element && typeof element !== "string") {
						element.focus();
					} else {
						// Move to the next row recursively if the current is a string
						focusElement(row + 1);
					}
				};

				focusElement(row + 1);
			}
		},
		[inputRefs]
	);

	useEffect(() => {
		if (errorResolved === errorCount && errorResolved > 0 && errorCount > 0) {
			setErrorResolved(0);
			setErrorCount(0);
		}
	}, [errorResolved, errorCount]);

	return (
		<div className='review-container'>
			<div className='sticky-top'>
				<div className='flex justifyBetween alignCenter w-100  '>
					<p className='title'>Review your fields</p>
					<div className='flex justifyBetween alignCenter gap-4'>
						<button
							className='button rounded-14 large40 secondary flex alignCenter gap-2 p-10'
							onClick={resetFile}
						>
							Cancel
						</button>
						<button
							disabled={errorResolved !== errorCount}
							className='button rounded-14 large40 primary flex alignCenter gap-2 p-10'
							onClick={() => {
								// api call
								uploadBulkImportData(data);
								setErrorResolved(0);
							}}
						>
							Submit
						</button>
					</div>
				</div>

				{errorResolved !== errorCount && (
					<div className='error-container flex justifyBetween alignCenter gap-4'>
						<p className='flex alignCenter gap-2 error-text'>
							<InfoCircleOutlined /> {errorCount} Errors Found.
						</p>
						<p className='error-resolved-text'>
							Error Resolved {errorResolved} / <span>{errorCount}</span>
						</p>
						<div className='flex alignCenter gap-4'>
							<button
								className=' rounded-16 large40  flex alignCenter gap-2 p-10'
								onClick={() => focusErrorInput("prev")}
							>
								<ArrowLeftOutlined /> Previous
							</button>
							<button
								className=' rounded-16 large40  flex alignCenter gap-2 p-10'
								onClick={() => focusErrorInput("next")}
							>
								Next <ArrowRightOutlined />
							</button>
						</div>
					</div>
				)}

				<div className=' review-table-container'>
					<table className='w-100  review-table'>
						<thead>
							<tr className='bg-gray-200'>
								<th className='border px-4 py-2'>Product Name</th>
								<th className='border px-4 py-2'>Product Size</th>
								<th className='border px-4 py-2'>Rate</th>
								<th className='border px-4 py-2'>Order Unit</th>
							</tr>
						</thead>
						<tbody>
							{data?.map((product, productIndex) => {
								return product?.productSizes?.map((size, sizeIndex) => {
									return (
										<BulkImportTableRow
											key={`${productIndex}-${sizeIndex}`}
											product={product}
											productIndex={productIndex}
											size={size}
											sizeIndex={sizeIndex}
											onInputChange={onInputChange}
											inputRefs={inputRefs.current}
											row={row++} // Increment row count for each size
											handleFocus={handleFocus}
											handleEnterKey={handleEnterKey}
											productUnits={productUnits?.data ?? []}
										/>
									);
								});
							})}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default BulkImportReview;
