import { createSlice } from "@reduxjs/toolkit";
import { fetchTokenData } from "../services/common";
import { SliceNames } from "../../shared/constants/constant";
import { REHYDRATE } from "redux-persist";
import { TRehydratePayload } from "../../types/store";
import { TUserDetailsState } from "../../types/common";

const initialState: TUserDetailsState = {
	userDetails: {
		isLoading: false,
		isSuccess: false,
		data: {
			user: {
				profileImage: "",
				firstName: "",
				lastName: "",
				userRole: {
					role: {
						roleName: ""
					},
					userRoleMappingId: 0
				}
			}
		},
		message: ""
	}
};

const commonSlice = createSlice({
	name: SliceNames.COMMON,
	initialState,
	reducers: {
		updateUserDetail: (state, { payload }) => {
			state.userDetails.data.user.profileImage = payload?.profileImage ?? "";
			state.userDetails.data.user.firstName = payload?.firstName;
			state.userDetails.data.user.lastName = payload?.lastName;
			state.userDetails.data.user.userRole = payload?.userRole;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(
				REHYDRATE,
				(
					state,
					action: { type: typeof REHYDRATE; payload: TRehydratePayload }
				) => {
					return {
						...state,
						...action?.payload?.common
					};
				}
			)
			.addCase(fetchTokenData.pending, state => {
				state.userDetails.isLoading = true;
				state.userDetails.isSuccess = false;
			})
			.addCase(fetchTokenData.fulfilled, (state, { payload }: any) => {
				state.userDetails.isSuccess = true;
				state.userDetails.isLoading = false;
				state.userDetails.data = payload?.data;
			})
			.addCase(fetchTokenData.rejected, (state, { payload }: any) => {
				state.userDetails.isSuccess = false;
				state.userDetails.isLoading = false;
				state.userDetails.message = payload?.message;
			});
	}
});

export const { updateUserDetail } = commonSlice.actions;
export default commonSlice.reducer;
