import Player from "lottie-react";
import WaitingLottie from "../../assets/images/lottie/timer-lottie.json";
import CommonModal from "../commonModal";

const BulkImportWaitingPopup = ({
	open,
	close
}: {
	open: boolean;
	close: () => void;
}) => {
	return (
		<CommonModal open={open} close={close}>
			<div className='waiting-container'>
				<div className='lottie-container'>
					<Player
						autoplay
						loop
						animationData={WaitingLottie}
						style={{ height: "80px", width: "80px" }}
					/>
				</div>
				<p className='main-title'>Importing your data...</p>
				<p className='sub-title'>
					Almost there! We&apos;re finalizing your data import.
				</p>
			</div>
		</CommonModal>
	);
};

export default BulkImportWaitingPopup;
