import { createAsyncThunk } from "@reduxjs/toolkit";

import endPoints from "./endPoints.json";
import axiosInstance from "../../middleware/axios";

export const getAllStatCount = createAsyncThunk(
	"get/statCount",
	async (args, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get(endPoints.getAllCount);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getRevenueChartData = createAsyncThunk(
	"dashboard/revenueData",
	async (payload: { period: string }, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(endPoints.revenueData, payload);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getTotalConnection = createAsyncThunk(
	"dashboard/totalConnection",
	async (args, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get(endPoints.totalConnection);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const customerSalesData = createAsyncThunk(
	"dashboard/customerReport/ordersAndRevenueData",
	async (payload: { period: string; customerId: number }, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(
				endPoints.customerSalesData + "/" + payload.customerId,
				{ period: payload.period }
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const mostOrderedProductdata = createAsyncThunk(
	"dashboard/customerReport/mostOrderedProduct",
	async (
		payload: { period: string; filterKey: string; customerId: number },
		{ rejectWithValue }
	) => {
		try {
			const response = await axiosInstance.post(
				endPoints.mostOrderedProductData + "/" + payload.customerId,
				{ period: payload.period, filterKey: payload.filterKey }
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const topSalesData = createAsyncThunk(
	endPoints.topSales,
	async (payload: { period: string }, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(endPoints.topSales, payload);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const customersListByRegion = createAsyncThunk(
	endPoints.customersListByRegion,
	async (payload: { state: string; period: string }, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(
				endPoints.customersListByRegion,
				payload
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const customerDetails = createAsyncThunk(
	endPoints.customerDetails,
	async (payload: { customerId: number; period: string }, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(
				endPoints.customerDetails + "/" + payload.customerId,
				{ period: payload.period }
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const productAnalysisProducts = createAsyncThunk(
	endPoints.productAnalysisProducts,
	async (
		payload: { period: string; filterKey: string; sortKey: string },
		{ rejectWithValue }
	) => {
		try {
			const response = await axiosInstance.post(
				endPoints.productAnalysisProducts,
				payload
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const productAnalysisChart = createAsyncThunk(
	endPoints.analysisChart,
	async (
		payload: {
			period: string;
			filterKey: string;
			sortKey: string;
			productId: number;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await axiosInstance.post(
				endPoints.analysisChart + "/" + payload.productId,
				{
					period: payload.period,
					filterKey: payload.filterKey,
					sortKey: payload.sortKey
				}
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getListofCustomers = createAsyncThunk(
	endPoints.companyCustomersList,
	async (args, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get(endPoints.companyCustomersList);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getRecentActivity = createAsyncThunk(
	endPoints.recentActivity,
	async (args, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get(endPoints.recentActivity);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);
