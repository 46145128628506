import { createSlice } from "@reduxjs/toolkit";
import { SliceNames } from "../../shared/constants/constant";
import { TSpecialPriceListState } from "../../types/specialPriceList";
import {
	addProductsForCustomer,
	getCompanySpecialPriceDetailsById,
	getCustomerProductSizes,
	getCustomersList,
	getProductsList,
	updateSpecialPriceBulkRate
} from "../services/specialPriceList";

const initialState: TSpecialPriceListState = {
	customersList: {
		isLoading: false,
		isSuccess: false,
		data: [],
		message: ""
	},
	customerDetails: {
		isLoading: false,
		isSuccess: false,
		data: {
			specialPriceList: [],
			customerDetails: {
				name: "",
				ownerName: "",
				profileThumbnailImg: ""
			},
			total: 0
		},
		message: ""
	},
	productsList: {
		isLoading: false,
		isSuccess: false,
		data: [],
		message: ""
	},
	productSizes: {
		isLoading: false,
		isSuccess: false,
		data: [],
		message: ""
	},
	specialPriceList: {
		isLoading: false,
		isSuccess: false,
		data: [],
		message: ""
	},
	updateBulkRate: {
		isLoading: false
	}
};

const specialPriceListSlice = createSlice({
	name: SliceNames.SPECIAL_PRICE_LIST,
	initialState,
	reducers: {
		clearCompanyDetails: state => {
			state.customerDetails = initialState.customerDetails;
		},
		clearProductsData: state => {
			state.productsList = initialState.productsList;
			state.productSizes = initialState.productSizes;
		},
		updateSpecialPrice: (state, { payload }) => {
			state.customersList.data = payload;
		}
	},
	extraReducers: builder => {
		// get Customers List
		builder
			.addCase(getCustomersList.pending, state => {
				state.customersList.isLoading = true;
				state.customersList.isSuccess = false;
			})
			.addCase(getCustomersList.fulfilled, (state, { payload }: any) => {
				state.customersList.isSuccess = true;
				state.customersList.isLoading = false;
				state.customersList.data = payload?.data?.customerList;
				state.customersList.message = payload?.message;
			})
			.addCase(getCustomersList.rejected, (state, { payload }: any) => {
				state.customersList.isSuccess = false;
				state.customersList.isLoading = false;
				state.customersList.message = payload?.message;
			})

			// get special pricelist details by customer id

			.addCase(getCompanySpecialPriceDetailsById.pending, state => {
				state.customerDetails.isLoading = true;
				state.customerDetails.isSuccess = false;
			})
			.addCase(
				getCompanySpecialPriceDetailsById.fulfilled,
				(state, { payload }: any) => {
					state.customerDetails.isSuccess = true;
					state.customerDetails.isLoading = false;
					state.customerDetails.data = payload?.data;
					state.customerDetails.message = payload?.message;
				}
			)
			.addCase(
				getCompanySpecialPriceDetailsById.rejected,
				(state, { payload }: any) => {
					state.customerDetails.isSuccess = false;
					state.customerDetails.isLoading = false;
					state.customerDetails.message = payload?.message;
				}
			)

			// get list of products by company

			.addCase(getProductsList.pending, state => {
				state.productsList.isLoading = true;
				state.productsList.isSuccess = false;
			})
			.addCase(getProductsList.fulfilled, (state, { payload }: any) => {
				state.productsList.isSuccess = true;
				state.productsList.isLoading = false;
				state.productsList.data = payload?.data;
				state.productsList.message = payload?.message;
			})
			.addCase(getProductsList.rejected, (state, { payload }: any) => {
				state.productsList.isSuccess = false;
				state.productsList.isLoading = false;
				state.productsList.message = payload?.message;
			})

			// get list of product sizes
			.addCase(getCustomerProductSizes.pending, state => {
				state.productSizes.isLoading = true;
				state.productSizes.isSuccess = false;
			})
			.addCase(getCustomerProductSizes.fulfilled, (state, { payload }: any) => {
				state.productSizes.isSuccess = true;
				state.productSizes.isLoading = false;
				state.productSizes.data = payload?.data?.productSizes;
				state.productSizes.message = payload?.message;
			})
			.addCase(getCustomerProductSizes.rejected, (state, { payload }: any) => {
				state.productSizes.isSuccess = false;
				state.productSizes.isLoading = false;
				state.productSizes.message = payload?.message;
			})
			// get customer products special price list
			.addCase(addProductsForCustomer.pending, state => {
				state.specialPriceList.isLoading = true;
				state.specialPriceList.isSuccess = false;
			})
			.addCase(addProductsForCustomer.fulfilled, (state, { payload }: any) => {
				state.specialPriceList.isSuccess = true;
				state.specialPriceList.isLoading = false;
				state.specialPriceList.data = payload?.data;
				state.specialPriceList.message = payload?.message;
			})
			.addCase(addProductsForCustomer.rejected, (state, { payload }: any) => {
				state.specialPriceList.isSuccess = false;
				state.specialPriceList.isLoading = false;
				state.specialPriceList.message = payload?.message;
			})

			// Update BulkRates

			.addCase(updateSpecialPriceBulkRate.pending, state => {
				state.updateBulkRate.isLoading = true;
			})
			.addCase(updateSpecialPriceBulkRate.fulfilled, state => {
				state.updateBulkRate.isLoading = false;
			})
			.addCase(updateSpecialPriceBulkRate.rejected, state => {
				state.updateBulkRate.isLoading = false;
			});
	}
});

export const { clearCompanyDetails, clearProductsData, updateSpecialPrice } =
	specialPriceListSlice.actions;
export default specialPriceListSlice.reducer;
