import Player from "lottie-react";
import { FC } from "react";
import NoInternet from "../assets/images/lottie/noInternet.json";
import { useNetworkStatus } from "../contexts/NetworkStatusContext";

const NetworkStatusBanner: FC = () => {
	const { isOnline } = useNetworkStatus();

	if (isOnline) return null;

	return (
		<div className='network-status-wrapper'>
			<Player
				autoplay
				loop
				animationData={NoInternet}
				style={{ height: "400px", width: "400px" }}
			/>
		</div>
	);
};

export default NetworkStatusBanner;
