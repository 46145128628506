import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from "antd";

import { handleKeyDown } from "../../utils/helper";
import VerifyOtpModal from "../../pages/settings/verifyOtpModal";
import { useAppSelector } from "../../hooks/useAppSelector";
import useMessageHook from "../../hooks/useMessageHook";
import ConnectionItem from "../stock/connectionItem";

interface IProps {
	value?: string;
	placeholder: string;
	name: string;
	label?: string;
	required?: boolean;
	type?: string;
	onChange?: any;
	className?: string | null;
	maxLength?: number;
	autoFocus?: boolean;
	disabled?: boolean;
	errorMessage?: string;
	showCount?: boolean;
	handleRemoveField?: () => void;
	suggetion?: string[];
	handleSuggetionClick?: (data: string) => void;
	verify?: "phone" | null;
	handleVerifyPhone?: any;
	icon?: any;
	isUser?: boolean;
	isInfoMessage?: string;
	suffix?: string;
	prefix?: string;
	onKeyDown?: any;
	onFocus?: any;
	onBlur?: any;
}

export const InputField = ({
	handleRemoveField,
	autoFocus = false,
	value,
	placeholder,
	name,
	label,
	required = false,
	onChange,
	className,
	maxLength = 50,
	type = "text",
	errorMessage,
	showCount = false,
	suggetion,
	handleSuggetionClick,
	verify,
	handleVerifyPhone,
	icon,
	isUser,
	isInfoMessage,
	suffix,
	prefix,
	onFocus,
	onBlur,
	onKeyDown,
	...props
}: IProps) => {
	const [isFocus, setFocus] = useState(false);
	const [verifyValue, setVerifyValue] = useState(false);
	const [activeIndex, setActiveIndex] = useState<number>(-1);
	const { showMessage, contextHolder } = useMessageHook();

	const inputRef = useRef<HTMLInputElement>(null);
	const listRef = useRef<HTMLUListElement>(null);
	const suffixRef = useRef<HTMLSpanElement>(null);
	const prefixRef = useRef<HTMLSpanElement>(null);
	const { otpSend } = useAppSelector(state => state.setting);

	useEffect(() => {
		setTimeout(() => {
			if (autoFocus && inputRef.current) {
				inputRef.current.focus();
			}
		}, 100);
	}, [autoFocus]);

	useEffect(() => {
		if (otpSend?.isSuccess && !otpSend?.isLoading) {
			setVerifyValue(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [otpSend]);

	const handleMouseDown = (e: React.MouseEvent) => {
		e.preventDefault(); // Prevent blur event when clicking inside result-container
	};

	const handleVerify = async (e: React.FormEvent) => {
		/^[6-9]\d{9}$/.test(value || "")
			? await handleVerifyPhone()
			: showMessage("error", "Please Enter Valid Phone number");
	};

	const handleKeyNavigation = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (!isFocus || !suggetion?.length) return;

		if (e.key === "ArrowDown") {
			e.preventDefault();
			setActiveIndex(prev => {
				const nextIndex = (prev + 1) % suggetion.length;
				scrollToItem(nextIndex);
				return nextIndex;
			});
		} else if (e.key === "ArrowUp") {
			e.preventDefault();
			setActiveIndex(prev => {
				const nextIndex = (prev - 1 + suggetion.length) % suggetion.length;
				scrollToItem(nextIndex);
				return nextIndex;
			});
		} else if (e.key === "Enter" && activeIndex !== -1) {
			e.preventDefault();
			handleSuggetionClick?.(suggetion[activeIndex]);
		}
	};

	const scrollToItem = (index: number) => {
		if (listRef.current) {
			const item = listRef.current.children[index] as HTMLElement;
			if (item) {
				item.scrollIntoView({ block: "nearest", behavior: "smooth" });
			}
		}
	};

	return (
		<div className={className ? className + " input" : "input"}>
			{contextHolder}
			<div
				className={value?.length ? "input-container bg-light" : "input-container"}
			>
				<label htmlFor={name}>
					{label && (
						<div className='flex justifyBetween'>
							<span className='flex gap-1'>
								{label}
								{required && <span className='require-icon'> *</span>}
							</span>
							{handleRemoveField ? (
								<button
									className='remove-field'
									onClick={() => handleRemoveField()}
								>
									Remove
								</button>
							) : (
								<></>
							)}
						</div>
					)}
					<div className={verify ? "flex input-wrapper alignCenter" : ""}>
						<Tooltip title={isInfoMessage}>
							<div
								className={
									icon
										? props.disabled
											? "flex icon-input alignCenter disabled"
											: "flex icon-input alignCenter"
										: "w-100"
								}
							>
								{icon}
								<div className='flex relative'>
									{prefix ? (
										<span className='prefix_text' ref={prefixRef}>
											{prefix}
										</span>
									) : (
										<></>
									)}
									<input
										ref={inputRef}
										value={value}
										type={type}
										name={name}
										placeholder={placeholder}
										{...props}
										onKeyDown={e =>
											suggetion
												? [
														handleKeyNavigation(e),
														handleKeyDown(e)
													]
												: [
														handleKeyDown(e),
														onKeyDown && onKeyDown(e)
													]
										}
										maxLength={maxLength}
										onChange={onChange}
										autoComplete='off'
										autoFocus={autoFocus}
										onFocus={() => {
											setFocus(true);
											onFocus && onFocus();
										}}
										onBlur={() => {
											setFocus(false);
											onBlur && onBlur();
										}}
										style={{
											paddingRight: `${suffix && (suffixRef.current?.clientWidth || 0) + 16}px`,
											paddingLeft: `${prefix && (prefixRef.current?.clientWidth || 0) + 16}px`
										}}
									/>
									{suffix ? (
										<span className='suffix_text' ref={suffixRef}>
											{suffix}
										</span>
									) : (
										<></>
									)}
								</div>
							</div>
						</Tooltip>
						{verify === "phone" && value?.length === 10 ? (
							<div className='verify-button'>
								<button
									type='button'
									className='verify'
									onClick={handleVerify}
								>
									Verify
								</button>
							</div>
						) : (
							""
						)}
					</div>
				</label>
				<div className='flex alignCenter justifyBetween'>
					{errorMessage ? <p className='error-message'>{errorMessage}</p> : ""}
					{showCount ? (
						<p className='total-char-count'>
							{value?.length || inputRef.current?.value.length || 0}/
							{maxLength}
						</p>
					) : (
						""
					)}
				</div>

				{isFocus && suggetion && suggetion?.length && (value?.length || 0) > 0 ? (
					<div className='suggesion-container' onMouseDown={handleMouseDown}>
						<ul ref={listRef}>
							{suggetion.map((data, index) => (
								<li
									key={index}
									className={activeIndex === index ? "active" : ""}
									onClick={() =>
										handleSuggetionClick && handleSuggetionClick(data)
									}
								>
									{data}
								</li>
							))}
						</ul>
					</div>
				) : (
					<></>
				)}

				{verify === "phone" && verifyValue ? (
					<VerifyOtpModal
						isUser={isUser}
						close={() => setVerifyValue(false)}
						open={verifyValue}
						phoneNumber={value || ""}
					/>
				) : (
					""
				)}
			</div>
		</div>
	);
};
