import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	getConnectionRequests,
	takeActionOnConnectionRequest
} from "../../service/connection-service";

const getConnectionRecievedRequestAsyncThunk = createAsyncThunk(
	"request/recieved",
	async ({
		search,
		offset,
		limit,
		signal
	}: {
		search: string;
		offset: number;
		limit: number;
		signal?: AbortSignal;
	}) => {
		const response = (
			await getConnectionRequests({
				search,
				offset,
				limit,
				signal
			})
		)?.data;

		return response;
	}
);

const takeActionOnConnectionRequestAsyncThunk = createAsyncThunk(
	"connection/request/action",
	async ({
		requestId,
		action,
		signal
	}: {
		requestId: number;
		action: string;
		signal?: AbortSignal;
	}) => {
		return (
			await takeActionOnConnectionRequest({
				requestId,
				action,
				signal
			})
		)?.data;
	}
);

export {
	getConnectionRecievedRequestAsyncThunk,
	takeActionOnConnectionRequestAsyncThunk
};
