import { useEffect, useState } from "react";
import { debounce } from "../utils/debounce";

const useWindowSize = (debounceDelay = 200) => {
	const [windowSize, setWindowSize] = useState<{
		width: number | undefined;
		height: number | undefined;
	}>({
		width: window.innerWidth,
		height: window.innerHeight
	});

	useEffect(() => {
		// Handler to call on window resize
		const handleResize = debounce(() => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight
			});
		}, debounceDelay);

		// Add event listener
		window.addEventListener("resize", handleResize);

		handleResize();

		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleResize);
	}, [debounceDelay]);

	return windowSize;
};

export default useWindowSize;
