import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import endPoint from "./endPoints.json";
import axiosInstance from "../../middleware/axios";

export const getComapnyDetailsById = createAsyncThunk(
	"companyProfile/details",
	async (id: number, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get(
				`${endPoint.getCompanyDetailsById}/${id}`
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const updateCompanyProfile = createAsyncThunk(
	"companyProfile/update",
	async (
		args: { id: number; payload: { image: any; data: any } },
		{ rejectWithValue }
	) => {
		try {
			const formData = new FormData();
			if (args.payload?.image) {
				args.payload?.image.forEach((file: string | Blob) => {
					formData.append("companyProfileImages", file);
				});
			}
			formData.append("companyUpdateData", JSON.stringify(args.payload?.data));
			const response = await axiosInstance.put(
				`${endPoint.companyProfileUpdate}/${args.id}`,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data"
					}
				}
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getProfileDetails = createAsyncThunk(
	endPoint.getUserProfile,
	async (_, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get(endPoint.getUserProfile);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const updateProfile = createAsyncThunk(
	endPoint.updateProfile,
	async (
		arg: {
			data: { firstName: string; lastName: string; email?: string };
			userId: number;
			profileImage: (string | File)[];
		},
		{ rejectWithValue }
	) => {
		try {
			const formData = new FormData();
			formData.append("userProfileData", JSON.stringify(arg.data));

			if (arg.profileImage) {
				arg.profileImage.forEach((file: string | Blob) => {
					formData.append("userProfileImages", file);
				});
			}

			const response = await axiosInstance.put(
				endPoint.updateProfile + "/" + arg.userId,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data"
					}
				}
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const updateMobile = createAsyncThunk(
	endPoint.updateMobile,
	async (arg: { userId: number; phoneNumber: string }, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.put(
				endPoint.updateMobile + "/" + arg.userId,
				{ phoneNo: arg.phoneNumber }
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getOtpforUpdate = createAsyncThunk(
	endPoint.getOtpUpdate,
	async (phoneNumber: string, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(endPoint.getOtpUpdate, {
				phoneNo: phoneNumber
			});
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const rolePermissionSendOTP = createAsyncThunk(
	endPoint.rolePermissionSendOTP,
	async (phoneNumber: string, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(endPoint.rolePermissionSendOTP, {
				phoneNo: phoneNumber
			});
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const updateVerifyOtp = createAsyncThunk(
	"auth/verifyOtpUpdate",
	async (arg: { phoneNumber: string; otp: string }, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(endPoint.verifyOtpUpdate, {
				phoneNo: arg.phoneNumber,
				OTP: arg.otp
			});
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const rolePermissionVerifyOTP = createAsyncThunk(
	endPoint.rolePermissionVerifyOTP,
	async (arg: { phoneNumber: string; otp: string }, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(endPoint.rolePermissionVerifyOTP, {
				phoneNo: arg.phoneNumber,
				OTP: arg.otp
			});
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getSupportData = createAsyncThunk(
	"support/getSupportEmailAndPhoneNo",
	async (_, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get(endPoint.getSupportEmailAndPhoneNo);
			return response?.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const removeUser = createAsyncThunk(
	"rolePermission/removeUser",
	async (payload: { userId: string | number }, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.patch(endPoint.removeUser, payload);
			return response?.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getUserByPhoneNo = createAsyncThunk(
	"rolePermission/getUserByPhoneNo",
	async (payload: { phoneNo: string | number }, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(endPoint.getUserByPhoneNo, payload);
			return response?.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);
