import ReactDOM from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import store, { persistor } from "./redux/store";
import { ThemeProvider } from "./utils/themeContext";
import CacheBusterProvider from "./shared/components/CacheBusterProvider";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
	<CacheBusterProvider>
		<ThemeProvider>
			<Provider store={store}>
				<PersistGate persistor={persistor}>
					<App />
				</PersistGate>
			</Provider>
		</ThemeProvider>
	</CacheBusterProvider>
);
