import { S3Link } from "../shared/constants/constant";

const BulkImportsImages = {
	SampleBulkImportCsvImage: `${S3Link}/assets/bulk-import-suggestion.png`,
	SampleBulkImportSuggestionImage: `${S3Link}/assets/sample-svg-bulk-import.png`
};

const sampleCsvLink = `${S3Link}/Biizline_Bulk_Product_Upload_Template.xlsx`;

export { BulkImportsImages, sampleCsvLink };
