import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import endPoints from "./endPoints.json";
import axiosInstance from "../../middleware/axios";

export const privacyPolicy = createAsyncThunk(
	endPoints.privacyPolicy,
	async (_, { rejectWithValue }) => {
		try {
			const response = await axios.get(
				process.env.REACT_APP_SOCKET_URL + "/api" + endPoints.privacyPolicy
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const disclaimerContent = createAsyncThunk(
	endPoints.disclaimer,
	async (_, { rejectWithValue }) => {
		try {
			const response = await axios.get(
				process.env.REACT_APP_SOCKET_URL + "/api" + endPoints.disclaimer
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const termsAndConditions = createAsyncThunk(
	endPoints.termsAndCondition,
	async (_, { rejectWithValue }) => {
		try {
			const response = await axios.get(
				process.env.REACT_APP_SOCKET_URL + "/api" + endPoints.termsAndCondition
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const registerDeviceToken = createAsyncThunk(
	endPoints.storeDevice,
	async (
		args: {
			id: number;
			payload: {
				token: string | null;
				from: string;
			};
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await axiosInstance.post(
				`${endPoints.storeDevice}/${args.id}`,
				args.payload
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const logoutDevice = createAsyncThunk(
	"logout/device",
	async (
		args: {
			id: number;
			payload: {
				token: string | null;
				from: string;
			};
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await axiosInstance.post(
				`${endPoints.logout}/${args.id}`,
				args.payload
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const searchHistory = createAsyncThunk(
	"searchHistory/get",
	async (searchType: string, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(endPoints.searchHistory, {
				searchType: searchType
			});
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const searchRemove = createAsyncThunk(
	"searchHistory/remove",
	async (serachHistoryIds: number[], { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(endPoints.searchRemove, {
				serachHistoryIds: serachHistoryIds
			});
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const storeSearchHistory = createAsyncThunk(
	"searchHistory/store",
	async (arg: { search: string; searchType: string }, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(endPoints.storeSearchHistory, arg);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const fetchTokenData = createAsyncThunk(
	endPoints.fetchTokenData,
	async (_, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get(endPoints.fetchTokenData);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);
