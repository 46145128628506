import { createSlice } from "@reduxjs/toolkit";
import { getComapnyDetailsById } from "../services/settings";
import { getCompanyDetails } from "../services/company";
import { SliceNames } from "../../shared/constants/constant";
import { TCompanyState } from "../../types/company";

const initialState: TCompanyState = {
	companyProfile: {
		isLoading: false,
		isSuccess: false,
		data: null,
		message: ""
	},
	companyDetailList: {
		isLoading: false,
		isSuccess: false,
		data: null,
		message: ""
	}
};

const companySlice = createSlice({
	name: SliceNames.COMPANY,
	initialState,
	reducers: {
		setCompanyProfileData: (state, { payload }) => {
			state.companyProfile.data = payload;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getComapnyDetailsById.pending, state => {
				state.companyProfile.data = null;
				state.companyProfile.isLoading = true;
				state.companyProfile.isSuccess = false;
			})
			.addCase(getComapnyDetailsById.fulfilled, (state, { payload }: any) => {
				state.companyProfile.isSuccess = true;
				state.companyProfile.isLoading = false;
				state.companyProfile.data = payload?.data;
				state.companyProfile.message = payload?.message;
			})
			.addCase(getComapnyDetailsById.rejected, (state, { payload }: any) => {
				state.companyProfile.data = null;
				state.companyProfile.isSuccess = false;
				state.companyProfile.isLoading = false;
				state.companyProfile.message = payload?.message;
			})

			//
			.addCase(getCompanyDetails.pending, state => {
				state.companyDetailList.data = null;
				state.companyDetailList.isLoading = true;
				state.companyDetailList.isSuccess = false;
			})
			.addCase(getCompanyDetails.fulfilled, (state, { payload }: any) => {
				state.companyDetailList.isSuccess = true;
				state.companyDetailList.isLoading = false;
				state.companyDetailList.data = payload?.data;
				state.companyDetailList.message = payload?.message;
			})
			.addCase(getCompanyDetails.rejected, (state, { payload }: any) => {
				state.companyDetailList.data = [];
				state.companyDetailList.isSuccess = false;
				state.companyDetailList.isLoading = false;
				state.companyDetailList.message = payload?.message;
			});
	}
});

export const { setCompanyProfileData } = companySlice.actions;
export default companySlice.reducer;
