import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PlusIcons } from "../../assets/svg";
import { UserIcon } from "../../assets/svg/icons";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { selectCompany } from "../../redux/services/auth";
import { fetchTokenData } from "../../redux/services/common";
import { getCompanyDetails } from "../../redux/services/company";
import { getSubscriptionPayloadData } from "../../redux/services/subscription";
import { clearAddressData } from "../../redux/slices/addressSlice";
import { setTokenAction } from "../../redux/slices/authSlice";
import { MOBILE_DEVICE_REGEX } from "../../shared/constants/constant";
import {
	ALTER_TEXT,
	CHOOSE_COMPANY_POPUP
} from "../../shared/constants/content-constant";
import { SubscriptionActivityName, SubscriptionModules } from "../../shared/enum/enum";
import { getRestrictions, useIsAdmin } from "../../utils/helper";
import CountCapsule from "../countCapsule";
import ImageLoad from "../ImageLoad";
import UserInfo from "../userInfo";

interface Props {
	handleOpenChange: () => void;
	companyNotificationCountList?: any;
}

const CompanyListAndSetting = ({
	handleOpenChange,
	companyNotificationCountList
}: Props) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const isAdmin = useIsAdmin();

	const [companyRestrictionData, setCompanyRestrictionData] = useState();
	const { userDetails } = useAppSelector(state => state.common);
	const { planDetailsSummary } = useAppSelector(state => state.subscription);

	const { companyDetailList } = useAppSelector(state => state.company);
	const { token } = useAppSelector(state => state.auth);

	const companyDetails = userDetails.data?.company;
	const { userId } = userDetails.data?.user || { userId: 0 };

	const userData = userDetails.data?.user;
	const currentCompany = userDetails.data?.company;
	const isMobileOrTablet = MOBILE_DEVICE_REGEX.test(navigator.userAgent);
	const createCompanyRestriction =
		companyRestrictionData &&
		getRestrictions(companyRestrictionData, SubscriptionActivityName.COMPANY);

	useEffect(() => {
		if (!isMobileOrTablet && userId && token) {
			dispatch(getSubscriptionPayloadData({ module: SubscriptionModules.COMPANY }))
				.then(result => {
					if (result?.payload?.status === 200) {
						setCompanyRestrictionData(result?.payload?.data);
					}
				})
				.catch(error => console.error(error));
		}
	}, [dispatch, token, userId, planDetailsSummary, companyDetails, isMobileOrTablet]);

	const handleSwitchCompany = async (companyId: number) => {
		userData?.userId &&
			(await dispatch(
				selectCompany({ companyId: companyId, userId: userData?.userId })
			)
				.then(result => {
					if (result?.payload?.status === 200) {
						dispatch(setTokenAction(result.payload.data.token));
						setTimeout(() => {
							dispatch(getCompanyDetails());
							dispatch(fetchTokenData());
						}, 200);
						navigate("/dashboard");
						handleOpenChange();
						dispatch({ type: "RESET_STATE" });
					}
				})
				.catch(error => console.error(error)));
	};

	const handleAddNewComapny = () => {
		dispatch(clearAddressData());
		navigate("/create-company", {
			state: { phoneNo: userData?.phoneNo, phoneNumber: userData?.phoneNo }
		});
		handleOpenChange();
	};

	return (
		<div className='company-list-popover'>
			<div className='selected-company-box'>
				<div className={"company-box"}>
					<div className='user-box gap-2 flex alignCenter'>
						<div className='img-box'>
							{currentCompany && currentCompany?.profileThumbnailImg ? (
								<ImageLoad
									className='profile-img'
									src={currentCompany?.profileThumbnailImg}
									alt={ALTER_TEXT.PROFILE_IMAGE}
								/>
							) : (
								<UserIcon />
							)}
						</div>
						<div className='customer-info flex direction-column'>
							<span>{currentCompany?.name}</span>
							{currentCompany?.district && (
								<span className='user-name'>{`${currentCompany?.district}, ${currentCompany?.state}`}</span>
							)}
						</div>
					</div>
				</div>
			</div>
			{companyDetailList?.data && companyDetailList?.data?.length > 1 && (
				<div className='switch-company-container'>
					<div className='switch-company-box'>{CHOOSE_COMPANY_POPUP.TITLE}</div>
				</div>
			)}

			{companyDetailList?.data &&
				companyDetailList?.data
					?.filter(data => data.companyId !== currentCompany?.companyId)
					?.map((data, index) => {
						const notificationCount = companyNotificationCountList?.filter(
							(item: { companyId: number; allNotificationCount: number }) =>
								item?.companyId === data.companyId
						)[0]?.allNotificationCount;
						return (
							<button
								key={index}
								className='switch-company-profile-container'
								onClick={() =>
									handleSwitchCompany(
										data.companyId
										// data?.user?.userRole?.role?.roleName?.toLowerCase() ===
										// 	"admin"
									)
								}
							>
								<div className='inner-box flex alignCenter justifyBetween'>
									<UserInfo
										profileImage={data.profileThumbnailImg || ""}
										comanyName={data.name}
										userName={`${data.district}, ${data.state}`}
									/>

									{notificationCount > 0 ? (
										<CountCapsule
											count={notificationCount}
											limit={10}
										/>
									) : (
										<></>
									)}
								</div>
							</button>
						);
					})}
			{isAdmin && createCompanyRestriction ? (
				<button className='add-company-container' onClick={handleAddNewComapny}>
					<div className='inner-box'>
						<PlusIcons />
						{CHOOSE_COMPANY_POPUP.ADD_COMPANY}
					</div>
				</button>
			) : (
				<></>
			)}
		</div>
	);
};

export default CompanyListAndSetting;
