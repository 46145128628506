const FILE_RESTRICTIONS = {
	MAX_IMAGE_SIZE: "Image upload size max. 2MB"
};

const ERROR_STRINGS = {
	DUPLICATE_BULK_RATE: "Duplicate Bulk rates are not Allowed",
	EMPTY_BULK_RATE: "Please Enter Bulk rate",
	INVALID_EMAIL: "Email address must be contain @, ., and domain name",
	GST_MESSAGE: "Please enter valid GST number",
	PHONE_NUMBER: "Please enter a valid number",
	VERIFY_PHONE_NUMBER: "Please verify phone number",
	ALTERNATE_PHONE_NUMBER: "Please enter a valid phone number",
	SOMETHING_WRONG: "Something Went Wrong",
	PIN_CODE: "Please enter 6 digit pincode",
	LOCATION: "Please enter correct location",
	COMPANY_NAME: "Please enter company name",
	LOGIN_FAIELD: "Login failed"
};

const SEARCH_INPUT_PLACEHOLDER = {
	PRODUCT_GROUP: "Search Group"
};

const INPUT_FIELDS = {
	FIRST_NAME: {
		LABEL: "First Name",
		PLACEHOLDER: "Enter First Name"
	},
	LAST_NAME: {
		LABEL: "Last Name",
		PLACEHOLDER: "Enter Last Name"
	},
	PHONE_NUMBER: {
		LABEL: "Phone Number",
		PLACEHOLDER: "Enter WhatsApp number"
	},
	EMAIL: {
		LABEL: "Email Address",
		PLACEHOLDER: "Enter your email address"
	},
	CREATE_ROLE: {
		LABEL: "Create Role",
		PLACEHOLDER: "Ex. Product Manager, Accountant"
	},
	SEARCH: {
		PLACEHOLDER: "Search Product"
	}
};

const ACCOUNT_SETTINGS = {
	TITLE: "Account Settings",
	TEXT: "Effortlessly organize your customer and vendor orders with ease and efficiency using our solution!",
	CREATE_USER_ROLL_FORM: {
		FORM_TITLE_1: "Update User",
		FORM_TITLE_2: "Add User"
	},
	ROLE_AND_ACCESS_PAGE: {
		TABLE: {
			TITLE: "Give Access",
			HEADING: "Modules"
		}
	},
	PRODUCT_MANAGEMENT_PAGE: {
		PAGE_TITLE: "Product Management",
		TEXT: "Adjust your product catalog settings and enable or disable modules to simplify your product management process.",
		TABLE_TITLE: "Module"
	},
	CONNECTION_PAGE: {
		TITLE: "Connections",
		DESCRIPTION:
			"Take charge of your connections by blocking or unblocking users. This section helps you maintain a secure and well-managed network."
	},
	NOTIFICATION_PAGE: {
		TITLE: "Notification",
		DESCRIPTION:
			"Manage your notification preferences to stay updated on important actions and events.",
		TABLE_TITLE: "Modules"
	},
	CONNECTION_UNBLOCK_POPUP: {
		TITLE: "Unblock Connection",
		DESCRIPTION: "Are you sure you want to unblock this connection?",
		BUTTON_TEXT_1: "Cancel",
		BUTTON_TEXT_2: "Unblock",
		TABLE_TITLE: "Blocked Connections"
	},
	SUBSCRIPTION_PAGE: {
		TITLE: "Subscription & Billing",
		DESCRIPTION:
			"Choose a plan that best suits your business needs and enjoy features that enhance your experience.",
		SUPPORT_BUTTON: "Get Support",
		SUBSCRIPTION_BODY: {
			SUBSCRIPTION_TITLE: "Plans & Features"
		}
	},
	SUBSCRIPTION_FORM: {
		TITLE: "Billing Details",
		TEXT: "Manage your subscriptions with ease and unlock exclusive business tools.",
		SUMMARY_TEXT:
			"Easily manage your business subscriptions with Biizline. Upgrade to premium plans and unlock advanced features tailored for your business needs. Simplify your workflow and grow your business seamlessly.",
		INPUT_FIELDS: {
			FIRST_NAME: {
				LABEL: "First Name",
				PLACEHOLDER: "Enter First name"
			},
			LAST_NAME: {
				LABEL: "Last Name",
				PLACEHOLDER: "Enter Last name"
			},
			PHONE_NUMBER: {
				LABEL: "Phone Number",
				PLACEHOLDER: "Enter Phone number"
			},
			ALTERNATE_NUMBER: {
				LABEL: "Alternate Phone Number (Optional)",
				PLACEHOLDER: "Enter Alternate phone number"
			},
			EMAIL_ADDRESS: {
				LABEL: "Email Address (Optional)",
				PLACEHOLDER: "Enter Email Address"
			},
			PROMOCODE: {
				LABEL: "Enter Code",
				PLACEHOLDER: "Enter Promo code"
			},
			GST_CODE: {
				LABEL: "GST Number",
				PLACEHOLDER: "Enter GST Number"
			},
			COMPANY_NAME: {
				LABEL: "Company Name",
				PLACEHOLDER: "Enter Company Name"
			},
			PINCODE: {
				LABEL: "Pincode",
				PLACEHOLDER: "Enter Pincode"
			},
			LOCATION: {
				LABEL: "Location",
				PLACEHOLDER: "District, State"
			},
			ADDRESS_LINE: {
				LABEL: "Address Line (Optional)",
				PLACEHOLDER: "Enter your address line"
			}
		},
		BUTTONS: {
			MAKE_PAYMENT: "Make Payment",
			GO_BACK: "Go Back"
		},
		CHECKBOX_INPUT_FIELDS: {
			PROMOCODE: {
				LABEL: "I have a Promo Code"
			},
			GST_CODE: {
				LABEL: "I Need GST Invoice"
			}
		},
		PAYMENT_FOR: "Payment for",
		APPLIED_DISCOUNT: "Applied Discount",
		CHANGE_PLAN: "Change Plan",
		SUMMARY: "Summary",
		SUBTOTAL: "Subtotal",
		WEIVE_OF_AMOUNT: "Waive of amount",
		PLAN_CREDIT: "Plan Credit",
		PLAN_AMOUNT: "Plan amount",
		IGST: "IGST",
		CGST: "CGST",
		SGST: "SGST",
		GRAND_TOTAL: "Grand Total"
	}
};

const CONFIRMATION_POPUP_STRINGS = {
	ORDER_MANAGEMENT_PAGE: {
		SAVE_CHANGES: {
			TITLE: "Save Changes",
			TEXT: "Are you sure you want to save your changes?",
			CONFIRM_BUTTON: "Save Changes",
			CANCEL_BUTTON: "Cancel"
		}
	}
};

const NO_DATA_FOUND_STRINGS = {
	NO_RECORD_DATA: "No Record Found."
};

const ALTER_TEXT = {
	PROFILE_IMAGE: "Profile Img",
	SUBSCRIPTION_PLAN_IMG: "plan-image",
	LOGO: "Logo",
	COMPANY_IMAGE: "companyImage",
	SUBSCRIPTION_POSTER: "SubscriptionPoster"
};

const WARNING_STRINGS = {
	ENTER_CANCEL_REASON: "Please enter a reason to cancel order",
	SELECT_CANCEL_REASON: "Please select a reason to cancel order"
};

const CANCEL_ORDER_MODAL = {
	TITLE: "Cancel Order",
	TEXT: "Let us know why you want to cancel your order.",
	SAVE_BUTTON: "Submit"
};

const CHOOSE_VENDOR_MODAL = {
	TITLE: "Choose Vendor",
	TEXT: "Connections.",
	BUTTON_TEXT_1: "Invite Vendor",
	BUTTON_TEXT_2: "Clear All",
	SAVE_BUTTON: "Confirm",
	CANCEL_BUTTON: "Cancel"
};

const CHOOSE_CUSTOMER_MODAL = {
	TITLE: "Choose Customer",
	TEXT: "Connections.",
	BUTTON_TEXT_1: "Invite Connection",
	BUTTON_TEXT_2: "Clear All",
	SAVE_BUTTON: "Confirm",
	CANCEL_BUTTON: "Cancel"
};

const CHOOSE_COMPANY_POPUP = {
	TITLE: "Switch company",
	TITLE_2: "Company setting",
	LOGOUT: "Logout",
	ADD_COMPANY: "Add Company"
};

const LOGOUT_POPUP = {
	TITLE: "Are you sure, you want to Logout?",
	SUCCESS: "Yes, Logout",
	NO: "No"
};

const UPGRADE_TO_PREMIUM_POPUP = {
	TITLE: "Please Upgrade to Premium",
	TEXT: "Upgrade to Premium and take your experience to the next level with advanced tools and benefits designed to make your work faster, smarter, and more efficient.",
	CANCEL_BUTTON: "Cancel",
	SUCCESS_BUTTON: "Upgrade to Premium"
};

const COMPANY_SETTING = {
	TITLE: "Company Details",
	TEXT: "Manage and update your company's essential details.",
	SAVE_BUTTON: "Save"
};

const COMPONENTS_STRINGS = {
	RECENT_ORDER_STRINGS: {
		REORDER: "Re-order",
		ITEMS: "Items",
		ORDER_NO: "Order No.",
		REF_ID: "Ref Id"
	},
	HEADER: {
		GET_PREMIUM: "Get Premium"
	}
};

const PLACEORDER_PAGE = {
	ITEMS: "items",
	RECENT_ORDERS: "Recent Orders",
	SORT_BY: "Sort by",
	PRODUCTS_SECTION_TITLE: "List of Products",
	FAVOURITE_PRODUCTS_TITLE: "My Favourite Products",
	MOST_ORDERED_PRODUCTS: "Most Ordered Products"
};

const GET_PREMIUM_PAGE = {
	PAGE_TITLE: "Upgrade to Access Web",
	SUB_TEXT: "To unlock full access to our Web Application, choose your plan today:",
	PLAN_HEADEING: "Business Plan",
	PLAN_1_TEXT: "Gold",
	PLAN_2_TEXT: "Premium",
	PLAN_SUB_TEXT: "Billing annually",
	WHY_UPGRADE: {
		HEADING: "Why Upgrade:",
		POINTS: {
			POINT_1: "Seamless order management",
			POINT_2: "Enhanced business insights",
			POINT_3: "Access from anywhere, anytime"
		}
	},
	BUTTON_TEXT_1: "Subscribe now",
	BUTTON_TEXT_2: "Logout",
	BUTTON_TEXT_3: "Buy Now",
	TEXT_1: "and take your business to the next level!"
};

const PRODUCTS_MODULE = {
	TITLE: "Products",
	Add_New_Product_Title: "Add New Product",
	Product_Information: "Product Information",
	Grooup_Sidebar: {
		Title: "Group",
		Create_Button: "Create"
	}
};

export {
	ACCOUNT_SETTINGS,
	ALTER_TEXT,
	CANCEL_ORDER_MODAL,
	CHOOSE_COMPANY_POPUP,
	CHOOSE_CUSTOMER_MODAL,
	CHOOSE_VENDOR_MODAL,
	COMPANY_SETTING,
	COMPONENTS_STRINGS,
	CONFIRMATION_POPUP_STRINGS,
	ERROR_STRINGS,
	FILE_RESTRICTIONS,
	GET_PREMIUM_PAGE,
	INPUT_FIELDS,
	LOGOUT_POPUP,
	NO_DATA_FOUND_STRINGS,
	PLACEORDER_PAGE,
	SEARCH_INPUT_PLACEHOLDER,
	UPGRADE_TO_PREMIUM_POPUP,
	WARNING_STRINGS,
	PRODUCTS_MODULE
};
