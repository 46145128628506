const S3Link = `https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com`;

const Testimonials = [
	`${S3Link}/assets/images/testimonial1.png`,
	`${S3Link}/assets/images/testimonial2.png`,
	`${S3Link}/assets/images/testimonial3.png`,
	`${S3Link}/assets/images/testimonial4.png`
];

const LandingPageImages = {
	IMAGE_ONE: `${S3Link}/assets/images/landingPage/img1.png`,
	IMAGE_TWO: `${S3Link}/assets/images/landingPage/img2.png`,
	IMAGE_THREE: `${S3Link}/assets/images/landingPage/img3.png`,
	IMAGE_FOUR: `${S3Link}/assets/images/landingPage/img4.png`,
	IMAGE_FIVE: `${S3Link}/assets/images/landingPage/img5.png`,
	IMAGE_SIX: `${S3Link}/assets/images/landingPage/img6.png`,
	IMAGE_SEVEN: `${S3Link}/assets/images/landingPage/img7.png`,
	IMAGE_EIGHT: `${S3Link}/assets/images/landingPage/img8.png`,
	IMAGE_NINE: `${S3Link}/assets/images/landingPage/img9.png`,
	IMAGE_TEN: `${S3Link}/assets/images/landingPage/img10.png`,
	IMAGE_ELEVEN: `${S3Link}/assets/images/landingPage/img11.png`
};

const FeatureListContents = [
	{
		content: {
			title: "Manage Your Orders",
			text: "Oversee all transactions in a unified location, regardless if they originate from WhatsApp, phone calls, emails, or offline.This guarantees users maintain orderliness sans the need to balance various platforms."
		},
		details: {
			image: LandingPageImages?.IMAGE_SIX
		}
	},
	{
		content: {
			title: "Make Private Connection with Vendors & Customers",
			text: "Securely connect with vendors and customers directly within the platform, enabling private and seamless communication."
		},
		details: {
			image: LandingPageImages?.IMAGE_EIGHT
		}
	},
	{
		content: {
			title: "Create Dynamic Product List",
			text: "Add and manage comprehensive product details effortlessly, offering more flexibility and information compared to other order management software."
		},
		details: {
			image: LandingPageImages?.IMAGE_NINE
		}
	},
	{
		content: {
			title: "Set Special Rate for Every Customer",
			text: "Set personalized prices for each customer, making it easier to maintain strong relationships and offer tailored pricing strategies."
		},
		details: {
			image: LandingPageImages?.IMAGE_TEN
		}
	},
	{
		content: {
			title: "Set Offers for Specific Customers",
			text: "Customize and set specific offers for different customers, helping to build loyalty and increase sales by offering promotions that resonate with them."
		},
		details: {
			image: LandingPageImages?.IMAGE_ELEVEN
		}
	}
];

const HeroImages = {
	DESKTOP: `${S3Link}/assets/images/heroImage.png`,
	MOBILE: `${S3Link}/assets/images/HeroImageMobile.png`
};

const nav_links = [
	{
		link: "#home",
		text: "Home"
	},
	{
		link: "#howitwork",
		text: "How it Work"
	},
	{
		link: "#features",
		text: "Features"
	},
	{
		link: "#about",
		text: "About us"
	},
	{
		link: "#contactus",
		text: "Contact Us"
	}
];

export { FeatureListContents, HeroImages, LandingPageImages, nav_links, Testimonials };
