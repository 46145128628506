import React, { ReactNode } from "react";
import { Drawer, DrawerProps } from "antd";

interface Props extends DrawerProps {
	title: ReactNode;
	onClose: () => void;
	open: boolean;
	rootClassName: string;
	children: React.ReactNode;
}

const classNames = {
	body: "my-drawer-body",
	mask: "my-drawer-mask",
	header: "my-drawer-header",
	content: "my-drawer-content"
};

const CommonDrawer = ({
	title,
	onClose,
	open,
	rootClassName,
	children,
	...props
}: Props) => {
	return (
		<Drawer
			title={title}
			closeIcon={false}
			onClose={onClose}
			open={open}
			rootClassName={rootClassName}
			classNames={classNames}
			{...props}
		>
			{children}
		</Drawer>
	);
};

export default CommonDrawer;
