import { Spin } from "antd";
import axios from "axios";
import React, { Suspense, useEffect, useState } from "react";
import OneSignal from "react-onesignal";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ConfirmationPopup from "../components/actionModals/confirmationPopup";
import { Header } from "../components/header";
import NotificationDrawer from "../components/notification/notificationDrawer";
import PushNotification from "../components/pushNotification";
import { NotificationModules } from "../enum/notification-enum";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { useAppSelector } from "../hooks/useAppSelector";
import useMessageHook from "../hooks/useMessageHook";
import { selectCompany } from "../redux/services/auth";
import { fetchTokenData, registerDeviceToken } from "../redux/services/common";
import { getCompanyDetails } from "../redux/services/company";
import endPoints from "../redux/services/endPoints.json";
import {
	clearNotificationData,
	notificationOrderData
} from "../redux/slices/notificationSlice";
import { updatePlaceOrderStatus } from "../redux/slices/placeorderSlice";
import { ERROR_STRINGS } from "../shared/constants/content-constant";
import { TUserDetailsData } from "../types/common";
import { setTokenAction } from "../redux/slices/authSlice";
import Loader from "../shared/components/Loader";
import { resetProductDraftState } from "../redux/slices/productDraftSlice";
interface IProps {
	children?: React.ReactNode;
}

const Layout = ({ children }: IProps) => {
	const baseURL = process.env.REACT_APP_API_BASE_URL;

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const location = useLocation();

	const { showMessage, contextHolder } = useMessageHook();

	const { isNotificationDrawerOpen } = useAppSelector(state => state.notification);
	const { placeOrderData } = useAppSelector(state => state.placeorder);
	const { userDetails } = useAppSelector(state => state.common);
	const { fcm_token: oneSignalToken, token } = useAppSelector(state => state.auth);
	const { product: draftProductData, isDraft: isDraftProduct } = useAppSelector(
		state => state.productDraft
	);

	const [isLoading, setIsLoading] = useState(false);
	const [productSaveLoading, setProductSaveLoading] = useState(false);
	const [isDraft, setIsDraft] = useState(false);

	const { userId } = userDetails.data?.user || { userId: 0 };
	const companyId = userDetails.data?.company?.companyId;

	useEffect(() => {
		if (isDraftProduct && location.pathname !== "/products/add") {
			setIsDraft(isDraftProduct);
		}
	}, [isDraftProduct, location?.pathname]);

	const handleRedirection = (event: any) => {
		if (
			event.notification.additionalData.moduleName ===
			NotificationModules.CONNECTION
		) {
			navigate("/connection");
		}
		if (event.notification.additionalData.moduleName === NotificationModules.ORDER) {
			navigate("/orders");
			dispatch(notificationOrderData(event.notification.additionalData));
		}
	};

	const handleCompany = (event: any, userDetails: TUserDetailsData | null) => {
		const notificationCompanyId = event.notification.additionalData.companyId;
		const currentCompanyId = userDetails?.company.companyId;
		const currentUserId = userDetails?.user?.userId ?? 0;
		if (notificationCompanyId === currentCompanyId) {
			handleRedirection(event);
		} else {
			dispatch(
				selectCompany({ companyId: notificationCompanyId, userId: currentUserId })
			)
				.then(result => {
					if (result?.payload?.status === 200) {
						dispatch(setTokenAction(result?.payload?.data?.token));
						setTimeout(() => {
							dispatch(getCompanyDetails());
							dispatch(fetchTokenData());
							handleRedirection(event);
						}, 200);
					} else {
						showMessage(
							"error",
							result?.payload.message || "Something went wrong"
						);
					}
				})
				.catch(() => showMessage("error", ERROR_STRINGS.SOMETHING_WRONG));
		}
	};

	useEffect(() => {
		if (userDetails?.data) {
			OneSignal.Notifications.addEventListener("click", (event: any) => {
				handleCompany(event, userDetails.data);
			});
		}

		return () => {
			OneSignal.Notifications.removeEventListener("click", (event: any) => {
				handleCompany(event, userDetails.data);
			});
		};
	}, [userDetails.data]);

	useEffect(() => {
		try {
			dispatch(fetchTokenData());
			dispatch(getCompanyDetails());
			setIsLoading(false);
		} catch (error) {
			showMessage("error", ERROR_STRINGS.SOMETHING_WRONG);
		}
	}, []);

	useEffect(() => {
		dispatch(clearNotificationData());
	}, [companyId, dispatch]);

	useEffect(() => {
		const placeorderTimeOut = setTimeout(() => {
			dispatch(updatePlaceOrderStatus(false));
		}, 3000);

		return () => {
			clearTimeout(placeorderTimeOut);
		};
	}, [placeOrderData.isSuccess, dispatch]);

	useEffect(() => {
		const id = OneSignal.User.PushSubscription.id;
		if (id && userId && oneSignalToken) {
			OneSignal.login(id);

			const payload = {
				token: id,
				from: "Web"
			};
			dispatch(registerDeviceToken({ id: userId, payload: payload }));
		}
	}, [oneSignalToken, userId, dispatch]);

	const handleClose = () => {
		dispatch(resetProductDraftState());
		setIsDraft(false);
	};

	const handleAddToDraft = async () => {
		if (isDraft) {
			setProductSaveLoading(true);
			const { product, productData } = draftProductData;
			const formData = new FormData();
			product?.forEach((file: string | Blob) => {
				formData.append("productImages", file);
			});
			const modifiedData = { ...productData, isDraft: true };
			formData.append("productData", JSON.stringify(modifiedData));
			await axios
				.post(baseURL + endPoints.addProduct, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: `Bearer ${token}`
					}
				})
				.then(result => {
					if (result.status === 200) {
						navigate(location.pathname, { replace: true });
						handleClose();
					} else {
						showMessage("error", result?.data?.message);
					}
				})
				.catch(errr => {
					showMessage("error", errr.response?.data?.message);
				})
				.finally(() => setProductSaveLoading(false));
		}
	};

	if (isLoading) {
		return <Loader />;
	}

	return (
		<div className='layout'>
			<Header />
			{companyId ? <PushNotification /> : <></>}
			{contextHolder}
			{isDraft ? (
				<ConfirmationPopup
					headerContent='Save as Draft'
					open={isDraft || false}
					close={() => handleClose()}
					confirm={() => handleAddToDraft()}
					infoContent='Are you sure you want to leave without saving your product?'
					cancelButtonText='Yes, Leave'
					confirmButtonText='Save Draft'
					disabled={productSaveLoading}
				/>
			) : (
				<div className='content'>
					<Suspense fallback={<Loader />}>
						<Outlet />
					</Suspense>
				</div>
			)}
			{isNotificationDrawerOpen && companyId ? <NotificationDrawer /> : <></>}
		</div>
	);
};

export default Layout;
