const RoundClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_3370_16160)">
        <path
          d="M8 5.33398V8.00065L9.66667 9.66732"
          stroke="black"
          strokeOpacity="0.45"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.26254 3.26303L2.80293 2.80341L2.80293 2.80341L3.26254 3.26303ZM2.32449 4.20109L1.6745 4.20435C1.67629 4.56079 1.96479 4.84929 2.32122 4.85108L2.32449 4.20109ZM4.20681 4.86055C4.56579 4.86236 4.85826 4.57281 4.86007 4.21383C4.86187 3.85485 4.57232 3.56238 4.21334 3.56057L4.20681 4.86055ZM2.96501 2.31224C2.9632 1.95326 2.67073 1.66371 2.31175 1.66551C1.95277 1.66731 1.66322 1.95979 1.66502 2.31877L2.96501 2.31224ZM1.9835 8.00062C1.9835 7.64163 1.69248 7.35062 1.3335 7.35062C0.974511 7.35062 0.683496 7.64163 0.683496 8.00062H1.9835ZM11.6589 14.338C11.9697 14.1583 12.0758 13.7606 11.8961 13.4499C11.7163 13.1392 11.3187 13.033 11.008 13.2127L11.6589 14.338ZM13.2122 11.0085C13.0325 11.3192 13.1387 11.7168 13.4494 11.8966C13.7602 12.0763 14.1578 11.9701 14.3375 11.6594L13.2122 11.0085ZM2.85247 2.80113C2.59736 3.0537 2.59531 3.46525 2.84789 3.72036C3.10046 3.97546 3.51201 3.97751 3.76712 3.72493L2.85247 2.80113ZM13.1501 2.85079C10.2841 -0.0152175 5.65148 -0.0451397 2.80293 2.80341L3.72216 3.72265C6.05435 1.39047 9.86379 1.40297 12.2309 3.77003L13.1501 2.85079ZM2.80293 2.80341L1.86487 3.74147L2.78411 4.66071L3.72216 3.72265L2.80293 2.80341ZM2.32122 4.85108L4.20681 4.86055L4.21334 3.56057L2.32776 3.5511L2.32122 4.85108ZM2.97448 4.19782L2.96501 2.31224L1.66502 2.31877L1.6745 4.20435L2.97448 4.19782ZM8.00013 1.98398C11.323 1.98398 14.0168 4.67772 14.0168 8.00062H15.3168C15.3168 3.95975 12.041 0.683984 8.00013 0.683984V1.98398ZM8.00013 14.0173C4.67724 14.0173 1.9835 11.3235 1.9835 8.00062H0.683496C0.683496 12.0415 3.95927 15.3173 8.00013 15.3173V14.0173ZM11.008 13.2127C10.1237 13.7243 9.0971 14.0173 8.00013 14.0173V15.3173C9.33173 15.3173 10.5821 14.9609 11.6589 14.338L11.008 13.2127ZM14.0168 8.00062C14.0168 9.09759 13.7238 10.1242 13.2122 11.0085L14.3375 11.6594C14.9605 10.5826 15.3168 9.33222 15.3168 8.00062H14.0168ZM3.76712 3.72493C4.85487 2.64796 6.34933 1.98398 8.00013 1.98398V0.683984C5.99309 0.683984 4.17372 1.49297 2.85247 2.80113L3.76712 3.72493Z"
          fill="black"
          fillOpacity="0.45"
        />
      </g>
      <defs>
        <clipPath id="clip0_3370_16160">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { RoundClockIcon };
