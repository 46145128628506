const CompanyIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M13.1302 2.4974V18.3307C13.1302 18.4965 13.0644 18.6555 12.9471 18.7727C12.8299 18.8899 12.671 18.9557 12.5052 18.9557H2.50521C2.11854 18.9557 1.74771 18.8024 1.47438 18.5282C1.33879 18.393 1.23123 18.2323 1.15787 18.0554C1.08451 17.8785 1.04679 17.6889 1.04688 17.4974V2.4974C1.04688 2.11073 1.20021 1.7399 1.47438 1.46656C1.60961 1.33097 1.77028 1.22342 1.94718 1.15006C2.12407 1.0767 2.31371 1.03898 2.50521 1.03906H11.6719C12.0585 1.03906 12.4294 1.1924 12.7027 1.46656C12.9769 1.7399 13.1302 2.11073 13.1302 2.4974ZM11.8802 2.4974C11.8802 2.44214 11.8583 2.38915 11.8192 2.35008C11.7801 2.31101 11.7271 2.28906 11.6719 2.28906H2.50521C2.44995 2.28906 2.39696 2.31101 2.35789 2.35008C2.31882 2.38915 2.29688 2.44214 2.29688 2.4974V17.4974C2.29688 17.5526 2.31882 17.6056 2.35789 17.6447C2.39696 17.6838 2.44995 17.7057 2.50521 17.7057H11.8802V2.4974Z'
				fill='black'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9.79427 12.9167V18.3333C9.79427 18.4991 9.72842 18.6581 9.61121 18.7753C9.494 18.8925 9.33503 18.9583 9.16927 18.9583H5.0026C4.83684 18.9583 4.67787 18.8925 4.56066 18.7753C4.44345 18.6581 4.3776 18.4991 4.3776 18.3333V12.9167C4.37804 12.6405 4.48793 12.3758 4.68319 12.1806C4.87844 11.9853 5.14314 11.8754 5.41927 11.875H8.7526C9.0286 11.8759 9.29304 11.9859 9.4882 12.1811C9.68336 12.3762 9.79339 12.6407 9.79427 12.9167ZM5.6276 13.125V17.7083H8.54427V13.125H5.6276ZM18.9609 7.5V17.5C18.9609 17.8868 18.8073 18.2577 18.5338 18.5312C18.2603 18.8047 17.8894 18.9583 17.5026 18.9583H12.5026C12.3368 18.9583 12.1779 18.8925 12.0607 18.7753C11.9435 18.6581 11.8776 18.4991 11.8776 18.3333V6.66667C11.8776 6.50091 11.9435 6.34193 12.0607 6.22472C12.1779 6.10751 12.3368 6.04167 12.5026 6.04167H17.5026C18.3076 6.04167 18.9609 6.69417 18.9609 7.5ZM17.7109 7.5C17.7109 7.44475 17.689 7.39176 17.6499 7.35269C17.6108 7.31362 17.5579 7.29167 17.5026 7.29167H13.1276V17.7083H17.5026C17.5579 17.7083 17.6108 17.6864 17.6499 17.6473C17.689 17.6082 17.7109 17.5553 17.7109 17.5V7.5ZM3.96094 3.75H5.21094V5H3.96094V3.75ZM3.96094 6.66667H5.21094V7.91667H3.96094V6.66667ZM3.96094 9.58333H5.21094V10.8333H3.96094V9.58333ZM6.46094 3.75H7.71094V5H6.46094V3.75ZM6.46094 6.66667H7.71094V7.91667H6.46094V6.66667ZM6.46094 9.58333H7.71094V10.8333H6.46094V9.58333ZM8.96094 3.75H10.2109V5H8.96094V3.75Z'
				fill='black'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M14.7943 8.95573H16.0443V10.2057H14.7943V8.95573ZM14.7943 11.8724H16.0443V13.1224H14.7943V11.8724ZM14.7943 14.7891H16.0443V16.0391H14.7943V14.7891ZM8.96094 6.66406H10.2109V7.91406H8.96094V6.66406ZM8.96094 9.58073H10.2109V10.8307H8.96094V9.58073Z'
				fill='black'
			/>
		</svg>
	);
};

export { CompanyIcon };
