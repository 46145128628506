import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import endPoints from "./endPoints.json";
import axiosInstance from "../../middleware/axios";

export const loginUser = createAsyncThunk(
	"auth/user",
	async (phoneNumber: string, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(endPoints.getOtp, {
				phoneNo: phoneNumber
			});
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const verifyOtp = createAsyncThunk(
	"auth/verifyOtp",
	async (arg: { phoneNumber: string; otp: string }, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(endPoints.verifyOtp, {
				phoneNo: arg.phoneNumber,
				OTP: arg.otp
			});
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const selectCompany = createAsyncThunk(
	"auth/selectProfile",
	async (arg: { companyId: number; userId: number }, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get(
				`${endPoints.selectProfile}/${arg.companyId}/${arg.userId}`
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const createCompanyProfile = createAsyncThunk(
	"auth/createProfile",
	async (
		arg: {
			companyProfileImages: string;
			companyData: object;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await axiosInstance.post(endPoints.createProfile, arg, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			});
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getCompanyBusinessType = createAsyncThunk(
	"business/types",
	async (_, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get(endPoints.getBusinessTypes);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);
