import React, { createContext, useContext, useEffect, useState } from "react";
import NetworkStatusBanner from "../components/NetworkStatusBanner";

interface NetworkStatusContextProps {
  isOnline: boolean;
}

const NetworkStatusContext = createContext<NetworkStatusContextProps | undefined>(undefined);

export const NetworkStatusProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const updateStatus = () => setIsOnline(navigator.onLine);

    window.addEventListener("online", updateStatus);
    window.addEventListener("offline", updateStatus);

    return () => {
      window.removeEventListener("online", updateStatus);
      window.removeEventListener("offline", updateStatus);
    };
  }, []);

  return (
    <NetworkStatusContext.Provider value={{ isOnline }}>
      {isOnline ? children : <NetworkStatusBanner />}
    </NetworkStatusContext.Provider>
  );
};

export const useNetworkStatus = () => {
  const context = useContext(NetworkStatusContext);
  if (!context) throw new Error("useNetworkStatus must be used within a NetworkStatusProvider");
  return context;
};
