import EndPoint from "../redux/services/endPoints.json";
import { POST } from "../shared/services/api-service";
import { TApiResponse } from "../types/axios";
import { TReceivedRequestProps } from "../types/connectionType";

const getConnectionRequests = ({
	signal,
	search,
	offset,
	limit,
}: {
	signal?: AbortSignal;
	search?: string;
	offset: number;
	limit: number;
}): TApiResponse<TReceivedRequestProps[]> => {
	return POST({
		URL: EndPoint?.getConnectionRequests,
		body: {
			search,
			offset,
			limit
		},
		signal
	});
};


const takeActionOnConnectionRequest = ({
	requestId,
	action,
	signal
}: {
	requestId: number;
	action: string;
	signal?: AbortSignal;
}) => {
	return POST({
		URL: EndPoint?.connectionRequestAction,
		body: {
			requestId,
			action,
		},
		signal
	});
};

export { getConnectionRequests, takeActionOnConnectionRequest }