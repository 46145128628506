import React from "react";
import Skeleton from "react-loading-skeleton";

const UserRoleCardSkeleton = () => {
  return (
    <div className="vendor-card-wrapper">
      <div className="vendor-card">
        <div className="image-container">
          <Skeleton circle width={72} height={72} />
        </div>
        <div className="info-container">
          <div className="business-info">
            <h3 className="business-name title-3"><Skeleton width={170} /></h3>
            <p className="vendor-name"><Skeleton width={100} /></p>
          </div>
          <p className="address-info"><Skeleton width={130} /></p>
        </div>
    
        <div className="buttons-container">
          <Skeleton width={120} height={40}  borderRadius={8}/>
        </div>
      </div>
    </div>
  );
};

export default UserRoleCardSkeleton;