import { message, Popover, Spin, Tooltip } from "antd";
import { useEffect, useState } from "react";
import OneSignal from "react-onesignal";
import { NavLink, useNavigate } from "react-router-dom";
import { BiizlineLogo } from "../../assets/images";
import "../../assets/styles/common.scss";
import { BellOutlinedIcon } from "../../assets/svg";
import {
	DiamondIcon,
	DownArrow,
	SettingOutlinedFilledIcon,
	UserFilledIcon
} from "../../assets/svg/icons";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import RequestBox from "../../pages/dashboard/components/requestBox";
import { getRoleAccessData } from "../../redux/services/accountSetting";
import { getConnectionList, getSentRequest } from "../../redux/services/connection";
import { getAllStatCount } from "../../redux/services/dashboard";
import {
	getSubscriptionPayloadData,
	getSubscriptionPlanDetails
} from "../../redux/services/subscription";
import { removeConnectionRequests } from "../../redux/slices/connectionSlice";
import {
	handleNotificationDrawer,
	setNotificationData
} from "../../redux/slices/notificationSlice";
import { MOBILE_DEVICE_REGEX } from "../../shared/constants/constant";
import { ALTER_TEXT, COMPONENTS_STRINGS } from "../../shared/constants/content-constant";
import {
	ConnectionsSubModules,
	RoleAccessModules,
	SubscriptionModules
} from "../../shared/enum/enum";
import socket from "../../socket";
import { TReceivedRequestProps } from "../../types/connectionType";
import {
	getDateLabel,
	getModuleRoleAccessData,
	getProfileIcon,
	groupDataByDate
} from "../../utils/helper";
import CompanyListAndSetting from "../companyListAndSetting";
import CountCapsule from "../countCapsule";
import ImageLoad from "../ImageLoad";
import NoDataFound from "../NoDataFound";
import SettingPopOver from "../SettingPopOver";
import { resetStateAction } from "../../redux/slices/authSlice";
import {
	getConnectionRecievedRequestAsyncThunk,
	takeActionOnConnectionRequestAsyncThunk
} from "../../redux/services/connection-async-thunk";
import { SocketRoutes } from "../../shared/constants/socket-constant";

export const Header = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { receivedRequest } = useAppSelector(state => state.connections);
	const { userDetails } = useAppSelector(state => state.common);
	const { companyDetailList } = useAppSelector(state => state.company);
	const { planDetails } = useAppSelector(state => state.subscription);
	const { loggedInUserRole } = useAppSelector(state => state.accountSetting);
	const { token } = useAppSelector(state => state.auth);
	const { isNotificationDrawerOpen } = useAppSelector(state => state.notification);

	const [isToggleCompany, setToggleCompany] = useState(false);
	const [isOpenPopover, setIsOpenPopover] = useState({
		isSetting: false,
		isConnection: false
	});
	const [notificationCount, setNotificationCount] = useState({
		newNotifications: "",
		totalNotifications: ""
	});
	const [companyNotificationCountList, setCompanyNotificationCountList] = useState([]);
	const [premiumButton, setPremiumButton] = useState(false);

	const [loading, setLoading] = useState(false);
	const { connectionActiveTab, connectionPayload } = useAppSelector(
		state => state.connections
	);
	const companyDetails = userDetails.data?.company;
	const { userId } = userDetails.data?.user || { userId: 0 };

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [navItems, setNavItems] = useState([
		{
			icon: "",
			text: <p>Home</p>,
			url: "/dashboard",
			checked: true,
			isDisable: true
		},
		{
			icon: "",
			text: (
				<span className='flex justifyCenter alignCenter gap-2'>
					<p>Orders</p>
				</span>
			),
			url: "/orders",
			checked: true,
			isDisable: true
		},
		{
			icon: "",
			text: <p>Special Price List</p>,
			url: "/special-price-list",
			checked: true
		},
		{
			icon: "",
			text: (
				<span className='flex justifyCenter alignCenter gap-2'>
					<p>Connections</p>
				</span>
			),
			url: "/connection",
			checked: true
		},
		{
			icon: "",
			text: <p>Products</p>,
			url: "/products",
			checked: true,
			isDisable: true
		},
		{
			icon: "",
			text: <p>Stock</p>,
			url: "/stock",
			checked: true,
			isDisable: true
		}
	]);

	const viewConnections = getModuleRoleAccessData(
		loggedInUserRole?.data?.activityAccess,
		RoleAccessModules.CONNECTIONS,
		ConnectionsSubModules.VIEW_ONLY
	);
	const inviteConnections = getModuleRoleAccessData(
		loggedInUserRole?.data?.activityAccess,
		RoleAccessModules.CONNECTIONS,
		ConnectionsSubModules.INVITE
	);
	const acceptRejectConnections = getModuleRoleAccessData(
		loggedInUserRole?.data?.activityAccess,
		RoleAccessModules.CONNECTIONS,
		ConnectionsSubModules.ACCEPT_REJECT
	);

	const noAccess = !viewConnections && !inviteConnections && !acceptRejectConnections;

	const role = userDetails.data?.user?.userRole?.role?.roleName;
	const userRoleMappingId = userDetails?.data?.user?.userRole?.userRoleMappingId;
	const isMobileOrTablet = MOBILE_DEVICE_REGEX?.test(navigator.userAgent);

	useEffect(() => {
		const activePlan = planDetails?.data?.plans?.filter(
			(item: { isActive: boolean }) => {
				return item?.isActive;
			}
		);
		if (activePlan?.[0]?.subscriptionId === 1) {
			setPremiumButton(true);
		} else {
			setPremiumButton(false);
		}
	}, [planDetails]);

	useEffect(() => {
		if (!isMobileOrTablet && userId && token) {
			dispatch(getSubscriptionPayloadData({ module: SubscriptionModules.COMPANY }));
			dispatch(getSubscriptionPlanDetails())
				.then(result => {
					if (result?.payload?.status === 200) {
						setLoading(false);
					}
				})
				.catch(() => {
					setLoading(false);
				});
			const payload = {
				search: "",
				offset: 0,
				limit: 0
			};
			dispatch(getConnectionRecievedRequestAsyncThunk(payload));
			dispatch(getRoleAccessData());
		}
	}, [dispatch, token, userId]);

	useEffect(() => {
		if ((companyDetailList?.data?.length, userRoleMappingId)) {
			const companyIdList = companyDetailList?.data?.map(item => ({
				companyId: item.companyId,
				roleMappingId: item?.user?.userRole?.userRoleMappingId
			}));
			socket.emit(
				SocketRoutes.GET_NEW_NOTIFICATION_COUNT_BY_COMPANY_ID,
				companyIdList,
				userRoleMappingId
			);
		}
	}, [companyDetailList, userRoleMappingId, isOpenPopover?.isSetting, isToggleCompany]);

	useEffect(() => {
		socket.on(
			SocketRoutes.GET_NEW_NOTIFICATION_COUNT_BY_COMPANY_ID_RESPONSE,
			data => {
				setCompanyNotificationCountList(data);
			}
		);
	}, [socket]);

	useEffect(() => {
		if (userRoleMappingId) {
			socket.emit(
				SocketRoutes.NEW_NOTIFICATION_COUNT,
				companyDetails?.companyId,
				userRoleMappingId
			);
		}
		socket.on(SocketRoutes.NEW_NOTIFICATION_COUNT_RESPONSE, (data: string) => {
			document.title = data
				? `(${data}) Biizline - India's Best B2B Order Management App`
				: "Biizline - India's Best B2B Order Management App";
			setNotificationCount(prev => ({
				newNotifications: data,
				totalNotifications: prev.totalNotifications
			}));
		});

		socket.on(
			SocketRoutes.READ_NOTIFICATION_RESPONSE,
			(data: { isRead: boolean }) => {
				if (data?.isRead) {
					dispatch(setNotificationData(data));
					if (userRoleMappingId) {
						socket.emit(
							SocketRoutes.NEW_NOTIFICATION_COUNT,
							companyDetails?.companyId,
							userRoleMappingId
						);
					}
				} else {
					console.warn("read notification error");
				}
			}
		);

		socket.on(SocketRoutes.ALL_NOTIFICATION_COUNT_RESPONSE, (data: string) => {
			setNotificationCount(prev => ({
				newNotifications: prev.newNotifications,
				totalNotifications: data
			}));
		});
		socket.on(SocketRoutes.DEVICE_LOGOUT(userId), data => {
			if (data) {
				OneSignal.logout();
				dispatch(resetStateAction());
				dispatch({ type: "RESET_STATE" });
			}
		});

		socket.on(SocketRoutes.USER_ACCESS_UPDATE(userId), data => {
			if (data) {
				dispatch(getRoleAccessData());
			}
		});

		return () => {
			socket.off(SocketRoutes.NEW_NOTIFICATION_COUNT_RESPONSE);
			socket.off(SocketRoutes.READ_NOTIFICATION_RESPONSE);
			socket.off(SocketRoutes.ALL_NOTIFICATION_COUNT_RESPONSE);
			socket.off(SocketRoutes.DEVICE_LOGOUT(userId));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socket, companyDetails?.companyId, userId, dispatch, userRoleMappingId]);

	const handleToggleNotification = () => {
		dispatch(handleNotificationDrawer(!isNotificationDrawerOpen));
	};

	const handleOpenChange = () => {
		setToggleCompany(!isToggleCompany);
	};

	const handlePopOverConnection = () => {
		setIsOpenPopover({
			isConnection: !isOpenPopover.isConnection,
			isSetting: isOpenPopover.isSetting
		});
	};

	const handleChange = () => {
		setIsOpenPopover(prev => ({
			...prev,
			isSetting: !isOpenPopover.isSetting
		}));
	};

	const getRecievedRequest = async () => {
		const payload = {
			search: "",
			limit: 0,
			offset: 0
		};
		try {
			await dispatch(getConnectionRecievedRequestAsyncThunk(payload));
		} catch (error) {
			console.log(error, "Received Request");
		}
	};

	const getSendedRequests = async () => {
		const payload = {
			search: "",
			limit: 0,
			offset: 0,
			forVendor: false
		};
		try {
			await dispatch(getSentRequest(payload));
		} catch (error) {
			console.log(error, "Received Request");
		}
	};

	const ListOfAllConnection = async () => {
		const payload = {
			search: "",
			sort: "ASC",
			type: connectionActiveTab,
			limit: connectionPayload.data,
			offset: 0
		};
		try {
			await dispatch(getConnectionList(payload));
		} catch (error: any) {
			message.error(error?.message);
		}
	};

	const requestAction = async (reqType: string, id: string | number) => {
		const payload = {
			requestId: +id,
			action: reqType
		};
		setLoading(true);
		await dispatch(takeActionOnConnectionRequestAsyncThunk(payload))
			.then(result => {
				if (result?.payload?.status === 200) {
					dispatch(getAllStatCount());
					const updateData = receivedRequest.data.filter(
						item => item.requestId !== id
					);
					dispatch(removeConnectionRequests(id));
					ListOfAllConnection();
					if (reqType === "Cancel") {
						getSendedRequests();
					} else {
						getRecievedRequest();
					}
				} else {
					getRecievedRequest();
				}
			})
			.finally(() => setLoading(false));
	};

	// Group the receivedRequest data by date
	const receivedRequestGroupedData = receivedRequest?.data
		? groupDataByDate(receivedRequest?.data || [])
		: {};

	const RequestsContent = Object.keys(receivedRequestGroupedData).length ? (
		Object.entries(receivedRequestGroupedData).map(([date, items]: any) => (
			<div key={date}>
				<div className='notification-time'>{getDateLabel(new Date(date))}</div>
				{items &&
					items?.map((item: TReceivedRequestProps) => {
						return (
							<RequestBox
								actions={
									acceptRejectConnections && !viewConnections
										? { accept: true, reject: true }
										: { accept: false, reject: false }
								}
								content={{
									companyId: item.sender?.companyId,
									companyName: item.sender?.name,
									img: item.sender?.profileThumbnailImg,
									name:
										item.sender?.createdBy?.firstName +
										" " +
										item.sender?.createdBy?.lastName,
									date: item.createdOn,
									requestId: item.requestId
								}}
								key={item.sender?.companyId}
								onClick={(data: string) =>
									requestAction(data, item.requestId || 0)
								}
								disabled={loading}
							/>
						);
					})}
			</div>
		))
	) : (
		<NoDataFound text='No Connection Request Found' />
	);

	return (
		<header className='header flex alignCenter justifyBetween w-100'>
			<div className='flex gap-4 justifyBetween alignCenter h-100 overflow-auto w-100 container'>
				<NavLink to={"/"} className='logo-icon'>
					<img src={BiizlineLogo} alt={ALTER_TEXT.LOGO} loading='lazy' />
				</NavLink>
				<nav className='navbar flex justifyStart'>
					<ul className='flex gap-2 nav-items'>
						{navItems &&
							navItems?.map((item, index) => {
								return item.checked ? (
									<li className='item relative' key={index}>
										<NavLink
											to={item.url}
											replace={true}
											className={"flex alignCenter justifyCenter"}
										>
											{item.icon}
											{item.text}
										</NavLink>
									</li>
								) : (
									""
								);
							})}
					</ul>
				</nav>
				<div className='flex alignCenter justifyEnd buttons-container gap-6 w-100'>
					{premiumButton && role === "Admin" ? (
						<div
							className='flex alignCenter get-premium-button'
							onClick={() => {
								navigate(
									`/settings/${companyDetails?.companyId}/subscribe/plans`
								);
							}}
						>
							<DiamondIcon />
							<span className='fontWeight-600'>
								{COMPONENTS_STRINGS.HEADER.GET_PREMIUM}
							</span>
						</div>
					) : (
						<></>
					)}
					{noAccess || !acceptRejectConnections ? (
						<></>
					) : (
						<Tooltip title='No. of Connection Requests'>
							{Object.entries(companyDetails || {}).length ? (
								<Popover
									content={RequestsContent}
									trigger='click'
									open={isOpenPopover.isConnection}
									placement='bottomRight'
									onOpenChange={handlePopOverConnection}
									rootClassName='receivedRequest-Popup'
								>
									<button className='invite-connection-button icon-button flex alignCenter justifyCenter'>
										<UserFilledIcon />
										<CountCapsule
											count={receivedRequest?.data?.length}
										/>
									</button>
								</Popover>
							) : (
								<></>
							)}
						</Tooltip>
					)}
					<Tooltip title='Notifications'>
						{companyDetails ||
						Object.entries(companyDetails || {})?.length ? (
							<button
								className='notification-icon flex alignCenter relative'
								onClick={() => handleToggleNotification()}
							>
								<BellOutlinedIcon />
								{notificationCount?.newNotifications ? (
									<CountCapsule
										count={Number(
											notificationCount?.newNotifications
										)}
										limit={10}
									/>
								) : (
									<></>
								)}
							</button>
						) : (
							<></>
						)}
					</Tooltip>
					<Tooltip title='Setting'>
						{companyDetails ||
						Object.entries(companyDetails || {})?.length ? (
							<Popover
								onOpenChange={handleChange}
								open={isOpenPopover.isSetting}
								rootClassName='select-company-popover'
								content={
									<SettingPopOver handleOpenChange={handleChange} />
								}
								trigger='click'
								placement='bottomRight'
							>
								<button className='setting-icon icon-button relative'>
									<SettingOutlinedFilledIcon />
								</button>
							</Popover>
						) : (
							<></>
						)}
					</Tooltip>
					{Object.entries(companyDetails || {}).length ? (
						<Popover
							onOpenChange={handleOpenChange}
							open={isToggleCompany}
							rootClassName='select-company-popover'
							content={
								<CompanyListAndSetting
									handleOpenChange={handleOpenChange}
									companyNotificationCountList={
										companyNotificationCountList
									}
								/>
							}
							trigger='click'
							placement='bottomRight'
						>
							<button className='selected-company-btn gap-1'>
								{userDetails.isLoading ? (
									<div className='loading-container'>
										<Spin />
									</div>
								) : (
									<>
										<div className='image-container'>
											{companyDetails?.profileThumbnailImg ? (
												<ImageLoad
													src={
														companyDetails.profileThumbnailImg
													}
													alt={ALTER_TEXT.COMPANY_IMAGE}
												/>
											) : (
												getProfileIcon(companyDetails?.name || "")
											)}
										</div>
										<span className='company-name'>
											{companyDetails?.name}
										</span>
										<div
											className={
												isToggleCompany
													? "action-dropdown active"
													: "action-dropdown"
											}
										>
											<DownArrow />
										</div>
									</>
								)}
							</button>
						</Popover>
					) : (
						<></>
					)}
				</div>
			</div>
		</header>
	);
};
