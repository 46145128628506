const MoreIconsDots = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g opacity="0.9">
        <path
          d="M3.60938 8.875C3.84144 8.875 4.064 8.78281 4.22809 8.61872C4.39219 8.45462 4.48438 8.23206 4.48438 8C4.48438 7.76794 4.39219 7.54538 4.22809 7.38128C4.064 7.21719 3.84144 7.125 3.60938 7.125C3.37731 7.125 3.15475 7.21719 2.99066 7.38128C2.82656 7.54538 2.73438 7.76794 2.73438 8C2.73438 8.23206 2.82656 8.45462 2.99066 8.61872C3.15475 8.78281 3.37731 8.875 3.60938 8.875ZM7.98438 8.875C8.21644 8.875 8.439 8.78281 8.60309 8.61872C8.76719 8.45462 8.85938 8.23206 8.85938 8C8.85938 7.76794 8.76719 7.54538 8.60309 7.38128C8.439 7.21719 8.21644 7.125 7.98438 7.125C7.75231 7.125 7.52975 7.21719 7.36566 7.38128C7.20156 7.54538 7.10937 7.76794 7.10938 8C7.10937 8.23206 7.20156 8.45462 7.36566 8.61872C7.52975 8.78281 7.75231 8.875 7.98438 8.875V8.875ZM12.3594 8.875C12.5914 8.875 12.814 8.78281 12.9781 8.61872C13.1422 8.45462 13.2344 8.23206 13.2344 8C13.2344 7.76794 13.1422 7.54538 12.9781 7.38128C12.814 7.21719 12.5914 7.125 12.3594 7.125C12.1273 7.125 11.9048 7.21719 11.7407 7.38128C11.5766 7.54538 11.4844 7.76794 11.4844 8C11.4844 8.23206 11.5766 8.45462 11.7407 8.61872C11.9048 8.78281 12.1273 8.875 12.3594 8.875V8.875Z"
          fill="black"
          fillOpacity="0.88"
        />
      </g>
    </svg>
  );
};

export { MoreIconsDots };
