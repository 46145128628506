import EndPoint from "../redux/services/endPoints.json";
import { DateFormats } from "../shared/constants/constant";
import { GET, POST } from "../shared/services/api-service";
import { DateFilterStartDateEndDate } from "../shared/utils/utils";
import { TApiResponse } from "../types/axios";
import {
	TAddStockApiPayloadTypes,
	TAdjustmentReasonApiResponse,
	TAdjustQunatityApiPayloadTypes,
	TInventoryProductStockChartApiResponse,
	TInventoryProductStockCountApiResponse,
	TLowStockAlertApiPayload,
	TProductDetailsApiResponse,
	TProductGroupListApiResponse,
	TProductListApiResponse,
	TStockHistoryListApiResponse,
	TStockProductListApiResponse,
	TStockProductSizeListApiResponse,
	TStockTransactionHistoryApiResponse,
	TVendorNameSuggessionsApiResponseTypes
} from "../types/stock";

const productGroupListApi = ({
	signal
}: {
	signal?: AbortSignal;
}): TApiResponse<TProductGroupListApiResponse> => {
	return POST({
		URL: EndPoint?.getProductGroups,
		body: {
			search: ""
		},
		signal
	});
};

const fetchProductsWithSizes = ({
	args,
	signal
}: {
	args: { id: string };
	signal?: AbortSignal;
}): TApiResponse<TProductDetailsApiResponse> => {
	return GET({
		URL: `${EndPoint?.fetchProductsWithSizes}/${args?.id}`,
		signal
	});
};

const productNameWithImage = ({
	args,
	signal
}: {
	args: {
		limit: number;
		offset: number;
	};
	signal?: AbortSignal;
}): TApiResponse<TProductListApiResponse> => {
	return GET({
		URL: `${EndPoint?.productNameWithImage}?limit=${args.limit}&offset=${args.offset}`,
		signal
	});
};

const vendorNameSuggession = ({
	payload,
	signal
}: {
	payload: { name: string };
	signal?: AbortSignal;
}): TApiResponse<TVendorNameSuggessionsApiResponseTypes> => {
	return GET({
		URL: `${EndPoint?.vendorNameSuggestion}?name=${payload?.name}`,
		signal
	});
};

const addStockForm = ({
	payload,
	signal
}: {
	payload: TAddStockApiPayloadTypes;
	signal?: AbortSignal;
}) => {
	// Create a FormData object
	const formData = new FormData();
	formData.append("stockData", JSON.stringify(payload.stockData)); // Convert object to string
	if (payload?.addStockAttachments?.length) {
		formData.append("addStockAttachments", payload.addStockAttachments[0]);
	}
	return POST({
		URL: EndPoint?.addStock,
		headers: {
			"Content-Type": "multipart/form-data"
		},
		body: formData,
		signal
	});
};

const checkCustomer = ({ signal }: { signal?: AbortSignal }) => {
	return GET({
		URL: EndPoint?.checkCustomer,
		signal
	});
};

const getLowStockDetails = ({ id, signal }: { id: number; signal: AbortSignal }) => {
	return GET({
		URL: `${EndPoint.getLowStockAlertData}/${id}`,
		signal
	});
};

const inventoryProductListApi = ({
	search,
	groupId,
	limit,
	offset,
	signal,
	status
}: {
	search: string;
	limit: number;
	offset: number;
	groupId: number;
	status: number;
	signal?: AbortSignal;
}): TApiResponse<TStockProductListApiResponse> => {
	return GET({
		URL: EndPoint?.inventoryStockProductList,
		params: {
			groupId,
			limit,
			offset,
			...(search && { search }),
			...(status && { status })
		},
		signal
	});
};

const inventoryProductSizeListApi = ({
	productId,
	signal
}: {
	productId: number;
	signal?: AbortSignal;
}): TApiResponse<TStockProductSizeListApiResponse> => {
	return GET({
		URL: `${EndPoint?.inventoryStockProductSizeList}/${productId}`,
		signal
	});
};

const inventoryProductStockCountApi = ({
	productId,
	sizeId,
	signal
}: {
	productId: number;
	sizeId: number;
	signal?: AbortSignal;
}): TApiResponse<TInventoryProductStockCountApiResponse> => {
	return GET({
		URL: `${EndPoint?.productStockCount}/${productId}/${sizeId}`,
		signal
	});
};

//stock analysis chart
const inventoryProductStockChartApi = ({
	productId,
	sizeId,
	signal,
	period
}: {
	productId: number;
	sizeId: number;
	signal?: AbortSignal;
	period: string;
}): TApiResponse<TInventoryProductStockChartApiResponse> => {
	const { endDate, startDate } = DateFilterStartDateEndDate[period];

	return GET({
		URL: `${EndPoint?.productStockChart}/${productId}/${sizeId}`,
		params: {
			...(startDate && {
				startDate: startDate?.format(DateFormats.DATE_FORMAT_WITH_TIME)
			}),
			...(endDate && {
				endDate: endDate?.format(DateFormats.DATE_FORMAT_WITH_TIME)
			})
		},
		signal
	});
};

const transactionHistoryApi = ({
	productId,
	sizeId,
	limit,
	offset,
	search,
	signal
}: {
	productId: number;
	sizeId: number;
	signal?: AbortSignal;
	limit: number;
	offset: number;
	search?: string;
}): TApiResponse<TStockTransactionHistoryApiResponse> => {
	return GET({
		URL: `${EndPoint?.transactionHistory}/${productId}/${sizeId}`,
		params: {
			limit,
			offset,
			...(search && { search })
		},
		signal
	});
};

const getAdjustmentReason = ({
	signal
}: {
	signal?: AbortSignal;
}): TApiResponse<TAdjustmentReasonApiResponse> => {
	return GET({
		URL: EndPoint?.adjustmentReasons,
		signal
	});
};

const postAdjustmentQuantity = ({
	payload,
	signal
}: {
	payload: TAdjustQunatityApiPayloadTypes;
	signal?: AbortSignal;
}) => {
	// Create a FormData object
	const formData = new FormData();
	formData.append("stockAdjustData", JSON.stringify(payload.stockAdjustData)); // Convert object to string
	if (payload?.adjustStockAttachments?.length) {
		formData.append("adjustStockAttachments", payload.adjustStockAttachments[0]);
	}
	return POST({
		URL: EndPoint?.adjustStock,
		headers: {
			"Content-Type": "multipart/form-data"
		},
		body: formData,
		signal
	});
};

const getStockHistoryListApi = ({
	limit,
	offset,
	search,
	status,
	startDate,
	endDate,
	signal
}: {
	signal?: AbortSignal;
	limit: number;
	offset: number;
	search: string;
	status: number;
	startDate?: string;
	endDate?: string;
}): TApiResponse<TStockHistoryListApiResponse> => {
	return GET({
		URL: EndPoint?.stockHistory,
		params: {
			limit,
			offset,
			status,
			...(search && { search }),
			...(startDate && { startDate }),
			...(endDate && { endDate })
		},
		signal
	});
};

const setProductStockAlert = ({
	payload,
	signal
}: {
	payload: TLowStockAlertApiPayload;
	signal?: AbortSignal;
}) => {
	return POST({
		URL: EndPoint?.setLowStockAlert,
		body: {
			...payload
		},
		signal
	});
};

const getSelectAllCustomersIds = ({ signal }: { signal?: AbortSignal }) => {
	return GET({
		URL: EndPoint?.selectAllCustomers,
		signal
	});
};

const getAllCustomersList = ({
	signal,
	payload
}: {
	signal?: AbortSignal;
	payload: any;
}) => {
	return POST({
		URL: EndPoint?.getConnectionList,
		body: payload,
		signal
	});
};

export {
	addStockForm,
	checkCustomer,
	fetchProductsWithSizes,
	getAdjustmentReason,
	getAllCustomersList,
	getLowStockDetails,
	getSelectAllCustomersIds,
	getStockHistoryListApi,
	inventoryProductListApi,
	inventoryProductSizeListApi,
	inventoryProductStockChartApi,
	inventoryProductStockCountApi,
	postAdjustmentQuantity,
	productGroupListApi,
	productNameWithImage,
	setProductStockAlert,
	transactionHistoryApi,
	vendorNameSuggession
};
