import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BulkImportSteps } from "../../enum/product-enum";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { bulkProductDataImport } from "../../redux/services/products";
import { resetBulkImportStateAction } from "../../redux/slices/productSlice";
import { BulkProductData } from "../../types/productsType";
import BulkImportFileUpload from "./BulkImportFileUpload";
import BulkImportHeader from "./BulkImportHeader";
import BulkImportReview from "./BulkImportReview";
import BulkImportWaitingPopup from "./BulkImportWaitingPopup";

const BulkImport = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { dataImportLoading } = useAppSelector(state => state.products.bulkImport);
	const [file, setFile] = useState<File | null>(null);

	const [step, setStep] = useState<number>(BulkImportSteps.FileUpload);

	const resetFile = useCallback(() => {
		setFile(null);
		setStep(BulkImportSteps.FileUpload);
	}, []);

	const uploadBulkImportData = (data: BulkProductData[]) => {
		dispatch(bulkProductDataImport({ productData: data }))
			.unwrap()
			.then(res => {
				if (res?.data?.totalErrors <= 0) {
					navigate("/products");
					dispatch(resetBulkImportStateAction());
				}
			});
	};

	return (
		<div className='container page'>
			<div className='bulk-import-page-container'>
				<BulkImportHeader />

				{step === BulkImportSteps.FileUpload && (
					<BulkImportFileUpload
						file={file}
						setFile={setFile}
						setStep={setStep}
						uploadBulkImportData={uploadBulkImportData}
					/>
				)}

				{step === BulkImportSteps.ReviewData && (
					<BulkImportReview
						resetFile={resetFile}
						uploadBulkImportData={uploadBulkImportData}
					/>
				)}
			</div>

			<BulkImportWaitingPopup
				open={dataImportLoading}
				close={() => {
					//
				}}
			/>
		</div>
	);
};

export default BulkImport;
