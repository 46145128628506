import { createAsyncThunk } from "@reduxjs/toolkit";
import { getNotificationListApi } from "../../service/notification-service";
import { NotificationTabs } from "../../constant/notification-constant";

const getNotificationListAsyncThunk = createAsyncThunk(
	"notification/notification-list",
	async ({
		limit,
		module,
		offset,
		signal
	}: {
		limit: number;
		offset: number;
		module: string;
		signal?: AbortSignal;
	}) => {
		const modifiedModule = module === NotificationTabs.ALL ? "" : module;
		const response = (
			await getNotificationListApi({
				limit,
				module: modifiedModule,
				offset,
				signal
			})
		)?.data;

		return response;
	}
);

export { getNotificationListAsyncThunk };
