import { createSlice } from "@reduxjs/toolkit";
import { TPlaceOrderState } from "../../types/place-order";
import {
	getAllSizesWithSearch,
	getCartDataByVendorID,
	getFavouriteProductList,
	getProductListByVendorId,
	mostOrderedProducts,
	placeOrder,
	recentOrders
} from "../services/placeOrder";
import { SliceNames } from "../../shared/constants/constant";

const initialState: TPlaceOrderState = {
	vendorsProductList: {
		isLoading: false,
		isSuccess: false,
		data: [],
		message: ""
	},
	cart: {
		isLoading: false,
		isSuccess: false,
		data: null,
		message: ""
	},
	recentOrdersData: {
		isLoading: false,
		isSuccess: false,
		data: null,
		message: ""
	},
	getAllSizes: {
		isLoading: false,
		isSuccess: false,
		data: null,
		message: ""
	},
	mostOrderedProductsData: {
		data: null,
		isLoading: false,
		isSuccess: false,
		message: ""
	},
	favouriteProductList: {
		data: null,
		isLoading: false,
		isSuccess: false,
		message: ""
	},
	placeOrderData: {
		isSuccess: false
	},
	currentItem: {
		productId: 0,
		qty: 0
	}
};

const placeorderSlice = createSlice({
	name: SliceNames.PLACE_ORDER,
	initialState,
	reducers: {
		clearCart: state => {
			state.cart = initialState.cart;
		},
		updateMostOrderProductsFilterd: (state, { payload }) => {
			state.mostOrderedProductsData.data = payload;
		},
		updateFavouriteProductList: (state, { payload }) => {
			state.favouriteProductList.data = payload;
		},
		updatePlaceOrderStatus: (state, { payload }) => {
			state.placeOrderData.isSuccess = payload;
		},
		updateCurrentItem: (state, { payload }) => {
			state.currentItem.productId = payload?.productId;
			state.currentItem.qty = payload?.qty;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getProductListByVendorId.pending, state => {
				state.vendorsProductList.data = null;
				state.vendorsProductList.isLoading = true;
			})
			.addCase(getProductListByVendorId.fulfilled, (state, { payload }: any) => {
				state.vendorsProductList.isSuccess = true;
				state.vendorsProductList.isLoading = false;
				state.vendorsProductList.data = payload?.data;
			})
			.addCase(getProductListByVendorId.rejected, (state, { payload }: any) => {
				state.vendorsProductList.isLoading = false;
				state.vendorsProductList.isSuccess = false;
			})

			//
			.addCase(getCartDataByVendorID.pending, state => {
				state.cart.isLoading = true;
				state.cart.isSuccess = false;
			})
			.addCase(getCartDataByVendorID.fulfilled, (state, { payload }: any) => {
				state.cart.isSuccess = true;
				state.cart.isLoading = false;
				state.cart.data = payload?.data;
			})
			.addCase(getCartDataByVendorID.rejected, (state, { payload }: any) => {
				state.cart.isLoading = false;
				state.cart.isSuccess = false;
			})

			// recentOrders

			.addCase(recentOrders.pending, state => {
				state.recentOrdersData.isLoading = true;
			})
			.addCase(recentOrders.fulfilled, (state, { payload }: any) => {
				state.recentOrdersData.isSuccess = true;
				state.recentOrdersData.isLoading = false;
				state.recentOrdersData.data = payload?.data;
			})
			.addCase(recentOrders.rejected, (state, { payload }: any) => {
				state.recentOrdersData.isLoading = false;
				state.recentOrdersData.isSuccess = false;
			})

			// getAllSizesWithSearch

			.addCase(getAllSizesWithSearch.pending, state => {
				state.getAllSizes.isLoading = true;
			})
			.addCase(getAllSizesWithSearch.fulfilled, (state, { payload }: any) => {
				state.getAllSizes.isSuccess = true;
				state.getAllSizes.isLoading = false;
				state.getAllSizes.data = payload;
			})
			.addCase(getAllSizesWithSearch.rejected, (state, { payload }: any) => {
				state.getAllSizes.isLoading = false;
				state.getAllSizes.isSuccess = false;
			})

			// mostOrderedProducts

			.addCase(mostOrderedProducts.pending, state => {
				state.mostOrderedProductsData.isLoading = true;
				state.mostOrderedProductsData.isSuccess = false;
			})
			.addCase(mostOrderedProducts.fulfilled, (state, { payload }: any) => {
				state.mostOrderedProductsData.isLoading = false;
				state.mostOrderedProductsData.isSuccess = true;
				state.mostOrderedProductsData.data = payload?.data;
				state.mostOrderedProductsData.message = payload?.message;
			})
			.addCase(mostOrderedProducts.rejected, (state, { payload }: any) => {
				state.mostOrderedProductsData.isLoading = false;
				state.mostOrderedProductsData.isSuccess = false;
				state.mostOrderedProductsData.message = payload?.message;
			})

			// get favourite products Data

			.addCase(getFavouriteProductList.pending, state => {
				state.favouriteProductList.isLoading = true;
				state.favouriteProductList.isSuccess = false;
			})
			.addCase(getFavouriteProductList.fulfilled, (state, { payload }: any) => {
				state.favouriteProductList.isLoading = false;
				state.favouriteProductList.isSuccess = true;
				state.favouriteProductList.data = payload?.data;
				state.favouriteProductList.message = payload?.message;
			})
			.addCase(getFavouriteProductList.rejected, (state, { payload }: any) => {
				state.favouriteProductList.isLoading = false;
				state.favouriteProductList.isSuccess = false;
				state.favouriteProductList.message = payload?.message;
			})

			// place order

			.addCase(placeOrder.pending, state => {
				state.placeOrderData.isSuccess = false;
			})
			.addCase(placeOrder.fulfilled, state => {
				state.placeOrderData.isSuccess = true;
			})
			.addCase(placeOrder.rejected, state => {
				state.placeOrderData.isSuccess = false;
			});
	}
});

export const {
	clearCart,
	updateMostOrderProductsFilterd,
	updateFavouriteProductList,
	updatePlaceOrderStatus,
	updateCurrentItem
} = placeorderSlice.actions;
export default placeorderSlice.reducer;
