interface Props {
  width?: number;
  height?: number;
}

const CrossIcon = ({ width = 12, height = 12 }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M9 3L3 9M3 3L9 9"
        stroke="#98A2B3"
        strokeOpacity="1"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { CrossIcon };
