import { createSlice } from "@reduxjs/toolkit";
import { TDashBoardState } from "../../types/dashboardTypes";
import { getAllStatCount } from "../services/dashboard";
import { SliceNames } from "../../shared/constants/constant";

const initialState: TDashBoardState = {
	statCount: {
		isLoading: false,
		isSuccess: false,
		message: "",
		data: null
	}
};

const dashboardSlice = createSlice({
	name: SliceNames.DASHBOARD,
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getAllStatCount.pending, state => {
				state.statCount.isLoading = true;
				state.statCount.isSuccess = false;
			})
			.addCase(getAllStatCount.fulfilled, (state, { payload }: any) => {
				state.statCount.isSuccess = true;
				state.statCount.isLoading = false;
				state.statCount.data = payload?.data;
				state.statCount.message = payload?.message;
			})
			.addCase(getAllStatCount.rejected, (state, { payload }: any) => {
				state.statCount.data = null;
				state.statCount.isSuccess = false;
				state.statCount.isLoading = false;
				state.statCount.message = payload?.message;
			});
	}
});

export default dashboardSlice.reducer;
