import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";

import endPoints from "./endPoints.json";
import axiosInstance from "../../middleware/axios";

export const getProductListByVendorId = createAsyncThunk(
	"product/byVendorId",
	async (
		args: {
			search: string;
			groupId: number;
			vendorId: number;
			limit: number;
			offset: number;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await axiosInstance.post(endPoints.getProductsList, args);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const addToCart = createAsyncThunk(
	"cart/addToCart",
	async (
		args: {
			payload: {
				vendorId: number;
				items: {
					productId: number;
					productSizes: {
						productSizeId: number;
						quantity: number;
					}[];
				}[];
			},
			signal?: AbortSignal
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await axiosInstance.post(endPoints.addToCart, args.payload, { signal: args.signal });
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getCartDataByVendorID = createAsyncThunk(
	"cart/getData",
	async (args: { vendorId: number, signal?: AbortSignal }, { rejectWithValue }) => {
		const { vendorId, signal } = args;
		try {
			const response = await axiosInstance.post(
				endPoints.getCartDataByVendor,
				{vendorId},
				{signal}
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const removeCartItem = createAsyncThunk(
	"cart/itemRemove",
	async (args: {payload: { cartId: number; cartItemIds: number[] }, signal?: AbortSignal}, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(endPoints.removeCartItem, args.payload, { signal: args.signal });
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const clearCartItem = createAsyncThunk(
	"cart/clear",
	async (args: { cartId: number, signal?: AbortSignal }, { rejectWithValue }) => {
		const { cartId, signal } = args;
		try {
			const response = await axiosInstance.post(endPoints.clearCart, { cartId }, { signal });
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const placeOrder = createAsyncThunk(
	"order/placeOrder",
	async (
		args: {
			placeOrderDetails: {
				cartId: number;
				deliveryAddressId?: number;
				message: string;
			};
			orderAttachments: any;
		},
		{ rejectWithValue }
	) => {
		try {
			const formData = new FormData();
			args.orderAttachments.forEach((file: string | Blob) => {
				formData.append("orderAttachments", file);
			});
			formData.append("placeOrderDetails", JSON.stringify(args.placeOrderDetails));

			const response = await axiosInstance.post(endPoints.placeOrder, formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			});
			// if(response.data.status === 200){
			//   message.success(response.data.message);
			// }
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const recentOrders = createAsyncThunk(
	"order/recentOrders",
	async (args: number, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(endPoints.recentOrders, {
				limit: 6,
				vendorId: args
			});
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const reOrderByOrderId = createAsyncThunk(
	"order/re_order",
	async (args: { vendorId: number, signal?: AbortSignal }, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(
				`${endPoints.reOrderByRecentOrderId}/${args.vendorId}`,
				{ signal: args?.signal }
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getAllSizesWithSearch = createAsyncThunk(
	endPoints.getAllSizesWithSearch,
	async (
		args: { productId: number; search: string; limit: number; offset: number },
		{ rejectWithValue }
	) => {
		try {
			const payload = {
				search: args.search,
				limit: args.limit,
				offset: args.offset
			};
			const response = await axiosInstance.post(
				`${endPoints.getAllSizesWithSearch}/${args.productId}`,
				payload
			);
			return response.data.data.productSizes;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const mostOrderedProducts = createAsyncThunk(
	endPoints.mostOrderedProducts,
	async (arg: { vendorId: number; limit: number }, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(
				`${endPoints.mostOrderedProducts}/${arg.vendorId}`,
				{ limit: arg.limit }
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const addFavouriteProduct = createAsyncThunk(
	endPoints.addFavouriteProduct,
	async (
		arg: { productId: number; vendorId: number; isFavourite: boolean },
		{ rejectWithValue }
	) => {
		try {
			const response = await axiosInstance.post(endPoints.addFavouriteProduct, arg);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getFavouriteProductList = createAsyncThunk(
	endPoints.getFavouriteProductList,
	async (arg: { vendorId: number; limit: number }, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(
				`${endPoints.getFavouriteProductList}/${arg.vendorId}`,
				{ limit: arg.limit }
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);
