import { createSlice } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist";
import { SliceNames } from "../../shared/constants/constant";
import { TAuthState } from "../../types/authTypes";
import { TRehydratePayload } from "../../types/store";
import { getCompanyBusinessType, verifyOtp } from "../services/auth";

const initialState: TAuthState = {
	isLoading: false,
	isSuccess: false,
	profile: null,
	companyData: null,
	allCompanies: [],
	businessTypes: {
		data: [],
		isLoading: true,
		isSuccess: true,
		message: ""
	},
	step: 0,
	fcm_token: null,
	token: "",
	phoneNumber: "",
	createNewCompany: false,
	isLoggedIn: false,
	isRegistered: false
};

const authSlice = createSlice({
	name: SliceNames.AUTH,
	initialState,
	reducers: {
		clearStep: state => {
			state.step = initialState.step;
		},
		loginAction: (state, action) => {
			state.token = action.payload.token;
			state.fcm_token = action.payload.fcm_token;
		},
		logOutAction: state => {
			state.token = "";
			state.fcm_token = "";
			state.isLoggedIn = false;
		},
		setTokenAction: (state, action) => {
			state.token = action.payload;
		},
		setFcmTokenAction: (state, action) => {
			state.fcm_token = action.payload;
		},
		resetStateAction: state => {
			state.isLoading = initialState.isLoading;
			state.isSuccess = initialState.isSuccess;
			state.profile = initialState.profile;
			state.companyData = initialState.companyData;
			state.allCompanies = initialState.allCompanies;
			state.businessTypes = initialState.businessTypes;
			state.step = initialState.step;
			state.fcm_token = initialState.fcm_token;
			state.token = initialState.token;
			state.createNewCompany = initialState.createNewCompany;
			state.phoneNumber = initialState.phoneNumber;
			state.isLoggedIn = initialState.isLoggedIn;
			state.isRegistered = initialState.isRegistered;
		},
		setPhoneNumberAction: (state, action) => {
			state.phoneNumber = action.payload;
		},
		isLoggedInAction: (state, action) => {
			state.isLoggedIn = action.payload;
		},
		isRegisteredAction: (state, action) => {
			state.isRegistered = action.payload;
		}
	},
	extraReducers: builder => {
		builder

			//INFO: rehydrate case
			.addCase(
				REHYDRATE,
				(_, action: { type: typeof REHYDRATE; payload: TRehydratePayload }) => {
					return {
						...action?.payload?.auth,
						loader: initialState.isLoading
					};
				}
			)
			// verify otp
			.addCase(verifyOtp.pending, state => {
				state.isLoading = true;
			})
			.addCase(verifyOtp.fulfilled, (state, { payload }: any) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.companyData = payload.data;
			})
			.addCase(verifyOtp.rejected, (state, { payload }: any) => {
				state.isSuccess = false;
				state.isLoading = false;
				state.companyData = payload || "Failed to login";
				state.step = 1;
			})

			// get all business Types

			.addCase(getCompanyBusinessType.pending, state => {
				state.businessTypes.isLoading = true;
				state.businessTypes.isSuccess = false;
				state.businessTypes.data = [];
			})
			.addCase(getCompanyBusinessType.fulfilled, (state, { payload }: any) => {
				state.businessTypes.isLoading = false;
				state.businessTypes.isSuccess = true;
				state.businessTypes.data = payload.data;
			})
			.addCase(getCompanyBusinessType.rejected, (state, { payload }: any) => {
				state.businessTypes.isSuccess = false;
				state.businessTypes.isLoading = false;
				state.businessTypes.data = [];
				state.businessTypes.message = payload?.message;
			});
	}
});
export const {
	clearStep,
	logOutAction,
	loginAction,
	setTokenAction,
	resetStateAction,
	setPhoneNumberAction,
	setFcmTokenAction,
	isLoggedInAction,
	isRegisteredAction
} = authSlice.actions;
export default authSlice.reducer;
