import { NoUser } from "../../assets/svg";
import { PlusCircleFilled } from "../../assets/svg/icons";
import { useAppSelector } from "../../hooks/useAppSelector";
import { ConnectionsSubModules, RoleAccessModules } from "../../shared/enum/enum";
import { getModuleRoleAccessData } from "../../utils/helper";

interface Props {
	onClick?: any;
}

const NoDataPage = ({ onClick }: Props) => {
	const { connection } = useAppSelector(state => state.connections);

	const { loggedInUserRole } = useAppSelector(state => state.accountSetting);
	const viewConnections = getModuleRoleAccessData(
		loggedInUserRole?.data?.activityAccess,
		RoleAccessModules.CONNECTIONS,
		ConnectionsSubModules.VIEW_ONLY
	);
	const inviteConnections = getModuleRoleAccessData(
		loggedInUserRole?.data?.activityAccess,
		RoleAccessModules.CONNECTIONS,
		ConnectionsSubModules.INVITE
	);

	return (
		<div className='no-data-page h-100'>
			<div className='no-data-container'>
				<div className='center-box flex direction-column alignCenter'>
					<div className='bg-img'>
						<NoUser />
					</div>
					<div className='invite-text-container'>
						<h4 className='title-4'>
							Invite your {connection.total > 0 ? "" : "first"} connection
						</h4>
						<p className='caption'>
							Invite your {connection.total > 0 ? "" : "first"} connection
							to kickstart your business! Reach out, introduce yourself, and
							start building meaningful relationships today!
						</p>
						{inviteConnections && !viewConnections ? (
							<button className='primary' onClick={onClick}>
								<PlusCircleFilled /> Invite
							</button>
						) : (
							<></>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default NoDataPage;
