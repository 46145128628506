import { createSlice } from "@reduxjs/toolkit";

import {
	getBlockedConnectionsList,
	getNotificationModulesList,
	getProductsManagementData,
	getRoleAccessData,
	getUserModulesActivity,
	getUserRoleDetails,
	getUserRoleList
} from "../services/accountSetting";
import { TAccountSettingState } from "../../types/account-settings";
import { SliceNames } from "../../shared/constants/constant";

const accountInitialState: TAccountSettingState = {
	isLoading: false,
	isSuccess: false,
	roles: {
		isLoading: false,
		isSuccess: false,
		data: []
	},
	selectedRole: [],
	usersList: {
		data: [],
		isLoading: false,
		isSuccess: false
	},
	userDetails: {
		data: {},
		isLoading: false,
		isSuccess: false
	},
	loggedInUserRole: {
		data: [],
		isSuccess: false,
		isLoading: false,
		message: ""
	},
	productManagementList: {
		data: [],
		isSuccess: false,
		isLoading: false,
		message: ""
	},
	blockedConnectionsList: {
		data: [],
		isSuccess: false,
		isLoading: false,
		message: ""
	},
	notificationModulesList: {
		data: [],
		isSuccess: false,
		isLoading: false,
		message: ""
	}
};

const accountSettingSlice = createSlice({
	name: SliceNames.ACCOUNT_SETTING,
	initialState: accountInitialState,
	reducers: {
		accessTable: (state, { payload }) => {
			state.selectedRole = payload;
		},
		clearForm: state => {
			state.userDetails = accountInitialState.userDetails;
			state.roles = accountInitialState.roles;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getUserModulesActivity.pending, state => {
				state.roles.isLoading = true;
				state.roles.isSuccess = false;
			})
			.addCase(getUserModulesActivity.fulfilled, (state, { payload }) => {
				state.roles.isLoading = false;
				state.roles.isSuccess = true;
				state.roles.data = payload?.data;
			})
			.addCase(getUserModulesActivity.rejected, state => {
				state.roles.isLoading = false;
				state.roles.isSuccess = false;
				state.roles.data = [];
			})

			//
			.addCase(getUserRoleList.pending, state => {
				state.usersList.isLoading = true;
				state.usersList.isSuccess = false;
			})
			.addCase(getUserRoleList.fulfilled, (state, { payload }) => {
				state.usersList.isLoading = false;
				state.usersList.isSuccess = true;
				state.usersList.data = payload?.data;
			})
			.addCase(getUserRoleList.rejected, state => {
				state.usersList.isLoading = false;
				state.usersList.isSuccess = false;
				state.usersList.data = [];
			})

			//
			.addCase(getUserRoleDetails.pending, state => {
				state.userDetails.isLoading = true;
				state.userDetails.isSuccess = false;
			})
			.addCase(getUserRoleDetails.fulfilled, (state, { payload }) => {
				state.userDetails.isLoading = false;
				state.userDetails.isSuccess = true;
				state.userDetails.data = payload?.data;
			})
			.addCase(getUserRoleDetails.rejected, state => {
				state.userDetails.isLoading = false;
				state.userDetails.isSuccess = false;
				state.userDetails.data = [];
			})

			//
			.addCase(getRoleAccessData.pending, state => {
				state.loggedInUserRole.isLoading = true;
				state.loggedInUserRole.isSuccess = false;
			})
			.addCase(getRoleAccessData.fulfilled, (state, { payload }) => {
				state.loggedInUserRole.isLoading = false;
				state.loggedInUserRole.isSuccess = true;
				state.loggedInUserRole.data = payload?.data;
				state.loggedInUserRole.message = payload?.message;
			})
			.addCase(getRoleAccessData.rejected, state => {
				state.loggedInUserRole.isLoading = false;
				state.loggedInUserRole.isSuccess = false;
				state.loggedInUserRole.data = [];
			})

			//
			.addCase(getProductsManagementData.pending, state => {
				state.productManagementList.isLoading = true;
				state.productManagementList.isSuccess = false;
				state.productManagementList.data = [];
			})
			.addCase(getProductsManagementData.fulfilled, (state, { payload }) => {
				state.productManagementList.isLoading = false;
				state.productManagementList.isSuccess = true;
				state.productManagementList.data = payload?.data;
				state.productManagementList.message = payload?.message;
			})
			.addCase(getProductsManagementData.rejected, state => {
				state.productManagementList.isLoading = false;
				state.productManagementList.isSuccess = false;
				state.productManagementList.data = [];
			})

			//
			.addCase(getBlockedConnectionsList.pending, state => {
				state.blockedConnectionsList.isLoading = true;
				state.blockedConnectionsList.isSuccess = false;
				state.blockedConnectionsList.data = [];
			})
			.addCase(getBlockedConnectionsList.fulfilled, (state, { payload }) => {
				state.blockedConnectionsList.isLoading = false;
				state.blockedConnectionsList.isSuccess = true;
				state.blockedConnectionsList.data = payload?.data;
				state.blockedConnectionsList.message = payload?.message;
			})
			.addCase(getBlockedConnectionsList.rejected, state => {
				state.blockedConnectionsList.isLoading = false;
				state.blockedConnectionsList.isSuccess = false;
				state.blockedConnectionsList.data = [];
			})

			// Notification Settings Slice

			.addCase(getNotificationModulesList.pending, state => {
				state.notificationModulesList.isLoading = true;
				state.notificationModulesList.isSuccess = false;
				state.notificationModulesList.data = [];
			})
			.addCase(getNotificationModulesList.fulfilled, (state, { payload }) => {
				state.notificationModulesList.isLoading = false;
				state.notificationModulesList.isSuccess = true;
				state.notificationModulesList.data = payload?.data;
				state.notificationModulesList.message = payload?.message;
			})
			.addCase(getNotificationModulesList.rejected, state => {
				state.notificationModulesList.isLoading = false;
				state.notificationModulesList.isSuccess = false;
				state.notificationModulesList.data = [];
			});
	}
});

export const { accessTable, clearForm } = accountSettingSlice.actions;
export { accountInitialState };
export default accountSettingSlice.reducer;
