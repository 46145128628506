import dayjs, { Dayjs } from "dayjs";
import { DateSelectionPeriodEnum } from "../enum/enum";

const getFileName = (fileURL: string) => {
	if (fileURL) {
		const filename = fileURL.split("/").pop();
		if (filename) {
			return decodeURI(filename);
		}
	}
	return "";
};

const DateFilterStartDateEndDate: {
	[key: string]: {
		startDate: Dayjs | null;
		endDate: Dayjs | null;
	};
} = {
	[DateSelectionPeriodEnum.SEVEN_DAYS]: {
		startDate: dayjs().subtract(7, "day").startOf("day"),
		endDate: dayjs().endOf("day")
	},
	[DateSelectionPeriodEnum.FIFTEEN_DAYS]: {
		startDate: dayjs().subtract(15, "day").startOf("day"),
		endDate: dayjs().endOf("day")
	},
	[DateSelectionPeriodEnum.ONE_MONTH]: {
		startDate: dayjs().subtract(1, "month").startOf("day"),
		endDate: dayjs().endOf("day")
	},
	[DateSelectionPeriodEnum.THREE_MONTHS]: {
		startDate: dayjs().subtract(3, "month").startOf("day"),
		endDate: dayjs().endOf("day")
	},
	[DateSelectionPeriodEnum.SIX_MONTHS]: {
		startDate: dayjs().subtract(6, "month").startOf("day"),
		endDate: dayjs().endOf("day")
	},
	[DateSelectionPeriodEnum.ONE_YEAR]: {
		startDate: dayjs().subtract(1, "year").startOf("day"),
		endDate: dayjs().endOf("day")
	},
	[DateSelectionPeriodEnum.ALL_TIME]: {
		startDate: null,
		endDate: null
	}
};

const dateRangeSelectionOption = ({
	isShowAllTimeOption = false,
	isShowCustomDateOption = false
}: {
	isShowCustomDateOption?: boolean;
	isShowAllTimeOption?: boolean;
}): {
	title: string;
	longTitle: string;
	value: string;
}[] => [
	{
		title: "7 D",
		longTitle: "7 Days",
		value: DateSelectionPeriodEnum.SEVEN_DAYS
	},
	{
		title: "15 D",
		longTitle: "15 Days",
		value: DateSelectionPeriodEnum.FIFTEEN_DAYS
	},
	{
		title: "30 D",
		longTitle: "30 Days",
		value: DateSelectionPeriodEnum.ONE_MONTH
	},
	{
		title: "3 M",
		longTitle: "3 Months",
		value: DateSelectionPeriodEnum.THREE_MONTHS
	},
	{
		title: "6 M",
		longTitle: "6 Months",
		value: DateSelectionPeriodEnum.SIX_MONTHS
	},
	{
		title: "1 Y",
		longTitle: "1 Years",
		value: DateSelectionPeriodEnum.ONE_YEAR
	},
	...(isShowAllTimeOption
		? [
				{
					title: "All Time",
					longTitle: "All Time",
					value: DateSelectionPeriodEnum.ALL_TIME
				}
			]
		: []),
	...(isShowCustomDateOption
		? [
				{
					title: "Custom",
					longTitle: "Custom",
					value: DateSelectionPeriodEnum.CUSTOM_DATE
				}
			]
		: [])
];

export {
	DateFilterStartDateEndDate,
	dateRangeSelectionOption,
	getFileName
};
