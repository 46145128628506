import { createSlice } from "@reduxjs/toolkit";
import { NotificationTabs } from "../../constant/notification-constant";
import { SliceNames } from "../../shared/constants/constant";
import { TNotificationState } from "../../types/notification";
import { getNotificationListAsyncThunk } from "../services/notification-async-thunk";

const initialState: TNotificationState = {
	loader: {
		notificationListLoader: false
	},
	dataForOrders: null,
	notifications: [],
	notificationTotalCount: 0,
	orderUnreadNotificationCount: 0,
	productUnreadNotificationCount: 0,
	totalUnreadNotificationCount: 0,
	notificationCurrentTab: NotificationTabs.ALL,
	isNotificationDrawerOpen: false
};

const notificationSlice = createSlice({
	name: SliceNames.NOTIFICATION,
	initialState,
	reducers: {
		notificationOrderData: (state, { payload }) => {
			state.dataForOrders = payload;
		},
		clearNotificationData: state => {
			state.notifications = [];
			state.notificationTotalCount = 0;
			state.orderUnreadNotificationCount = 0;
			state.productUnreadNotificationCount = 0;
			state.totalUnreadNotificationCount = 0;
		},
		appendNotificationData: (state, { payload }) => {
			if (state.isNotificationDrawerOpen) {
				if (
					payload?.module === state.notificationCurrentTab ||
					state.notificationCurrentTab === NotificationTabs.ALL
				) {
					state.notifications = [payload, ...state.notifications];
				}
				if (payload?.module === NotificationTabs.ORDER) {
					state.orderUnreadNotificationCount =
						state.orderUnreadNotificationCount + 1;
				}
				if (payload?.module === NotificationTabs.PRODUCTS) {
					state.productUnreadNotificationCount =
						state.productUnreadNotificationCount + 1;
				}
				state.totalUnreadNotificationCount =
					state.totalUnreadNotificationCount + 1;
				state.notificationTotalCount = state.notificationTotalCount + 1;
			}
		},
		setNotificationData: (state, { payload }) => {
			const filterData = state.notifications.find(
				data => data.notificationHistoryId === payload.notificationHistoryId
			);
			if (filterData?.isRead === false) {
				state.notifications = state.notifications.map(data =>
					data.notificationHistoryId === payload.notificationHistoryId
						? { ...data, isRead: true, isNew: false }
						: data
				);

				state.totalUnreadNotificationCount =
					state.totalUnreadNotificationCount - 1;
				if (payload?.module === NotificationTabs.ORDER) {
					state.orderUnreadNotificationCount =
						state.orderUnreadNotificationCount - 1;
				}
				if (payload?.module === NotificationTabs.PRODUCTS) {
					state.productUnreadNotificationCount =
						state.productUnreadNotificationCount - 1;
				}
			}
		},
		readAllNotifications: state => {
			state.notifications = state.notifications.map(data => ({
				...data,
				isRead: true,
				isNew: false
			}));
			state.totalUnreadNotificationCount = 0;
			state.orderUnreadNotificationCount = 0;
			state.productUnreadNotificationCount = 0;
		},
		handleNotificationDrawer: (state, { payload }) => {
			state.isNotificationDrawerOpen = payload;
		},
		changeNotificationTabAction: (state, { payload }) => {
			state.notificationCurrentTab = payload;
		},
		resetNotificationListAction: state => {
			state.notifications = [];
		},
		handleRejectConnectionNotificationAction: (state, { payload }) => {
			const notification = state.notifications.filter(
				data => data.notificationHistoryId !== payload.notificationHistoryId
			);

			state.notifications = notification;
			state.notificationTotalCount = state.notificationTotalCount - 1;

			if (payload?.isRead === false) {
				state.totalUnreadNotificationCount =
					state.totalUnreadNotificationCount - 1;
			}
			if (payload?.module === NotificationTabs.ORDER) {
				state.orderUnreadNotificationCount =
					state.orderUnreadNotificationCount - 1;
			}
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getNotificationListAsyncThunk.pending, state => {
				state.loader.notificationListLoader = true;
			})
			.addCase(getNotificationListAsyncThunk.fulfilled, (state, action) => {
				const { offset } = action?.meta?.arg ?? { limit: 0 };
				const data = action?.payload?.data;

				if (offset) {
					state.notifications = [
						...state.notifications,
						...(data?.notifications ?? [])
					];
				} else {
					state.notifications = data?.notifications;
				}

				state.notificationTotalCount = data?.totalCount;
				state.orderUnreadNotificationCount = data?.orderUnreadNotificationCount;
				state.productUnreadNotificationCount =
					data?.productUnreadNotificationCount;
				state.totalUnreadNotificationCount = data?.totalUnreadNotificationCount;
				state.loader.notificationListLoader = false;
			})
			.addCase(getNotificationListAsyncThunk.rejected, state => {
				state.loader.notificationListLoader = false;
			});
	}
});

export const {
	notificationOrderData,
	appendNotificationData,
	handleNotificationDrawer,
	clearNotificationData,
	setNotificationData,
	readAllNotifications,
	changeNotificationTabAction,
	resetNotificationListAction,
	handleRejectConnectionNotificationAction
} = notificationSlice.actions;
export default notificationSlice.reducer;
