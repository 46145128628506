import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	productGroupListApi,
	fetchProductsWithSizes,
	productNameWithImage,
	vendorNameSuggession,
	inventoryProductListApi,
	inventoryProductSizeListApi,
	inventoryProductStockCountApi,
	inventoryProductStockChartApi,
	transactionHistoryApi,
	addStockForm,
	getAdjustmentReason,
	postAdjustmentQuantity,
	checkCustomer,
	setProductStockAlert,
	getStockHistoryListApi,
	getSelectAllCustomersIds,
	getAllCustomersList,
	getLowStockDetails
} from "../../service/stock-service";
import axios from "axios";
import {
	TAddStockApiPayloadTypes,
	TAdjustQunatityApiPayloadTypes,
	TLowStockAlertApiPayload
} from "../../types/stock";

const productGroupListAsyncThunk = createAsyncThunk(
	"productGroup/getGroups",
	async (_, { signal }) => {
		const response = (await productGroupListApi({ signal }))?.data;

		return response;
	}
);

const getProductDetailAsyncThunk = createAsyncThunk(
	"stock/fetchProductsWithSizes",
	async (args: { id: string }, { signal }) => {
		const response = (await fetchProductsWithSizes({ args, signal }))?.data;
		return response;
	}
);

const getLowStockDetailsAsyncThunk = createAsyncThunk(
	"stock/getLowStockDetails",
	async (id: number, { signal }) => {
		const response = (await getLowStockDetails({ id, signal }))?.data;
		return response;
	}
);

const getProductWithImageListAsyncThunk = createAsyncThunk(
	"stock/productNameWithImage",
	async (args: { limit: number; offset: number }, { signal }) => {
		const response = (await productNameWithImage({ args, signal }))?.data;
		return response;
	}
);

const getCustomerListAsyncThunk = createAsyncThunk(
	"stock/checkCustomer",
	async (_, { signal }) => {
		const response = (await checkCustomer({ signal }))?.data;
		return response;
	}
);

const inventoryProductListAsyncThunk = createAsyncThunk(
	"stock/inventoryProductList",
	async (
		payload: {
			search: string;
			limit: number;
			offset: number;
			groupId: number;
			status: number;
			signal?: AbortSignal;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = (await inventoryProductListApi(payload))?.data;
			return response;
		} catch (error) {
			if (axios.isCancel(error)) {
				return rejectWithValue({ aborted: true });
			}
			return rejectWithValue(error);
		}
	}
);

const inventoryProductSizeListAsyncThunk = createAsyncThunk(
	"stock/inventoryProductSizeList",
	async (payload: { productId: number; signal?: AbortSignal }) => {
		const response = (await inventoryProductSizeListApi(payload))?.data;

		return response;
	}
);

const getVendorNameSuggestionAsyncThunk = createAsyncThunk(
	"stock/vendorNameSuggestion",
	async (payload: { name: string }, { signal }) => {
		const response = (await vendorNameSuggession({ payload, signal }))?.data;
		return response;
	}
);

const inventoryProductStockCountAsyncThunk = createAsyncThunk(
	"stock/productStockCount",
	async (payload: { productId: number; sizeId: number }, { signal }) => {
		return (await inventoryProductStockCountApi({ ...payload, signal }))?.data;
	}
);

const inventoryProductStockChartAsyncThunk = createAsyncThunk(
	"stock/productStockChart",
	async (
		payload: { productId: number; sizeId: number; period: string },
		{ signal }
	) => {
		return (await inventoryProductStockChartApi({ ...payload, signal }))?.data;
	}
);

const transactionHistoryAsyncThunk = createAsyncThunk(
	"stock/stockMovementLogs",
	async (payload: {
		productId: number;
		sizeId: number;
		signal?: AbortSignal;
		limit: number;
		offset: number;
		search?: string;
	}) => {
		return (await transactionHistoryApi(payload))?.data;
	}
);

const transactionHistoryChildDrawerAsyncThunk = createAsyncThunk(
	"stock/stockMovementLogsChild",
	async (
		payload: {
			productId: number;
			sizeId: number;
			signal?: AbortSignal;
			limit: number;
			offset: number;
			search?: string;
		},
		{ signal, rejectWithValue }
	) => {
		const signalS = payload?.signal ? payload?.signal : signal;

		try {
			const response = (
				await transactionHistoryApi({ ...payload, signal: signalS })
			)?.data;
			return response;
		} catch (error) {
			if (axios.isCancel(error)) {
				return rejectWithValue({ aborted: true });
			}
			return rejectWithValue(error);
		}
	}
);

const postAddStockFormDataAsyncThunk = createAsyncThunk(
	"stock/addStock",
	async (payload: TAddStockApiPayloadTypes, { signal }) => {
		const response = (await addStockForm({ payload, signal }))?.data;
		return response;
	}
);

const getAdjustmentReasonAsyncThunk = createAsyncThunk(
	"stock/adjustmentReasons",
	async (__dirname, { signal }) => {
		const response = await getAdjustmentReason({ signal });
		return response;
	}
);

const postAdjustmentQuantityAsyncThunk = createAsyncThunk(
	"stock/adjustStock",
	async (payload: TAdjustQunatityApiPayloadTypes, { signal }) => {
		const response = (await postAdjustmentQuantity({ payload, signal }))?.data;
		return response;
	}
);

const postProductLowStockAlertAsyncThunk = createAsyncThunk(
	"stock/adjustStock",
	async (payload: TLowStockAlertApiPayload, { signal }) => {
		const response = (await setProductStockAlert({ payload, signal }))?.data;
		return response;
	}
);

const getStockHistoryListAsyncThunk = createAsyncThunk(
	"stock/stockHistoryList",
	async (
		payload: {
			signal?: AbortSignal;
			limit: number;
			offset: number;
			search: string;
			status: number;
			startDate?: string;
			endDate?: string;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = (await getStockHistoryListApi(payload))?.data;
			return response;
		} catch (error) {
			if (axios.isCancel(error)) {
				return rejectWithValue({ aborted: true });
			}
			return rejectWithValue(error);
		}
	}
);

const getAllCustomersListAsyncThunk = createAsyncThunk(
	"stock/selectAllCustomers",
	async (_, { signal }) => {
		const response = (await getSelectAllCustomersIds({ signal }))?.data;
		return response;
	}
);

const getAllConnectionListAsyncThunk = createAsyncThunk(
	"stock/connectionList",
	async (
		payload: {
			limit: number;
			offset: number;
			search: string;
			sort: string;
			type: string;
		},
		{ signal }
	) => {
		const response = (await getAllCustomersList({ payload, signal }))?.data;
		return response;
	}
);

export {
	getProductDetailAsyncThunk,
	getProductWithImageListAsyncThunk,
	productGroupListAsyncThunk,
	inventoryProductListAsyncThunk,
	inventoryProductSizeListAsyncThunk,
	getVendorNameSuggestionAsyncThunk,
	postAddStockFormDataAsyncThunk,
	getAdjustmentReasonAsyncThunk,
	postAdjustmentQuantityAsyncThunk,
	getCustomerListAsyncThunk,
	postProductLowStockAlertAsyncThunk,
	inventoryProductStockCountAsyncThunk,
	inventoryProductStockChartAsyncThunk,
	transactionHistoryAsyncThunk,
	transactionHistoryChildDrawerAsyncThunk,
	getStockHistoryListAsyncThunk,
	getAllCustomersListAsyncThunk,
	getAllConnectionListAsyncThunk,
	getLowStockDetailsAsyncThunk
};
