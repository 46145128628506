import * as Sentry from "@sentry/react";
import clevertap from "clevertap-web-sdk";
import { Suspense, useEffect } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import OneSignal from "react-onesignal";
import "./assets/styles/common.scss";
import "./common.css";
import { NetworkStatusProvider } from "./contexts/NetworkStatusContext";
import { useAppDispatch } from "./hooks/useAppDispatch";
import { useAppSelector } from "./hooks/useAppSelector";
import { registerDeviceToken } from "./redux/services/common";
import { readAllNotifications } from "./redux/slices/notificationSlice";
import Routing from "./router/router";
import Loader from "./shared/components/Loader";
import socket from "./socket";
import { cleverTapInit } from "./utils/clevertapEvents";
import { ThemeProvider } from "./utils/themeContext";

function App() {
	const dispatch = useAppDispatch();
	const appId = process.env.REACT_APP_ONE_SIGNAL_APP_ID as string;
	const safariWebId = process.env.REACT_APP_ONE_SIGNAL_SAFARI_ID as string;

	const { userDetails } = useAppSelector(state => state.common);
	const { userId } = userDetails?.data?.user || { userId: 0 };

	useEffect(() => {
		async function initOneSignal() {
			if (!window.OneSignal) {
				console.warn("OneSignal is not available");
				return;
			}

			if (window.location.hostname !== "localhost") {
				try {
					OneSignal.init({
						appId: appId,
						safari_web_id: safariWebId,
						notifyButton: {
							enable: false
						},
						allowLocalhostAsSecureOrigin: true
					});
					OneSignal.Notifications.requestPermission();
				} catch (err) {
					console.log(err);
				}
			}
		}
		if (appId && safariWebId) initOneSignal();
	}, [appId, safariWebId]);

	function permissionChangeListener(permission: any) {
		if (permission) {
			const id = OneSignal.User.PushSubscription.id;
			if (id) {
				OneSignal.login(id);
				if (id && userId) {
					const payload = {
						token: id,
						from: "Web"
					};
					dispatch(registerDeviceToken({ id: userId, payload: payload }));
				}
			}
		}
	}

	useEffect(() => {
		OneSignal.Notifications.addEventListener(
			"permissionChange",
			permissionChangeListener
		);

		return () => {
			OneSignal.Notifications.removeEventListener(
				"permissionChange",
				permissionChangeListener
			);
		};
	}, [userId]);

	useEffect(() => {
		socket.on("readAllNotificationResponse", data => {
			if (data) {
				dispatch(readAllNotifications());
			}
		});

		return () => {
			socket.off("readAllNotificationResponse");
		};
	}, [socket]);

	clevertap.spa = true;

	clevertap.setOffline(true);
	clevertap.setOffline(false);

	useEffect(() => {
		cleverTapInit();
		clevertap.setLogLevel(3);
		clevertap.privacy.push({ useIP: true });
	}, []);

	if (!Sentry.getCurrentHub().getClient()) {
		Sentry.init({
			dsn: process.env.REACT_APP_SENTRY_DSN,
			integrations: [
				Sentry.browserTracingIntegration(),
				Sentry.replayIntegration()
			],
			// Tracing
			tracesSampleRate: 1.0, //  Capture 100% of the transactions
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: ["localhost", /^https:\/\/biizline\.com\/api\/v1/],
			// Session Replay
			replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
			replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
		});
	}

	return (
		<NetworkStatusProvider>
			<ThemeProvider>
				<Suspense fallback={<Loader />}>
					<Routing />
				</Suspense>
			</ThemeProvider>
		</NetworkStatusProvider>
	);
}

export default App;
