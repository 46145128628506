import { GET } from "../shared/services/api-service";
import Endpoints from "../redux/services/endPoints.json";
import { TApiResponse } from "../types/axios";
import { TNotificationListApiResponse } from "../types/notification";

const getNotificationListApi = ({
	limit,
	offset,
	module,
	signal
}: {
	limit: number;
	offset: number;
	module: string;
	signal?: AbortSignal;
}): TApiResponse<TNotificationListApiResponse> => {
	return GET({
		URL: Endpoints?.notificationList,
		params: {
			limit,
			offset,
			...(module && { module })
		},
		signal
	});
};

export { getNotificationListApi };
