import { UserIcon } from "../../assets/svg/icons";
import { useAppSelector } from "../../hooks/useAppSelector";
import {
	ConnectionsSubModules,
	NewOrdersSubModules,
	RoleAccessModules
} from "../../shared/enum/enum";
import socket from "../../socket";
import { ConvertDateToTime, getModuleRoleAccessData } from "../../utils/helper";
import ImageLoad from "../ImageLoad";
import { NotificationTypes } from "../../enum/notification-enum";
import { TNotificationData } from "../../types/notification";
import { setNotificationData } from "../../redux/slices/notificationSlice";
import { useAppDispatch } from "../../hooks/useAppDispatch";

interface Props {
	notification: TNotificationData;
	handleAction: (
		value: string,
		type: NotificationTypes,
		notification: TNotificationData
	) => void;
	loading?: boolean;
}

export const NotificationCard = ({
	notification,
	handleAction,
	loading = false
}: Props) => {
	const { userDetails } = useAppSelector(state => state.common);
	const { loggedInUserRole } = useAppSelector(state => state.accountSetting);

	const companyId = userDetails.data?.company?.companyId;
	const dispatch = useAppDispatch();

	const acceptRejectConnections = getModuleRoleAccessData(
		loggedInUserRole?.data?.activityAccess,
		RoleAccessModules.CONNECTIONS,
		ConnectionsSubModules.ACCEPT_REJECT
	);
	const acceptRejectOrder = getModuleRoleAccessData(
		loggedInUserRole?.data?.activityAccess,
		RoleAccessModules.NEW_ORDERS,
		NewOrdersSubModules.ACCEPT_REJECT
	);

	const handleReadNotification = (notification: TNotificationData) => {
		if (!notification?.isRead) {
			dispatch(setNotificationData(notification));
			socket.emit(
				"readNotification",
				notification.notificationHistoryId,
				companyId
			);
		}
	};

	return (
		<div
			className={`notifcation-card-container flex direction-column gap-1 ${notification?.isRead ? "" : "un-read"}`}
			onClick={() => {
				handleReadNotification(notification);
			}}
		>
			<div className='notification-header flex gap-2 alignCenter'>
				<h3
					className={
						notification.notificationTypes ===
							NotificationTypes.CONNECTION_REQUEST_REJECTED ||
						notification.notificationTypes ===
							NotificationTypes.ORDER_REJECTED_BY_VENDOR
							? "red-text"
							: ""
					}
				>
					{notification?.header}
				</h3>
				{notification?.isNew && <span className='new-label'>NEW</span>}
			</div>
			<div className='flex alignStart gap-2'>
				<div className='image-container'>
					{notification.senderImage ? (
						<ImageLoad src={notification.senderImage} alt='user_image' />
					) : (
						<UserIcon />
					)}
				</div>
				<div className='notification-info-container flex direction-column gap-2'>
					<div className='header-box'>
						<h4 className='title'>{notification.senderName}</h4>
						<div className='info product-notification'>
							{notification.message}
						</div>
					</div>
					{notification?.actionParams ? (
						<div className='action-container flex gap-3'>
							{notification.notificationTypes !==
								NotificationTypes.CONNECTION_REQUEST_RECEIVED &&
							acceptRejectOrder ? (
								<>
									<button
										className={`reject-btn ${NotificationTypes.ORDER_PLACED_BY_CUSTOMER === notification.notificationTypes ? "order-reject" : ""}`}
										onClick={e => [
											e.stopPropagation(),
											handleAction(
												"Reject",
												notification.notificationTypes,
												notification
											)
										]}
									>
										Reject
									</button>
									<button
										className={`accept-btn ${NotificationTypes.ORDER_PLACED_BY_CUSTOMER === notification.notificationTypes ? "order-accept" : ""}`}
										onClick={e => [
											e.stopPropagation(),
											handleAction(
												"Accept",
												notification.notificationTypes,
												notification
											)
										]}
									>
										Accept
									</button>
								</>
							) : acceptRejectConnections ? (
								<>
									<button
										className='reject-btn'
										onClick={e => [
											e.stopPropagation(),
											handleAction(
												"Reject",
												notification.notificationTypes,
												notification
											)
										]}
									>
										Reject
									</button>
									<button
										className='accept-btn'
										onClick={e => [
											e.stopPropagation(),
											handleAction(
												"Accept",
												notification.notificationTypes,
												notification
											)
										]}
									>
										Accept
									</button>
								</>
							) : (
								<></>
							)}
						</div>
					) : (
						<></>
					)}
					<span className='notification-time-text'>
						{ConvertDateToTime(notification.createdOn)}
					</span>
				</div>
			</div>
		</div>
	);
};

export const PushNotificationCard = ({ notification, handleAction }: Props) => {
	const { loggedInUserRole } = useAppSelector(state => state.accountSetting);

	const acceptRejectConnections = getModuleRoleAccessData(
		loggedInUserRole?.data?.activityAccess,
		RoleAccessModules.CONNECTIONS,
		ConnectionsSubModules.ACCEPT_REJECT
	);
	const acceptRejectOrder = getModuleRoleAccessData(
		loggedInUserRole?.data?.activityAccess,
		RoleAccessModules.NEW_ORDERS,
		NewOrdersSubModules.ACCEPT_REJECT
	);

	return (
		<div
			className={`notifcation-card-container flex direction-column gap-1 ${notification?.isRead ? "" : "un-read"}`}
		>
			<div className='notification-header flex gap-2 alignCenter'>
				<h3>{notification?.header}</h3>
				{notification?.isNew && <span className='new-label'>NEW</span>}
			</div>
			<div className='flex alignStart gap-2'>
				<div className='image-container'>
					{notification.senderImage ? (
						<ImageLoad src={notification.senderImage} alt='user_image' />
					) : (
						<UserIcon />
					)}
				</div>
				<div className='notification-info-container flex direction-column gap-2'>
					<div className='header-box'>
						<h4 className='title'>{notification.senderName}</h4>
						<div className='info product-notification'>
							{notification.message}
						</div>
						{/* <div className="info product-notification">The rate for <b>Product Name</b> has changed. New rate is <b>₹10,00000 /</b> pcs</div> */}
					</div>
					{notification?.actionParams ? (
						// {(notification.notificationTypes !== NotificationTypes.CONNECTION_REQUEST_REJECTED && notification.notificationTypes !== NotificationTypes.CONNECTION_REQUEST_ACCEPTED) &&
						<div className='action-container flex gap-3'>
							{notification.notificationTypes !==
								NotificationTypes.CONNECTION_REQUEST_RECEIVED &&
							acceptRejectOrder ? (
								<>
									<button
										className={`reject-btn ${NotificationTypes.ORDER_PLACED_BY_CUSTOMER === notification.notificationTypes ? "order-reject" : ""}`}
										onClick={e => [
											e.stopPropagation(),
											handleAction(
												"Reject",
												notification.notificationTypes,
												notification
											)
										]}
									>
										Reject
									</button>
									<button
										className={`accept-btn ${NotificationTypes.ORDER_PLACED_BY_CUSTOMER === notification.notificationTypes ? "order-accept" : ""}`}
										onClick={e => [
											e.stopPropagation(),
											handleAction(
												"Accept",
												notification.notificationTypes,
												notification
											)
										]}
									>
										Accept
									</button>
								</>
							) : acceptRejectConnections ? (
								<>
									<button
										className='reject-btn'
										onClick={e => [
											e.stopPropagation(),
											handleAction(
												"Reject",
												notification.notificationTypes,
												notification
											)
										]}
									>
										Reject
									</button>
									<button
										className='accept-btn'
										onClick={e => [
											e.stopPropagation(),
											handleAction(
												"Accept",
												notification.notificationTypes,
												notification
											)
										]}
									>
										Accept
									</button>
								</>
							) : (
								<></>
							)}
						</div>
					) : (
						<></>
					)}
					<span className='notification-time-text'>
						{ConvertDateToTime(notification.createdOn)}
					</span>
				</div>
			</div>
		</div>
	);
};
