import { Spin, Tabs, TabsProps } from "antd";
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CrossIcon } from "../../assets/svg";
import { NotificationTabs } from "../../constant/notification-constant";
import { NotificationTypes } from "../../enum/notification-enum";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import useMessageHook from "../../hooks/useMessageHook";
import useWindowSize from "../../hooks/useWindowSize";
import { getConnectionList } from "../../redux/services/connection";
import { getNotificationListAsyncThunk } from "../../redux/services/notification-async-thunk";
import { clearConnectionList } from "../../redux/slices/connectionSlice";
import {
	changeNotificationTabAction,
	clearNotificationData,
	handleNotificationDrawer,
	handleRejectConnectionNotificationAction,
	notificationOrderData
} from "../../redux/slices/notificationSlice";
import { ERROR_STRINGS } from "../../shared/constants/content-constant";
import { ProductsSubModules, RoleAccessModules } from "../../shared/enum/enum";
import socket from "../../socket";
import { TNotificationData } from "../../types/notification";
import {
	getDateLabel,
	getModuleRoleAccessData,
	groupDataByDate
} from "../../utils/helper";
import CommonDrawer from "../commonDrower";
import NoDataFound from "../NoDataFound";
import { NotificationCard } from "./notificationCard";
import { getConnectionRecievedRequestAsyncThunk, takeActionOnConnectionRequestAsyncThunk } from "../../redux/services/connection-async-thunk";

const NotificationDrawer = () => {
	const { height: windowHeight } = useWindowSize();

	const abortControllerRef = useRef<AbortController | null>(null);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { showMessage, contextHolder } = useMessageHook();
	const [currentPage, setCurrentPage] = useState(1);
	const [notificationCurrentTab, setNotificationCurrentTab] = useState(
		NotificationTabs.ALL
	);

	const observerRef = useRef<HTMLDivElement | null>(null);

	const [isLoading, setLoading] = useState(false);
	const [hasMore, setHasMore] = useState(true);

	const { userDetails } = useAppSelector(state => state.common);
	const { connectionPayload } = useAppSelector(state => state.connections);

	const { connectionActiveTab } = useAppSelector(state => state.connections);
	const { loggedInUserRole } = useAppSelector(state => state.accountSetting);

	const notificationLimit = useMemo(() => {
		const tempNumber = Math.ceil(Number(windowHeight) / 100);

		return tempNumber || 20;
	}, [windowHeight]);

	const {
		notifications,
		isNotificationDrawerOpen,
		orderUnreadNotificationCount,
		productUnreadNotificationCount,
		totalUnreadNotificationCount,
		notificationTotalCount,
		loader: { notificationListLoader }
	} = useAppSelector(state => state.notification);

	const companyId = userDetails.data?.company?.companyId;

	const viewProducts = getModuleRoleAccessData(
		loggedInUserRole?.data?.activityAccess,
		RoleAccessModules.PRODUCTS,
		ProductsSubModules.VIEW_ONLY
	);
	const addEditProducts = getModuleRoleAccessData(
		loggedInUserRole?.data?.activityAccess,
		RoleAccessModules.PRODUCTS,
		ProductsSubModules.ADD_EDIT
	);
	const manageGroup = getModuleRoleAccessData(
		loggedInUserRole?.data?.activityAccess,
		RoleAccessModules.PRODUCTS,
		ProductsSubModules.MANAGE_GROUP
	);
	const noAccessForProducts = !viewProducts && !addEditProducts && !manageGroup;

	const userRoleMappingId = userDetails?.data?.user?.userRole?.userRoleMappingId;

	useEffect(() => {
		if (notifications?.length < notificationTotalCount) {
			setHasMore(true);
		} else {
			setHasMore(false);
		}
	}, [notifications]);

	useEffect(() => {
		socket.on(`requestUpdated_${companyId}`, () => {
			dispatch(clearNotificationData());
			if (currentPage === 1) {
				fetchNotification(0, notificationCurrentTab);
			} else {
				setCurrentPage(1);
			}
		});
		return () => {
			socket.off(`requestUpdated_${companyId}`);
		};
	}, [companyId, userRoleMappingId, socket]);

	const fetchNotification = (offset = 0, module: string) => {
		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}

		const newAbortController = new AbortController();

		abortControllerRef.current = newAbortController;

		dispatch(
			getNotificationListAsyncThunk({
				limit: notificationLimit,
				offset,
				module,
				signal: abortControllerRef.current?.signal
			})
		);
	};

	useEffect(() => {
		const offSet = notifications?.length || 0;

		if (userRoleMappingId && !notificationListLoader && hasMore) {
			fetchNotification(offSet, notificationCurrentTab);
		}
	}, [userRoleMappingId, currentPage, notificationCurrentTab]);

	const handleTabChange = (key: string) => {
		dispatch(clearNotificationData());
		setHasMore(true);
		setNotificationCurrentTab(key);
		dispatch(changeNotificationTabAction(key));

		if (currentPage === 1) {
			setCurrentPage(1);
		} else {
			fetchNotification(0, key);
		}
	};

	useEffect(() => {
		const observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting == true) {
					setCurrentPage(prev => prev + 1);
				}
			});
		});

		if (observerRef.current) observer.observe(observerRef.current);

		return () => {
			if (observerRef.current) observer.unobserve(observerRef.current);
			observer.disconnect();
		};
	}, [observerRef.current, notificationCurrentTab]);

	const ListOfAllConnection = async () => {
		const payload = {
			search: "",
			sort: "ASC",
			type: connectionActiveTab,
			limit: connectionPayload.data,
			offset: 0
		};
		await dispatch(getConnectionList(payload));
	};

	const getRecievedRequest = async () => {
		const payload = {
			search: "",
			limit: 0,
			offset: 0
		};
		await dispatch(getConnectionRecievedRequestAsyncThunk(payload));
	};

	const handleAction = async (
		action: string,
		type: NotificationTypes,
		notification: TNotificationData
	) => {
		setLoading(true);
		if (type === NotificationTypes.ORDER_PLACED_BY_CUSTOMER) {
			handleCloseNotification();
			dispatch(notificationOrderData(notification));
			navigate("/orders");
			setLoading(false);
		} else if (type === NotificationTypes.CONNECTION_REQUEST_RECEIVED) {
			const payload = {
				requestId: notification.actionParams.requestId,
				action: action
			};
			dispatch(takeActionOnConnectionRequestAsyncThunk(payload))
				.then(result => {
					dispatch(clearConnectionList());
					if (userRoleMappingId) {
						socket.emit("newNotificationCount", companyId, userRoleMappingId);
					}
					if (result.payload.status === 200) {
						ListOfAllConnection();
						getRecievedRequest();
						if (action === "Accept") {
							navigate("/connection", {
								state: { isSelectedConnection: true }
							});
							handleCloseNotification();
							setLoading(false);
						} else {
							dispatch(
								handleRejectConnectionNotificationAction(notification)
							);
						}
						setLoading(false);
					} else {
						showMessage(
							"error",
							result?.payload.message || "Something went wrong"
						);
						setLoading(false);
						handleCloseNotification();
					}
				})
				.catch(() => showMessage("error", ERROR_STRINGS.SOMETHING_WRONG));
		}
	};

	const handleCloseNotification = () => {
		dispatch(handleNotificationDrawer(false));
		dispatch(clearNotificationData());
		socket.emit("updateNewBadge", companyId);
		if (userRoleMappingId) {
			socket.emit("newNotificationCount", companyId, userRoleMappingId);
		}
	};

	const handleReadAllNotification = () => {
		socket.emit("readAllNotification", companyId);
		socket.emit("newNotificationCount", companyId, userRoleMappingId);
	};

	const items: TabsProps["items"] = [
		{
			key: NotificationTabs.ALL,
			label: <span className='tab-label flex gap-2'>All</span>,
			children: (
				<NotificationContent
					handleAction={handleAction}
					isLoading={isLoading}
					ref={observerRef}
				/>
			),
			className: "notification-container"
		},
		{
			key: NotificationTabs.PRODUCTS,
			label: (
				<span className='tab-label flex gap-2'>
					Products
					{productUnreadNotificationCount > 0 && (
						<span className='count'>{productUnreadNotificationCount}</span>
					)}
				</span>
			),
			children: noAccessForProducts ? (
				<NoDataFound />
			) : (
				<NotificationContent
					handleAction={handleAction}
					isLoading={isLoading}
					ref={observerRef}
				/>
			),
			className: "notification-container"
		},
		{
			key: NotificationTabs.ORDER,
			label: (
				<span className='tab-label flex gap-2'>
					Orders
					{orderUnreadNotificationCount > 0 && (
						<span className='count'>{orderUnreadNotificationCount}</span>
					)}
				</span>
			),
			children: (
				<NotificationContent
					handleAction={handleAction}
					isLoading={isLoading}
					ref={observerRef}
				/>
			),
			className: "notification-container"
		}
	];

	return (
		<>
			<CommonDrawer
				title={
					<div className='notification-header-container flex alignCenter justifyBetween'>
						<div className='gap-1 flex alignCenter'>
							<h1 className='title'>Notification</h1>
							{totalUnreadNotificationCount ? (
								<span className='count flex justifyCenter alignCenter'>
									{totalUnreadNotificationCount > 10
										? "10+"
										: totalUnreadNotificationCount}
								</span>
							) : (
								<></>
							)}
						</div>

						<div className='actions-container'>
							{totalUnreadNotificationCount > 0 && (
								<button
									className='mark-all-read'
									onClick={handleReadAllNotification}
								>
									Mark as all read
								</button>
							)}
							<button
								className='close-btn flex alignCenter'
								onClick={handleCloseNotification}
							>
								<CrossIcon />
							</button>
						</div>
					</div>
				}
				onClose={handleCloseNotification}
				open={isNotificationDrawerOpen}
				rootClassName='notification-drawer'
			>
				<Tabs
					defaultActiveKey={NotificationTabs.ALL}
					items={items}
					rootClassName='tabs-container'
					onChange={handleTabChange}
					destroyInactiveTabPane
				/>
			</CommonDrawer>
			{contextHolder}
		</>
	);
};

export default NotificationDrawer;

type NotificationContentProps = {
	handleAction: (
		action: string,
		type: NotificationTypes,
		notification: TNotificationData
	) => Promise<void>;
	isLoading: boolean;
};

const NotificationContent = forwardRef<HTMLDivElement, NotificationContentProps>(
	({ handleAction, isLoading }, observerRef) => {
		const {
			notifications,
			loader: { notificationListLoader }
		} = useAppSelector(state => state.notification);

		const groupedNotification = useMemo(() => {
			return groupDataByDate(notifications);
		}, [notifications]);

		return (
			<>
				{notifications?.length > 0 ? (
					<>
						{Object.entries(groupedNotification).map(
							([date, notifications]) => {
								return (
									<div key={date}>
										<div className='notification-time'>
											{getDateLabel(new Date(date))}
										</div>
										{notifications?.map(notification => (
											<div key={notification.notificationHistoryId}>
												<NotificationCard
													notification={notification}
													handleAction={handleAction}
													loading={isLoading}
													// companyId={companyId || 0}
												/>
											</div>
										))}
									</div>
								);
							}
						)}
					</>
				) : (
					<>
						{notificationListLoader ? (
							<div className='flex justifyCenter alignCenter p-14'>
								<Spin />
							</div>
						) : (
							<NoDataFound />
						)}
					</>
				)}

				{notifications?.length > 0 && notificationListLoader && (
					<div className='flex justifyCenter alignCenter p-14'>
						<Spin />
					</div>
				)}

				<div ref={observerRef} style={{ height: "1px" }}></div>
			</>
		);
	}
);
