const ProfileIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'
		>
			<circle
				cx='9.9974'
				cy='4.9974'
				r='3.33333'
				stroke='black'
				strokeWidth='1.25'
			/>
			<ellipse
				cx='9.9974'
				cy='14.1693'
				rx='5.83333'
				ry='3.33333'
				stroke='black'
				strokeWidth='1.25'
			/>
		</svg>
	);
};

export { ProfileIcon };
