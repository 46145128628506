import { createAsyncThunk } from "@reduxjs/toolkit";
import endPoints from "./endPoints.json";
import axiosInstance from "../../middleware/axios";

export const getCompanyDetails = createAsyncThunk(
	"company/details",
	async (args, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get(endPoints.getAllCompanies);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);
