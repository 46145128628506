import { createSlice } from "@reduxjs/toolkit";

import { TManageOrderState } from "../../types/ordersTypes";
import {
	acceptOrRejectOrder,
	getCompanyOrderData,
	getDispatchDetails,
	getNewOrders,
	getOrderDetails,
	getOrderItemsByOrder,
	getOrdersData,
	orderDispatch,
	updateStatusByorderDispatchId,
	updateStatusByOrderId
} from "../services/orders";
import { SliceNames } from "../../shared/constants/constant";

const initialState: TManageOrderState = {
	newOrders: {
		data: null,
		isLoading: false,
		isSuccess: false,
		message: ""
	},
	companyOrderData: {
		data: null,
		isLoading: false,
		isSuccess: false,
		message: ""
	},
	orderData: {
		data: null,
		isLoading: false,
		isSuccess: false,
		message: ""
	},
	orderItems: {
		data: null,
		isLoading: false,
		isSuccess: false,
		message: ""
	},
	orderDetails: {
		data: null,
		isLoading: false,
		isSuccess: false,
		message: ""
	},
	statusDetails: {
		data: null,
		isLoading: false,
		isSuccess: false,
		message: ""
	},
	acceptOrRejectOrderData: {
		data: null,
		isLoading: false,
		isSuccess: false,
		message: ""
	},
	getDispatchDetailsData: {
		data: null,
		isLoading: false,
		isSuccess: false,
		message: ""
	}
};

const ordersSlice = createSlice({
	name: SliceNames.MANAGE_ORDERS,
	initialState,
	reducers: {
		clearOrderItemData: state => {
			state.orderItems.data = initialState.orderItems.data;
		},
		clearOrderData: state => {
			state.orderData.data = initialState.orderData.data;
		},
		clearOrderDetailData: state => {
			state.orderDetails = initialState.orderDetails;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getNewOrders.pending, state => {
				state.newOrders.isLoading = true;
				state.newOrders.isSuccess = false;
			})
			.addCase(getNewOrders.fulfilled, (state, { payload }: any) => {
				state.newOrders.isLoading = false;
				state.newOrders.isSuccess = true;
				state.newOrders.data = payload?.data;
				state.newOrders.message = payload?.message;
			})
			.addCase(getNewOrders.rejected, (state, { payload }: any) => {
				state.newOrders.isLoading = false;
				state.newOrders.isSuccess = false;
				state.newOrders.data = payload?.data;
				state.newOrders.message = payload?.message;
			})

			//
			.addCase(getCompanyOrderData.pending, state => {
				state.companyOrderData.isLoading = true;
				state.companyOrderData.isSuccess = false;
			})
			.addCase(getCompanyOrderData.fulfilled, (state, { payload }: any) => {
				state.companyOrderData.isLoading = false;
				state.companyOrderData.isSuccess = true;
				state.companyOrderData.data = payload?.data;
				state.companyOrderData.message = payload?.message;
			})
			.addCase(getCompanyOrderData.rejected, (state, { payload }: any) => {
				state.companyOrderData.isLoading = false;
				state.companyOrderData.isSuccess = false;
				state.companyOrderData.data = payload?.data;
				state.companyOrderData.message = payload?.message;
			})

			//
			.addCase(getOrdersData.pending, state => {
				state.orderData.isLoading = true;
				state.orderData.isSuccess = false;
			})
			.addCase(getOrdersData.fulfilled, (state, { payload }: any) => {
				state.orderData.isLoading = false;
				state.orderData.isSuccess = true;
				state.orderData.data = payload?.data;
				state.orderData.message = payload?.message;
			})
			.addCase(getOrdersData.rejected, (state, { payload }: any) => {
				state.orderData.isLoading = false;
				state.orderData.isSuccess = false;
				state.orderData.data = payload?.data;
				state.orderData.message = payload?.message;
			})

			//
			.addCase(getOrderItemsByOrder.pending, state => {
				state.orderItems.data = initialState.orderItems.data;
				state.orderItems.isLoading = true;
				state.orderItems.isSuccess = false;
			})
			.addCase(getOrderItemsByOrder.fulfilled, (state, { payload }: any) => {
				state.orderItems.isLoading = false;
				state.orderItems.isSuccess = true;
				state.orderItems.data = payload?.data;
				state.orderItems.message = payload?.message;
			})
			.addCase(getOrderItemsByOrder.rejected, (state, { payload }: any) => {
				state.orderItems.isLoading = false;
				state.orderItems.isSuccess = false;
				state.orderItems.data = payload?.data;
				state.orderItems.message = payload?.message;
			})

			//
			.addCase(getOrderDetails.pending, state => {
				state.orderDetails.isLoading = true;
				state.orderDetails.isSuccess = false;
				state.orderDetails.data = initialState.orderDetails.data;
			})
			.addCase(getOrderDetails.fulfilled, (state, { payload }: any) => {
				state.orderDetails.isLoading = false;
				state.orderDetails.isSuccess = true;
				state.orderDetails.data = payload?.data;
				state.orderDetails.message = payload?.message;
			})
			.addCase(getOrderDetails.rejected, (state, { payload }: any) => {
				state.orderDetails.isLoading = false;
				state.orderDetails.isSuccess = false;
				state.orderDetails.data = payload?.data;
				state.orderDetails.message = payload?.message;
			})

			//
			.addCase(updateStatusByOrderId.pending, state => {
				state.statusDetails.isLoading = true;
				state.statusDetails.isSuccess = false;
			})
			.addCase(updateStatusByOrderId.fulfilled, (state, { payload }: any) => {
				state.statusDetails.isLoading = false;
				state.statusDetails.isSuccess = true;
				state.statusDetails.data = payload?.data;
				state.statusDetails.message = payload?.message;
			})
			.addCase(updateStatusByOrderId.rejected, (state, { payload }: any) => {
				state.statusDetails.isLoading = false;
				state.statusDetails.isSuccess = false;
				state.statusDetails.data = payload?.data;
				state.statusDetails.message = payload?.message;
			})

			//
			.addCase(updateStatusByorderDispatchId.pending, state => {
				state.statusDetails.isLoading = true;
				state.statusDetails.isSuccess = false;
			})
			.addCase(
				updateStatusByorderDispatchId.fulfilled,
				(state, { payload }: any) => {
					state.statusDetails.isLoading = false;
					state.statusDetails.isSuccess = true;
					state.statusDetails.data = payload?.data;
					state.statusDetails.message = payload?.message;
				}
			)
			.addCase(
				updateStatusByorderDispatchId.rejected,
				(state, { payload }: any) => {
					state.statusDetails.isLoading = false;
					state.statusDetails.isSuccess = false;
					state.statusDetails.data = payload?.data;
					state.statusDetails.message = payload?.message;
				}
			)

			//
			.addCase(orderDispatch.pending, state => {
				state.statusDetails.isLoading = true;
				state.statusDetails.isSuccess = false;
			})
			.addCase(orderDispatch.fulfilled, (state, { payload }: any) => {
				state.statusDetails.isLoading = false;
				state.statusDetails.isSuccess = true;
				state.statusDetails.message = payload?.message;
			})
			.addCase(orderDispatch.rejected, (state, { payload }: any) => {
				state.statusDetails.isLoading = false;
				state.statusDetails.isSuccess = false;
				state.statusDetails.message = payload?.message;
			})

			// acceptOrRejectOrder

			.addCase(acceptOrRejectOrder.pending, state => {
				state.acceptOrRejectOrderData.isLoading = true;
				state.acceptOrRejectOrderData.isSuccess = false;
			})
			.addCase(acceptOrRejectOrder.fulfilled, (state, { payload }: any) => {
				state.acceptOrRejectOrderData.isLoading = false;
				state.acceptOrRejectOrderData.isSuccess = true;
				state.acceptOrRejectOrderData.message = payload?.message;
			})
			.addCase(acceptOrRejectOrder.rejected, (state, { payload }: any) => {
				state.acceptOrRejectOrderData.isLoading = false;
				state.acceptOrRejectOrderData.isSuccess = false;
				state.acceptOrRejectOrderData.message = payload?.message;
			})

			// getDispatchDetails

			.addCase(getDispatchDetails.pending, state => {
				state.getDispatchDetailsData.isLoading = true;
				state.getDispatchDetailsData.isSuccess = false;
			})
			.addCase(getDispatchDetails.fulfilled, (state, { payload }: any) => {
				state.getDispatchDetailsData.isLoading = false;
				state.getDispatchDetailsData.isSuccess = true;
				state.getDispatchDetailsData.data = payload?.data;
			})
			.addCase(getDispatchDetails.rejected, (state, { payload }: any) => {
				state.getDispatchDetailsData.isLoading = false;
				state.getDispatchDetailsData.isSuccess = false;
				state.getDispatchDetailsData.message = payload?.message;
			});
	}
});

export const { clearOrderItemData, clearOrderDetailData, clearOrderData } =
	ordersSlice.actions;
export default ordersSlice.reducer;
