import { lazy } from "react";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { useSelector } from "react-redux";
import BulkImport from "../components/bulk-import/BulkImport";
import ErrorBoundary from "../components/errorBoundary";
import DownloadApp from "../downloadApp";
import ConnectionSettings from "../pages/accountSettings/connectionSettings";
import CreateRollForm from "../pages/accountSettings/createRollForm";
import NotificationSettings from "../pages/accountSettings/notificationSettings";
import OrdersPage from "../pages/accountSettings/orders";
import ProductSetting from "../pages/accountSettings/productSetting";
import UserRollPage from "../pages/accountSettings/userRollPage";
import AuthLayout from "../pages/auth/authLayout";
import ConnectionDetails from "../pages/connections/connectionDetails";
import CreateCompany from "../pages/createCompany";
import LayoutPage from "../pages/landingPage/Layout";
import Layout from "../pages/layout";
import AppComingSoonPage from "../pages/onborading";
import Subscription from "../pages/subscription";
import SubscribeForm from "../pages/subscription/subscribeForm";
import SubscriptionBody from "../pages/subscription/subscriptionBody";
import SubscriptionDetails from "../pages/subscription/subscriptionDetails";
import { authObjectSelector } from "../redux/memorize-selector";
import AuthGuard from "./guards/auth-guard";
import PreventMobile from "./guards/prevent-mobile";

const PlaceOrder = lazy(() => import("../pages/placeOrder"));
const Login = lazy(() => import("../pages/auth/login"));
const Products = lazy(() => import("../pages/products"));
const AddProduct = lazy(() => import("../pages/products/AddProduct"));
const Connection = lazy(() => import("../pages/connections"));
const Orders = lazy(() => import("../pages/orders"));
const OrderDetails = lazy(() => import("../pages/orders/orderDetails"));
const SettingsPage = lazy(() => import("../pages/settings"));
const LandingPage = lazy(() => import("../pages/landingPage"));
const ProductDetails = lazy(() => import("../pages/placeOrder/productDetails"));
const Chat = lazy(() => import("../pages/chat"));
const DiscountAndOffers = lazy(() => import("../pages/discountAndOffers"));
const PrivacyPolicy = lazy(() => import("../pages/privacyPolicy"));
const AboutPage = lazy(() => import("../pages/about"));
const ContactPage = lazy(() => import("../pages/contact"));
const ProfileSetting = lazy(() => import("../pages/settings/profileSetting"));
const SpecialPriceListPage = lazy(() => import("../pages/specialPriceList"));
const ProductsPriceList = lazy(
	() => import("../pages/specialPriceList/productsPriceList")
);
const Disclaimer = lazy(() => import("../pages/landingPage/disclaimer"));
const TearmsAndConditions = lazy(() => import("../pages/landingPage/tearmsAndCondition"));
const Dashboard = lazy(() => import("../pages/dashboard"));
const OrdersData = lazy(() => import("../pages/orders/ordersData"));
const Vendors = lazy(() => import("../pages/vendors"));
const ErrorPage = lazy(() => import("../pages/ErrorPage"));
const SomethingWrong = lazy(() => import("../pages/ErrorPage/SomethingWrong"));
const Page404 = lazy(() => import("../pages/ErrorPage/404"));
const EditProduct = lazy(() => import("../pages/products/editProduct"));
const GetHelp = lazy(() => import("../pages/getHelp"));
const AccountSetting = lazy(() => import("../pages/accountSettings"));
const Stock = lazy(() => import("../pages/stock/Stock"));
const AddStockPage = lazy(() => import("../components/stock/addStock"));
const AdjustStockForm = lazy(() => import("../components/stock/adjustStockForm"));
const StockDashboard = lazy(
	() => import("../components/stock/stockDashBoard/StockDashboard")
);
const LowStock = lazy(() => import("../components/stock/lowStock"));

const Routing = () => {
	const auth = useSelector(authObjectSelector);
	const isAuthenticated = auth.authenticated;

	return (
		<Router>
			<Routes>
				{/* Public Routes */}
				<Route
					path='/'
					element={
						<Navigate replace to={isAuthenticated ? "/dashboard" : "/home"} />
					}
				/>
				<Route
					path='/login'
					element={
						isAuthenticated ? (
							<Navigate to='/dashboard' />
						) : (
							<PreventMobile>
								<AuthLayout>
									<Login />
								</AuthLayout>
							</PreventMobile>
						)
					}
				/>
				<Route path='/create-company' element={<CreateCompany />} />
				<Route
					path='/home'
					element={
						isAuthenticated ? <Navigate to='/dashboard' /> : <LayoutPage />
					}
				>
					<Route index element={<LandingPage />} />
					<Route path='about' element={<AboutPage />} />
					<Route path='contact' element={<ContactPage />} />
					<Route path='privacyPolicy' element={<PrivacyPolicy />} />
					<Route path='terms-and-condition' element={<TearmsAndConditions />} />
					<Route path='disclaimer' element={<Disclaimer />} />
				</Route>

				<Route
					path='/get-app'
					element={
						<PreventMobile>
							<DownloadApp />
						</PreventMobile>
					}
				/>
				<Route path='/coming-soon' element={<AppComingSoonPage />} />
				<Route path='/500' element={<ErrorPage />} />
				<Route path='/error' element={<SomethingWrong />} />
				<Route path='/404' element={<Page404 />} />
				<Route path='/gethelp' element={<GetHelp />} />

				{/* Protected Routes */}

				<Route
					element={
						<ErrorBoundary>
							<PreventMobile>
								<AuthGuard>
									<Layout />
								</AuthGuard>
							</PreventMobile>
						</ErrorBoundary>
					}
				>
					<Route path='/dashboard' element={<Dashboard />} />

					<Route path='/products' element={<Products />} />
					<Route path='/products/:id' element={<EditProduct />} />
					<Route path='/products/add' element={<AddProduct />} />

					<Route path='/products/bulk-import' element={<BulkImport />} />

					<Route path='/placeorder' element={<PlaceOrder />} />
					<Route path='/placeorder/:id' element={<ProductDetails />} />

					<Route path='/vendors' element={<Vendors />} />

					<Route path='/orders' element={<Orders />}>
						<Route index element={<OrdersData />} />
						<Route path='customer-orders' element={<OrdersData />} />
						<Route path='my-orders' element={<OrdersData />} />
					</Route>
					{/* <Route path="/cart" element={<CartPage />}>
						<Route index element={<CartScreen />} />
					</Route> */}
					<Route path='/orders/:orderType/:id' element={<OrderDetails />} />

					<Route path='/settings' element={<AccountSetting />}>
						<Route path=':id' element={<ProfileSetting />} />
						<Route path=':id/profile' element={<ProfileSetting />} />
						<Route
							path=':id/user-and-permission'
							element={<UserRollPage />}
						/>
						<Route
							path=':id/user-and-permission/createUser'
							element={<CreateRollForm />}
						/>
						<Route
							path=':id/user-and-permission/user/:userId'
							element={<CreateRollForm />}
						/>
						<Route path=':id/orders' element={<OrdersPage />} />
						<Route path=':id/products' element={<ProductSetting />} />
						<Route path=':id/connections' element={<ConnectionSettings />} />

						<Route path=':id/subscribe' element={<Subscription />}>
							<Route index element={<SubscriptionDetails />} />
							<Route path='plans' element={<SubscriptionBody />} />
							<Route
								path='plans/:subscribeId'
								element={<SubscribeForm />}
							/>
						</Route>
						<Route
							path=':id/notification'
							element={<NotificationSettings />}
						/>
						<Route path=':id/company-details' element={<SettingsPage />} />

						<Route path=':id/gethelp' element={<GetHelp />} />
					</Route>

					<Route path='/special-price-list' element={<SpecialPriceListPage />}>
						<Route path=':id' element={<ProductsPriceList />} />
					</Route>

					<Route path='/connection' element={<Connection />}>
						<Route index path=':id' element={<ConnectionDetails />} />
					</Route>

					<Route path='/chat' element={<Chat />} />

					<Route path='/discount&offers' element={<DiscountAndOffers />} />

					<Route path='/stock' element={<Stock />}>
						<Route index element={<StockDashboard />} />
						<Route path=':id/add' element={<AddStockPage />} />
						<Route path='adjust/:productId' element={<AdjustStockForm />} />
						<Route path='alert/:productId' element={<LowStock />} />
					</Route>
					<Route path='*' element={<Navigate to='404' />} />
				</Route>
			</Routes>
		</Router>
	);
};

export default Routing;
