import React from "react";

const BulkImportHeader = () => {
	return (
		<div className='header__text'>
			<h2 className='main-title'>Bulk Import / Upload .CSV File</h2>
			<p className='caption'>
				Easily upload and import bulk data using a CSV file.
			</p>
		</div>
	);
};

export default BulkImportHeader;
