import { HelpIcon } from "../../assets/svg";
import CommonModal from "../commonModal";
import ImageLoad from "../ImageLoad";

interface Props {
	headerContent?: string;
	open: boolean;
	infoContent: string;
	close: () => void;
	confirm: () => void;
	cancelButtonText?: string;
	confirmButtonText?: string;
	disabled?: boolean;
	icon?: string;
	rootClassName?: string;
}

const ConfirmationPopup = ({
	headerContent = "Confirm this action",
	open,
	infoContent,
	close,
	confirm,
	cancelButtonText = "No",
	confirmButtonText = "Yes",
	disabled = false,
	icon,
	rootClassName
}: Props) => {
	return (
		<CommonModal
			open={open}
			close={close}
			rootClassName={rootClassName}
			className='confirmation-popup'
		>
			<div className='flex direction-column gap-8'>
				<div className='content-container flex direction-column justifyCenter alignCenter gap-5'>
					{icon ? (
						<ImageLoad src={icon} alt='icon' />
					) : (
						<div className='icon-box flex'>
							<HelpIcon />
						</div>
					)}
					<div className='flex direction-column textCenter gap-4'>
						<div className='confirmation-header'>{headerContent}</div>
						<div className='confirmation-info'>{infoContent}</div>
					</div>
				</div>
				<div className='action-container flex gap-3'>
					<button
						className='secondary large40 rounded-14'
						onClick={close}
						disabled={disabled}
					>
						{cancelButtonText}
					</button>
					<button
						className='primary large40 rounded-14'
						onClick={confirm}
						disabled={disabled}
					>
						{confirmButtonText}
					</button>
				</div>
			</div>
		</CommonModal>
	);
};

export default ConfirmationPopup;
