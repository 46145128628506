const IndiaFlagIcon = () => {
  return (
    <svg
      width="395"
      height="263"
      viewBox="0 0 395 263"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="395" height="88" fill="#FF9933" />
      <rect width="395" height="88" transform="translate(0 88)" fill="white" />
      <rect
        width="395"
        height="87"
        transform="translate(0 176)"
        fill="#138808"
      />
      <circle cx="197.5" cy="131.5" r="6.15471" fill="#000080" />
      <path
        d="M230.42 131.5C230.42 149.681 215.681 164.42 197.5 164.42C179.319 164.42 164.58 149.681 164.58 131.5C164.58 113.319 179.319 98.5801 197.5 98.5801C215.681 98.5801 230.42 113.319 230.42 131.5Z"
        stroke="#000080"
        strokeWidth="4.5"
      />
      <ellipse
        cx="193.483"
        cy="100.99"
        rx="1.75849"
        ry="1.75849"
        transform="rotate(-7.5 193.483 100.99)"
        fill="#000080"
      />
      <circle
        cx="201.517"
        cy="162.01"
        r="1.75849"
        transform="rotate(-7.5 201.517 162.01)"
        fill="#000080"
      />
      <ellipse
        cx="225.931"
        cy="143.277"
        rx="1.75849"
        ry="1.75849"
        transform="rotate(112.5 225.931 143.277)"
        fill="#000080"
      />
      <ellipse
        cx="169.069"
        cy="119.724"
        rx="1.75849"
        ry="1.75849"
        transform="rotate(112.5 169.069 119.724)"
        fill="#000080"
      />
      <circle
        cx="221.914"
        cy="112.766"
        r="1.75849"
        transform="rotate(52.5 221.914 112.766)"
        fill="#000080"
      />
      <ellipse
        cx="173.086"
        cy="150.234"
        rx="1.75849"
        ry="1.75849"
        transform="rotate(52.5 173.086 150.234)"
        fill="#000080"
      />
      <circle
        cx="216.234"
        cy="107.086"
        r="1.75849"
        transform="rotate(37.5 216.234 107.086)"
        fill="#000080"
      />
      <ellipse
        cx="178.766"
        cy="155.914"
        rx="1.75849"
        ry="1.75849"
        transform="rotate(37.5 178.766 155.914)"
        fill="#000080"
      />
      <ellipse
        cx="209.277"
        cy="159.931"
        rx="1.75849"
        ry="1.75849"
        transform="rotate(157.5 209.277 159.931)"
        fill="#000080"
      />
      <ellipse
        cx="185.723"
        cy="103.069"
        rx="1.75849"
        ry="1.75849"
        transform="rotate(157.5 185.723 103.069)"
        fill="#000080"
      />
      <ellipse
        cx="228.01"
        cy="135.517"
        rx="1.75849"
        ry="1.75849"
        transform="rotate(97.5 228.01 135.517)"
        fill="#000080"
      />
      <ellipse
        cx="166.99"
        cy="127.483"
        rx="1.75849"
        ry="1.75849"
        transform="rotate(97.5 166.99 127.483)"
        fill="#000080"
      />
      <ellipse
        cx="209.277"
        cy="103.069"
        rx="1.75849"
        ry="1.75849"
        transform="rotate(22.5 209.277 103.069)"
        fill="#000080"
      />
      <ellipse
        cx="185.723"
        cy="159.931"
        rx="1.75849"
        ry="1.75849"
        transform="rotate(22.5 185.723 159.931)"
        fill="#000080"
      />
      <ellipse
        cx="216.234"
        cy="155.915"
        rx="1.75849"
        ry="1.75849"
        transform="rotate(142.5 216.234 155.915)"
        fill="#000080"
      />
      <ellipse
        cx="178.766"
        cy="107.086"
        rx="1.75849"
        ry="1.75849"
        transform="rotate(142.5 178.766 107.086)"
        fill="#000080"
      />
      <ellipse
        cx="228.01"
        cy="127.483"
        rx="1.75849"
        ry="1.75849"
        transform="rotate(82.5 228.01 127.483)"
        fill="#000080"
      />
      <ellipse
        cx="166.99"
        cy="135.517"
        rx="1.75849"
        ry="1.75849"
        transform="rotate(82.5 166.99 135.517)"
        fill="#000080"
      />
      <ellipse
        cx="201.517"
        cy="100.99"
        rx="1.75849"
        ry="1.75849"
        transform="rotate(7.5 201.517 100.99)"
        fill="#000080"
      />
      <ellipse
        cx="193.483"
        cy="162.011"
        rx="1.75849"
        ry="1.75849"
        transform="rotate(7.5 193.483 162.011)"
        fill="#000080"
      />
      <circle
        cx="221.914"
        cy="150.234"
        r="1.75849"
        transform="rotate(127.5 221.914 150.234)"
        fill="#000080"
      />
      <ellipse
        cx="173.086"
        cy="112.766"
        rx="1.75849"
        ry="1.75849"
        transform="rotate(127.5 173.086 112.766)"
        fill="#000080"
      />
      <circle
        cx="225.931"
        cy="119.724"
        r="1.75849"
        transform="rotate(67.5 225.931 119.724)"
        fill="#000080"
      />
      <ellipse
        cx="169.069"
        cy="143.277"
        rx="1.75849"
        ry="1.75849"
        transform="rotate(67.5 169.069 143.277)"
        fill="#000080"
      />
      <g clipPath="url(#clip0_10939_33532)">
        <path
          d="M197.186 162.135L196.131 143.671L197.186 134.879L198.241 143.671L197.186 162.135Z"
          fill="#000080"
        />
      </g>
      <g clipPath="url(#clip1_10939_33532)">
        <path
          d="M197.538 100.589L198.593 119.053L197.538 127.845L196.483 119.053L197.538 100.589Z"
          fill="#000080"
        />
      </g>
      <g clipPath="url(#clip2_10939_33532)">
        <path
          d="M170.8 115.823L187.317 124.142L194.404 129.451L186.262 125.969L170.8 115.823Z"
          fill="#000080"
        />
      </g>
      <g clipPath="url(#clip3_10939_33532)">
        <path
          d="M223.925 146.901L207.407 138.583L200.32 133.273L208.462 136.755L223.925 146.901Z"
          fill="#000080"
        />
      </g>
      <g clipPath="url(#clip4_10939_33532)">
        <path
          d="M170.624 146.596L186.086 136.451L194.228 132.968L187.142 138.278L170.624 146.596Z"
          fill="#000080"
        />
      </g>
      <g clipPath="url(#clip5_10939_33532)">
        <path
          d="M224.101 116.127L208.638 126.273L200.496 129.756L207.583 124.446L224.101 116.127Z"
          fill="#000080"
        />
      </g>
      <g clipPath="url(#clip6_10939_33532)">
        <path
          d="M175.478 152.997L187.788 139.195L194.751 133.724L189.28 140.687L175.478 152.997Z"
          fill="#000080"
        />
      </g>
      <g clipPath="url(#clip7_10939_33532)">
        <path
          d="M219.247 109.726L206.937 123.528L199.973 128.999L205.445 122.036L219.247 109.726Z"
          fill="#000080"
        />
      </g>
      <g clipPath="url(#clip8_10939_33532)">
        <path
          d="M189.567 101.592L195.365 119.154L196.622 127.92L193.327 119.7L189.567 101.592Z"
          fill="#000080"
        />
      </g>
      <g clipPath="url(#clip9_10939_33532)">
        <path
          d="M205.157 161.133L199.359 143.571L198.103 134.805L201.397 143.025L205.157 161.133Z"
          fill="#000080"
        />
      </g>
      <g clipPath="url(#clip10_10939_33532)">
        <path
          d="M167.683 123.227L185.791 126.987L194.011 130.282L185.245 129.025L167.683 123.227Z"
          fill="#000080"
        />
      </g>
      <g clipPath="url(#clip11_10939_33532)">
        <path
          d="M227.042 139.497L208.934 135.737L200.714 132.442L209.48 133.699L227.042 139.497Z"
          fill="#000080"
        />
      </g>
      <g clipPath="url(#clip12_10939_33532)">
        <path
          d="M181.823 157.925L190.141 141.407L195.451 134.32L191.969 142.462L181.823 157.925Z"
          fill="#000080"
        />
      </g>
      <g clipPath="url(#clip13_10939_33532)">
        <path
          d="M212.901 104.799L204.583 121.317L199.273 128.404L202.755 120.262L212.901 104.799Z"
          fill="#000080"
        />
      </g>
      <g clipPath="url(#clip14_10939_33532)">
        <path
          d="M182.128 104.624L192.274 120.087L195.756 128.229L190.446 121.142L182.128 104.624Z"
          fill="#000080"
        />
      </g>
      <g clipPath="url(#clip15_10939_33532)">
        <path
          d="M212.597 158.101L202.451 142.638L198.968 134.496L204.278 141.583L212.597 158.101Z"
          fill="#000080"
        />
      </g>
      <g clipPath="url(#clip16_10939_33532)">
        <path
          d="M166.589 131.186L185.053 130.131L193.845 131.186L185.053 132.241L166.589 131.186Z"
          fill="#000080"
        />
      </g>
      <g clipPath="url(#clip17_10939_33532)">
        <path
          d="M228.136 131.538L209.672 132.593L200.879 131.538L209.672 130.482L228.136 131.538Z"
          fill="#000080"
        />
      </g>
      <g clipPath="url(#clip18_10939_33532)">
        <path
          d="M189.228 161.042L192.987 142.934L196.282 134.714L195.026 143.48L189.228 161.042Z"
          fill="#000080"
        />
      </g>
      <g clipPath="url(#clip19_10939_33532)">
        <path
          d="M205.497 101.683L201.737 119.791L198.442 128.011L199.699 119.245L205.497 101.683Z"
          fill="#000080"
        />
      </g>
      <g clipPath="url(#clip20_10939_33532)">
        <path
          d="M175.726 109.477L189.529 121.787L195 128.751L188.036 123.279L175.726 109.477Z"
          fill="#000080"
        />
      </g>
      <g clipPath="url(#clip21_10939_33532)">
        <path
          d="M218.998 153.246L205.196 140.936L199.725 133.973L206.688 139.444L218.998 153.246Z"
          fill="#000080"
        />
      </g>
      <g clipPath="url(#clip22_10939_33532)">
        <path
          d="M167.592 139.157L185.154 133.359L193.92 132.102L185.7 135.397L167.592 139.157Z"
          fill="#000080"
        />
      </g>
      <g clipPath="url(#clip23_10939_33532)">
        <path
          d="M227.133 123.568L209.571 129.366L200.805 130.622L209.025 127.327L227.133 123.568Z"
          fill="#000080"
        />
      </g>
      <defs>
        <clipPath id="clip0_10939_33532">
          <rect
            width="10.5509"
            height="28.1358"
            fill="white"
            transform="matrix(-1 0 0 1 202.638 134.439)"
          />
        </clipPath>
        <clipPath id="clip1_10939_33532">
          <rect
            width="10.5509"
            height="28.1358"
            fill="white"
            transform="matrix(1 0 0 -1 192.087 128.285)"
          />
        </clipPath>
        <clipPath id="clip2_10939_33532">
          <rect
            width="10.5509"
            height="28.1358"
            fill="white"
            transform="matrix(0.5 -0.866025 -0.866025 -0.5 192.059 134.392)"
          />
        </clipPath>
        <clipPath id="clip3_10939_33532">
          <rect
            width="10.5509"
            height="28.1358"
            fill="white"
            transform="matrix(-0.5 0.866025 0.866025 0.5 202.665 128.332)"
          />
        </clipPath>
        <clipPath id="clip4_10939_33532">
          <rect
            width="10.5509"
            height="28.1358"
            fill="white"
            transform="matrix(-0.5 -0.866025 -0.866025 0.5 197.335 137.469)"
          />
        </clipPath>
        <clipPath id="clip5_10939_33532">
          <rect
            width="10.5509"
            height="28.1358"
            fill="white"
            transform="matrix(0.5 0.866025 0.866025 -0.5 197.39 125.254)"
          />
        </clipPath>
        <clipPath id="clip6_10939_33532">
          <rect
            width="10.5509"
            height="28.1358"
            fill="white"
            transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 198.917 137.268)"
          />
        </clipPath>
        <clipPath id="clip7_10939_33532">
          <rect
            width="10.5509"
            height="28.1358"
            fill="white"
            transform="matrix(0.707107 0.707107 0.707107 -0.707107 195.808 125.456)"
          />
        </clipPath>
        <clipPath id="clip8_10939_33532">
          <rect
            width="10.5509"
            height="28.1358"
            fill="white"
            transform="matrix(0.965926 -0.258819 -0.258819 -0.965926 191.47 129.755)"
          />
        </clipPath>
        <clipPath id="clip9_10939_33532">
          <rect
            width="10.5509"
            height="28.1358"
            fill="white"
            transform="matrix(-0.965926 0.258819 0.258819 0.965926 203.254 132.969)"
          />
        </clipPath>
        <clipPath id="clip10_10939_33532">
          <rect
            width="10.5509"
            height="28.1358"
            fill="white"
            transform="matrix(0.258819 -0.965926 -0.965926 -0.258819 193.024 135.661)"
          />
        </clipPath>
        <clipPath id="clip11_10939_33532">
          <rect
            width="10.5509"
            height="28.1358"
            fill="white"
            transform="matrix(-0.258819 0.965926 0.965926 0.258819 201.7 127.062)"
          />
        </clipPath>
        <clipPath id="clip12_10939_33532">
          <rect
            width="10.5509"
            height="28.1358"
            fill="white"
            transform="matrix(-0.866025 -0.5 -0.5 0.866025 200.392 136.665)"
          />
        </clipPath>
        <clipPath id="clip13_10939_33532">
          <rect
            width="10.5509"
            height="28.1358"
            fill="white"
            transform="matrix(0.866025 0.5 0.5 -0.866025 194.332 126.059)"
          />
        </clipPath>
        <clipPath id="clip14_10939_33532">
          <rect
            width="10.5509"
            height="28.1358"
            fill="white"
            transform="matrix(0.866025 -0.5 -0.5 -0.866025 191.255 131.335)"
          />
        </clipPath>
        <clipPath id="clip15_10939_33532">
          <rect
            width="10.5509"
            height="28.1358"
            fill="white"
            transform="matrix(-0.866025 0.5 0.5 0.866025 203.47 131.39)"
          />
        </clipPath>
        <clipPath id="clip16_10939_33532">
          <rect
            width="10.5509"
            height="28.1358"
            fill="white"
            transform="matrix(3.69127e-08 -1 -1 -3.69127e-08 194.285 136.637)"
          />
        </clipPath>
        <clipPath id="clip17_10939_33532">
          <rect
            width="10.5509"
            height="28.1358"
            fill="white"
            transform="matrix(-3.69127e-08 1 1 3.69127e-08 200.44 126.086)"
          />
        </clipPath>
        <clipPath id="clip18_10939_33532">
          <rect
            width="10.5509"
            height="28.1358"
            fill="white"
            transform="matrix(-0.965926 -0.258819 -0.258819 0.965926 201.661 135.7)"
          />
        </clipPath>
        <clipPath id="clip19_10939_33532">
          <rect
            width="10.5509"
            height="28.1358"
            fill="white"
            transform="matrix(0.965926 0.258819 0.258819 -0.965926 193.063 127.024)"
          />
        </clipPath>
        <clipPath id="clip20_10939_33532">
          <rect
            width="10.5509"
            height="28.1358"
            fill="white"
            transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 191.456 132.916)"
          />
        </clipPath>
        <clipPath id="clip21_10939_33532">
          <rect
            width="10.5509"
            height="28.1358"
            fill="white"
            transform="matrix(-0.707107 0.707107 0.707107 0.707107 203.269 129.808)"
          />
        </clipPath>
        <clipPath id="clip22_10939_33532">
          <rect
            width="10.5509"
            height="28.1358"
            fill="white"
            transform="matrix(-0.258819 -0.965926 -0.965926 0.258819 195.755 137.254)"
          />
        </clipPath>
        <clipPath id="clip23_10939_33532">
          <rect
            width="10.5509"
            height="28.1358"
            fill="white"
            transform="matrix(0.258819 0.965926 0.965926 -0.258819 198.969 125.47)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export { IndiaFlagIcon };
