const CheckMarkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9557 5.90113L9.05079 19.5068L3.04214 13.4981L4.50055 12.0397L8.95032 16.4895L19.4035 4.54297L20.9557 5.90113Z"
        fill="black"
      />
    </svg>
  );
};

export { CheckMarkIcon };
