import { createSlice } from "@reduxjs/toolkit";
import { SliceNames } from "../../shared/constants/constant";
import { REHYDRATE } from "redux-persist";
import { TProductDraftState } from "../../types/productDraft";
import { TRehydratePayload } from "../../types/store";

const initialState: TProductDraftState = {
	product: {},
	isDraft: false
};

const productDraftSlice = createSlice({
	name: SliceNames.PRODUCT_DRAFT,
	initialState,
	reducers: {
		updateProductDraft: (state, { payload }) => {
			state.product = payload;
		},
		isDraftProduct: (state, { payload }) => {
			state.isDraft = payload;
		},
		resetProductDraftState: state => {
			state.product = initialState.product;
			state.isDraft = false;
		}
	},

	extraReducers: builder => {
		builder
			// INFO: Select Products For Cart

			.addCase(
				REHYDRATE,
				(
					state,
					action: { type: typeof REHYDRATE; payload?: TRehydratePayload }
				) => {
					return {
						...state,
						...action?.payload?.productDraft
					};
				}
			);
	}
});

export const { updateProductDraft, isDraftProduct, resetProductDraftState } =
	productDraftSlice.actions;
export default productDraftSlice.reducer;
