import React, { useState } from "react";
import { AndroidIcon, BiizlineLogo, PhoneIcon } from "../../assets/images";
import { Button, InputField } from "../../components";
import useMessageHook from "../../hooks/useMessageHook";
import { REGEX_STRINGS } from "../../shared/constants/constant";
import { ACCOUNT_SETTINGS, ERROR_STRINGS } from "../../shared/constants/content-constant";
import { WpIcon } from "../../assets/svg";
import { LogoEyeIcon } from "../../assets/svg/icons";
import axiosInstance from "../../middleware/axios";

const AppComingSoonPage = () => {
	const [step, setStep] = useState(1);
	const [value, setValue] = useState<any>();
	const [error, setError] = useState<any>();
	const { showMessage, contextHolder } = useMessageHook();

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value, name } = e.target;
		setValue((prev: any) => ({
			...prev,
			[name]:
				name === "phone_number"
					? REGEX_STRINGS.NUMBER.test(value)
						? value
						: ""
					: value
		}));
	};

	const handleValidate = () => {
		const newObj = {};
		setError({});
		if (!value?.company_name?.length) {
			Object.assign(newObj, { company_name: true });
		}
		if (!value?.phone_number?.length) {
			Object.assign(newObj, { phone_number: true });
		}
		if (
			value?.phone_number?.length &&
			!REGEX_STRINGS.PHONE_NUMBER.test(value?.phone_number)
		) {
			Object.assign(newObj, { phone_number: true });
			showMessage("error", ERROR_STRINGS.PHONE_NUMBER);
		}
		if (Object.keys(newObj)?.length) {
			setError(newObj);
			return false;
		} else {
			setError({});
			return true;
		}
	};

	const handleSubmit = async () => {
		if (handleValidate()) {
			const payload = {
				phoneNo: value?.phone_number,
				companyName: value?.company_name
			};
			await axiosInstance
				.post("connectUs/submitDetails", payload)
				.then(result => {
					if (result.status === 200) {
						showMessage("success", result.data?.message);
						setStep(3);
					} else {
						showMessage("error", result.data?.message);
					}
				})
				.catch(() => {
					showMessage("error", ERROR_STRINGS.SOMETHING_WRONG);
				});
		}
	};

	return (
		<div className='app-coming-soon-page'>
			<div className='container flex direction-column justify-center alignCenter'>
				<div className='logo'>
					<img src={BiizlineLogo} alt='logo' />
				</div>
				{step === 1 ? (
					<div className='page-title'>
						<img
							src={AndroidIcon}
							alt='androidIcon'
							className='android-icon'
						/>
						<h2 className='title-2'>Android App is Coming Soon!</h2>
					</div>
				) : step === 2 ? (
					<p className='caption'>Be the First to Know When We Launch!</p>
				) : (
					<></>
				)}
				{step === 1 ? (
					<>
						<div className='mobile-container'>
							<img src={PhoneIcon} alt='phoneIcon' />
						</div>
						<div className='button-container'>
							<Button
								type='button'
								className='primary'
								onClick={() => setStep(2)}
							>
								Notify Me
							</Button>
						</div>
					</>
				) : step === 2 ? (
					<div className='form-container flex direction-column gap-5'>
						{contextHolder}
						<InputField
							name='company_name'
							placeholder='Please Enter your company name'
							onChange={handleChange}
							value={value?.company_name}
							label='Company Name'
							required
							className={error?.company_name ? "error" : ""}
						/>
						<InputField
							label={
								ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.INPUT_FIELDS
									.PHONE_NUMBER.LABEL
							}
							placeholder={
								ACCOUNT_SETTINGS.SUBSCRIPTION_FORM.INPUT_FIELDS
									.PHONE_NUMBER.PLACEHOLDER
							}
							name='phone_number'
							required
							maxLength={10}
							value={value?.phone_number}
							onChange={handleChange}
							className={error?.phone_number ? "error" : ""}
							icon={<WpIcon />}
						/>
						<Button
							type='button'
							onClick={handleSubmit}
							className='primary w-100'
						>
							Submit
						</Button>
					</div>
				) : (
					<div className='card commin-soon-wrapper'>
						<div className='coming-soon-container'>
							<div className='center-container textCenter'>
								<div className='icon-box'>
									<LogoEyeIcon />
								</div>
								<div className='content-container p-4'>
									<div className='title'>Thank You!</div>
									<div className='info'>
										We&apos;ll notify you as soon as we launch.
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default AppComingSoonPage;
