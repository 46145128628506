import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Dispatch, DragEvent, SetStateAction, useState } from "react";
import { XlsxIcon } from "../../assets/svg";
import { UploadOutlinedIcon } from "../../assets/svg";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { bulkProductFileImport } from "../../redux/services/products";
import { BulkProductData } from "../../types/productsType";
import { ConvertSizeToMb } from "../../utils/helper";
import Limits from "../../utils/limits.json";
import BulkImportInstruction from "./BulkImportIntruction";
import { BulkImportSteps } from "../../enum/product-enum";
import { Link } from "react-router-dom";

const BulkImportFileUpload = ({
	file,
	setFile,
	setStep,
	uploadBulkImportData
}: {
	file: File | null;
	setFile: Dispatch<SetStateAction<File | null>>;
	setStep: Dispatch<SetStateAction<number>>;
	uploadBulkImportData: (data: BulkProductData[]) => void;
}) => {
	const dispatch = useAppDispatch();
	const { isLoading, isSuccess, isErrorCount, data } = useAppSelector(
		state => state?.products?.bulkImport
	);

	const [abortController, setAbortController] = useState<AbortController | null>(null);

	const [isError, setIsError] = useState({
		errorMsg: "",
		sizeError: false
	});

	const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		event.stopPropagation();
	};

	const handleDragEnter = (event: DragEvent<HTMLDivElement>) => {
		if (event.target === event.currentTarget) {
			event.preventDefault();
			event.stopPropagation();
			event.currentTarget.classList.add("drag-active");
		}
	};

	const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
		// Check if the event target is a child of the parent div
		if (event.currentTarget.contains(event.relatedTarget as Node)) return;
		event.currentTarget.classList.remove("drag-active");
	};

	const handleDrop = (event: DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		event.stopPropagation();
		event.currentTarget.classList.remove("drag-active");

		if (file) {
			return;
		}

		const droppedFiles = event.dataTransfer.files;
		if (droppedFiles.length > 0) {
			setFile(droppedFiles[0]);
			validateFile(droppedFiles[0]);
		}
	};

	const resetFile = () => {
		if (abortController) {
			abortController.abort();
			setAbortController(null);
		}
		setFile(null);
		setIsError({
			errorMsg: "",
			sizeError: false
		});
	};

	const validateFile = (file: File) => {
		const validFormat = ["xlsx", "csv"];
		const fileType = file?.name?.split(".");
		const fileSize = ConvertSizeToMb(file?.size);

		if (!validFormat?.includes(fileType[fileType?.length - 1])) {
			setIsError({
				errorMsg: "Invalid file format. Please try again.",
				sizeError: false
			});
			return;
		} else if (Number(fileSize) > Limits?.bulkImportFileSize) {
			setIsError({
				errorMsg: "File size larger than 10 MB.",
				sizeError: true
			});
			return;
		}

		setIsError({ errorMsg: "", sizeError: false });

		const controller = new AbortController();
		setAbortController(controller); // Save controller in state

		dispatch(bulkProductFileImport({ file, signal: controller.signal }))
			.unwrap()
			.then(() => {
				setAbortController(null);
			})
			.catch(err => {
				setIsError({
					errorMsg: err?.message,
					sizeError: false
				});
			});
	};

	return (
		<div className='content-container'>
			<div className='content-box'>
				<p className='title'>Getting Started</p>
				<BulkImportInstruction />
			</div>
			<div>
				<div className='content-box file-upload-content-box'>
					<p className='title'>Upload File</p>
					<div className='upload-container'>
						<div className='upload-circle-wrapper'>
							{!file && (
								<>
									<div
										className='upload-circle'
										onDragOver={handleDragOver}
										onDragEnter={handleDragEnter}
										onDragLeave={handleDragLeave}
										onDrop={handleDrop}
									>
										<span className='upload-icon'>
											<UploadOutlinedIcon />
										</span>

										<p className='text'>
											Supported format .CSV .XLXS (max 10MB)
										</p>

										<label
											htmlFor='profile-picture'
											className='rounded-14 p-10'
										>
											Select File
										</label>
										<input
											id='profile-picture'
											type='file'
											accept='.csv, .xlsx'
											name='myImage'
											hidden
											onChange={e => {
												const selectedFile = e.target.files?.[0];

												if (selectedFile) {
													setFile(selectedFile);
													validateFile(selectedFile);
												}
											}}
										/>
									</div>
									<div className='file-info-container'>
										<p className='text'>
											For a smoother experience, please upload up to
											500 records at a time. Need help? <br />
											<Link to={"/gethelp"}>
												Contact us for support!
											</Link>
										</p>
									</div>
								</>
							)}

							{isError?.errorMsg && file && (
								<div className='upload-circle file-upload-error'>
									<XlsxIcon />
									<div>
										<p className='text'>{file?.name}</p>
										<p
											className={`text ${
												isError?.sizeError ? "size-error" : ""
											}`}
										>
											{ConvertSizeToMb(file?.size)} MB
										</p>
									</div>

									<p className='text error-text'>{isError.errorMsg}</p>
									<p
										className='text under-line-text'
										onClick={resetFile}
									>
										Try Again
									</p>
								</div>
							)}

							{isLoading && file && (
								<div className='upload-circle file-loading'>
									<Spin
										indicator={
											<LoadingOutlined
												spin
												style={{ fontSize: 40, color: "#FFBC0D" }}
											/>
										}
									/>
									<p className='text'>Uploading...</p>
									<p
										className='text under-line-text'
										onClick={resetFile}
									>
										Cancel
									</p>
								</div>
							)}

							{isSuccess && file && (
								<div className='upload-circle file-success'>
									<XlsxIcon />
									<div className='text-wrapper'>
										<p className='text'>{file?.name}</p>
										<p className={"text"}>
											{ConvertSizeToMb(file?.size ?? 0)} MB
										</p>
									</div>
									<button
										className='btn rounded-14 large40 primary flex alignCenter gap-2 download-sample-file'
										onClick={() => {
											if (isErrorCount > 0) {
												setStep(BulkImportSteps.ReviewData);
											} else {
												//Implement api
												uploadBulkImportData(data);
											}
										}}
									>
										Continue
									</button>
									<p
										className='text under-line-text'
										onClick={resetFile}
									>
										Remove
									</p>
									{isErrorCount > 0 && (
										<p className='text error-text'>
											<InfoCircleOutlined /> {isErrorCount} error
											found
										</p>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BulkImportFileUpload;
