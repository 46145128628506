import { createSlice } from "@reduxjs/toolkit";
import { SliceNames } from "../../shared/constants/constant";

export type TPaginationState = {
	ordersDataPagination: {
		currentPage: number;
		pageSize: number;
	};
	productListPagination: {
		currentPage: number;
		pageSize: number;
	};
	connectionOrdersPagination: {
		currentPage: number;
		pageSize: number;
	};
	specialPricePagination: {
		currentPage: number;
		pageSize: number;
	};
	stockProductListPagination: {
		currentPage: number;
		pageSize: number;
	};
};

const initialState: TPaginationState = {
	ordersDataPagination: {
		currentPage: 1,
		pageSize: 10
	},
	productListPagination: {
		currentPage: 1,
		pageSize: 10
	},
	connectionOrdersPagination: {
		currentPage: 1,
		pageSize: 10
	},
	specialPricePagination: {
		currentPage: 1,
		pageSize: 10
	},
	stockProductListPagination: {
		currentPage: 1,
		pageSize: 10
	}
};

const paginationSlice = createSlice({
	name: SliceNames.PAGINATION,
	initialState,
	reducers: {
		setOrderDataPagination: (state, { payload }) => {
			state.ordersDataPagination = payload;
		},
		setProductListPagination: (state, { payload }) => {
			state.productListPagination = payload;
		},
		setConnectionOrdersPagination: (state, { payload }) => {
			state.connectionOrdersPagination = payload;
		},
		setSpecialPricePagination: (state, { payload }) => {
			state.specialPricePagination = payload;
		},
		setStockProductPagination: (state, { payload }) => {
			state.stockProductListPagination = payload;
		}
	}
});

export const {
	setOrderDataPagination,
	setProductListPagination,
	setConnectionOrdersPagination,
	setSpecialPricePagination,
	setStockProductPagination
} = paginationSlice.actions;
export default paginationSlice.reducer;
