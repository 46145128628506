const WpIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M6.10352e-05 40L2.82718 29.7338C1.07933 26.7205 0.16243 23.308 0.171981 19.8194C0.171981 8.88783 9.1118 0 20.086 0C25.4155 0 30.4203 2.06274 34.1739 5.80799C37.937 9.55323 40.0096 14.5342 40 19.8289C40 30.7605 31.0602 39.6483 20.0765 39.6483H20.0669C16.7336 39.6483 13.4575 38.8118 10.5445 37.2338L6.10352e-05 40ZM11.0507 33.6502L11.6524 34.0114C14.193 35.5133 17.1061 36.3023 20.0765 36.3118H20.086C29.2073 36.3118 36.638 28.9259 36.638 19.8384C36.638 15.4373 34.9188 11.3023 31.7956 8.18441C28.6724 5.06654 24.5082 3.35551 20.086 3.35551C10.9647 3.34601 3.53396 10.7319 3.53396 19.8194C3.53396 22.9278 4.40311 25.9601 6.065 28.5837L6.4566 29.211L4.78516 35.2852L11.0507 33.6502Z"
        fill="white"
      />
      <path
        d="M0.69722 39.306L3.42883 29.3916C1.73829 26.4923 0.850037 23.1939 0.850037 19.8288C0.859588 9.2775 9.48422 0.693848 20.0859 0.693848C25.234 0.693848 30.0573 2.69005 33.6867 6.30221C37.3161 9.91438 39.3123 14.7243 39.3123 19.8383C39.3123 30.3897 30.6781 38.9733 20.0859 38.9733H20.0764C16.8577 38.9733 13.6963 38.1653 10.8882 36.6444L0.69722 39.306Z"
        fill="url(#paint0_linear_2344_1080)"
      />
      <path
        d="M6.10352e-05 40L2.82718 29.7338C1.07933 26.7205 0.16243 23.308 0.171981 19.8194C0.171981 8.88783 9.1118 0 20.086 0C25.4155 0 30.4203 2.06274 34.1739 5.80799C37.937 9.55323 40.0096 14.5342 40 19.8289C40 30.7605 31.0602 39.6483 20.0765 39.6483H20.0669C16.7336 39.6483 13.4575 38.8118 10.5445 37.2338L6.10352e-05 40ZM11.0507 33.6502L11.6524 34.0114C14.193 35.5133 17.1061 36.3023 20.0765 36.3118H20.086C29.2073 36.3118 36.638 28.9259 36.638 19.8384C36.638 15.4373 34.9188 11.3023 31.7956 8.18441C28.6724 5.06654 24.5082 3.35551 20.086 3.35551C10.9647 3.34601 3.53396 10.7319 3.53396 19.8194C3.53396 22.9278 4.40311 25.9601 6.065 28.5837L6.4566 29.211L4.78516 35.2852L11.0507 33.6502Z"
        fill="url(#paint1_linear_2344_1080)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1098 11.5305C14.7373 10.7036 14.3457 10.6845 13.9923 10.675C13.7057 10.6655 13.3715 10.6655 13.0372 10.6655C12.7029 10.6655 12.168 10.7891 11.7096 11.2834C11.2511 11.7777 9.97127 12.9754 9.97127 15.4184C9.97127 17.8518 11.7573 20.2093 12.0057 20.542C12.254 20.8747 15.4536 26.0363 20.5061 28.0229C24.7086 29.6769 25.5682 29.3442 26.4756 29.2587C27.3829 29.1731 29.4173 28.061 29.8375 26.9013C30.2482 25.7416 30.2482 24.753 30.1241 24.5439C29.9999 24.3347 29.6656 24.2112 29.169 23.964C28.6723 23.7169 26.2272 22.5191 25.7688 22.348C25.3103 22.1864 24.976 22.1009 24.6513 22.5952C24.317 23.0895 23.3619 24.2017 23.0754 24.5344C22.7888 24.8671 22.4928 24.9051 21.9961 24.6579C21.4994 24.4108 19.8949 23.888 17.9942 22.1959C16.5138 20.8842 15.5109 19.2587 15.2244 18.7644C14.9378 18.2701 15.1957 18.0039 15.444 17.7568C15.6637 17.5382 15.9407 17.1769 16.189 16.8918C16.4374 16.6066 16.5233 16.3975 16.6857 16.0648C16.8481 15.7321 16.7716 15.4469 16.6475 15.1997C16.5233 14.9621 15.5491 12.5096 15.1098 11.5305Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2344_1080"
          x1="20.0037"
          y1="39.3041"
          x2="20.0037"
          y2="0.692497"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#20B038" />
          <stop offset="1" stopColor="#60D66A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2344_1080"
          x1="20.004"
          y1="39.9962"
          x2="20.004"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9F9F9" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export { WpIcon };
