import { ReactNode } from "react";
import CacheBuster from "react-cache-buster";
import packageFile from "../../../package.json";
import Loader from "./Loader";

const CacheBusterProvider = ({ children }: { children: ReactNode }) => {
	const { version } = packageFile;

	return (
		<CacheBuster
			currentVersion={version}
			isEnabled
			isVerboseMode={false}
			loadingComponent={<Loader />}
			metaFileDirectory={`.`}
		>
			{children}
		</CacheBuster>
	);
};

export default CacheBusterProvider;
