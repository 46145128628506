import { DatePicker, DatePickerProps, Spin, Tooltip } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { InputField } from "../../components";
import ConfirmationPopup from "../../components/actionModals/confirmationPopup";
import Page from "../../components/page";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import useMessageHook from "../../hooks/useMessageHook";
import {
	getOrederSeriesData,
	setPrefixSuffix
} from "../../redux/services/accountSetting";
import limit from "../../utils/limits.json";
import {
	CONFIRMATION_POPUP_STRINGS,
	ERROR_STRINGS
} from "../../shared/constants/content-constant";
import {
	COMMON_STRINGS,
	OPERATER_STRINGS,
	REGEX_STRINGS
} from "../../shared/constants/constant";

const OrdersPage = () => {
	const [date, setDate] = useState({
		start: "",
		end: ""
	});
	const [value, setValue] = useState<any>({});
	const [isLoading, setIsLoading] = useState(false);
	const [isToggle, setIsToggle] = useState(false);
	const dispatch = useAppDispatch();
	const { showMessage, contextHolder } = useMessageHook();

	useEffect(() => {
		setIsLoading(true);
		dispatch(getOrederSeriesData())
			.then(result => {
				if (result?.payload?.status === 200) {
					setIsLoading(false);
					setValue({
						prefix: result?.payload?.data?.orderNumberPrefix,
						suffix: result?.payload?.data?.orderNumberSuffix
					});
					setDate({
						start: result?.payload?.data?.financialYearStart,
						end: result?.payload?.data?.financialYearEnd
					});
				}
			})
			.catch(() => {
				setIsLoading(false);
				showMessage("error", ERROR_STRINGS.SOMETHING_WRONG);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const changeStartDate: DatePickerProps<Dayjs[]>["onChange"] = (date, dateString) => {
		if (typeof dateString === "string") {
			setDate({
				start: dateString?.toString(),
				end: dayjs(dateString?.toString())
					.add(1, "year")
					?.subtract(1, "day")
					?.toString()
			});
		}
	};
	const changeEndDate: DatePickerProps<Dayjs[]>["onChange"] = (date, dateString) => {
		if (typeof dateString === "string") {
			setDate({
				start: dayjs(dateString?.toString())
					.subtract(1, "year")
					?.add(1, "day")
					?.toString(),
				end: dateString?.toString()
			});
		}
	};

	const handleSubmit = () => {
		setIsLoading(true);
		dispatch(
			setPrefixSuffix({
				orderNumberPrefix: value?.prefix || "",
				orderNumberSuffix: value?.suffix || "",
				financialYearStart: date?.start || "",
				financialYearEnd: date?.end || ""
			})
		)
			.then(result => {
				if (result?.payload?.status === 200) {
					setIsLoading(false);
					setIsToggle(false);
					showMessage("success", result?.payload?.message);
				} else {
					setIsLoading(false);
					setIsToggle(false);
					showMessage("error", result?.payload?.message);
				}
			})
			.catch(error => {
				setIsLoading(false);
				setIsToggle(false);
				showMessage("error", ERROR_STRINGS.SOMETHING_WRONG);
			});
	};

	const handleChange = (e: any) => {
		const { value, name } = e.target;
		setValue((prev: any) => ({
			...prev,
			[name]: !REGEX_STRINGS.SPECIAL_CHAR.test(value) ? value : ""
		}));
	};

	return (
		<Page title={[]} className='orders-setting-page'>
			{contextHolder}
			{isLoading ? (
				<div className='page-loader'>
					<Spin />
				</div>
			) : (
				<></>
			)}
			<div className='orders-setting-wrapper'>
				<div className='orders-setting-header'>
					<h3 className='title-3'>Order Management</h3>
					<p className='caption'>
						Manage and configure your order processing settings here.
					</p>
				</div>
				<div className='orders-setting-body'>
					<h4 className='title-4'>Order voucher series</h4>
					<div className='flex date-pickers-container gap-4'>
						<div className='flex direction-column date-picker'>
							<p className='label'>Financial Year Start</p>
							<DatePicker
								onChange={changeStartDate}
								name='start'
								format={"DD-MMM-YYYY"}
								value={[dayjs(date?.start)]}
								allowClear={false}
							/>
						</div>
						<div className='flex direction-column date-picker'>
							<p className='label'>Financial Year End</p>
							<DatePicker
								onChange={changeEndDate}
								name='end'
								format={"DD-MMM-YYYY"}
								value={[dayjs(date?.end)]}
								allowClear={false}
							/>
						</div>
					</div>

					<h4 className='title-4'>Set order prefix/suffix</h4>
					<div className='order-prefix-section'>
						<div className='order-prefix-section-view-box flex direction-column alignCenter justifyCenter rounded-24'>
							<p className='prefix-text'>
								Your order number will appear as
							</p>
							<div className='order-number-container flex'>
								<Tooltip title={value?.prefix && COMMON_STRINGS.PREFIX}>
									<p className='prefix'>
										{value?.prefix || (
											<span className='gray'>
												{COMMON_STRINGS.PREFIX}
											</span>
										)}
									</p>
								</Tooltip>
								<p className='seprator'>{OPERATER_STRINGS.DIVISION}</p>
								<p className='order-number'>01</p>
								<p className='seprator'>{OPERATER_STRINGS.DIVISION}</p>
								<Tooltip title={value?.suffix && COMMON_STRINGS.SUFFIX}>
									<p className='suffix-year'>
										{value?.suffix || (
											<span className='gray'>
												{COMMON_STRINGS.SUFFIX}
											</span>
										)}
									</p>
								</Tooltip>
							</div>
							<div className='help-text flex gap-1'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='17'
									height='16'
									viewBox='0 0 17 16'
									fill='none'
								>
									<path
										d='M8.49992 5.33203V5.9987M8.49992 7.66536V10.6654M8.49992 14.6654C12.1818 14.6654 15.1666 11.6806 15.1666 7.9987C15.1666 4.3168 12.1818 1.33203 8.49992 1.33203C4.81802 1.33203 1.83325 4.3168 1.83325 7.9987C1.83325 11.6806 4.81802 14.6654 8.49992 14.6654Z'
										stroke='#00000030'
										strokeWidth='0.888889'
										strokeLinecap='round'
										strokeLinejoin='round'
									/>
								</svg>
								<p className='caption'>Prefix / Order No. / Suffix</p>
							</div>
						</div>
					</div>

					<div className='inputs-container flex gap-5'>
						<InputField
							name='prefix'
							placeholder=''
							label='Prefix'
							value={value?.prefix}
							onChange={handleChange}
							maxLength={limit.orderSeriesPrefix}
						/>
						<InputField
							name='suffix'
							label='Suffix'
							placeholder=''
							onChange={handleChange}
							value={value?.suffix}
							maxLength={limit.orderSeriesSuffix}
						/>
					</div>
					<button
						className='submit-button primary rounded-14'
						disabled={isLoading}
						onClick={() => setIsToggle(true)}
					>
						Submit
					</button>
				</div>
			</div>
			<ConfirmationPopup
				infoContent={
					CONFIRMATION_POPUP_STRINGS.ORDER_MANAGEMENT_PAGE.SAVE_CHANGES.TEXT
				}
				confirmButtonText={
					CONFIRMATION_POPUP_STRINGS.ORDER_MANAGEMENT_PAGE.SAVE_CHANGES
						.CONFIRM_BUTTON
				}
				cancelButtonText={
					CONFIRMATION_POPUP_STRINGS.ORDER_MANAGEMENT_PAGE.SAVE_CHANGES
						.CANCEL_BUTTON
				}
				close={() => setIsToggle(false)}
				confirm={handleSubmit}
				open={isToggle}
				headerContent={
					CONFIRMATION_POPUP_STRINGS.ORDER_MANAGEMENT_PAGE.SAVE_CHANGES.TITLE
				}
				disabled={isLoading}
			/>
		</Page>
	);
};

export default OrdersPage;
