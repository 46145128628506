import { Checkbox, Select, Space } from "antd";
import { useState } from "react";
import { ChevronDown } from "../../assets/svg/icons";

export const SelectInput = ({
	className,
	options,
	defaultOption,
	style,
	label,
	required,
	status,
	placeholder,
	onChange,
	value,
	showSearch = true,
	...props
}: any) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	return (
		<div className='select-input-wrapper'>
			<label htmlFor=''>
				<span className='label-text'>
					{label}
					{required && <span className='require-icon'> *</span>}
				</span>
				<Select
					mode='multiple'
					showSearch={showSearch}
					className={className}
					defaultOpen={defaultOption}
					labelInValue={false}
					options={options}
					optionLabelProp='label'
					maxTagCount='responsive'
					value={value}
					style={style}
					status={status}
					placeholder={placeholder}
					onChange={onChange}
					filterOption={false}
					searchValue=''
					{...props}
					suffixIcon={
						isDropdownOpen ? (
							<span className='up-arrow'>
								<ChevronDown />
							</span>
						) : (
							<span>
								<ChevronDown />
							</span>
						)
					}
					labelRender={option => (
						<span className='custom-label-render'>{option.label}</span>
					)}
					optionRender={option => (
						<Space
							style={{ width: "100%", justifyContent: "space-between" }}
							key={option.value}
							onKeyDown={e => {
								if (e.key === " ") {
									// Prevent default spacebar behavior (scrolling)
									e.preventDefault();
									// Toggle selection of the option
									// handleOptionSelect(option.value);
								}
							}}
							tabIndex={0}
						>
							<Space>
								<Checkbox checked={value?.includes(option.value)} />
								{option?.label}
							</Space>
						</Space>
					)}
					onDropdownVisibleChange={e => setIsDropdownOpen(e)}
				></Select>
			</label>
		</div>
	);
};
