import { Spin } from "antd";
import axios from "axios";
import { useState } from "react";
import { DownloadRecieptIcon } from "../../assets/svg/icons";
import ImageLoad from "../ImageLoad";
import { BulkImportsImages, sampleCsvLink } from "../../constant/bulk-import";
import { generateSignedUrl } from "../../utils/helper";

const BulkImportInstruction = () => {
	const [downloadLoading, setDownloadLoading] = useState(false);

	const handleDownloadSampleCsv = async () => {
		setDownloadLoading(true);
		const signedUrl = await generateSignedUrl(sampleCsvLink);
		if (signedUrl) {
			axios
				.get(signedUrl, {
					responseType: "blob"
				})
				.then(response => {
					const blob = new Blob([response.data]);
					const objectUrl = URL.createObjectURL(blob); // Use a different variable name
					const link = document.createElement("a");
					link.href = objectUrl;
					link.download = "Biizline_Bulk_Product_Upload_Template.xlsx"; // Set the default file name
					document.body.appendChild(link);
					link.click();
					setDownloadLoading(false);
				})
				.catch(() => {
					setDownloadLoading(false);
				});
		}
		setDownloadLoading(false);
	};
	return (
		<div className='image-info'>
			<div className='stepper-box flex gap-4'>
				<p className='stepper-count'>1</p>
				<div className='stepper-title'>
					<p className='title'>Download our sample file to get started</p>
					<button
						disabled={downloadLoading}
						className='btn rounded-14 large40 primary flex alignCenter gap-2 download-sample-file'
						onClick={handleDownloadSampleCsv}
					>
						{downloadLoading && <Spin />}
						<DownloadRecieptIcon />
						Download Sample File
					</button>
				</div>
			</div>
			<div className='stepper-box flex gap-4'>
				<p className='stepper-count'>2</p>
				<div className='stepper-title'>
					<p className='title'>
						Open the file and fill data in the given format
					</p>

					<ImageLoad
						alt='sample-csv'
						src={BulkImportsImages?.SampleBulkImportCsvImage}
						className='sample-csv-img'
					/>
				</div>
			</div>

			<div className='stepper-box flex gap-4'>
				<p className='stepper-count'>3</p>
				<div className='stepper-title'>
					<p className='title'>Save the complete file on your device</p>
				</div>
			</div>

			<div className='stepper-box flex gap-4'>
				<p className='stepper-count'>4</p>
				<div className='stepper-title'>
					<p className='title'>Upload the file to review file data</p>

					<ImageLoad
						alt='sample-csv'
						src={BulkImportsImages?.SampleBulkImportSuggestionImage}
						className='sample-csv-img'
					/>
				</div>
			</div>
		</div>
	);
};

export default BulkImportInstruction;
