import { Outlet } from "react-router-dom";

import Footer from "./Footer";
import Header from "./Header";

const LayoutPage = () => {
	return (
		<div className='landing-page-wrapper'>
			<div className='landing-page-content'>
				<Header />
				<Outlet />
			</div>
			<Footer />
		</div>
	);
};

export default LayoutPage;
