import { createSlice } from "@reduxjs/toolkit";
import { SliceNames } from "../../shared/constants/constant";
import { TSubscriptionState } from "../../types/subscription";
import {
	getBillingDetailsData,
	getPaymentDetailsSummary,
	getPlateformSubscriptionPayloadData,
	getProductsSubscriptionPayloadData,
	getSubscriptionPayloadData,
	getSubscriptionPlanDetails
} from "../services/subscription";

const initialState: TSubscriptionState = {
	planDetails: {
		isLoading: false,
		isSuccess: false,
		data: null,
		message: ""
	},
	planDetailsSummary: {
		isLoading: false,
		isSuccess: false,
		data: null,
		message: ""
	},
	subscriptionData: {
		isLoading: false,
		isSuccess: false,
		data: null,
		message: ""
	},
	platformSubscriptionData: {
		isLoading: false,
		isSuccess: false,
		data: null,
		message: ""
	},
	productsSubscriptionData: {
		isLoading: false,
		isSuccess: false,
		data: null,
		message: ""
	},
	billingDetails: {
		isLoading: false,
		isSuccess: false,
		data: null,
		message: ""
	}
};

const subscriptionSlice = createSlice({
	name: SliceNames.SUBSCRIPTION,
	initialState,
	reducers: {
		clearPaymentSummary: state => {
			state.planDetailsSummary = initialState.planDetailsSummary;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getSubscriptionPlanDetails.pending, state => {
				state.planDetails.isLoading = true;
				state.planDetails.isSuccess = false;
			})
			.addCase(getSubscriptionPlanDetails.fulfilled, (state, { payload }: any) => {
				state.planDetails.isSuccess = true;
				state.planDetails.isLoading = false;
				state.planDetails.data = payload?.data;
				state.planDetails.message = payload?.message;
			})
			.addCase(getSubscriptionPlanDetails.rejected, (state, { payload }: any) => {
				state.planDetails.isSuccess = false;
				state.planDetails.isLoading = false;
				state.planDetails.message = payload?.message;
			})

			//
			.addCase(getPaymentDetailsSummary.pending, state => {
				state.planDetailsSummary.isLoading = true;
				state.planDetailsSummary.isSuccess = false;
			})
			.addCase(getPaymentDetailsSummary.fulfilled, (state, { payload }: any) => {
				state.planDetailsSummary.isSuccess = true;
				state.planDetailsSummary.isLoading = false;
				state.planDetailsSummary.data = payload?.data;
				state.planDetailsSummary.message = payload?.message;
			})
			.addCase(getPaymentDetailsSummary.rejected, (state, { payload }: any) => {
				state.planDetailsSummary.isSuccess = false;
				state.planDetailsSummary.isLoading = false;
				state.planDetailsSummary.message = payload?.message;
			})

			//
			.addCase(getSubscriptionPayloadData.pending, state => {
				state.subscriptionData.isLoading = true;
				state.subscriptionData.isSuccess = false;
			})
			.addCase(getSubscriptionPayloadData.fulfilled, (state, { payload }: any) => {
				state.subscriptionData.isSuccess = true;
				state.subscriptionData.isLoading = false;
				state.subscriptionData.data = payload?.data;
				state.subscriptionData.message = payload?.message;
			})
			.addCase(getSubscriptionPayloadData.rejected, (state, { payload }: any) => {
				state.subscriptionData.isSuccess = false;
				state.subscriptionData.isLoading = false;
				state.subscriptionData.message = payload?.message;
			})

			//
			.addCase(getProductsSubscriptionPayloadData.pending, state => {
				state.productsSubscriptionData.isLoading = true;
				state.productsSubscriptionData.isSuccess = false;
			})
			.addCase(
				getProductsSubscriptionPayloadData.fulfilled,
				(state, { payload }: any) => {
					state.productsSubscriptionData.isSuccess = true;
					state.productsSubscriptionData.isLoading = false;
					state.productsSubscriptionData.data = payload?.data;
					state.productsSubscriptionData.message = payload?.message;
				}
			)
			.addCase(
				getProductsSubscriptionPayloadData.rejected,
				(state, { payload }: any) => {
					state.productsSubscriptionData.isSuccess = false;
					state.productsSubscriptionData.isLoading = false;
					state.productsSubscriptionData.message = payload?.message;
				}
			)

			//
			.addCase(getPlateformSubscriptionPayloadData.pending, state => {
				state.platformSubscriptionData.isLoading = true;
				state.platformSubscriptionData.isSuccess = false;
			})
			.addCase(
				getPlateformSubscriptionPayloadData.fulfilled,
				(state, { payload }: any) => {
					state.platformSubscriptionData.isSuccess = true;
					state.platformSubscriptionData.isLoading = false;
					state.platformSubscriptionData.data = payload?.data;
					state.platformSubscriptionData.message = payload?.message;
				}
			)
			.addCase(
				getPlateformSubscriptionPayloadData.rejected,
				(state, { payload }: any) => {
					state.platformSubscriptionData.isSuccess = false;
					state.platformSubscriptionData.isLoading = false;
					state.platformSubscriptionData.message = payload?.message;
				}
			)

			//
			.addCase(getBillingDetailsData.pending, state => {
				state.billingDetails.isLoading = true;
				state.billingDetails.isSuccess = false;
			})
			.addCase(getBillingDetailsData.fulfilled, (state, { payload }: any) => {
				state.billingDetails.isSuccess = true;
				state.billingDetails.isLoading = false;
				state.billingDetails.data = payload?.data;
				state.billingDetails.message = payload?.message;
			})
			.addCase(getBillingDetailsData.rejected, (state, { payload }: any) => {
				state.billingDetails.isSuccess = false;
				state.billingDetails.isLoading = false;
				state.billingDetails.message = payload?.message;
			});
	}
});

export const { clearPaymentSummary } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
