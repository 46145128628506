import { createSlice } from "@reduxjs/toolkit";
import { SliceNames } from "../../shared/constants/constant";
import { getExpandedTableData } from "../services/table";

export type TTableState = {
	expandedTable: {
		isLoading: boolean;
		isSuccess: boolean;
		data: any[];
		message: string;
	};
};

const initialState: TTableState = {
	expandedTable: {
		isLoading: false,
		isSuccess: false,
		data: [],
		message: ""
	}
};

const tableSlice = createSlice({
	name: SliceNames.TABLE,
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getExpandedTableData.pending, state => {
				state.expandedTable.data = initialState.expandedTable.data;
				state.expandedTable.isLoading = true;
				state.expandedTable.isSuccess = false;
			})
			.addCase(getExpandedTableData.fulfilled, (state, { payload }: any) => {
				state.expandedTable.isSuccess = true;
				state.expandedTable.isLoading = false;
				state.expandedTable.data = payload?.data;
				state.expandedTable.message = payload?.message;
			})
			.addCase(getExpandedTableData.rejected, (state, { payload }: any) => {
				state.expandedTable.data = payload;
				state.expandedTable.isSuccess = false;
				state.expandedTable.isLoading = false;
				state.expandedTable.message = payload?.message;
			});
	}
});

export default tableSlice.reducer;
