const FileDownloadSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M16.7143 1.92857C16.7143 1.41708 16.5111 0.926543 16.1495 0.564866C15.7878 0.203188 15.2973 0 14.7858 0H7.60505C7.09374 0.000450134 6.60353 0.203934 6.2422 0.565714L1.85148 4.95643C1.4897 5.31776 1.28622 5.80797 1.28577 6.31929V16.0714C1.28577 16.5829 1.48895 17.0735 1.85063 17.4351C2.21231 17.7968 2.70285 18 3.21434 18H14.7858C15.2973 18 15.7878 17.7968 16.1495 17.4351C16.5111 17.0735 16.7143 16.5829 16.7143 16.0714V1.92857ZM6.42862 2.19214V4.5C6.42862 4.6705 6.36089 4.83401 6.24034 4.95457C6.11978 5.07513 5.95626 5.14286 5.78577 5.14286H3.47791L6.42862 2.19214ZM15.4286 16.0714C15.4286 16.2419 15.3609 16.4054 15.2403 16.526C15.1198 16.6466 14.9563 16.7143 14.7858 16.7143H3.21434C3.04384 16.7143 2.88033 16.6466 2.75977 16.526C2.63921 16.4054 2.57148 16.2419 2.57148 16.0714V6.42857H5.78577C6.29726 6.42857 6.7878 6.22538 7.14947 5.86371C7.51115 5.50203 7.71434 5.01149 7.71434 4.5V1.28571H14.7858C14.9563 1.28571 15.1198 1.35344 15.2403 1.474C15.3609 1.59456 15.4286 1.75808 15.4286 1.92857V16.0714Z"
        fill="black"
      />
      <path
        d="M11.2756 10.317L9.64279 11.9499V8.04129C9.64279 7.8708 9.57506 7.70729 9.4545 7.58673C9.33394 7.46617 9.17043 7.39844 8.99993 7.39844C8.82943 7.39844 8.66592 7.46617 8.54536 7.58673C8.4248 7.70729 8.35707 7.8708 8.35707 8.04129V11.9499L6.72422 10.317C6.60124 10.2117 6.44305 10.1567 6.28125 10.1629C6.11946 10.1692 5.96599 10.2362 5.8515 10.3507C5.73701 10.4652 5.66994 10.6187 5.66369 10.7805C5.65744 10.9423 5.71247 11.1005 5.81779 11.2234L8.5435 13.9556C8.60326 14.0158 8.67436 14.0637 8.7527 14.0963C8.83104 14.1289 8.91507 14.1457 8.99993 14.1457C9.0848 14.1457 9.16882 14.1289 9.24716 14.0963C9.3255 14.0637 9.3966 14.0158 9.45636 13.9556L12.2142 11.2234C12.3195 11.1005 12.3746 10.9423 12.3683 10.7805C12.3621 10.6187 12.295 10.4652 12.1805 10.3507C12.066 10.2362 11.9125 10.1692 11.7508 10.1629C11.589 10.1567 11.4308 10.2117 11.3078 10.317H11.2756Z"
        fill="black"
      />
    </svg>
  );
};

export { FileDownloadSvg };
