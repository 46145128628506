import { ChangeEvent } from "react";
import { SearchIcon } from "../../assets/svg";
import { handleKeyDown } from "../../utils/helper";

interface SearchBarProps {
	value: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	placeholder?: string;
	maxLength?: number;
	onFocus?: () => void;
	onBlur?: () => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
	value,
	onChange,
	placeholder,
	maxLength,
	onBlur,
	onFocus
}) => {
	return (
		<div className='search-bar' onFocus={onFocus} onBlur={onBlur}>
			<span className='search-icon'>
				<SearchIcon />
			</span>
			<input
				onKeyDown={handleKeyDown}
				type='text'
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				maxLength={maxLength}
			/>
		</div>
	);
};

export default SearchBar;
