import { createSlice } from "@reduxjs/toolkit";
import { TVendorInitialState } from "../../types/vendorType";
import { addToCart } from "../services/placeOrder";
import { getProductListByVendorId, getVendorList } from "../services/vendor";
import { SliceNames } from "../../shared/constants/constant";

const initialState: TVendorInitialState = {
	vendor: {
		isLoading: false,
		isSuccess: false,
		data: [],
		message: ""
	},
	vendorsProductList: {
		isLoading: false,
		isSuccess: false,
		data: null,
		message: ""
	},
	addToCartData: {
		isLoading: false,
		isSuccess: false,
		data: {
			cartId: 0,
			cartItems: []
		},
		message: ""
	}
};

const vendorSlice = createSlice({
	name: SliceNames.VENDOR,
	initialState,
	reducers: {
		updateVendorsProductList: (state, { payload }) => {
			state.vendorsProductList.data = payload;
		},
		updateVendorList: (state, { payload }) => {
			state.vendor.data = payload;
		},
		updateCartData: (state, { payload }) => {
			state.addToCartData.data = payload?.data;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getVendorList.pending, state => {
				state.vendor.isLoading = true;
				state.vendor.isSuccess = false;
			})
			.addCase(getVendorList.fulfilled, (state, { payload }: any) => {
				state.vendor.isSuccess = true;
				state.vendor.isLoading = false;
				state.vendor.data = payload?.vendors;
				state.vendor.message = payload?.message;
			})
			.addCase(getVendorList.rejected, (state, { payload }: any) => {
				state.vendor.data = payload;
				state.vendor.isSuccess = false;
				state.vendor.isLoading = false;
				state.vendor.message = payload?.message;
			})

			//
			.addCase(getProductListByVendorId.pending, state => {
				state.vendorsProductList.data = initialState.vendorsProductList.data;
				state.vendorsProductList.isLoading = true;
				state.vendorsProductList.isSuccess = false;
			})
			.addCase(getProductListByVendorId.fulfilled, (state, { payload }: any) => {
				state.vendorsProductList.isSuccess = true;
				state.vendorsProductList.isLoading = false;
				state.vendorsProductList.data = payload?.data;
			})
			.addCase(getProductListByVendorId.rejected, (state, { payload }: any) => {
				state.vendorsProductList.data = payload;
				state.vendorsProductList.isLoading = false;
				state.vendorsProductList.isSuccess = false;
			})

			//
			.addCase(addToCart.pending, state => {
				state.addToCartData.isLoading = true;
				state.addToCartData.isSuccess = false;
			})
			.addCase(addToCart.fulfilled, (state, { payload }) => {
				state.addToCartData.isSuccess = true;
				state.addToCartData.isLoading = false;
				state.addToCartData.data = payload?.data;
			})
			.addCase(addToCart.rejected, state => {
				state.addToCartData.isLoading = false;
				state.addToCartData.isSuccess = false;
			});
	}
});

export const { updateVendorsProductList, updateVendorList, updateCartData } =
	vendorSlice.actions;
export default vendorSlice.reducer;
