import { configureStore, Reducer } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage";
import { TRootState } from "../types/store";
import { REDUX_PERSISTENT_STORE_KEY, SliceNames } from "../shared/constants/constant";
import axiosInstance, { apiMiddleware } from "../middleware/axios";
import rootReducer from "./reducers";

const encryptor = encryptTransform({
	secretKey: process.env.REACT_APP_REDUX_PERSISTENT_STORE_ENCRYPT_KEY ?? ""
});

const persistedReducer = persistReducer(
	{
		transforms: [encryptor],
		key: REDUX_PERSISTENT_STORE_KEY,
		storage,
		version: 1,
		whitelist: [SliceNames.AUTH, SliceNames.COMMON, SliceNames.PRODUCT_DRAFT]
	},
	rootReducer as Reducer<TRootState>
);

const store = configureStore({
	reducer: persistedReducer,
	devTools: false,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
			thunk: { extraArgument: axiosInstance }
		}).concat(apiMiddleware)
});

export const useAppSelector: TypedUseSelectorHook<TRootState> = useSelector;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

export default store;
