const SocketRoutes = {
	NEW_CONNECTION_REQUEST_ADDED: (companyId: number) => `newRequestAdded_${companyId}`,
	CONNECTION_REQUEST_UPDATE: (companyId: number) => `requestUpdated_${companyId}`,
	DEVICE_LOGOUT: (userId: number) => `webDeviceLogedOut_${userId}`,
	USER_ACCESS_UPDATE: (userId: number) => `userAccessChanged_${userId}`,
	NEW_NOTIFICATION_COUNT_RESPONSE: "newNotificationCountResponse",
	NEW_NOTIFICATION_COUNT: "newNotificationCount",
	READ_NOTIFICATION_RESPONSE: "readNotificationResponse",
	ALL_NOTIFICATION_COUNT_RESPONSE: "allNotificationCountResponse",
	GET_NEW_NOTIFICATION_COUNT_BY_COMPANY_ID_RESPONSE: "getNewNotificationsCountsByCompanyIdsResponse",
	GET_NEW_NOTIFICATION_COUNT_BY_COMPANY_ID: "getNewNotificationsCountsByCompanyIds",
	MY_ORDER_STATUS_CHANGED: (companyId: number) => `myOrderstatusChanged_${companyId}`,
	ORDER_STATUS_CHANGED: (companyId: number) => `orderstatusChanged_${companyId}`,
	CONNECTION_BLOCK_UNBLOCK: (companyId: number) => `connectionBlockUnblock_${companyId}`,
	CONNECTION_REMOVE: (companyId: number) => `connectionRemove_${companyId}`,
	NOTIFICATION: ({ companyId, userId }: { companyId?: number; userId?: number }) => companyId ? `notification_${companyId}_${userId}` : "notification"
}

export {
	SocketRoutes
}


