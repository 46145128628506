import { message, notification } from "antd";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationTypes } from "../../enum/notification-enum";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { getConnectionList } from "../../redux/services/connection";
import { clearConnectionList } from "../../redux/slices/connectionSlice";
import {
	appendNotificationData,
	clearNotificationData,
	handleNotificationDrawer,
	handleRejectConnectionNotificationAction,
	notificationOrderData
} from "../../redux/slices/notificationSlice";
import { ERROR_STRINGS } from "../../shared/constants/content-constant";
import socket from "../../socket";
import { TNotificationData } from "../../types/notification";
import { PushNotificationCard } from "../notification/notificationCard";
import {
	getConnectionRecievedRequestAsyncThunk,
	takeActionOnConnectionRequestAsyncThunk
} from "../../redux/services/connection-async-thunk";
import { ConnectionActionsEnum } from "../../enum/connection-enum";
import { SocketRoutes } from "../../shared/constants/socket-constant";

const PushNotification = () => {
	const [api, contextHolder] = notification.useNotification();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { connectionActiveTab, connectionPayload } = useAppSelector(
		state => state.connections
	);

	const { userDetails } = useAppSelector(state => state.common);

	const companyId = userDetails.data?.company?.companyId;
	const userRoleMappingId = userDetails?.data?.user?.userRole?.userRoleMappingId;
	const userId = userDetails?.data?.user?.userId;

	const closePushNotification = () => {
		api.destroy();
	};

	const ListOfAllConnection = async () => {
		dispatch(clearConnectionList());
		const payload = {
			search: "",
			sort: "ASC",
			type: connectionActiveTab,
			limit: connectionPayload?.data,
			offset: 0
		};
		await dispatch(getConnectionList(payload));
	};

	const getRecievedRequest = async () => {
		const payload = {
			search: "",
			limit: 0,
			offset: 0
		};
		await dispatch(getConnectionRecievedRequestAsyncThunk(payload));
	};

	const handleAction = async (
		action: string,
		type: NotificationTypes,
		notification: TNotificationData
	) => {
		closePushNotification();
		if (type === NotificationTypes.ORDER_PLACED_BY_CUSTOMER) {
			dispatch(notificationOrderData(notification));
			dispatch(handleNotificationDrawer(false));
			dispatch(clearNotificationData());
			navigate("/orders");
		} else if (type === NotificationTypes.CONNECTION_REQUEST_RECEIVED) {
			const payload = {
				requestId: notification.actionParams.requestId,
				action: action
			};
			await dispatch(takeActionOnConnectionRequestAsyncThunk(payload))
				.then(result => {
					if (userRoleMappingId) {
						socket.emit(
							SocketRoutes.NEW_NOTIFICATION_COUNT,
							companyId,
							userRoleMappingId
						);
					}
					if (result.payload.status === 200) {
						getRecievedRequest();
						if (action === ConnectionActionsEnum.ACCEPT) {
							ListOfAllConnection();
							navigate(`/connection/${result.payload.data.connectionId}`);
						}
					} else {
						message.error(result?.payload.message || "Something went wrong");
					}
				})
				.catch(() => console.error(ERROR_STRINGS.SOMETHING_WRONG));
			dispatch(handleRejectConnectionNotificationAction(notification));
		}
	};

	const handleNotification = useCallback(
		(data: any) => {
			if (userRoleMappingId) {
				socket.emit(
					SocketRoutes.NEW_NOTIFICATION_COUNT,
					companyId,
					userRoleMappingId
				);
			}
			dispatch(appendNotificationData(data));
			if (data?.showPushNotification) {
				api.open({
					message: "",
					className: "push-notification",
					description: (
						<div className='push-notification-container'>
							<PushNotificationCard
								notification={data}
								handleAction={handleAction}
							/>
						</div>
					),
					placement: "topRight",
					duration: 5
				});
			}
		},
		[companyId, userRoleMappingId, userId]
	);

	useEffect(() => {
		const notificationEvent = companyId
			? SocketRoutes.NOTIFICATION({
					companyId: companyId ?? 0,
					userId: userId ?? 0
				})
			: SocketRoutes.NOTIFICATION({});
		socket.on(notificationEvent, handleNotification);

		return () => {
			socket.off(notificationEvent, handleNotification);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socket, companyId, userRoleMappingId, userId]);

	return <div>{contextHolder}</div>;
};

export default PushNotification;
