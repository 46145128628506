import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosInstance from "../../middleware/axios";
import {
	TCheckGSTCodeProps,
	TCheckPromoCodeProps,
	TCreatePaymentProps,
	TGetPaymentDetailsSummary,
	TVerifyRazorpayPaymentProps
} from "../../types/subscription";
import endPoint from "./endPoints.json";

export const getSubscriptionPlanDetails = createAsyncThunk(
	"subscription/getSubscriptionPlanDetails",
	async (_, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get(endPoint.getSubscriptionPlans);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const createSubscriptionPayment = createAsyncThunk(
	"subscription/createPayment",
	async (args: { id: number; payload: TCreatePaymentProps }, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(
				`${endPoint.createPayment}/${args.id}`,
				args.payload
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getPaymentDetailsSummary = createAsyncThunk(
	"subscription/getSubscriptionBillingDetailSummary",
	async (
		args: { id: number; payload: TGetPaymentDetailsSummary },
		{ rejectWithValue }
	) => {
		try {
			const response = await axiosInstance.post(
				`${endPoint.getSubscriptionBillingDetailSummary}/${args.id}`,
				args.payload
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const checkPromoCode = createAsyncThunk(
	"subscription/verifyPromoCode",
	async (payload: TCheckPromoCodeProps, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(endPoint.verifyPromoCode, payload);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const checkGSTCode = createAsyncThunk(
	"subscription/verifyGST",
	async (payload: TCheckGSTCodeProps, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(endPoint.verifyGST, payload);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const verifyRazorpayPayment = createAsyncThunk(
	"subscription/verifyPayment",
	async (payload: TVerifyRazorpayPaymentProps, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(endPoint.verifyPayment, payload);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getSubscriptionPayloadData = createAsyncThunk(
	"subscription/getSubscriptionPayload",
	async (payload: { module: string }, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(
				endPoint.getSubscriptionPayload,
				payload
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getProductsSubscriptionPayloadData = createAsyncThunk(
	"subscription/getProductsSubscriptionPayload",
	async (payload: { module: string }, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(
				endPoint.getSubscriptionPayload,
				payload
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getPlateformSubscriptionPayloadData = createAsyncThunk(
	"subscription/getPlateformSubscriptionPayloadData",
	async (payload: { module: string }, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(
				endPoint.getSubscriptionPayload,
				payload
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getBillingDetailsData = createAsyncThunk(
	"subscription/getBillingDetails",
	async (_, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get(endPoint.getBillingDetails);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);
