const PromoCodeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_12737_36721)">
        <path
          d="M14.5938 3.75H12.6862C12.9812 3.358 13.1562 2.87097 13.1562 2.34375C13.1562 1.05141 12.1048 0 10.8125 0C10.034 0 9.46 0.278906 9.00597 0.877719C8.62613 1.37872 8.34838 2.07822 8 2.96203C7.65163 2.07819 7.37388 1.37872 6.99403 0.877719C6.54 0.278906 5.96597 0 5.1875 0C3.89516 0 2.84375 1.05141 2.84375 2.34375C2.84375 2.87097 3.01884 3.358 3.31378 3.75H1.40625C0.630844 3.75 0 4.38084 0 5.15625V6.09375C0 6.70484 0.391906 7.22587 0.9375 7.41937V14.5938C0.9375 15.3692 1.56834 16 2.34375 16H13.6562C14.4317 16 15.0625 15.3692 15.0625 14.5938V7.41937C15.6081 7.22587 16 6.70484 16 6.09375V5.15625C16 4.38084 15.3692 3.75 14.5938 3.75ZM8.86044 3.33562C9.57853 1.51381 9.85153 0.9375 10.8125 0.9375C11.5879 0.9375 12.2188 1.56834 12.2188 2.34375C12.2188 3.11916 11.5879 3.75 10.8125 3.75H8.69647C8.75391 3.60578 8.80869 3.46694 8.86044 3.33562ZM5.1875 0.9375C6.14847 0.9375 6.42147 1.51381 7.13956 3.33562C7.19131 3.46694 7.24609 3.60578 7.30353 3.75H5.1875C4.41209 3.75 3.78125 3.11916 3.78125 2.34375C3.78125 1.56834 4.41209 0.9375 5.1875 0.9375ZM6.125 15.0625H2.34375C2.08528 15.0625 1.875 14.8522 1.875 14.5938V7.5H6.125V15.0625ZM6.125 6.5625H1.40625C1.14778 6.5625 0.9375 6.35222 0.9375 6.09375V5.15625C0.9375 4.89778 1.14778 4.6875 1.40625 4.6875H6.125V6.5625ZM8.9375 15.0625H7.0625V4.6875H8.9375V15.0625ZM14.125 14.5938C14.125 14.8522 13.9147 15.0625 13.6562 15.0625H9.875V7.5H14.125V14.5938ZM15.0625 6.09375C15.0625 6.35222 14.8522 6.5625 14.5938 6.5625H9.875V4.6875H14.5938C14.8522 4.6875 15.0625 4.89778 15.0625 5.15625V6.09375Z"
          fill="#F5222D"
        />
      </g>
      <defs>
        <clipPath id="clip0_12737_36721">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export { PromoCodeIcon };
