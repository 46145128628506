import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { BiizlineLogo } from "../../assets/images";
import { SecureIcon } from "../../assets/svg";

const AuthLayout = ({ children }: { children: ReactNode }) => {
	return (
		<div className='main-container container'>
			{/* Header */}
			<div className='header-container flex justifyBetween alignCenter'>
				<div className='logo-container flex'>
					<Link to={"/home"}>
						<img src={BiizlineLogo} alt='logo' loading='lazy' />
					</Link>
				</div>
			</div>

			<div className='auth-content'>{children}</div>

			{/* <Footer />   */}
			<div className='footer-container flex justifyBetween alignCenter'>
				<div className='safe-box flex alignCenter gap-1'>
					<SecureIcon />
					<div className='text-box flex direction-column justifyCenter'>
						<span className='secure-text'>100% Secure</span>
						<span>Your Data Safety, Our Priority</span>
					</div>
				</div>
				<p className='copyright-text'>
					&#169;2024 Biizline by Linics Enterprise Private Limited. All Rights
					Reserved
				</p>
			</div>
		</div>
	);
};

export default AuthLayout;
