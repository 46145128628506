import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { authObjectSelector } from "../../redux/memorize-selector";
import { MOBILE_DEVICE_REGEX } from "../../shared/constants/constant";

const AuthGuard = ({ children }: { children: ReactNode }) => {
	const auth = useSelector(authObjectSelector);

	const isMobileOrTablet = MOBILE_DEVICE_REGEX?.test(navigator.userAgent);

	if (auth?.authenticated && !isMobileOrTablet) return children;

	return <Navigate replace to='/login' />;
};

export default AuthGuard;
