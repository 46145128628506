enum SubscriptionActivityName {
	PLACE_ORDER = "Place Order",
	CUSTOMERS_ORDER = "Customer's Order",
	SPECIAL_PRICE_LIST = "Special Price List",
	CONNECTIONS = "Connections",
	ADD_PRODUCTS = "Add Products",
	PRODUCT_GROUP = "Product Group",
	COMPANY = "Company",
	CHAT = "Chat",
	PARTIAL_DISPATCH = "Partial Dispatch",
	MANAGE_USER = "Manage User",
	DISCOUNT_OFFERS = "Discount & Offers",
	BULK_IMPORT = "Bulk Import",
	BULK_PRICE_UPDATE = "Bulk Price Update",
	ADVANCE_DASHBOARD_ANALYTICS = "Advance Dashboard Analytics"
}

enum SubscriptionModules {
	ORDER = "Order",
	SPECIAL_PRICE_LIST = "Special Price List",
	CONNECTIONS = "Connections",
	PRODUCTS = "Products",
	COMPANY = "Company",
	CHAT = "Chat",
	SETTING = "Setting",
	DISCOUNT_OFFERS = "Discount & Offers",
	DASHBOARD = "Dashboard"
}

enum RoleAccessModules {
	PRODUCTS = "PRODUCT",
	NEW_ORDERS = "NEW_ORDERS",
	CUSTOMERS_ORDERS = "CUSTOMER_ORDERS",
	MY_ORDERS = "MY_ORDERS",
	PLACE_ORDER = "PLACE_ORDERS",
	CONNECTIONS = "CONNECTION",
	SPECIAL_RATE = "SPECIAL_RATE",
	OFFERS_DISCOUNTS = "Offers & Discounts",
	VIEW_ONLY = "View Only",
	SEND_NOTIFICATION = "Notification",
	STOCK_AND_INVENTORY = "STOCK_AND_INVENTORY"
}

enum ProductsSubModules {
	ADD_EDIT = "ADD_OR_EDIT",
	VIEW_ONLY = "VIEW_ONLY",
	MANAGE_GROUP = "MANAGE_GROUP",
	SEND_NOTIFICATION = "SEND_NOTIFICATION"
}

enum NewOrdersSubModules {
	VIEW_ONLY = "VIEW_ONLY",
	ACCEPT_REJECT = "ACCEPT_OR_REJECT",
	SEND_NOTIFICATION = "SEND_NOTIFICATION"
}

enum CustomerOrdersSubModules {
	VIEW_ONLY = "VIEW_ONLY",
	ORDER_TRACKING = "ORDER_TRACKING",
	CANCEL_ORDER = "CANCEL_ORDER",
	SEND_NOTIFICATION = "SEND_NOTIFICATION"
}

enum MyOrdersSubModules {
	VIEW_ONLY = "VIEW_ONLY",
	ORDER_TRACKING = "ORDER_TRACKING",
	CANCEL_ORDER = "CANCEL_ORDER",
	SEND_NOTIFICATION = "SEND_NOTIFICATION"
}

enum PlaceOrderSubModules {
	PLACE_ORDER = "PLACE_ORDER"
}

enum ConnectionsSubModules {
	VIEW_ONLY = "VIEW_ONLY",
	INVITE = "INVITE",
	ACCEPT_REJECT = "ACCEPT_OR_REJECT",
	SEND_NOTIFICATION = "SEND_NOTIFICATION"
}

enum SpaecialRateSubModules {
	ADD_EDIT = "ADD_OR_EDIT",
	VIEW_ONLY = "VIEW_ONLY",
	SEND_NOTIFICATION = "SEND_NOTIFICATION"
}

enum OfferAndDiscountSubModules {
	ADD = "Add",
	VIEW_ONLY = "VIEW_ONLY",
	EDIT_DELETE = "Edit/Delete",
	SEND_NOTIFICATION = "SEND_NOTIFICATION"
}

enum ProductManagementKeys {
	BULK_PRODUCT_IMPORT = "BULK_PRODUCT_IMPORT",
	CUSTOM_FIELD = "CUSTOM_FIELD",
	SEND_NOTIFICATION = "SEND_NOTIFICATION"
}

enum StockAndInventorySubModuleEnum {
	VIEW_ONLY = "VIEW_ONLY",
	ADD_ADJUST_STOCK = "ADD_OR_ADJUST_STOCK"
}

enum OrderStatusEnum {
	PENDING = "Pending",
	CANCELLED = "Cancelled",
	ACCEPTED = "Accepted",
	REJECTED = "Rejected",
	IN_PROGRESS = "In Progress",
	DISPATCHED = "Dispatched",
	PARTIALLY_DISPATCHED = "Partially Dispatched",
	COMPLETED = "Completed"
}

enum DateSelectionPeriodEnum {
	SEVEN_DAYS = "7days",
	FIFTEEN_DAYS = "15days",
	ONE_MONTH = "1month",
	THREE_MONTHS = "3months",
	SIX_MONTHS = "6months",
	ONE_YEAR = "1year",
	ALL_TIME = "",
	CUSTOM_DATE = "custom"
}



enum SubScriptionPlanEnum {
	FREE = 1,
	GOLD = 2,
	PREMIUM = 3
}



export {
	ConnectionsSubModules,
	CustomerOrdersSubModules,
	MyOrdersSubModules,
	NewOrdersSubModules,
	OfferAndDiscountSubModules,
	PlaceOrderSubModules,
	ProductsSubModules,
	RoleAccessModules,
	SpaecialRateSubModules,
	SubscriptionActivityName,
	SubscriptionModules,
	ProductManagementKeys,
	OrderStatusEnum,
	DateSelectionPeriodEnum,
	StockAndInventorySubModuleEnum,
	SubScriptionPlanEnum,
};
