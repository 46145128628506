import { Spin } from "antd";
import React from "react";
import Breadcrumbs from "../breadcrumb";
import ImageLoad from "../ImageLoad";
import { PlusCircleFilled } from "../../assets/svg/icons";
import { FileDownloadSvg, ProductsGroupIcon } from "../../assets/svg";

interface IProps {
	children: React.ReactNode;
	title: Array<BreadcrumbItem>;
	header?: {
		pageTitle: string | React.ReactNode;
		profileIcon?: any;
		profileTitle?: string;
		buttons?: {
			addVendor?: boolean;
			addProduct?: boolean;
			saveProduct?: boolean;
			cart?: boolean;
			onClick?: () => void;
			createGroup?: boolean;
			onCreate?: () => void;
			bulkImport?: boolean;
			onBulkImportClick?: () => void;
		};
		loading?: boolean;
	};
	className?: string;
	loading?: boolean;
}

interface BreadcrumbItem {
	title: string | React.ReactNode;
	href?: string;
}

interface ISubHeaderProps {
	props?: {
		pageTitle: string | React.ReactNode;
		profileIcon?: any;
		profileTitle?: string;
		buttons?: {
			addVendor?: boolean;
			addProduct?: boolean;
			saveProduct?: boolean;
			cart?: boolean;
			onClick?: () => void;
			createGroup?: boolean;
			onCreate?: () => void;
			bulkImport?: boolean;
			onBulkImportClick?: () => void;
		};
		children?: React.ReactNode;
		loading?: boolean;
	};
}

const SubHeader = ({ props }: ISubHeaderProps) => {
	return (
		<div className='flex header-container alignStart'>
			<div className='flex profile-box gap-4'>
				{props?.profileIcon && (
					<ImageLoad src={props.profileIcon} alt='profile-icon' />
				)}
				{props?.profileTitle && (
					<h3 className='profile-title'>{props.profileTitle}</h3>
				)}
				{props?.pageTitle && <h2>{props?.pageTitle}</h2>}
				{props?.children && <>{props?.children}</>}
			</div>
			{props?.buttons?.addProduct && (
				<button
					type='button'
					className='rounded-14 primary large add-product'
					onClick={props.buttons.onClick}
				>
					{props?.loading ? (
						<Spin />
					) : (
						<>
							<PlusCircleFilled /> Add Product
						</>
					)}
				</button>
			)}
			{props?.buttons?.addVendor && (
				<button
					type='button'
					className='rounded-14 primary large add-vender'
					onClick={props.buttons.onClick}
				>
					<PlusCircleFilled /> Add Vendor
				</button>
			)}
			{props?.buttons?.createGroup && (
				<button
					type='button'
					className='rounded-14 large bg-light gap-1'
					onClick={props.buttons.onCreate}
				>
					<ProductsGroupIcon /> Create Group
				</button>
			)}
			{props?.buttons?.saveProduct && (
				<button
					type='button'
					className='rounded-14 primary large save-product'
					onClick={props.buttons.onClick}
					disabled={props?.loading}
				>
					{props?.loading ? (
						<>
							<Spin /> Saving...
						</>
					) : (
						"Save Product"
					)}
				</button>
			)}

			{props?.buttons?.bulkImport && (
				<button
					type='button'
					className='rounded-14 large bg-light gap-1'
					onClick={props?.buttons?.onBulkImportClick}
					disabled={props?.loading}
				>
					<FileDownloadSvg /> Bulk Import
				</button>
			)}
		</div>
	);
};

const Page = ({ children, title, header, className, loading }: IProps) => {
	return (
		<div className={className ? className + " container page" : "container page"}>
			{header || title?.length ? (
				<div className='header-section flex direction-column'>
					{title?.length ? <Breadcrumbs items={title} /> : <></>}
					{header ? <SubHeader props={header} /> : <></>}
				</div>
			) : (
				<></>
			)}
			{loading ? (
				<div className='page-loader'>
					<Spin />
				</div>
			) : (
				children
			)}
		</div>
	);
};

export default Page;
