import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import endPoints from "./endPoints.json";
import axiosInstance from "../../middleware/axios";

export const getConnectionList = createAsyncThunk(
	"connection/list",
	async (
		args: {
			search: string;
			sort: string;
			type: string;
			limit: number;
			offset: number;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await axiosInstance.post(endPoints.getConnectionList, args);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getConnectionDetailsById = createAsyncThunk(
	"connection/details",
	async (id: number, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get(
				`${endPoints.getConnectionDetailsById}/${id}`
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getSentRequest = createAsyncThunk(
	"getRequest/sent",
	async (
		payload: { search: string; offset: number; limit: number; forVendor: boolean },
		{ rejectWithValue }
	) => {
		try {
			const response = await axiosInstance.post(endPoints.getSentRequest, payload);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error?.message);
		}
	}
);

export const sendConnectionRequest = createAsyncThunk(
	"request/send",
	async (
		payload: {
			receiverId: number;
			requestAsVendor: boolean;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await axiosInstance.post(endPoints.requestSend, payload);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error?.message);
		}
	}
);

export const getCompanyByPhoneNumber = createAsyncThunk(
	"company/phoneNumber",
	async (payload: { phoneNo: string }, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.post(
				endPoints.getCompanyByPhoneNumber,
				payload
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const sendInvitationRequest = createAsyncThunk(
	"invitation/request",
	async (
		payload: { phoneNo: string; inviteAsVendor: boolean },
		{ rejectWithValue }
	) => {
		try {
			const response = await axiosInstance.post(
				endPoints.sendInviteRequest,
				payload
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const getConnectedCompanyOrder = createAsyncThunk(
	"company/orders",
	async (
		payload: {
			connectedCompanyId: number;
			type: number;
			search: string;
			startDate: string;
			endDate: string;
			limit: number;
			offset: number;
			sort:
				| {
						field: string;
						order: string;
				  }[]
				| [];
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await axiosInstance.post(
				endPoints.getCompanyOrders,
				payload
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				message.error(error?.response?.data?.message || error.message);
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);

export const connectionActiveTransations = createAsyncThunk(
	"connection/activeTransations",
	async (
		payload: {
			id: number;
			placedOrderslimit: number;
			receivedOrderslimit: number;
		},
		{ rejectWithValue }
	) => {
		try {
			const response = await axiosInstance.post(
				`${endPoints.activeTransations}/${payload.id}`,
				{
					placedOrderslimit: payload.placedOrderslimit,
					receivedOrderslimit: payload.receivedOrderslimit
				}
			);
			return response.data;
		} catch (error: any) {
			if (error.response && error.response.data) {
				return rejectWithValue(error.response.data);
			}
			return rejectWithValue(error.message);
		}
	}
);
