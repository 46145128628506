import { combineReducers } from "@reduxjs/toolkit";

import accountSettingSlice from "./slices/accountSettingSlice";
import addressSlice from "./slices/addressSlice";
import authSlice from "./slices/authSlice";
import commonSlice from "./slices/commonSlice";
import companySlice from "./slices/companySlice";
import connectionSlice from "./slices/connectionSlice";
import dashboardSlice from "./slices/dashboardSlice";
import notificationSlice from "./slices/notificationSlice";
import orders from "./slices/orderSlice";
import paginationSlice from "./slices/paginationSlice";
import placeorderSlice from "./slices/placeorderSlice";
import productSlice from "./slices/productSlice";
import settingSlice from "./slices/settingSlice";
import specialPriceList from "./slices/specialPriceListSlice";
import subscriptionSlice from "./slices/subscriptionSlice";
import tableSlice from "./slices/tableSlice";
import vendorSlice from "./slices/vendorSlice";
import productDraftSlice from "./slices/productDraftSlice";
import stockSlice from "./slices/StockSlice";

const rootReducer = (state: any, action: any) => {
	if (action.type === "RESET_STATE") {
		const { auth } = state;
		state = { auth };
	}
	return appReducer(state, action);
};

const appReducer = combineReducers({
	auth: authSlice,
	products: productSlice,
	vendors: vendorSlice,
	connections: connectionSlice,
	address: addressSlice,
	table: tableSlice,
	placeorder: placeorderSlice,
	manageOrders: orders,
	notification: notificationSlice,
	company: companySlice,
	setting: settingSlice,
	specialPriceList: specialPriceList,
	dashboard: dashboardSlice,
	pagination: paginationSlice,
	common: commonSlice,
	accountSetting: accountSettingSlice,
	subscription: subscriptionSlice,
	productDraft: productDraftSlice,
	stock: stockSlice
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
