const UploadOutlinedIcon = () => {
	return (
		<svg
			width='17'
			height='16'
			viewBox='0 0 17 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M13.5 9.66667V10.3333C13.5 11.2668 13.5 11.7335 13.3183 12.09C13.1586 12.4036 12.9036 12.6586 12.59 12.8183C12.2335 13 11.7668 13 10.8333 13H6.16667C5.23325 13 4.76654 13 4.41002 12.8183C4.09641 12.6586 3.84144 12.4036 3.68166 12.09C3.5 11.7335 3.5 11.2668 3.5 10.3333V9.66667M11.2778 5.77778L8.5 3M8.5 3L5.72222 5.77778M8.5 3V9.66667'
				stroke='#D9D9D9'
				strokeWidth='1.28'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export { UploadOutlinedIcon };
