const BoxIcon = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='18'
			height='18'
			viewBox='0 0 18 18'
			fill='none'
		>
			<g clipPath='url(#clip0_12189_35687)'>
				<path
					d='M16.4883 7.5C16.4605 6.5167 16.367 5.88979 16.052 5.35481C15.6039 4.59393 14.797 4.1705 13.1833 3.32364L11.6833 2.53648C10.3666 1.84549 9.7082 1.5 9 1.5C8.2918 1.5 7.63344 1.84549 6.31672 2.53647L4.81672 3.32364C3.20297 4.1705 2.3961 4.59393 1.94805 5.35481C1.5 6.11569 1.5 7.0625 1.5 8.95613V9.04387C1.5 10.9375 1.5 11.8843 1.94805 12.6452C2.3961 13.4061 3.20297 13.8295 4.81672 14.6764L6.31672 15.4635C7.63344 16.1545 8.2918 16.5 9 16.5C9.7082 16.5 10.3666 16.1545 11.6833 15.4635L13.1833 14.6764C14.797 13.8295 15.6039 13.4061 16.052 12.6452C16.367 12.1102 16.4605 11.4833 16.4883 10.5'
					stroke='black'
					strokeWidth='1.5'
					strokeLinecap='round'
				/>
				<path
					d='M15.75 5.625L12.75 7.125M9 9L2.25 5.625M9 9V16.125M9 9C9 9 11.057 7.97151 12.375 7.3125C12.5214 7.23928 12.75 7.125 12.75 7.125M12.75 7.125V9.75M12.75 7.125L5.625 3.375'
					stroke='black'
					strokeWidth='1.5'
					strokeLinecap='round'
				/>
			</g>
			<defs>
				<clipPath id='clip0_12189_35687'>
					<rect width='18' height='18' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};

export { BoxIcon };
