import { createSlice } from "@reduxjs/toolkit";
import { TConnectionDataProps, TConnectionState } from "../../types/connectionType";
import {
	getCompanyByPhoneNumber,
	getConnectedCompanyOrder,
	getConnectionDetailsById,
	getConnectionList,
	getSentRequest
} from "../services/connection";
import { SliceNames } from "../../shared/constants/constant";
import { getConnectionRecievedRequestAsyncThunk } from "../services/connection-async-thunk";
import { ConnectionActionsEnum } from "../../enum/connection-enum";

const initialState: TConnectionState = {
	connectionActiveTab: "All",
	connection: {
		isLoading: false,
		isSuccess: false,
		data: [],
		message: "",
		total: 0,
		customers: 0,
		vendors: 0
	},
	connectionDetails: {
		isLoading: false,
		isSuccess: false,
		data: {
			connection: {
				aboutCompany: "",
				addressLine: "",
				blockByCompany: 0,
				city: "",
				companyId: 0,
				companyType: [],
				connectionId: 0,
				connectionsCount: 0,
				country: "",
				createdAt: "",
				createdBy: {
					firstName: "",
					isActive: false,
					isDelete: false,
					isVerified: false,
					lastName: "",
					phoneNo: "",
					role: "",
					userId: 0
				},
				industryType: [],
				isActive: false,
				isBlock: false,
				isBlockAsCustomer: false,
				isBlockAsVendor: false,
				isBlockFromOtherSide: false,
				isCustomer: false,
				isDelete: false,
				isVendor: false,
				isVerified: false,
				modifiedOn: "",
				name: "",
				placedOrders: 0,
				receivedOrders: 0,
				state: "",
				district: ""
			}
		},
		message: ""
	},
	sentRequest: {
		isLoading: false,
		isSuccess: false,
		data: [],
		message: ""
	},
	receivedRequest: {
		isLoading: false,
		isSuccess: false,
		data: [],
		message: ""
	},
	companyList: {
		isLoading: false,
		isSuccess: false,
		data: null,
		message: ""
	},
	companyOrders: {
		isLoading: false,
		isSuccess: false,
		data: null,
		message: ""
	},
	connectionPayload: {
		data: 0
	}
};

const connectionSlice = createSlice({
	name: SliceNames.CONNECTION,
	initialState,
	reducers: {
		clearCompanyList: state => {
			state.companyList = initialState.companyList;
		},
		clearConnectionDetail: state => {
			state.connectionDetails = initialState.connectionDetails;
		},
		clearCompanyOrder: state => {
			state.companyOrders = initialState.companyOrders;
		},
		setConnectionActiveTab: (state, { payload }) => {
			state.connectionActiveTab = payload;
		},
		connectionListPayload: (state, { payload }) => {
			state.connectionPayload.data = payload;
		},
		clearConnectionList: state => {
			state.connection.data = initialState.connection.data;
		},
		updatedConnectionList: (state, { payload }) => {
			state.connection.data = payload;
		},
		updatedConnectionDetails: (state, { payload }) => {
			const { action, id } = payload;

			if (
				action === ConnectionActionsEnum.BLOCK ||
				action === ConnectionActionsEnum.UNBLOCK
			) {
				const isBlock = action === ConnectionActionsEnum.BLOCK;
				state.connectionDetails.data.connection.isBlock = isBlock;

				state.connection.data = state.connection.data.map(
					(item: TConnectionDataProps) =>
						item.connectionId === id ? { ...item, isBlock } : item
				);
			} else if (action === ConnectionActionsEnum.REMOVE) {
				state.connection.data = state.connection.data.filter(
					(item: TConnectionDataProps) => item.connectionId !== id
				);
			}
		},
		removeConnectionRequests: (state, { payload }) => {
			const updatedData = state.receivedRequest.data.filter(
				item => item?.requestId !== payload
			);
			state.receivedRequest.data = updatedData;
		},
		removePendingRequest: (state, { payload }) => {
			const updatedData = state.sentRequest.data.filter(
				item => item?.requestId !== payload
			);
			state.sentRequest.data = updatedData;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getConnectionList.pending, state => {
				state.connection.isLoading = true;
				state.connection.isSuccess = false;
			})
			.addCase(getConnectionList.fulfilled, (state, { payload }: any) => {
				const existingData = state.connection?.data?.map(item => ({
					...item
				}));
				const newData = payload?.data?.connections?.map((item: any) => ({
					...item
				}));

				const combinedData = Array.from(
					new Set([...existingData, ...newData].map(item => item.connectionId))
				).map(id =>
					[...existingData, ...newData].find(item => item.connectionId === id)
				);

				state.connection.isSuccess = true;
				state.connection.isLoading = false;
				state.connection.data = combinedData;
				state.connection.message = payload?.message;
				state.connection.total = payload?.data?.total;
				state.connection.customers = payload?.data?.customers;
				state.connection.vendors = payload?.data?.vendors;
			})
			.addCase(getConnectionList.rejected, (state, { payload }: any) => {
				state.connection.data = payload;
				state.connection.isSuccess = false;
				state.connection.isLoading = false;
				state.connection.message = payload?.message;
				state.connection.total = 0;
				state.connection.customers = 0;
				state.connection.vendors = 0;
			})

			//
			.addCase(getConnectionDetailsById.pending, state => {
				state.connectionDetails.isLoading = true;
				state.connectionDetails.isSuccess = false;
			})
			.addCase(getConnectionDetailsById.fulfilled, (state, { payload }: any) => {
				state.connectionDetails.isSuccess = true;
				state.connectionDetails.isLoading = false;
				state.connectionDetails.data = payload?.data;
				state.connectionDetails.message = payload?.message;
			})
			.addCase(getConnectionDetailsById.rejected, (state, { payload }: any) => {
				state.connectionDetails.data = payload;
				state.connectionDetails.isSuccess = false;
				state.connectionDetails.isLoading = false;
				state.connectionDetails.message = payload?.message;
			})

			//
			.addCase(getSentRequest.pending, state => {
				state.sentRequest.isLoading = true;
				state.sentRequest.isSuccess = false;
			})
			.addCase(getSentRequest.fulfilled, (state, { payload }: any) => {
				state.sentRequest.isSuccess = true;
				state.sentRequest.isLoading = false;
				state.sentRequest.data = payload?.data;
				state.sentRequest.message = payload?.message;
			})
			.addCase(getSentRequest.rejected, (state, { payload }: any) => {
				state.sentRequest.isSuccess = false;
				state.sentRequest.isLoading = false;
				state.sentRequest.data = payload?.data;
				state.sentRequest.message = payload?.message;
			})

			// Connection Recieved Requests
			.addCase(getConnectionRecievedRequestAsyncThunk.pending, state => {
				state.receivedRequest.isLoading = true;
				state.receivedRequest.isSuccess = false;
			})
			.addCase(
				getConnectionRecievedRequestAsyncThunk.fulfilled,
				(state, { payload }) => {
					state.receivedRequest.isSuccess = true;
					state.receivedRequest.isLoading = false;
					state.receivedRequest.data = payload?.data;
					state.receivedRequest.message = payload?.message;
				}
			)
			.addCase(getConnectionRecievedRequestAsyncThunk.rejected, state => {
				state.receivedRequest.isSuccess = false;
				state.receivedRequest.isLoading = false;
			})

			//
			.addCase(getCompanyByPhoneNumber.pending, state => {
				state.companyList.isLoading = true;
				state.companyList.isSuccess = false;
			})
			.addCase(getCompanyByPhoneNumber.fulfilled, (state, { payload }: any) => {
				state.companyList.isSuccess = true;
				state.companyList.isLoading = false;
				state.companyList.data = payload?.data;
				state.companyList.message = payload?.message;
			})
			.addCase(getCompanyByPhoneNumber.rejected, (state, { payload }: any) => {
				state.companyList.isSuccess = false;
				state.companyList.isLoading = false;
				state.companyList.data = payload?.data;
				state.companyList.message = payload?.message;
			})

			//
			.addCase(getConnectedCompanyOrder.pending, state => {
				state.companyOrders.isLoading = true;
				state.companyOrders.isSuccess = false;
			})
			.addCase(getConnectedCompanyOrder.fulfilled, (state, { payload }: any) => {
				state.companyOrders.isSuccess = true;
				state.companyOrders.isLoading = false;
				state.companyOrders.data = payload?.data;
				state.companyOrders.message = payload?.message;
			})
			.addCase(getConnectedCompanyOrder.rejected, (state, { payload }: any) => {
				state.companyOrders.isSuccess = false;
				state.companyOrders.isLoading = false;
				state.companyOrders.data = payload?.data;
				state.companyOrders.message = payload?.message;
			});
	}
});

export const {
	clearCompanyList,
	clearConnectionDetail,
	clearCompanyOrder,
	setConnectionActiveTab,
	connectionListPayload,
	clearConnectionList,
	updatedConnectionList,
	updatedConnectionDetails,
	removeConnectionRequests
} = connectionSlice.actions;
export default connectionSlice.reducer;
