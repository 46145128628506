import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { getUserRoleList } from "../../redux/services/accountSetting";
import { getSubscriptionPayloadData } from "../../redux/services/subscription";
import { clearForm } from "../../redux/slices/accountSettingSlice";
import { clearOTPData } from "../../redux/slices/settingSlice";
import { getProfileIcon, getRestrictions } from "../../utils/helper";
import UserRoleCardSkeleton from "./skeletons/userRoleCard";
import { SubscriptionActivityName, SubscriptionModules } from "../../shared/enum/enum";
import { ACCOUNT_SETTINGS } from "../../shared/constants/content-constant";
import { PlusIcons } from "../../assets/svg";

interface ISettingCardProps {
	data: {
		role: {
			roleId: number;
			roleName: string;
		};
		user: {
			firstName: string;
			lastName: string;
		};
	};
}

const AccountsList = () => {
	const [isSettingsData, setIsSettingsData] = useState<any>();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const params = useParams();

	const { usersList } = useAppSelector(state => state.accountSetting);
	const isManageUser =
		isSettingsData?.data?.length &&
		getRestrictions(isSettingsData?.data, SubscriptionActivityName.MANAGE_USER);

	useEffect(() => {
		dispatch(getUserRoleList());
		dispatch(clearOTPData());
		dispatch(clearForm());
		dispatch(
			getSubscriptionPayloadData({ module: SubscriptionModules.SETTING })
		).then(result => {
			if (result?.payload) {
				setIsSettingsData(result?.payload);
			}
		});
	}, [dispatch]);

	const EmptyCard = () => {
		return (
			<div className='settings-card'>
				<div
					className='user-empty-card flex direction-column justifyCenter alignCenter gap-2'
					onClick={() =>
						navigate(`/settings/${params?.id}/user-and-permission/createUser`)
					}
				>
					<button className='add-button'>
						<PlusIcons />
					</button>
					<span className='text fontWeight-600'>
						{ACCOUNT_SETTINGS.CREATE_USER_ROLL_FORM.FORM_TITLE_2}
					</span>
				</div>
			</div>
		);
	};

	const UserRoleCard = ({ data }: ISettingCardProps) => {
		return (
			<div className='userRole-card'>
				<div
					className='card-data flex direction-column justifyCenter alignCenter gap-2'
					onClick={() =>
						navigate(
							`/settings/${params?.id}/user-and-permission/user/${data?.role?.roleId}`
						)
					}
				>
					<button className='profile'>
						{getProfileIcon(
							data?.user?.firstName + " " + data?.user?.lastName
						)}
					</button>
					<span className='text fontWeight-600'>
						{data?.user?.firstName + " " + data?.user?.lastName}
					</span>
					<div className='designation-button'>{data?.role?.roleName}</div>
				</div>
			</div>
		);
	};
	return (
		<div className='flex gap-5 cards-container'>
			{isManageUser || !usersList?.data?.length ? <EmptyCard /> : <></>}
			{usersList?.isLoading ? (
				<>
					<UserRoleCardSkeleton />
					<UserRoleCardSkeleton />
					<UserRoleCardSkeleton />
				</>
			) : (
				usersList?.data?.map(
					(
						item: {
							role: {
								roleId: number;
								roleName: string;
							};
							user: {
								firstName: string;
								lastName: string;
							};
						},
						index
					) => {
						return <UserRoleCard data={item} key={index} />;
					}
				)
			)}
		</div>
	);
};

export default AccountsList;
