import { ReactNode } from "react";
import { FallbackProps, ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";

const ErrorFallback: React.FC<FallbackProps> = ({ resetErrorBoundary, error }) => {
	console.log(error);
	const navigate = useNavigate();

	return (
		<div className='h-screen w-screen flex justifyCenter alignCenter error-boundary-wrapper'>
			<div className='error-boundary'>
				<h4>Error</h4>
				<p>Oops! Something went wrong.</p>
				<div className='flex w-100 gap-3'>
					<div className='mt-5 w-100'>
						<button
							className='button primary w-100'
							onClick={() => {
								navigate("/");
							}}
						>
							Back Home
						</button>
					</div>
					<div className='mt-5 w-100'>
						<button
							className='button primary w-100  '
							onClick={resetErrorBoundary}
							type='button'
						>
							Retry
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

const ErrorBoundary = ({ children }: { children: ReactNode }) => {
	const handleReset = () => {
		window.location.reload();
	};

	return (
		<ReactErrorBoundary onReset={handleReset} FallbackComponent={ErrorFallback}>
			{children}
		</ReactErrorBoundary>
	);
};

export default ErrorBoundary;
