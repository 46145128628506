import { useEffect } from "react";
import Page from "../../components/page";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { getBlockedConnectionsList } from "../../redux/services/accountSetting";
import CommonTable from "./commonTable";
import { ACCOUNT_SETTINGS } from "../../shared/constants/content-constant";

const ConnectionSettings = () => {
	const dispatch = useAppDispatch();
	const { blockedConnectionsList: data } = useAppSelector(
		state => state.accountSetting
	);

	useEffect(() => {
		dispatch(getBlockedConnectionsList());
	}, [dispatch]);

	return (
		<Page title={[]} className='connection-settings-page'>
			<div className='connection-settings-header'>
				<h3 className='title-3'>{ACCOUNT_SETTINGS.CONNECTION_PAGE.TITLE}</h3>
				<p className='caption'>{ACCOUNT_SETTINGS.CONNECTION_PAGE.DESCRIPTION}</p>
			</div>
			<div className='connection-settings-body'>
				<div className='connection-settings-table'>
					<CommonTable
						isLoading={data?.isLoading}
						switchButton={false}
						title={ACCOUNT_SETTINGS.CONNECTION_UNBLOCK_POPUP.TABLE_TITLE}
						data={data?.data}
					/>
				</div>
			</div>
		</Page>
	);
};

export default ConnectionSettings;
