import { ChangeEvent, useEffect, useState } from "react";
import { CrossRoundedIcon } from "../../assets/svg";
import { AttachmentIcon, PdfIcon } from "../../assets/svg/icons";
import { InputField } from "../../components";
import CommonModal from "../../components/commonModal";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import useMessageHook from "../../hooks/useMessageHook";
import { getAddressByPincode } from "../../redux/services/address";
import {
	getDispatchDetails,
	getOrderDetails,
	orderDispatch,
	updateTransportation
} from "../../redux/services/orders";
import { ERROR_STRINGS } from "../../shared/constants/content-constant";
import { dispatchOrderItemsTypes } from "../../types/ordersTypes";
import { compressImage } from "../../utils/helper";
import Limit from "../../utils/limits.json";

interface Props {
	open: boolean;
	close: () => void;
	orderId: number;
	isUpdate?: boolean;
	transportationDetailsData?: {
		orderDispatchId: number;
		transporterName: string;
		trakingId: string;
		pincode: string;
		address: string;
		district: string;
		state: string;
		country: string;
		city: string;
	};
	dispatchOrderItems?: dispatchOrderItemsTypes[];
}

type SelectedFile = {
	name: string;
	type: string;
	file: File;
};

interface valueProps {
	transporterName: string;
	trakingId: string;
	pincode: string;
	address: string;
	city: string;
	district: string;
	state: string;
	country: string;
}

const TransportationDetails = ({
	open,
	close,
	orderId,
	isUpdate = false,
	transportationDetailsData,
	dispatchOrderItems
}: Props) => {
	const [error, setError] = useState<any>({});
	const [loading, setLoading] = useState(false);
	const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([]);
	const [values, setValues] = useState<valueProps>({
		transporterName: "",
		trakingId: "",
		pincode: "",
		address: "",
		city: "",
		district: "",
		state: "",
		country: ""
	});
	const { showMessage, contextHolder } = useMessageHook();

	const dispatch = useAppDispatch();

	const { address }: any = useAppSelector(state => state.address);

	useEffect(() => {
		if (
			transportationDetailsData &&
			Object.entries(transportationDetailsData).length
		) {
			setValues({
				transporterName: transportationDetailsData.transporterName,
				trakingId: transportationDetailsData.trakingId,
				pincode: transportationDetailsData.pincode,
				address: transportationDetailsData.address,
				state: transportationDetailsData.state,
				district: transportationDetailsData.district,
				city: transportationDetailsData.city,
				country: transportationDetailsData.country
			});
		}
	}, [transportationDetailsData]);

	const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		const files = Array.from(event.target.files || []);

		const validFiles = files.filter(file => {
			const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
			// const sizeLimit = file.type === "image/jpeg" ? 400 * 1024 : 600 * 1024; // Size limit in bytes (KB * 1024)

			if (!allowedTypes.includes(file.type)) {
				showMessage(
					"error",
					"Invalid file type. Only JPEG, PNG, and PDF files are allowed."
				);
				return false;
			}

			// if (file.size > sizeLimit) {
			//   showMessage("error", `File must be smaller than ${sizeLimit / 1024}KB!`);
			//   return false;
			// }

			return true;
		});

		if (validFiles.length + selectedFiles.length > 3) {
			showMessage("error", "You can only upload up to 3 files.");
		} else {
			setSelectedFiles(prevFiles => [
				...prevFiles,
				...validFiles.map(file => ({
					name: file.name,
					type: file.type,
					file: file
				}))
			]);
		}

		event.target.value = ""; // Clear the input to allow re-uploading the same file if needed
	};

	const handleDelete = (fileName: string) => {
		setSelectedFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
	};

	const handleGetAddress = (pincode: string) => {
		if (pincode?.length === 6) {
			dispatch(getAddressByPincode({ pincode: pincode }))
				.then(result => {
					if (result.payload.status === 200) {
						setValues(prev => ({
							...prev,
							city: result.payload.data.city,
							country: result.payload.data.country,
							state: result.payload.data.state,
							district: result.payload.data.district
						}));
						setError((prev: any) => ({ ...prev, pincode: false }));
					} else {
						setError((prev: any) => ({ ...prev, pincode: true }));
					}
				})
				.catch(() => console.error(ERROR_STRINGS.SOMETHING_WRONG));
		}
	};

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		if (name === "pincode") {
			const validPincode = value.replace(/[^0-9]/g, "");
			if (validPincode.length <= 6) {
				setValues(prevValues => ({ ...prevValues, [name]: validPincode }));
				setError((prev: any) => ({ ...prev, [name]: false, city: false }));
			}
			if (validPincode.length === 6) {
				handleGetAddress(validPincode);
			}
		} else {
			setValues(prevValues => ({ ...prevValues, [name]: value }));
			setError((prev: any) => ({ ...prev, [name]: false }));
		}
		if (name === "pincode")
			setValues(prev => ({
				...prev,
				city: "",
				district: "",
				state: "",
				country: ""
			}));
	};

	const handleValidation = () => {
		const errorObj = {};
		if (!values?.transporterName) {
			Object.assign(errorObj, { transporterName: true });
		}
		if (!values?.trakingId) {
			Object.assign(errorObj, { trakingId: true });
		}
		if (!values?.pincode) {
			Object.assign(errorObj, { pincode: true });
		}
		if (!values?.address) {
			Object.assign(errorObj, { address: true });
		}
		if (!values?.city) {
			Object.assign(errorObj, { city: true });
		}
		if (!values?.district) {
			Object.assign(errorObj, { district: true });
		}
		if (!values?.state) {
			Object.assign(errorObj, { state: true });
		}
		if (!values?.country) {
			Object.assign(errorObj, { country: true });
		}
		if (Object.keys(errorObj)?.length) {
			setError(errorObj);
			return false;
		} else {
			return true;
		}
	};

	const handleTransportation = async (value: number) => {
		setLoading(true);
		if (value === 1 ? !handleValidation() : false) {
			setLoading(false);
			return;
		}

		const dispatchDetails =
			value === 1
				? {
						orderId: orderId,
						transporterName: values.transporterName,
						trackingId: values.trakingId,
						pincode: values.pincode,
						addressLine: values.address,
						city: address.city,
						district: address.district,
						state: address.state,
						country: address.country,
						dispatchItems: dispatchOrderItems
					}
				: {
						orderId: orderId,
						dispatchItems: dispatchOrderItems
					};

		const transportationAttachments = selectedFiles.map(data => data.file);

		const attachments = await Promise.all(
			transportationAttachments?.map(async (item: any) => {
				let compressedImgFile: any = item;

				if (
					Math.round(item?.size / 1024) > 200 &&
					item?.type !== "application/pdf"
				) {
					try {
						compressedImgFile = await compressImage(item, 200);
					} catch (error) {
						showMessage("error", "Image compression failed");
						return null; // Ensure a consistent return type
					}
				}
				return compressedImgFile;
			})
		);

		const payload = {
			dispatchDetails: dispatchDetails,
			transportationAttachments: attachments
		};
		dispatch(orderDispatch(payload))
			.then(result => {
				if (result.payload.status === 200) {
					setValues(prev => ({ ...prev, location: "" }));
					close();
				} else {
					showMessage(
						"error",
						result.payload.message || "Something went wrong"
					);
				}
			})
			.finally(() => setLoading(false));
	};

	const handleSubmit = async () => {
		if (!isUpdate) {
			handleTransportation(1);
		} else {
			if (!handleValidation()) {
				return;
			}
			setLoading(true);
			const dispatchDetails = {
				orderDispatchId: transportationDetailsData?.orderDispatchId,
				orderId: orderId,
				transporterName: values.transporterName,
				trackingId: values.trakingId,
				pincode: values.pincode,
				addressLine: values?.address,
				city: values?.city,
				district: values?.district,
				state: values?.state,
				country: values?.country
			};

			const transportationAttachments = selectedFiles?.map(data => data.file);
			const attachments = await Promise.all(
				transportationAttachments?.map(async (item: any) => {
					let compressedImgFile: any = item;

					if (
						Math.round(item?.size / 1024) > 200 &&
						item?.type !== "application/pdf"
					) {
						try {
							compressedImgFile = await compressImage(item, 200);
						} catch (error) {
							showMessage("error", "Image compression failed");
							return null; // Ensure a consistent return type
						}
					}
					return compressedImgFile;
				})
			);

			const payload = {
				transportationDetails: dispatchDetails,
				transportationAttachments: attachments
			};
			dispatch(updateTransportation(payload))
				.then(result => {
					if (result.payload.status === 200) {
						dispatch(getDispatchDetails(Number(orderId)));
						dispatch(getOrderDetails(Number(orderId)));
						close();
					}
				})
				.finally(() => setLoading(false));
		}
	};

	const handleCancel = () => {
		if (!isUpdate) {
			handleTransportation(0);
		} else {
			close();
			setValues(prev => ({ ...prev, location: "" }));
			setValues({
				transporterName: "",
				trakingId: "",
				pincode: "",
				address: "",
				city: "",
				district: "",
				state: "",
				country: ""
			});
		}
	};

	return (
		<CommonModal
			open={open}
			close={() => {
				close();
				setValues(prev => ({
					...prev,
					city: "",
					country: "",
					district: "",
					state: ""
				}));
			}}
			closeIcon
			className='transportation-detail-modal'
			footer
			saveButtonText={isUpdate ? "Submit" : "Dispatch"}
			cancelButtonText={isUpdate ? "Cancel" : "Skip"}
			onSave={() => handleSubmit()}
			onCancel={() => handleCancel()}
			loading={loading}
		>
			<div className='transportation-modal-container flex direction-column gap-5'>
				{contextHolder}
				<div className='header-container'>
					<h3 className='title'>Add Transportation Details</h3>
					<span className='info-text'>
						Enter transportation details to complete your order.
					</span>
				</div>
				<div className='transportation-modal-content flex direction-column'>
					<div className='gap-3 flex direction-column transport-info'>
						<InputField
							placeholder='Enter Transporter Name'
							label='Transporter Name'
							name='transporterName'
							onChange={handleChange}
							value={values.transporterName}
							autoFocus
							maxLength={Limit.transporterName}
							className={error?.transporterName ? "error" : null}
							disabled={
								transportationDetailsData &&
								transportationDetailsData.transporterName.length !== 0
							}
						/>
						<InputField
							placeholder='Enter ⁠⁠LR/ Tracking ID'
							label='⁠⁠LR/ Tracking ID'
							name='trakingId'
							onChange={handleChange}
							value={values.trakingId}
							maxLength={Limit.lr_trackingId}
							className={error?.trakingId ? "error" : null}
							disabled={
								transportationDetailsData &&
								transportationDetailsData.transporterName.length !== 0
							}
						/>
					</div>
					<div className='delivery-info-container'>
						<div className='flex direction-column'>
							<h4 className='delivery-header-text'>Delivery Info</h4>
							<span className='delivery-info-text'>
								Enter your delivery information to ensure timely and
								accurate shipping.
							</span>
						</div>
						<div className='input-field-box flex direction-column gap-2'>
							<div className='flex gap-2'>
								<InputField
									onChange={handleChange}
									placeholder='Enter Pincode'
									label='Pincode'
									name='pincode'
									value={values.pincode}
									errorMessage={error?.pincodeMessage}
									className={
										error?.pincode || error?.city ? "error" : null
									}
									disabled={
										transportationDetailsData &&
										transportationDetailsData.transporterName
											.length !== 0
									}
									maxLength={6}
								/>
								<InputField
									placeholder='Enter Location'
									label='Location'
									name='location'
									value={
										values?.district && values?.state
											? values?.district + ", " + values?.state
											: ""
									}
									disabled
								/>
							</div>
							<InputField
								onChange={handleChange}
								placeholder='Enter Address'
								label='Address Line'
								name='address'
								value={values.address}
								maxLength={Limit.addressLine}
								className={error?.address ? "error" : null}
								disabled={
									transportationDetailsData &&
									transportationDetailsData.transporterName.length !== 0
								}
							/>
						</div>
						<button
							className='attach-file-box'
							onClick={() => document.getElementById("fileInput")?.click()}
						>
							<span className='attach-file-text flex alignCenter gap-2'>
								<AttachmentIcon /> Attach file
							</span>
						</button>
						<div className='attachments-files-container'>
							<input
								id='fileInput'
								type='file'
								accept='image/*,.pdf'
								multiple
								style={{ display: "none" }}
								onChange={handleFileChange}
							/>

							<div className='selected-files-list flex gap-4'>
								{selectedFiles.map((file, index) => (
									<div key={index} className='selected-file'>
										{file.type === "application/pdf" ? (
											<div className='file-preview flex direction-column justifyCenter alignCenter'>
												<div className='file-box'>
													<PdfIcon />
												</div>
												<span className='file-name'>
													{file.name}
												</span>
											</div>
										) : (
											<div className='file-preview flex direction-column'>
												<div className='file-box'>
													<img
														src={URL.createObjectURL(
															file.file
														)}
														alt={file.name}
														className='image-preview'
														loading='lazy'
													/>
												</div>
												<span className='file-name'>
													{file.name}
												</span>
											</div>
										)}
										<button
											onClick={() => handleDelete(file.name)}
											className='delete-icon'
										>
											<CrossRoundedIcon />
										</button>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</CommonModal>
	);
};

export default TransportationDetails;
