import React from "react";

interface CountCapsuleProps {
	count: number;
	limit?: number;
	position?: "absolute" | "relative";
	style?: React.CSSProperties;
}

const CountCapsule = ({
	count,
	limit = 10,
	position = "absolute",
	style
}: CountCapsuleProps) => {
	return count ? (
		<span className={`count-capsule-badge ${position || ""}`} style={style}>
			{count > limit ? `${limit}+` : count === 0 ? "" : count}
		</span>
	) : (
		<></>
	);
};

export default CountCapsule;
