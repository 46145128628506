import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SliceNames } from "../../shared/constants/constant";
import {
	TInventoryProductStockCount,
	TStockHistoryType,
	TStockState,
	TStockTransactionHistoryType
} from "../../types/stock";
import {
	getAdjustmentReasonAsyncThunk,
	getLowStockDetailsAsyncThunk,
	getProductDetailAsyncThunk,
	getProductWithImageListAsyncThunk,
	getStockHistoryListAsyncThunk,
	getVendorNameSuggestionAsyncThunk,
	inventoryProductListAsyncThunk,
	inventoryProductSizeListAsyncThunk,
	inventoryProductStockChartAsyncThunk,
	inventoryProductStockCountAsyncThunk,
	productGroupListAsyncThunk,
	transactionHistoryAsyncThunk,
	transactionHistoryChildDrawerAsyncThunk
} from "../services/stock-async-thunk";

const stockInitialState: TStockState = {
	loader: {
		stockListLoading: false,
		stockDashBoardCountLoading: false,
		productGroupListLoading: false,
		productDetails: false,
		productsWithImageList: false,
		stockProductListLoading: false,
		stockProductSizeListLoading: false,
		productStockCountLoading: false,
		stockChartDataLoading: false,
		stockTransactionHistoryLoading: false,
		stockTransactionHistoryChildDrawerLoading: false,
		stockHistoryLoading: false,
		lowStockAlertData: false
	},
	groupList: [],
	product: {
		productId: 0,
		productImage: {
			thumbnailURL: null
		},
		productName: "",
		productSizes: [],
		moreSizes: ""
	},
	productList: {
		productList: [],
		totalCount: 0
	},
	stockInFormData: [],
	vendorNameSuggestions: [],
	stockProductList: {
		list: [],
		totalCount: 0,
		stockProductSizeList: []
	},
	stockHistory: {
		isStockHistoryDrawerOpen: false,
		hasMore: true,
		stockHistoryList: [],
		totalCount: 0
	},
	adjustmentReasons: [],
	updatedAvailableStock: {
		sizeId: 0,
		quantity: 0
	},
	stockAnalysis: {
		isStockAnalysisDrawerOpen: false,
		isChildStockDrawerOpen: false,
		productId: 0,
		sizeId: 0,
		stockCounts: {
			totalStocks: 0,
			unitSold: 0,
			availableStock: 0,
			minimumStockLevel: 0
		},
		stockChartData: [],
		transactionHistory: {
			list: [],
			totalCount: 0,
			hasMore: true
		},
		transactionChildDrawerHistory: {
			list: [],
			totalCount: 0,
			hasMore: true
		}
	},
	selectedCustomers: {
		list: [],
		selectAll: false
	},
	lowStockAlertData: {
		productLowStockAlertId: 0,
		stockQuantity: 0,
		applicableTo: 0,
		applicable: []
	}
};

const StockSlice = createSlice({
	name: SliceNames.STOCK,
	initialState: stockInitialState,
	reducers: {
		persistStockInFormData: (state, { payload }) => {
			const { sizeId, data } = payload;
			const isExistingSize = state.stockInFormData?.findIndex(
				item => item?.sizeId === sizeId
			);
			if (isExistingSize === -1) {
				state.stockInFormData.push({
					sizeId: sizeId,
					formData: data
				});
			} else {
				state.stockInFormData[isExistingSize].formData = data;
			}
		},
		clearSuggestions: state => {
			state.vendorNameSuggestions = [];
		},
		handleStockHistoryDrawerActionHandler: (
			state,
			{ payload }: PayloadAction<boolean>
		) => {
			state.stockHistory.isStockHistoryDrawerOpen = payload;
		},
		handleUpdateCurrentSizeStock: (state, { payload }) => {
			state.product.productSizes = state.product.productSizes?.map(size =>
				size.productSizeId === payload.sizeId
					? {
							...size,
							productStocks: {
								...size.productStocks,
								availableStock: payload.quantity
							}
						}
					: size
			);
		},

		handleStockAnalysisActionHandler: (
			state,
			{
				payload
			}: PayloadAction<{
				isStockAnalysisDrawerOpen: boolean;
				productId: number;
				sizeId: number;
			}>
		) => {
			state.stockAnalysis = { ...stockInitialState?.stockAnalysis, ...payload };
		},
		handleStockAnalysisSizeActionHandler: (
			state,
			{
				payload
			}: PayloadAction<{
				sizeId: number;
			}>
		) => {
			state.stockAnalysis.sizeId = payload?.sizeId;
		},
		handleCloseStockAnalysisActionHandler: state => {
			state.stockAnalysis = stockInitialState?.stockAnalysis;
		},
		resetProductActionHandler: state => {
			state.product = stockInitialState.product;
		},
		handleStockAnalysisChildDrawerActionHandler: (
			state,
			{ payload }: PayloadAction<boolean>
		) => {
			state.stockAnalysis.isChildStockDrawerOpen = payload;
		},
		resetStockTransactionHistory: state => {
			state.stockAnalysis.transactionHistory =
				stockInitialState.stockAnalysis.transactionHistory;
		},
		updateCustomerSelect: (state, { payload }) => {
			state.selectedCustomers = payload;
		},
		resetStockTransactionChildDrawerHistory: state => {
			state.stockAnalysis.transactionChildDrawerHistory =
				stockInitialState.stockAnalysis.transactionChildDrawerHistory;
		},
		resetStockTransactionHistoryListActionHandler: state => {
			state.stockAnalysis.transactionChildDrawerHistory.list = [];
			state.stockAnalysis.transactionChildDrawerHistory.totalCount = 0;
		},
		resetStockHistoryListActionHandler: state => {
			state.stockHistory.stockHistoryList = [];
			state.stockHistory.totalCount = 0;
		},
		resetStockHistoryStateActionHandler: state => {
			state.stockHistory = stockInitialState.stockHistory;
		},
		resetStockProductListActionHandler: state => {
			state.stockProductList = stockInitialState.stockProductList;
		},
		resetGroupListActionHandler: state => {
			state.groupList = [];
		},
		handleStockCountActionHandler: (
			state,
			{ payload }: PayloadAction<TInventoryProductStockCount>
		) => {
			state.stockAnalysis.stockCounts = payload;
		},
		handleTransactionHistoryActionHandler: (
			state,
			{ payload }: PayloadAction<TStockTransactionHistoryType>
		) => {
			state.stockAnalysis.transactionHistory.list = [
				payload,
				...state.stockAnalysis.transactionHistory.list
			];

			state.stockAnalysis.transactionHistory.totalCount =
				state.stockAnalysis.transactionHistory.totalCount + 1;

			const updatedLength =
				state.stockAnalysis.transactionHistory.list?.length || 0;
			const totalCount = state.stockAnalysis.transactionHistory.totalCount ?? 0;

			state.stockAnalysis.transactionHistory.hasMore = updatedLength < totalCount;
		},
		handleTransactionChildDrawerHistoryActionHandler: (
			state,
			{ payload }: PayloadAction<TStockTransactionHistoryType>
		) => {
			state.stockAnalysis.transactionChildDrawerHistory.list = [
				payload,
				...state.stockAnalysis.transactionChildDrawerHistory.list
			];

			state.stockAnalysis.transactionChildDrawerHistory.totalCount =
				state.stockAnalysis.transactionChildDrawerHistory.totalCount + 1;

			const updatedLength =
				state.stockAnalysis.transactionChildDrawerHistory.list?.length || 0;
			const totalCount =
				state.stockAnalysis.transactionChildDrawerHistory.totalCount ?? 0;

			state.stockAnalysis.transactionChildDrawerHistory.hasMore =
				updatedLength < totalCount;
		},
		handleStockHistoryActionHandler: (
			state,
			{ payload }: PayloadAction<TStockHistoryType>
		) => {
			state.stockHistory.stockHistoryList = [
				payload,
				...state.stockHistory.stockHistoryList
			];

			state.stockHistory.totalCount = state.stockHistory.totalCount + 1;

			const updatedLength = state.stockHistory.stockHistoryList?.length || 0;
			const totalCount = state.stockHistory.totalCount ?? 0;

			state.stockHistory.hasMore = updatedLength < totalCount;
		}
	},
	extraReducers: builder => {
		builder

			//product group list api

			.addCase(productGroupListAsyncThunk.pending, state => {
				state.loader.productGroupListLoading = true;
			})
			.addCase(productGroupListAsyncThunk.fulfilled, (state, { payload }) => {
				state.loader.productGroupListLoading = false;

				state.groupList = payload?.data?.productGroup;
			})
			.addCase(productGroupListAsyncThunk.rejected, state => {
				state.loader.productGroupListLoading = false;
				state.groupList = [];
			})

			//
			.addCase(getProductDetailAsyncThunk.pending, state => {
				state.loader.productDetails = true;
			})
			.addCase(getProductDetailAsyncThunk.fulfilled, (state, { payload }) => {
				const productDetails = payload?.data.product;
				state.product = productDetails || stockInitialState?.product;
				state.loader.productDetails = false;
			})
			.addCase(getProductDetailAsyncThunk.rejected, state => {
				state.loader.productDetails = false;
				state.product = stockInitialState?.product;
			})

			//
			.addCase(getProductWithImageListAsyncThunk.pending, state => {
				state.loader.productsWithImageList = true;
			})
			.addCase(
				getProductWithImageListAsyncThunk.fulfilled,
				(state, { payload, meta }) => {
					const { offset } = meta?.arg ?? { limit: 0 };

					const data = payload?.data;

					if (offset !== 0) {
						state.productList.productList = [
							...state.productList.productList,
							...(data?.product ?? [])
						];
					} else {
						// First fetch: Replace the list
						state.productList.productList = data?.product;
					}

					// Update total count
					state.productList.totalCount = data?.totalCount;

					// Mark loader as false
					state.loader.productsWithImageList = false;
				}
			)
			.addCase(getProductWithImageListAsyncThunk.rejected, state => {
				state.loader.productsWithImageList = false;
				state.productList = stockInitialState?.productList;
			})

			//
			.addCase(getVendorNameSuggestionAsyncThunk.pending, state => {
				state.loader.productsWithImageList = true;
			})
			.addCase(
				getVendorNameSuggestionAsyncThunk.fulfilled,
				(state, { payload }) => {
					state.vendorNameSuggestions = payload.data?.vendorNames;
					state.loader.productsWithImageList = false;
				}
			)
			.addCase(getVendorNameSuggestionAsyncThunk.rejected, state => {
				state.loader.productsWithImageList = false;
			})

			// stock product list

			.addCase(inventoryProductListAsyncThunk.pending, state => {
				state.loader.stockProductListLoading = true;
				state.stockProductList.stockProductSizeList = [];
			})
			.addCase(inventoryProductListAsyncThunk.fulfilled, (state, { payload }) => {
				state.loader.stockProductListLoading = false;
				state.stockProductList.list = payload?.data?.stockProducts ?? [];
				state.stockProductList.totalCount = payload?.data?.totalCount ?? 0;
			})
			.addCase(inventoryProductListAsyncThunk.rejected, (state, action) => {
				const errorPayload = action.payload as
					| {
							aborted?: boolean;
							message?: string;
					  }
					| undefined;

				if (errorPayload?.aborted) {
					return;
				}
				state.stockProductList.list = [];
				state.stockProductList.totalCount = 0;
				state.loader.stockProductListLoading = false;
			})

			//stock product size list

			.addCase(inventoryProductSizeListAsyncThunk.pending, state => {
				state.loader.stockProductSizeListLoading = true;
				state.stockProductList.stockProductSizeList = [];
			})
			.addCase(
				inventoryProductSizeListAsyncThunk.fulfilled,
				(state, { payload, meta }) => {
					const productId = meta?.arg?.productId;
					const product = state.stockProductList?.list?.find(
						res => res?.productId === productId
					);

					const [size] = product?.productSizes ?? [];

					const productSize = (payload?.data?.stockProductSizes ?? [])?.filter(
						res => res?.productSizeId !== size?.productSizeId
					);

					state.loader.stockProductSizeListLoading = false;
					state.stockProductList.stockProductSizeList = productSize ?? [];
				}
			)
			.addCase(inventoryProductSizeListAsyncThunk.rejected, state => {
				state.loader.stockProductSizeListLoading = false;
				state.stockProductList.stockProductSizeList = [];
			})

			// Adjustment Reason

			.addCase(getAdjustmentReasonAsyncThunk.pending, state => {
				state.loader.stockProductSizeListLoading = true;
			})
			.addCase(getAdjustmentReasonAsyncThunk.fulfilled, (state, { payload }) => {
				state.loader.stockProductSizeListLoading = false;
				state.adjustmentReasons = payload?.data.data?.reasons || [];
			})
			.addCase(getAdjustmentReasonAsyncThunk.rejected, state => {
				state.loader.stockProductSizeListLoading = false;
				state.stockProductList.stockProductSizeList = [];
			})

			//inventory product Stock Count

			.addCase(inventoryProductStockCountAsyncThunk.pending, state => {
				state.loader.productStockCountLoading = true;
			})
			.addCase(
				inventoryProductStockCountAsyncThunk.fulfilled,
				(state, { payload }) => {
					state.loader.productStockCountLoading = false;
					state.stockAnalysis.stockCounts =
						payload?.data?.counts ??
						stockInitialState?.stockAnalysis?.stockCounts;
				}
			)
			.addCase(inventoryProductStockCountAsyncThunk.rejected, state => {
				state.loader.productStockCountLoading = false;
				state.stockAnalysis.stockCounts =
					stockInitialState?.stockAnalysis?.stockCounts;
			})

			//inventory stock chart data

			.addCase(inventoryProductStockChartAsyncThunk.pending, state => {
				state.loader.stockChartDataLoading = true;
			})
			.addCase(
				inventoryProductStockChartAsyncThunk.fulfilled,
				(state, { payload }) => {
					state.loader.stockChartDataLoading = false;
					state.stockAnalysis.stockChartData = payload?.data?.counts;
				}
			)
			.addCase(inventoryProductStockChartAsyncThunk.rejected, state => {
				state.loader.stockChartDataLoading = false;
				state.stockAnalysis.stockChartData = [];
			})

			//stock history list

			.addCase(transactionHistoryAsyncThunk.pending, state => {
				state.loader.stockTransactionHistoryLoading = true;
			})
			.addCase(
				transactionHistoryAsyncThunk.fulfilled,
				(state, { meta, payload }) => {
					const { offset } = meta?.arg ?? { limit: 0 };

					const data = payload?.data;

					if (offset) {
						state.stockAnalysis.transactionHistory.list = [
							...state.stockAnalysis.transactionHistory.list,
							...(data?.stockMovementLogs ?? [])
						];
					} else {
						state.stockAnalysis.transactionHistory.list =
							data?.stockMovementLogs;
					}

					state.stockAnalysis.transactionHistory.totalCount = data?.totalCount;

					const updatedLength =
						state.stockAnalysis.transactionHistory.list?.length || 0;
					const totalCount = data?.totalCount ?? 0;

					state.stockAnalysis.transactionHistory.hasMore =
						updatedLength < totalCount;

					state.loader.stockTransactionHistoryLoading = false;
				}
			)
			.addCase(transactionHistoryAsyncThunk.rejected, state => {
				state.loader.stockTransactionHistoryLoading = false;
			})

			//stock history child drawer list

			.addCase(transactionHistoryChildDrawerAsyncThunk.pending, state => {
				state.loader.stockTransactionHistoryChildDrawerLoading = true;
			})
			.addCase(
				transactionHistoryChildDrawerAsyncThunk.fulfilled,
				(state, { meta, payload }) => {
					const { offset } = meta?.arg ?? { limit: 0 };

					const data = payload?.data;

					if (offset) {
						state.stockAnalysis.transactionChildDrawerHistory.list = [
							...state.stockAnalysis.transactionChildDrawerHistory.list,
							...(data?.stockMovementLogs ?? [])
						];
					} else {
						state.stockAnalysis.transactionChildDrawerHistory.list =
							data?.stockMovementLogs;
					}

					state.stockAnalysis.transactionChildDrawerHistory.totalCount =
						data?.totalCount;

					const updatedLength =
						state.stockAnalysis.transactionChildDrawerHistory.list?.length ||
						0;
					const totalCount = data?.totalCount ?? 0;

					state.stockAnalysis.transactionChildDrawerHistory.hasMore =
						updatedLength < totalCount;

					state.loader.stockTransactionHistoryChildDrawerLoading = false;
				}
			)
			.addCase(
				transactionHistoryChildDrawerAsyncThunk.rejected,
				(state, { payload }) => {
					const errorPayload = payload as
						| {
								aborted?: boolean;
						  }
						| undefined;

					if (errorPayload?.aborted) {
						return;
					}

					state.loader.stockTransactionHistoryChildDrawerLoading = false;
				}
			)

			//stock history list

			.addCase(getStockHistoryListAsyncThunk.pending, state => {
				state.loader.stockHistoryLoading = true;
			})
			.addCase(
				getStockHistoryListAsyncThunk.fulfilled,
				(state, { payload, meta }) => {
					const { offset } = meta?.arg ?? { limit: 0 };

					const data = payload?.data;

					if (offset) {
						state.stockHistory.stockHistoryList = [
							...state.stockHistory.stockHistoryList,
							...(data?.stockMovementLogs ?? [])
						];
					} else {
						state.stockHistory.stockHistoryList = data?.stockMovementLogs;
					}

					state.stockHistory.totalCount = data?.totalCount;

					const updatedLength =
						state.stockHistory.stockHistoryList?.length || 0;
					const totalCount = data?.totalCount ?? 0;

					state.stockHistory.hasMore = updatedLength < totalCount;

					state.loader.stockHistoryLoading = false;
				}
			)
			.addCase(getStockHistoryListAsyncThunk.rejected, (state, { payload }) => {
				const errorPayload = payload as
					| {
							aborted?: boolean;
					  }
					| undefined;

				if (errorPayload?.aborted) {
					return;
				}

				state.loader.stockHistoryLoading = false;
			})

			// Low Stock Alert Edit API Response
			.addCase(getLowStockDetailsAsyncThunk.pending, state => {
				state.loader.lowStockAlertData = true;
			})
			.addCase(getLowStockDetailsAsyncThunk.fulfilled, (state, { payload }) => {
				state.loader.lowStockAlertData = false;
				state.lowStockAlertData = payload?.data?.lowStockAlertData;
			})
			.addCase(getLowStockDetailsAsyncThunk.rejected, (state, { payload }) => {
				state.loader.lowStockAlertData = false;
			});
	}
});

export const {
	persistStockInFormData,
	clearSuggestions,
	handleStockHistoryDrawerActionHandler,
	handleUpdateCurrentSizeStock,
	handleStockAnalysisActionHandler,
	handleStockAnalysisSizeActionHandler,
	resetProductActionHandler,
	handleStockAnalysisChildDrawerActionHandler,
	handleCloseStockAnalysisActionHandler,
	resetStockTransactionHistory,
	updateCustomerSelect,
	resetStockTransactionChildDrawerHistory,
	resetStockHistoryListActionHandler,
	resetStockHistoryStateActionHandler,
	resetStockTransactionHistoryListActionHandler,
	resetStockProductListActionHandler,
	resetGroupListActionHandler,
	handleStockCountActionHandler,
	handleTransactionHistoryActionHandler,
	handleTransactionChildDrawerHistoryActionHandler,
	handleStockHistoryActionHandler
} = StockSlice.actions;
export default StockSlice.reducer;
