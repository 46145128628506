import { useNavigate } from "react-router-dom";
import { AppQRCode } from "./assets/images";
import { Button } from "./components";
import Page from "./components/page";

const DownloadApp = () => {
	const navigate = useNavigate();

	return (
		<Page title={[]} className='download-app-page'>
			<div className='flex direction-column gap-8'>
				<h2 className='title-1 center'>Download Biizline App</h2>
				<div className='img-container'>
					<img src={AppQRCode} alt='QR Code' />
				</div>
				<Button
					type='button'
					className='primary'
					onClick={() => navigate("/login")}
				>
					Go to Web Application
				</Button>
			</div>
		</Page>
	);
};

export default DownloadApp;
