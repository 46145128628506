import { ReactNode, useEffect } from "react";
import {
	COMPANY_INFO,
	IOS_DEVICE_REGEX,
	MOBILE_DEVICE_REGEX
} from "../../shared/constants/constant";

const PreventMobile = ({ children }: { children: ReactNode }) => {
	const isMobileOrTablet = MOBILE_DEVICE_REGEX.test(navigator.userAgent);
	const isMobileIOS = IOS_DEVICE_REGEX.test(navigator.userAgent);

	useEffect(() => {
		if (isMobileOrTablet) {
			if (isMobileIOS) {
				window.location.href = COMPANY_INFO.APP_STORE;
			} else {
				window.location.href = COMPANY_INFO.PLAY_STORE;
			}
		}
	}, [window.location]);

	return isMobileOrTablet ? null : <>{children}</>;
};

export default PreventMobile;
