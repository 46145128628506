import { LeftOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, InputField } from "../../components";
import ConfirmationPopup from "../../components/actionModals/confirmationPopup";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import useMessageHook from "../../hooks/useMessageHook";
import {
	createUserRolePermission,
	getUserRoleDetails,
	roleSuggestionList
} from "../../redux/services/accountSetting";
import endPoints from "../../redux/services/endPoints.json";
import {
	getUserByPhoneNo,
	removeUser,
	rolePermissionSendOTP
} from "../../redux/services/settings";
import { clearOTPData } from "../../redux/slices/settingSlice";
import { REGEX_STRINGS } from "../../shared/constants/constant";
import {
	ACCOUNT_SETTINGS,
	ERROR_STRINGS,
	INPUT_FIELDS
} from "../../shared/constants/content-constant";
import { debounce } from "../../utils/debounce";
import AccessTable from "./accessTable";
import SuccessPopup from "./successPopup";
import { DeleteIcon } from "../../assets/svg/icons";
import axiosInstance from "../../middleware/axios";

const CreateRollForm = () => {
	const [value, setValue] = useState<any>({});
	const [error, setError] = useState<any>({});
	const [isPhoneNumberVerified, setIsPhoneNumberVerified] = useState(false);
	const [isUpdated, setIsUpdated] = useState(false);
	const [isUserCreated, setIsUserCreated] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [suggestion, setSuggestion] = useState([]);
	const [removePopup, setRemovePopup] = useState(false);
	const [isRegisterdUser, setIsRegisterdUser] = useState(false);
	const { showMessage, contextHolder } = useMessageHook();
	const navigate = useNavigate();

	const dispatch = useAppDispatch();
	const params = useParams();
	const { userDetails, selectedRole } = useAppSelector(state => state.accountSetting);
	const { otpVerify } = useAppSelector(state => state.setting);
	const isDisabled =
		(isRegisterdUser || (params?.userId && isRegisterdUser) || !isUpdated) &&
		value?.phone_number?.length === 10;

	useEffect(() => {
		if (params?.userId) {
			dispatch(getUserRoleDetails(params?.userId));
		}
	}, [dispatch, params?.userId]);

	useEffect(() => {
		if (params?.userId && !userDetails?.isLoading && userDetails?.isSuccess) {
			setValue({
				f_name: userDetails?.data?.userRole?.user?.firstName,
				l_name: userDetails?.data?.userRole?.user?.lastName,
				email: userDetails?.data?.userRole?.user?.email,
				phone_number: userDetails?.data?.userRole?.user?.phoneNo,
				role: userDetails?.data?.userRole?.role?.roleName
			});
		}
	}, [params, userDetails]);

	useEffect(() => {
		if (!otpVerify.isLoading && otpVerify.isSuccess) {
			setIsPhoneNumberVerified(true);
			setError({ ...error, phone_number: false });
		}
	}, [otpVerify]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleSearchRole = useCallback(
		debounce((search: string) => {
			const payload = {
				keyword: search
			};
			dispatch(roleSuggestionList(payload))
				.then(result => {
					if (result.payload?.status === 200) {
						setSuggestion(result?.payload?.data);
					}
				})
				.catch(() => {
					console.error(ERROR_STRINGS.SOMETHING_WRONG);
				});
		}, 500),
		[dispatch]
	);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsUpdated(true);
		const { name, value } = e.target;
		if (name === "phone_number") {
			dispatch(clearOTPData());
			setIsPhoneNumberVerified(false);
			if (REGEX_STRINGS.NUMBER.test(value)) {
				handleSearch(value);
				setIsRegisterdUser(false);
				setValue((prev: any) => ({
					...prev,
					[name]: value
				}));
			}
		} else if (name === "role") {
			handleSearchRole(value);
			setValue((prev: any) => ({
				...prev,
				[name]: value
			}));
		} else {
			setValue((prev: any) => ({
				...prev,
				[name]: value
			}));
		}
	};

	const handleValidate = () => {
		const errorObj = {};
		setError({});
		const isValidPhoneNumber = REGEX_STRINGS.PHONE_NUMBER.test(value?.phone_number);
		if (!value?.f_name) {
			Object.assign(errorObj, { f_name: true });
		}
		if (!value?.l_name) {
			Object.assign(errorObj, { l_name: true });
		}
		if (!value?.phone_number || value?.phone_number?.length !== 10) {
			Object.assign(errorObj, { phone_number: true });
		}
		if (value?.phone_number?.length === 10 && !isValidPhoneNumber) {
			Object.assign(errorObj, { phone_number_message: true });
		}
		if (value?.email && !REGEX_STRINGS.EMAIL.test(value?.email)) {
			Object.assign(errorObj, { email: true });
		}
		if (!value?.role) {
			Object.assign(errorObj, { role: true });
		}
		if (value?.role?.toLocaleLowerCase() === "admin") {
			Object.assign(errorObj, {
				roleMessage: `You can't able to create ${value?.role}`
			});
		}
		if (Object.keys(errorObj)?.length) {
			setError(errorObj);
			showMessage("error", "Please fill all mandatory fields");
			return false;
		} else {
			return true;
		}
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (handleValidate()) {
			if (isPhoneNumberVerified) {
				const payload = {
					firstName: value?.f_name?.trim() || "",
					lastName: value?.l_name?.trim() || "",
					email: value?.email?.trim() || "",
					phoneNo: value?.phone_number?.trim() || "",
					roleName: value?.role?.trim() || "",
					moduleRights: selectedRole || []
				};
				setIsLoading(true);
				if (!params?.userId) {
					const response = await dispatch(createUserRolePermission(payload));
					if (response?.payload?.status === 200) {
						showMessage("success", response?.payload?.message);
						navigate(-1);
						setIsLoading(false);
					} else {
						showMessage(
							"error",
							response?.payload?.message || ERROR_STRINGS.SOMETHING_WRONG
						);
						setIsLoading(false);
					}
				} else {
					Object.assign(payload, {
						userId: userDetails?.data?.userRole?.user?.userId
					});
					await axiosInstance
						.post(endPoints.editUserRolePermission, payload)
						.then(result => {
							if (result?.data?.status === 200) {
								setIsLoading(false);
								showMessage("success", result?.data?.message);
								navigate(-1);
								dispatch(clearOTPData());
							}
						})
						.catch(() => {
							setIsLoading(false);
						})
						.finally(() => setIsLoading(false));
				}
			} else if (
				value?.phone_number !== userDetails?.data?.userRole?.user?.phoneNo
			) {
				showMessage("error", ERROR_STRINGS.VERIFY_PHONE_NUMBER);
				setError((prev: any) => ({
					...prev,
					phone_number: true
				}));
			} else if (
				isUpdated &&
				value?.phone_number === userDetails?.data?.userRole?.user?.phoneNo
			) {
				setIsLoading(true);
				const payload = {
					firstName: value?.f_name?.trim() || "",
					lastName: value?.l_name?.trim() || "",
					email: value?.email?.trim() || "",
					phoneNo: value?.phone_number?.trim() || "",
					roleName: value?.role?.trim() || "",
					moduleRights: selectedRole || []
				};

				if (!params?.userId) {
					await dispatch(createUserRolePermission(payload))
						.then((result: any) => {
							if (result?.data?.status === 200) {
								setIsLoading(false);
								setIsUserCreated(true);
								setTimeout(() => {
									setIsUserCreated(false);
									showMessage("success", result?.data?.message);
									navigate(-1);
									dispatch(clearOTPData());
								}, 2000);
							}
						})
						.catch(() => {
							showMessage("error", ERROR_STRINGS.SOMETHING_WRONG);
							setIsLoading(false);
						})
						.finally(() => {
							setIsLoading(false);
						});
				} else {
					Object.assign(payload, {
						userId: userDetails?.data?.userRole?.user?.userId
					});
					await axiosInstance
						.post(endPoints.editUserRolePermission, payload)
						.then(result => {
							if (result?.data?.status === 200) {
								setIsUserCreated(true);
								setTimeout(() => {
									setIsUserCreated(false);
									showMessage("success", result?.data?.message);
									navigate(-1);
									dispatch(clearOTPData());
								}, 2000);
							}
						})
						.catch(() => {
							showMessage("error", ERROR_STRINGS.SOMETHING_WRONG);
						})
						.finally(() => {
							setIsLoading(false);
						});
				}
			}
		}
	};

	const handleSearch = useCallback(
		debounce(phone_number => {
			dispatch(getUserByPhoneNo({ phoneNo: phone_number })).then(result => {
				setValue((prev: any) => ({
					...prev,
					f_name: result?.payload?.data?.firstName || "",
					l_name: result?.payload?.data?.lastName || "",
					email: result?.payload?.data?.email || ""
				}));
				setIsRegisterdUser(
					result?.payload?.data?.firstName || result?.payload?.data?.lastName
						? true
						: false
				);
			});
		}, 500),
		[dispatch, value?.phoneNo]
	);

	const sendOTP = async () => {
		await dispatch(rolePermissionSendOTP(value?.phone_number))
			.then(result => {
				if (result?.payload?.status === 400) {
					showMessage("error", result?.payload?.message);
					dispatch(clearOTPData());
				}
			})
			.catch(error => {
				console.error(error);
			});
	};

	const handleSuggetionClick = (data: string) => {
		setValue((prev: any) => ({
			...prev,
			role: data
		}));
		setSuggestion([]);
	};

	const handleRemove = () => {
		dispatch(removeUser({ userId: userDetails?.data?.userRole?.user?.userId })).then(
			result => {
				if (result?.payload?.status === 200) {
					showMessage("success", result?.payload?.message);
					setRemovePopup(false);
					navigate(-1);
				}
			}
		);
	};

	return (
		<div className='create_user_roll_form'>
			{contextHolder}
			<div className='form-title-container'>
				<div className='flex justifyBetween alignCenter'>
					<div className='flex alignCenter gap-2'>
						<button className='back-button' onClick={() => navigate(-1)}>
							<LeftOutlined />
						</button>
						<h3 className='form-title'>
							{params?.userId
								? ACCOUNT_SETTINGS.CREATE_USER_ROLL_FORM.FORM_TITLE_1
								: ACCOUNT_SETTINGS.CREATE_USER_ROLL_FORM.FORM_TITLE_2}
						</h3>
					</div>
					{params?.userId ? (
						<div className='buttons-container'>
							<Button
								type='button'
								className='rounded-12 text delete-button'
								onClick={() => setRemovePopup(true)}
							>
								<DeleteIcon />
								Remove User
							</Button>
						</div>
					) : (
						<></>
					)}
					<ConfirmationPopup
						close={() => setRemovePopup(false)}
						open={removePopup}
						infoContent='Are you sure, you want to remove the user?'
						headerContent='Remove user'
						confirm={() => handleRemove()}
					/>
				</div>
			</div>
			<div className='form-input-container gap-4 flex direction-column'>
				<InputField
					name='phone_number'
					label={INPUT_FIELDS.PHONE_NUMBER.LABEL}
					placeholder={INPUT_FIELDS.PHONE_NUMBER.PLACEHOLDER}
					onChange={handleChange}
					value={value?.phone_number}
					required
					verify={
						value?.phone_number ===
							userDetails?.data?.userRole?.user?.phoneNo ||
						(!otpVerify?.isLoading && otpVerify?.isSuccess)
							? null
							: "phone"
					}
					className={
						(!isPhoneNumberVerified && value?.phone_number === 10) ||
						error?.phone_number
							? "error"
							: ""
					}
					handleVerifyPhone={sendOTP}
					isUser={true}
				/>
				<div className='flex gap-4'>
					<InputField
						name='f_name'
						label={INPUT_FIELDS.FIRST_NAME.LABEL}
						placeholder={INPUT_FIELDS.FIRST_NAME.PLACEHOLDER}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							isDisabled ? e.preventDefault() : handleChange(e);
						}}
						value={value?.f_name}
						required
						className={error?.f_name ? "error" : ""}
						disabled={isDisabled}
						isInfoMessage={
							isDisabled
								? "Please log in to user for edit the first name of this user."
								: undefined
						}
					/>
					<InputField
						name='l_name'
						label={INPUT_FIELDS.LAST_NAME.LABEL}
						placeholder={INPUT_FIELDS.LAST_NAME.PLACEHOLDER}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							isDisabled ? e.preventDefault() : handleChange(e);
						}}
						value={value?.l_name}
						required
						className={error?.l_name ? "error" : ""}
						disabled={isDisabled}
						isInfoMessage={
							isDisabled
								? "Please log in to user for edit the last name of this user."
								: undefined
						}
					/>
				</div>
				<InputField
					name='email'
					type='email'
					label={INPUT_FIELDS.EMAIL.LABEL}
					placeholder={INPUT_FIELDS.EMAIL.PLACEHOLDER}
					onChange={handleChange}
					value={value?.email}
					disabled={isDisabled}
					className={error?.email ? "error" : ""}
					errorMessage={error?.email ? ERROR_STRINGS.INVALID_EMAIL : ""}
					isInfoMessage={
						isDisabled
							? "Please log in to user for edit the email of this user."
							: undefined
					}
				/>
				<InputField
					name='role'
					type='text'
					label={INPUT_FIELDS.CREATE_ROLE.LABEL}
					placeholder={INPUT_FIELDS.CREATE_ROLE.PLACEHOLDER}
					onChange={handleChange}
					value={value?.role}
					className={error?.roleMessage || error?.role ? "error" : ""}
					required
					suggetion={suggestion}
					handleSuggetionClick={handleSuggetionClick}
					errorMessage={error?.roleMessage}
				/>
			</div>
			{isUserCreated ? (
				<SuccessPopup type={params?.userId ? "update" : "create"} />
			) : (
				""
			)}
			{userDetails?.isLoading ? (
				<div className='page-loader'>
					<Spin />
				</div>
			) : (
				""
			)}
			<AccessTable setIsUpdated={setIsUpdated} />
			{
				<div className='form-footer flex alignCenter justifyEnd'>
					<div className='buttons-container flex alignCenter gap-3'>
						<button
							className='cancel rounded-14'
							type='button'
							onClick={() => {
								navigate(-1);
								dispatch(clearOTPData());
							}}
						>
							Cancel
						</button>
						<button
							className='submit primary rounded-14'
							type='submit'
							onClick={handleSubmit}
							disabled={!isUpdated || isLoading}
						>
							{isLoading ? <Spin /> : <></>}Submit
						</button>
					</div>
				</div>
			}
		</div>
	);
};

export default CreateRollForm;
