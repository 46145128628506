import { createSlice } from "@reduxjs/toolkit";
import { TSettingState } from "../../types/settingTypes";
import {
	getOtpforUpdate,
	getProfileDetails,
	rolePermissionSendOTP,
	rolePermissionVerifyOTP,
	updateVerifyOtp
} from "../services/settings";
import { SliceNames } from "../../shared/constants/constant";

const initialState: TSettingState = {
	userProfile: {
		isLoading: false,
		isSuccess: false,
		data: null,
		message: ""
	},
	otpSend: {
		message: "",
		isLoading: false,
		isSuccess: false
	},
	otpVerify: {
		message: "",
		isLoading: false,
		isSuccess: false
	}
};

const settingSlice = createSlice({
	name: SliceNames.SETTING,
	initialState,
	reducers: {
		clearOTPData: state => {
			state.otpSend = initialState.otpSend;
			state.otpVerify = initialState.otpVerify;
		},
		updateUserProfile: (state, { payload }) => {
			state.userProfile.data = payload;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getProfileDetails.pending, state => {
				state.userProfile.isLoading = true;
				state.userProfile.isSuccess = false;
			})
			.addCase(getProfileDetails.fulfilled, (state, { payload }: any) => {
				state.userProfile.isSuccess = true;
				state.userProfile.isLoading = false;
				state.userProfile.data = payload?.data;
				state.userProfile.message = payload?.message;
			})
			.addCase(getProfileDetails.rejected, (state, { payload }: any) => {
				state.userProfile.data = payload;
				state.userProfile.isSuccess = false;
				state.userProfile.isLoading = false;
				state.userProfile.message = payload?.message;
			});

		builder
			.addCase(getOtpforUpdate.pending, state => {
				state.otpSend.isLoading = true;
				state.otpSend.isSuccess = false;
				state.otpSend.message = "";
			})
			.addCase(getOtpforUpdate.fulfilled, (state, { payload }: any) => {
				state.otpSend.isSuccess = true;
				state.otpSend.isLoading = false;
				state.otpSend.message = payload?.message;
			})
			.addCase(getOtpforUpdate.rejected, (state, { payload }: any) => {
				state.otpSend.isSuccess = false;
				state.otpSend.isLoading = false;
				state.otpSend.message = payload?.message;
			});

		builder
			.addCase(rolePermissionSendOTP.pending, state => {
				state.otpSend.isLoading = true;
				state.otpSend.isSuccess = false;
				state.otpSend.message = "";
			})
			.addCase(rolePermissionSendOTP.fulfilled, (state, { payload }: any) => {
				state.otpSend.isSuccess = true;
				state.otpSend.isLoading = false;
				state.otpSend.message = payload?.message;
			})
			.addCase(rolePermissionSendOTP.rejected, (state, { payload }: any) => {
				state.otpSend.isSuccess = false;
				state.otpSend.isLoading = false;
				state.otpSend.message = payload?.message;
			});

		builder
			.addCase(updateVerifyOtp.pending, state => {
				state.otpVerify.isLoading = true;
				state.otpVerify.isSuccess = false;
			})
			.addCase(updateVerifyOtp.fulfilled, (state, { payload }: any) => {
				state.otpVerify.isSuccess = true;
				state.otpVerify.isLoading = false;
				state.otpVerify.message = payload?.message;
			})
			.addCase(updateVerifyOtp.rejected, (state, { payload }: any) => {
				state.otpVerify.isSuccess = false;
				state.otpVerify.isLoading = false;
				state.otpVerify.message = payload?.message;
			});

		builder
			.addCase(rolePermissionVerifyOTP.pending, state => {
				state.otpVerify.isLoading = true;
				state.otpVerify.isSuccess = false;
			})
			.addCase(rolePermissionVerifyOTP.fulfilled, (state, { payload }: any) => {
				state.otpVerify.isSuccess = true;
				state.otpVerify.isLoading = false;
				state.otpVerify.message = payload?.message;
			})
			.addCase(rolePermissionVerifyOTP.rejected, (state, { payload }: any) => {
				state.otpVerify.isSuccess = false;
				state.otpVerify.isLoading = false;
				state.otpVerify.message = payload?.message;
			});
	}
});

export const { clearOTPData, updateUserProfile } = settingSlice.actions;
export default settingSlice.reducer;
