import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import useMessageHook from "../../hooks/useMessageHook";
import { getUserModulesActivity } from "../../redux/services/accountSetting";
import { accessTable } from "../../redux/slices/accountSettingSlice";
import { ACCOUNT_SETTINGS, ERROR_STRINGS } from "../../shared/constants/content-constant";
import { RoleAccessModules } from "../../shared/enum/enum";

interface IAccessTableProps {
	setIsUpdated: (e: boolean) => void;
	disabled?: boolean;
}

const AccessTable = ({ setIsUpdated, disabled }: IAccessTableProps) => {
	const [tableData, setTableData] = useState([]);
	const { contextHolder, showMessage } = useMessageHook();

	const dispatch = useAppDispatch();
	const { userDetails } = useAppSelector(state => state.accountSetting);
	const params = useParams();

	useEffect(() => {
		if (params?.userId && !userDetails?.isLoading) {
			setTableData(userDetails?.data?.modules);
		} else if (!params?.userId) {
			try {
				dispatch(getUserModulesActivity())
					.then((result: any) => {
						setTableData(result?.payload?.data);
					})
					.catch(() => {
						showMessage("error", ERROR_STRINGS.SOMETHING_WRONG);
					});
			} catch (error) {
				showMessage("error", ERROR_STRINGS.SOMETHING_WRONG);
			}
		}
	}, [dispatch, params, userDetails]);

	useEffect(() => {
		const arr = tableData?.map((moduleData: any) => {
			return {
				moduleId: moduleData?.moduleId,
				activities: moduleData?.activities?.map((activities: any) => {
					return activities?.isActive
						? {
								activityId: activities?.activityId,
								isActive: activities?.isActive
							}
						: { activityId: activities?.activityId, isActive: false };
				})
			};
		});
		dispatch(accessTable(arr));
	}, [tableData, dispatch]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>, moduleId: number) => {
		const { name, checked } = e.target;
		setIsUpdated(true);
		setTableData((prevTableData: any) =>
			prevTableData.map((moduleData: any) =>
				moduleData.moduleId === moduleId
					? {
							...moduleData,
							activities: moduleData?.activities?.map((activities: any) => {
								return activities?.activityName === name
									? { ...activities, isActive: checked }
									: name === RoleAccessModules.SEND_NOTIFICATION
										? {
												...activities,
												isActive: [
													RoleAccessModules.SEND_NOTIFICATION
												]?.includes(activities?.activityName)
													? false
													: activities?.isActive || false
											}
										: {
												...activities,
												isActive: [
													RoleAccessModules.VIEW_ONLY
												]?.includes(activities?.activityName)
													? false
													: activities?.isActive || false
											};
							})
						}
					: moduleData
			)
		);
		if ([name].includes(RoleAccessModules.PLACE_ORDER) && checked) {
			setTableData((prevTableData: any) =>
				prevTableData.map((moduleData: any) =>
					moduleData.moduleName === RoleAccessModules.MY_ORDERS
						? {
								...moduleData,
								activities: moduleData?.activities?.map(
									(activities: any) => {
										return {
											activityId: activities?.activityId,
											isActive: ![
												RoleAccessModules.VIEW_ONLY
											]?.includes(activities?.activityName)
												? true
												: false,
											activityName: activities?.activityName
										};
									}
								)
							}
						: moduleData
				)
			);
		}
	};

	const handleView = (id: number) => {
		setIsUpdated(true);
		setTableData((prevTableData: any) =>
			prevTableData.map((moduleData: any) =>
				moduleData.moduleId === id
					? {
							...moduleData,
							activities: moduleData?.activities?.map(
								(activities: any, index: number) => {
									return [RoleAccessModules.VIEW_ONLY].includes(
										activities?.activityName
									)
										? {
												activityId: activities?.activityId,
												isActive: [
													RoleAccessModules.SEND_NOTIFICATION
												]?.includes(
													moduleData?.activities[index]
														?.activityName
												)
													? activities?.isActive
													: !activities?.isActive,
												activityName: activities?.activityName
											}
										: {
												activityId: activities?.activityId,
												isActive: [
													RoleAccessModules.SEND_NOTIFICATION
												]?.includes(
													moduleData?.activities[index]
														?.activityName
												)
													? activities?.isActive
													: false,
												activityName: activities?.activityName
											};
								}
							)
						}
					: moduleData
			)
		);
	};

	return (
		<div className='access-table-wrapper'>
			{contextHolder}
			<h3 className='title-3'>
				{ACCOUNT_SETTINGS.ROLE_AND_ACCESS_PAGE.TABLE.TITLE}
			</h3>
			<div className='custom-table-wrapper'>
				<div className='table-head'>
					<p className='caption'>
						{ACCOUNT_SETTINGS.ROLE_AND_ACCESS_PAGE.TABLE.HEADING}
					</p>
				</div>
				<div className='table-body'>
					{tableData?.map((item: any, index: number) => (
						<div className='table-row flex' key={index}>
							<div className='table-data w-30'>{item.moduleName}</div>
							<div className='flex w-70 gap-2'>
								{item?.activities?.map((val: any, ind: number) => (
									<div className='table-data flex gap-2' key={ind}>
										<input
											type='checkbox'
											name={val.activityName}
											id={`${item.moduleId}-${val.activityId}`} // Unique id
											checked={val.isActive}
											disabled={disabled}
											onChange={
												disabled
													? e => e.preventDefault()
													: e => {
															val.activityId === 1
																? handleView(
																		item?.moduleId
																	)
																: handleChange(
																		e,
																		item?.moduleId
																	);
														}
											}
										/>
										<label
											htmlFor={`${item.moduleId}-${val.activityId}`}
											className='flex gap-2'
										>
											<span className='label-text'>
												{val.activityName}
											</span>
										</label>
									</div>
								))}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default AccessTable;
