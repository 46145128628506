const COMPANY_INFO = {
	LINK: "http://biizlinedevops.com/",
	APP_STORE: "https://apps.apple.com/in/app/biizline/id6599846985",
	PLAY_STORE:
		"https://play.google.com/store/apps/details?id=app.biizline&pcampaignid=web_share"
};

const REGEX_STRINGS = {
	EMAIL: /^([\w\\.\\+]{1,})([^\W])(@)([\w]{1,})(\.[\w]{1,})+$/,
	PRICE: /^\d{0,6}(\.\d{0,2})?$/,
	RATE: /^\d{0,6}(\.\d{0,2})?$/,
	QUANTITY: /^\d{0,5}?$/,
	PERCENTAGE: /^\d{0,2}(\.\d{0,2})?$/,
	SPECIAL_CHAR: /^[^a-zA-Z0-9]+$/,
	GST_NUMBER: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}[Z]{1}[A-Z0-9]{1}$/,
	PHONE_NUMBER: /^[6-9]\d{9}$/,
	NUMBER: /^\d{0,10}$/,
	FILE_ACCEPT: /^.*\.(jpg|jpeg|png|pdf)$/i,
	FILE_PNG: /^.*\.(png)$/i,
	FILE_JPG: /^.*\.(jpg|jpeg)$/i,
	FILE_PDF: /^.*\.(pdf)$/i
};

const MOBILE_DEVICE_REGEX = /Mobi|Android|iPhone|iPad|iPod/i;
const IOS_DEVICE_REGEX = /iPhone|iPad|iPod/i;

const OPERATER_STRINGS = {
	DIVISION: "/",
	MODULO: "%"
};

const NUMBERS = {
	1: 1,
	0: 0
};

const COMMON_STRINGS = {
	PREFIX: "Prefix",
	SUFFIX: "Suffix",
	SUMMARY: "Summary",
	YEAR: "Year",
	BLOCKED: "Blocked",
	UNBLOCK: "Unblock",
	CREATE: "Create",
	SORT_BY: {
		TEXT: "Sort by",
		OPTIONS: [
			{
				value: "ASC",
				label: "A - Z"
			},
			{
				value: "DESC",
				label: "Z - A"
			}
		]
	}
};

const CONNECTION_SIDEBAR = {};

const REDUX_PERSISTENT_STORE_KEY = "root";

const SliceNames = {
	AUTH: "auth",
	COMMON: "common",
	ACCOUNT_SETTING: "accountSetting",
	ADDRESS: "address",
	COMPANY: "company",
	CONNECTION: "connection",
	DASHBOARD: "dashboard",
	NOTIFICATION: "notification",
	MANAGE_ORDERS: "manageOrders",
	PAGINATION: "pagination",
	PLACE_ORDER: "placeOrder",
	PRODUCTS: "products",
	SETTING: "setting",
	SPECIAL_PRICE_LIST: "SpecialPriceList",
	SUBSCRIPTION: "subscription",
	TABLE: "table",
	VENDOR: "vendor",
	CART: "cart",
	PRODUCT_DRAFT: "productDraft",
	STOCK: "stock"
};

const S3Link = `https://${process.env.REACT_APP_AWS_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com`;

const PLAN_LINKS = {
	GOLD: `${S3Link}/planImages/gold.png`,
	PREMIUM: `${S3Link}/planImages/premium.png`
};

const DateFormats = {
	DASHED_DATE: "DD-MM-YYYY",
	DATE_REVERSE: "YYYY-MM-DD",
	DD_MM_YYYY: "DD, MMM YYYY",
	DATE_FORMAT_WITH_TIME: "YYYY-MM-DD HH:mm:ss",
	SLASH_DATE: "DD/MM/YYYY",
	MONTH_YEAR: "MMM YY",
	FULL_DATE_WITH_TIME_AM_PM: "DD, MMMM YYYY - hh:mm A",
	MMM_DD: "MMM DD"
};

const Confirmation_Popup = {
	TITLE: "Discard Changes?",
	TEXT: "Are you Sure? your changes need to discard?",
	SUBMIT: "Submit",
	CANCEL: "Cancel"
}

const ORDERS_TAB = {
	CUSTOMER_ORDER: "Customer-order",
	MY_ORDER: "My-Order"
}

const API_PAYLOAD_ORDER_STATUS = {
	DISPATCHED: "DISPATCHED",
	CANCELLED: "CANCELLED",
	COMPLETED: "COMPLETED",
	IN_PROGRESS: "IN_PROGRESS"
}

const ORDER_STATUS = {
	DISPATCHED: "Dispatched",
	CANCELLED: "Cancelled",
	COMPLETED: "COMPLETED",
	IN_PROGRESS: "inProgress",
	ACCEPTED: "Accepted",
	PENDING: "Pending",
	PARTIALLY_DISPATCHED: "Partially Dispatched",
	RECEIVED: "Received",
	CANCEL_ORDER: "Cancel Order",
	DELIVERED: "Delivered",
	REJECTED: "Rejected",
	IN_PROCESS: "In Process"
}

export {
	COMMON_STRINGS,
	COMPANY_INFO,
	CONNECTION_SIDEBAR,
	DateFormats,
	IOS_DEVICE_REGEX,
	MOBILE_DEVICE_REGEX,
	NUMBERS,
	OPERATER_STRINGS,
	REDUX_PERSISTENT_STORE_KEY,
	REGEX_STRINGS,
	S3Link,
	SliceNames,
	Confirmation_Popup,
	PLAN_LINKS,
	ORDERS_TAB,
	API_PAYLOAD_ORDER_STATUS,
	ORDER_STATUS
};
