enum ConnectionActionsEnum {
	UNBLOCK = "Unblock",
	ACCEPT = "Accept",
	REJECT = "Reject",
	CANCEL = "Cancel",
	REMOVE = "Remove",
	BLOCK = "Block"
}

enum ConnectionGetAllAPIParamsEnum {
	ASCENDING = "ASC",
	DESCENDING = "DSC",
	CUSTOMERS = "Customers",
	CREATEDON = "createdOn"
}

export {
	ConnectionActionsEnum,
	ConnectionGetAllAPIParamsEnum
}