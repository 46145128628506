import { Middleware } from "@reduxjs/toolkit";
import axios from "axios";
import { TApiResponse } from "../types/axios";
import { TRootState } from "../types/store";
import { POST } from "../shared/services/api-service";
import { loginAction, logOutAction } from "../redux/slices/authSlice";
import socket from "../socket";
 
const refreshTokenApi = (payload: {
    FCMtoken: string | null;
    from: string;
}): TApiResponse<any> => {
    return POST({
        URL: "/fetchRefreshToken",
        body: payload
    });
};
 
const getLocationLatLong = (): Promise<{ lat: number; long: number }> => {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const lat = position.coords.latitude;
                const long = position.coords.longitude;
                resolve({ lat, long }); // Correctly resolve the Promise
            },
            (error) => {
                reject(error); // Reject in case of an error
            },
            { enableHighAccuracy: true, timeout: 5000 }
        );
    });
};
 
let latitude = 0;
let longitude = 0;
 
// Usage
getLocationLatLong()
    .then(({ lat, long }) => {
        latitude = lat;
        longitude = long;
    })
    .catch((error) => {
        console.error("Error getting location:", error);
    });
 
const backendBaseUrl = process.env.REACT_APP_API_BASE_URL;
 
const axiosInstance = axios.create({
    baseURL: backendBaseUrl,
    headers: {
        "Content-Type": "application/json",
        "x-forword-from": "Web",
        "x-user-latitude": latitude,
        "x-user-longitude": longitude
    }
});
 
// Variable to hold the promise while refreshing the token
let isRefreshing = false;
let failedQueue: Array<() => void> = [];
 
const apiMiddleware: Middleware<object, TRootState> =
    storeApi => next => async action => {
        const { dispatch, getState } = storeApi;
 
        // Request interceptor
        axiosInstance.interceptors.request.use(
            config => {
                (config as any).metadata = { startTime: Date.now() }; // Store start time (epoch)
                // Add authorization token or other headers here
                const token = getState().auth.token;
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );
 
        // Response interceptor
        axiosInstance.interceptors.response.use(
            response => {
                const endTime = Date.now();
                const startTime = (response.config as any).metadata.startTime || endTime;
                const responseTime = endTime - startTime;
 
                const log = `URL: ${response?.config?.url}, responseTime: ${responseTime}, statusCode: ${response?.status}, method: ${response.config.method?.toUpperCase()}`;
                socket.emit("writeLog", "WEB", log);
                return response;
            },
            async error => {
                const originalRequest = error.config;
 
                if (axios.isCancel(error)) {
                    return Promise.reject(error);
                }
 
                const endTime = Date.now();
                const startTime = (error.config as any)?.metadata?.startTime || endTime;
                const responseTime = endTime - startTime;
                const log = `URL: ${error?.config?.url}, responseTime: ${responseTime}, statusCode: ${error?.status}, method: ${error?.config?.method?.toUpperCase()}`;
                socket.emit("writeLog", "WEB", log);
 
                if (originalRequest?.url === `${backendBaseUrl}/fetchRefreshToken`) {
                    return Promise.reject(error);
                }
 
                if (
                    error.response &&
                    error.response.status === 401 &&
                    !originalRequest._retry
                ) {
                    if (isRefreshing) {
                        try {
                            await new Promise<void>(resolve => {
                                failedQueue.push(() => {
                                    originalRequest.headers["Authorization"] =
                                        `Bearer ${getState().auth.token}`;
                                    resolve();
                                });
                            });
                            return axiosInstance(originalRequest);
                        } catch (error) {
                            return Promise.reject(error);
                        }
                    }
 
                    originalRequest._retry = true;
                    isRefreshing = true;
                    const fcm_token = getState()?.auth?.fcm_token;
 
                    try {
                        const response = await refreshTokenApi({
                            FCMtoken: fcm_token,
                            from: "Web"
                        });
 
                        console.log(response?.data?.data?.token);
 
                        dispatch(
                            loginAction({
                                token: response?.data?.data?.token,
                                fcm_token: fcm_token
                            })
                        );
                        failedQueue.forEach(callback => callback());
                        failedQueue = [];
                        isRefreshing = false;
                        return axiosInstance(originalRequest);
                    } catch (error) {
                        // console.log(error);
 
                        dispatch(logOutAction());
                        return Promise.reject(error);
                    }
                }
                if (
					error?.response?.status === 504 ||
					error?.response?.status === 500 ||
					error?.code === "ERR_NETWORK"
                ) {
					// Handle server/network errors gracefully
					window.location.replace("/500");
                } else if (error?.response?.status === 404) {
					window.location.replace("/404");
                } else if (
					error?.response?.status !== 401 &&
					error?.response?.status !== 400
                ) {
					window.location.replace("/error");
                }
 
                return Promise.reject(error);
            }
        );
        next(action);
    };
 
export default axiosInstance;
export { apiMiddleware };