import { useSelector } from "react-redux";
import { accessPermissionSelector } from "../redux/memorize-selector";
import { TRootState } from "../types/store";

//INFO: not make child or parent key array
const useAccessPermission = (parentKey: string, childPermissionKeys: string) => {
	const permissions = useSelector((state: TRootState) =>
		accessPermissionSelector(state, parentKey, childPermissionKeys)
	);

	return permissions;
};

export default useAccessPermission;
