import { InfoCircleOutlined, RightOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import { useEffect, useState } from "react";
import { CheckedRounedOutlineIcon, ClockIcon, TimerIcon } from "../../assets/svg/icons";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import {
	updateStatusByorderDispatchId,
	updateStatusByOrderId
} from "../../redux/services/orders";
import {
	CustomerOrdersSubModules,
	MyOrdersSubModules,
	RoleAccessModules
} from "../../shared/enum/enum";
import { CleverTapEvents } from "../../utils/clevertapEvents";
import { getModuleRoleAccessData } from "../../utils/helper";

interface StatusSwitchProps {
	status: string;
	orderId: number;
	onDispatch?: () => void;
	activeTab?: string;
	disabled?: boolean; // Optional prop with default value
	onCancel?: () => void;
	orderDetails?: boolean;
	isPartiallyReceived?: boolean;
	onPartiallyReceived?: () => void;
	orderDispatchId?: number;
}

const StatusSwitch = ({
	status,
	orderId,
	onDispatch,
	activeTab,
	disabled = false,
	orderDetails = false,
	onCancel,
	isPartiallyReceived,
	onPartiallyReceived,
	orderDispatchId
}: StatusSwitchProps) => {
	const [open, setOpen] = useState(false);

	const dispatch = useAppDispatch();
	const { loggedInUserRole } = useAppSelector(state => state.accountSetting);
	const viewCustomerOrders = getModuleRoleAccessData(
		loggedInUserRole?.data?.activityAccess,
		RoleAccessModules.CUSTOMERS_ORDERS,
		CustomerOrdersSubModules.VIEW_ONLY
	);
	const viewMyOrders = getModuleRoleAccessData(
		loggedInUserRole?.data?.activityAccess,
		RoleAccessModules.MY_ORDERS,
		MyOrdersSubModules.VIEW_ONLY
	);
	const cancelOrder = getModuleRoleAccessData(
		loggedInUserRole?.data?.activityAccess,
		RoleAccessModules.CUSTOMERS_ORDERS,
		CustomerOrdersSubModules.CANCEL_ORDER
	);
	const cancelMyOrder = getModuleRoleAccessData(
		loggedInUserRole?.data?.activityAccess,
		RoleAccessModules.MY_ORDERS,
		MyOrdersSubModules.CANCEL_ORDER
	);
	const trackMyOrder = getModuleRoleAccessData(
		loggedInUserRole?.data?.activityAccess,
		RoleAccessModules.MY_ORDERS,
		MyOrdersSubModules.ORDER_TRACKING
	);
	const orderTracking = getModuleRoleAccessData(
		loggedInUserRole?.data?.activityAccess,
		RoleAccessModules.CUSTOMERS_ORDERS,
		CustomerOrdersSubModules.ORDER_TRACKING
	);

	const isCustomerTab = activeTab === "Customer-order";
	const isMyOrderTab = activeTab === "My-order";

	useEffect(() => {
		const handleScroll = () => {
			setOpen(false);
		};
		const element = document.querySelector(".ant-table-body");
		if (element) {
			element.addEventListener("scroll", handleScroll);
		}
		return () => {
			if (element) {
				element.removeEventListener("scroll", handleScroll);
			}
		};
	}, []);

	const changeOrderStatus = (nextStatus: string) => {
		setOpen(false);
		if (nextStatus === "DISPATCHED") {
			onDispatch && onDispatch();
		} else if (nextStatus === "CANCELLED") {
			onCancel && onCancel();
		} else if (nextStatus === "COMPLETED" && orderDispatchId) {
			if (onDispatch) {
				onDispatch && onDispatch();
			} else if (orderDispatchId) {
				dispatch(
					updateStatusByorderDispatchId({
						orderDispatchId: orderDispatchId,
						action: "COMPLETED"
					})
				).then(result => {
					if (result.payload.status === 200) {
						CleverTapEvents.trackEvent(
							`Order Status ${nextStatus?.toLowerCase()}`,
							{
								orderId: result.payload?.data?.orderId,
								orderNumber: result.payload?.data?.orderNumber,
								customer: result.payload?.data?.customer,
								status: result.payload?.data?.status
							}
						);
					}
				});
			}
		} else {
			dispatch(updateStatusByOrderId({ orderId: orderId, action: nextStatus }))
				.then(result => {
					if (result.payload.status === 200) {
						CleverTapEvents.trackEvent(
							`Order Status ${nextStatus?.toLowerCase()}`,
							{
								orderId: result.payload?.data?.orderId,
								orderNumber: result.payload?.data?.orderNumber,
								customer: result.payload?.data?.customer,
								status: result.payload?.data?.status
							}
						);
					}
				})
				.finally(() => setOpen(false));
		}
	};

	const handleOpenChange = (newOpen: boolean) => {
		setOpen(newOpen);
	};

	const content = () => (
		<div className='status-popover-content'>
			{orderTracking && status === "Accepted" && isCustomerTab && (
				<div className='status-box in-progress'>
					<button
						onClick={() =>
							orderTracking &&
							!viewCustomerOrders &&
							changeOrderStatus("IN_PROGRESS")
						}
					>
						<ClockIcon /> In Process
					</button>
				</div>
			)}

			{(status === "Accepted" ||
				status === "inProgress" ||
				status === "Partially Dispatched") &&
				isCustomerTab &&
				orderTracking && (
					<>
						<div className='status-box dispatch'>
							<button
								onClick={() =>
									orderTracking &&
									!viewCustomerOrders &&
									changeOrderStatus("DISPATCHED")
								}
							>
								<CheckedRounedOutlineIcon /> Dispatch
							</button>
						</div>
					</>
				)}

			{(status === "Dispatched" || status === "Partially Dispatched") &&
				isMyOrderTab &&
				trackMyOrder && (
					<div className='status-box received'>
						<button onClick={() => changeOrderStatus("COMPLETED")}>
							<CheckedRounedOutlineIcon /> Received
						</button>
					</div>
				)}

			{isMyOrderTab || isCustomerTab
				? (status === "Pending" ||
						status === "Accepted" ||
						status === "Partially Dispatched" ||
						status === "inProgress") &&
					(cancelMyOrder || cancelOrder) && (
						<div className='status-box cancel-order'>
							<button onClick={() => changeOrderStatus("CANCELLED")}>
								<InfoCircleOutlined /> Cancel Order
							</button>
						</div>
					)
				: ((orderTracking && cancelOrder) || cancelOrder) &&
					(status === "Pending" ||
						status === "Accepted" ||
						status === "Partially Dispatched" ||
						status === "inProgress") && (
						<div className='status-box cancel-order'>
							<button onClick={() => changeOrderStatus("CANCELLED")}>
								<InfoCircleOutlined /> Cancel Order
							</button>
						</div>
					)}
		</div>
	);

	return (
		<div className='status-switch-wrapper'>
			{status === "Accepted" ? (
				<div
					className={
						isCustomerTab ? "switch accepted" : "switch accepted outlined"
					}
				>
					{!disabled && (isCustomerTab || (isMyOrderTab && !viewMyOrders)) ? (
						<Popover
							open={
								isCustomerTab &&
								(orderTracking || cancelOrder) &&
								!viewCustomerOrders
									? open
									: false
							}
							onOpenChange={handleOpenChange}
							content={content}
							trigger='click'
							placement='bottomLeft'
							rootClassName='status-popover'
						>
							<button
								className={
									isCustomerTab ? "accepted" : "accepted outlined"
								}
							>
								Accepted
							</button>
						</Popover>
					) : (
						<button
							className={isCustomerTab ? "accepted" : "accepted outlined"}
						>
							Accepted
						</button>
					)}
					{isCustomerTab ? (
						<button
							className='arrow-down-button'
							onClick={() =>
								isCustomerTab && orderTracking && !viewCustomerOrders
									? changeOrderStatus("IN_PROGRESS")
									: []
							}
						>
							{isCustomerTab ? (
								<RightOutlined />
							) : (
								<div className='my-order-accepted'>
									<CheckedRounedOutlineIcon />
								</div>
							)}
						</button>
					) : (
						<></>
					)}
				</div>
			) : (
				<></>
			)}

			{status === "In Progress" || status === "inProgress" ? (
				isCustomerTab ? (
					<div className='switch inProgress'>
						<Popover
							open={
								isCustomerTab &&
								(orderTracking || cancelOrder) &&
								!viewCustomerOrders
									? open
									: false
							}
							onOpenChange={handleOpenChange}
							content={content}
							trigger={!disabled && isCustomerTab ? "click" : "contextMenu"}
							placement='bottomLeft'
							rootClassName='status-popover'
						>
							<button
								className={
									isCustomerTab ? "inProgress" : "inProgress outlined"
								}
							>
								In Process
							</button>
						</Popover>
						<button
							className='arrow-down-button'
							onClick={() =>
								orderTracking &&
								!viewCustomerOrders &&
								!disabled &&
								isCustomerTab
									? changeOrderStatus("DISPATCHED")
									: []
							}
						>
							<TimerIcon />
						</button>
					</div>
				) : (
					<div
						className={`switch in-process-myorder ${orderDetails && "order-inprocess-switch"}`}
					>
						In Process
					</div>
				)
			) : (
				<></>
			)}

			{status === "Dispatched" ? (
				<div
					className={`switch dispatched ${isMyOrderTab ? "dispatched-bg" : ""} ${orderDetails && "order-detail-dispatched"}`}
				>
					{!disabled && isMyOrderTab ? (
						isPartiallyReceived ? (
							<>
								<button
									className='outlined'
									onClick={onPartiallyReceived}
								>
									Dispatched
								</button>
								<button
									className='arrow-down-button'
									onClick={onPartiallyReceived}
								>
									<RightOutlined />
								</button>{" "}
							</>
						) : (
							<>
								<Popover
									open={
										(cancelMyOrder && trackMyOrder) ||
										(isCustomerTab && orderTracking) ||
										(isMyOrderTab && trackMyOrder)
											? open
											: false
									}
									onOpenChange={handleOpenChange}
									content={content}
									trigger={
										!disabled && isMyOrderTab
											? "click"
											: "contextMenu"
									}
									placement='bottomLeft'
									rootClassName='status-popover'
								>
									<button className='outlined'>Dispatched</button>
								</Popover>
								<button
									className='arrow-down-button'
									onClick={
										(cancelMyOrder && trackMyOrder) ||
										(isCustomerTab && orderTracking)
											? () => changeOrderStatus("COMPLETED")
											: e => e.preventDefault()
									}
								>
									<RightOutlined />
								</button>
							</>
						)
					) : (
						<button className='outlined'>Dispatched</button>
					)}
				</div>
			) : (
				<></>
			)}

			{status === "Rejected" ? (
				<div
					className={`switch rejected ${orderDetails && "order-detail-cancelled"}`}
				>
					<button className='rejected outlined'>Rejected</button>
					{/* <button className="arrow-down-button"><InfoCircleOutlined /></button> */}
				</div>
			) : (
				<></>
			)}
			{status === "Completed" ? (
				<div
					className={
						isCustomerTab ? "switch completed outlined" : "switch completed"
					}
				>
					<button className={isCustomerTab ? "completed" : "completed"}>
						{isCustomerTab ? "Delivered" : "Received"}
					</button>
					{/* <button className="arrow-down-button"><CheckedRounedOutlineIcon /></button> */}
				</div>
			) : (
				<></>
			)}

			{status === "Pending" ? (
				<div className='switch pending'>
					<Popover
						open={
							isMyOrderTab &&
							(cancelMyOrder || (cancelMyOrder && !trackMyOrder))
								? open
								: false
						}
						onOpenChange={handleOpenChange}
						content={content}
						trigger={
							!disabled && activeTab !== "Customer-order" && cancelMyOrder
								? "click"
								: "contextMenu"
						}
						placement='bottomLeft'
						rootClassName='status-popover'
					>
						<button className='pending'>Pending</button>
					</Popover>
					<button className='arrow-down-button'>
						<ClockIcon />
					</button>
				</div>
			) : (
				<></>
			)}

			{status === "Partially Dispatched" ? (
				<div className='switch partially-dispatched'>
					{(isPartiallyReceived && isMyOrderTab) ||
					(isMyOrderTab && trackMyOrder) ? (
						<>
							<button className='pending' onClick={onPartiallyReceived}>
								Dispatched
							</button>
							<button
								className='arrow-down-button'
								onClick={onPartiallyReceived}
							>
								<RightOutlined />
							</button>
						</>
					) : (
						<>
							<Popover
								open={
									(isCustomerTab &&
										orderTracking &&
										!viewCustomerOrders) ||
									(isMyOrderTab && trackMyOrder)
										? open
										: false
								}
								onOpenChange={handleOpenChange}
								content={content}
								trigger={!disabled ? "click" : "contextMenu"}
								placement='bottomLeft'
								rootClassName='status-popover'
							>
								<button className='pending'>Dispatched</button>
							</Popover>
							<button
								className='arrow-down-button'
								onClick={
									!(
										isCustomerTab &&
										orderTracking &&
										!viewCustomerOrders
									)
										? e => e.preventDefault()
										: () =>
												orderTracking && !cancelOrder
													? changeOrderStatus("DISPATCHED")
													: onDispatch && onDispatch()
								}
							>
								<RightOutlined />
							</button>
						</>
					)}
				</div>
			) : (
				<></>
			)}

			{status === "Cancelled" ? (
				<div
					className={`switch canceled ${orderDetails && "order-detail-cancelled"}`}
				>
					<button className='canceled outlined'>Cancelled</button>
					{/* <button className="arrow-down-button"><InfoCircleOutlined /></button> */}
				</div>
			) : (
				<></>
			)}
		</div>
	);
};

export default StatusSwitch;
