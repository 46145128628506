import { UserIcon } from "../../assets/svg/icons";
import ImageLoad from "../ImageLoad";

interface Props {
	profileImage: string | null;
	comanyName?: string;
	userName?: string;
}

const UserInfo = ({ profileImage, comanyName, userName }: Props) => {
	return (
		<div className='user-box gap-2 flex alignCenter'>
			<div className='img-box'>
				{profileImage ? (
					<ImageLoad className='profile-img' src={profileImage} alt='profile' />
				) : (
					<UserIcon />
				)}
			</div>
			<div className='customer-info flex direction-column'>
				{comanyName && <span>{comanyName}</span>}
				{userName && <span className='user-name'>{userName}</span>}
			</div>
		</div>
	);
};

export default UserInfo;
